<template>
  <div>
    <div class="mb-6">
      <v-toolbar flat dense class="tool-sty" color="transparent">
        <v-btn :disabled="allbasketsdata && allbasketsdata.length == 10 ? true : false"
          class="elevation-0 rounded-pill font-weight-bold text-none" color="primary" height="40px"
          @click="setSIPdialog(), (setmode = true)">+ Create SIP</v-btn>
        <v-spacer></v-spacer>
        <v-text-field style="max-width: 220px" v-model="opensearch" hide-details prepend-inner-icon="mdi-magnify"
          label="Search" class="rounded-pill" dense flat solo background-color="secbg"></v-text-field>
        <v-icon class="ml-3 cursor-p" @click="getOrderbook()" color="maintext" size="24">mdi-reload</v-icon>
      </v-toolbar>

      <v-data-table @click:row="setviewSIP" must-sort :sort-by="['reg_date']" :sort-desc="[true]" hide-default-footer
        fixed-header :loading="loading" class="mt-3 rounded-lg overflow-y-auto"
        style="border-radius: 4px; border: 1px solid var(--outline)" height="480px" :headers="orderheader"
        :search="opensearch" :items="allbasketsdata" :items-per-page="10">
        <template v-slot:[`item.sip_name`]="{ item }">
          <span class="font-weight-medium maintext--text ws-p">{{ item.sip_name }}</span>
        </template>
        <template v-slot:[`item.reg_date`]="{ item }">
          <span class="font-weight-medium maintext--text ws-p">{{ item.reg_date ? setDate(item.reg_date) : "-" }}</span>
        </template>
        <template v-slot:[`item.internal`]="{ item }">
          <span class="font-weight-bold ws-p" :class="item.internal.active ? 'success--text' : 'warning--text'">{{
            item.internal ? (item.internal.active ? "Active" : "Inactive") : "-" }}</span>
        </template>
        <template v-slot:[`item.frequency`]="{ item }">
          <span class="font-weight-medium maintext--text ws-p">{{ item.frequency ? frequencylist[item.frequency] : "-"
            }}</span>
        </template>
        <template v-slot:[`item.end_period`]="{ item }">
          <span class="font-weight-medium maintext--text ws-p">{{ item.internal ? item.internal.period : "-" }}/{{
            item.end_period ? item.end_period : 0 }}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div @click.stop>
            <v-btn icon small class="text-align-center mr-1 mt-2" @click="setSIPdialog(item), (setmode = false)">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 28 28" fill="none">
                <path
                  d="M5 18.7782C5.00006 18.481 5.11819 18.1959 5.32841 17.9858L17.8214 5.49281C17.9775 5.33658 18.1629 5.21264 18.3669 5.12808C18.571 5.04352 18.7897 5 19.0106 5C19.2315 5 19.4502 5.04352 19.6542 5.12808C19.8583 5.21264 20.0437 5.33658 20.1998 5.49281L23.0972 8.3902C23.2534 8.54634 23.3774 8.73173 23.4619 8.93578C23.5465 9.13983 23.59 9.35854 23.59 9.57942C23.59 9.8003 23.5465 10.019 23.4619 10.2231C23.3774 10.4271 23.2534 10.6125 23.0972 10.7686L10.6042 23.2616C10.3941 23.4718 10.109 23.5899 9.81179 23.59H6.12085C5.82358 23.59 5.53849 23.4719 5.32829 23.2617C5.11809 23.0515 5 22.7664 5 22.4692V18.7782ZM6.12085 18.7782V22.4692H9.81179L22.3047 9.9762C22.3569 9.92414 22.3983 9.8623 22.4266 9.79421C22.4548 9.72613 22.4694 9.65313 22.4694 9.57942C22.4694 9.5057 22.4548 9.43271 22.4266 9.36463C22.3983 9.29654 22.3569 9.2347 22.3047 9.18264L19.4074 6.28525C19.3553 6.23306 19.2935 6.19165 19.2254 6.1634C19.1573 6.13515 19.0843 6.12061 19.0106 6.12061C18.9369 6.12061 18.8639 6.13515 18.7958 6.1634C18.7277 6.19165 18.6659 6.23306 18.6138 6.28525L6.12085 18.7782Z"
                  fill="currentColor" />
                <path
                  d="M16.3721 7.73451L20.8555 12.2179L21.649 11.4243L17.1657 6.94095L16.3721 7.73451ZM6.28448 17.8221L10.7679 22.3055L11.5614 21.512L7.07804 17.0286L6.28448 17.8221Z"
                  fill="currentColor" />
                <path d="M18.6138 9.18265L8.52618 19.2703L9.31974 20.0638L19.4074 9.97621L18.6138 9.18265Z"
                  fill="currentColor" />
              </svg>
            </v-btn>
            <v-btn icon small class="text-align-center mt-2" @click="(singledata = item), (canceldialog = true)">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="24" height="24">
                <path fill="currentColor" fill-rule="evenodd"
                  d="M11.5 6a.5.5 0 0 0-.5.5V8h6V6.5a.5.5 0 0 0-.5-.5h-5zM18 8V6.5c0-.83-.67-1.5-1.5-1.5h-5c-.83 0-1.5.67-1.5 1.5V8H5.5a.5.5 0 0 0 0 1H7v12.5A2.5 2.5 0 0 0 9.5 24h9a2.5 2.5 0 0 0 2.5-2.5V9h1.5a.5.5 0 0 0 0-1H18zm2 1H8v12.5c0 .83.67 1.5 1.5 1.5h9c.83 0 1.5-.67 1.5-1.5V9zm-8.5 3c.28 0 .5.22.5.5v7a.5.5 0 0 1-1 0v-7c0-.28.22-.5.5-.5zm5.5.5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z">
                </path>
              </svg>
            </v-btn>
          </div>
        </template>
        <template v-slot:no-data>
          <v-col cols="12" class="text-center pa-16">
            <div class="mx-auto">
              <img class="align-self-stretch mx-auto" width="80px" :src="require('@/assets/no data folder.svg')"
                alt="no data" />
              <h5 class="txt-999 font-weight-regular">There is no data here yet!</h5>
            </div>
          </v-col>
        </template>
      </v-data-table>
    </div>
    <v-dialog v-model="basketdialog" hide-overlay width="680px">
      <v-card class="pb-6 px-6 overflow-hidden rounded-lg" color="cardbg">
        <v-list-item-title class="font-weight-bold title maintext--text mt-4 mb-1">{{ setmode ? "Create" : "Edit" }} SIP
          order <v-icon @click="(basketdialog = false), getOrderbook()" class="float-right" color="maintext"
            :disabled="orderloader">mdi-close</v-icon></v-list-item-title>
        <v-divider class="mb-2"></v-divider>
        <v-row class="pt-2 pb-4">
          <v-col cols="3">
            <p class="font-weight-regular fs-14 subtext--text mb-2">SIP name</p>
            <v-text-field placeholder="SIP order" v-on:keypress="isLetter($event)" hide-details height="40px" dense
              background-color="secbg" flat class="rounded-pill mb-0" type="text" solo v-model="sip_name">
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <p class="font-weight-regular fs-14 subtext--text mb-2">Start date</p>
            <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
              offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field placeholder="YYYY-MM-DD" hide-details height="40px" dense background-color="secbg" flat
                  class="rounded-pill mb-0" hide-spin-buttons solo v-model="sipdate" append-icon="mdi-calendar" readonly
                  v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker no-title :min="maxdate" v-model="sipdate" @input="menu2 = false"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="3">
            <p class="font-weight-regular fs-14 subtext--text mb-2">Frequency</p>
            <v-select placeholder="Daily, Weekly, Monthly" hide-details v-model="frequency" :items="frequencylist"
              height="40px" append-icon="mdi-chevron-down" dense background-color="secbg" flat block
              class="rounded-pill" solo> </v-select>
          </v-col>
          <v-col cols="3">
            <p class="font-weight-regular fs-14 subtext--text mb-2">No.of SIPs</p>
            <v-text-field placeholder="4" hide-details height="40px" dense background-color="secbg" flat
              class="rounded-pill mb-0" type="number" hide-spin-buttons solo v-model="sipinstall"> </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-select @change="setSearchFilter()" hide-details v-model="searchexch" :items="['NSE', 'BSE']"
              height="40px" append-icon="mdi-chevron-down" dense background-color="secbg" flat block
              class="rounded-pill" solo>
            </v-select>
          </v-col>
          <v-col cols="10" class="ml-auto">
            <v-autocomplete :disabled="model && model.set" @change="setListinbsk()" :loading="searchloading"
              item-text="tsym" return-object class="rounded-pill" flat solo background-color="secbg" dense
              v-model="model" :search-input.sync="search" hide-details label="Search script" :items="sfilterdata"
              prepend-inner-icon="mdi-magnify" append-icon="" no-filter oninput="this.value = this.value.toUpperCase()"
              clearable>
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-data-table must-sort :sort-by="['idx']" :sort-desc="[true]" hide-default-footer fixed-header
          :loading="loading" class="mt-0 rounded-lg overflow-y-auto" style="border: 1px solid var(--outline)"
          height="240px" :headers="singlebskheader" :items="orderbookdata" :items-per-page="20">
          <template v-slot:[`item.tsym`]="{ item }">
            <p class="font-weight-medium maintext--text mb-0 ws-p">
              {{ item.tsym ? item.tsym : "" }}
              <span class="ml-1 txt-999 fs-10">{{ item.exch ? item.exch : "" }}</span>
            </p>
          </template>
          <template v-slot:[`item.qty`]="{ item }">
            <v-text-field height="30px" v-if="item.invby == 'Qty'" hide-details dense class="rounded-lg" type="number"
              hide-spin-buttons outlined v-model="item.qty"> </v-text-field>
            <span v-else>{{ "--" }}</span>
          </template>
          <template v-slot:[`item.prc`]="{ item }">
            <v-text-field v-if="item.invby == 'Amount'" hide-details dense class="rounded-lg" type="number"
              hide-spin-buttons outlined v-model="item.prc"> </v-text-field>
            <span v-else>{{ "--" }}</span>
          </template>
          <template v-slot:[`item.invby`]="{ item }">
            <v-select @change="setInvestby(item)" hide-details v-model="item.invby" :items="['Qty', 'Amount']" dense
              append-icon="mdi-chevron-down" outlined class="rounded-lg"> </v-select>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div @click.stop>
              <v-btn icon small class="text-align-center mt-2" @click="setListcancel(item, 'pop')">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="24" height="24">
                  <path fill="currentColor" fill-rule="evenodd"
                    d="M11.5 6a.5.5 0 0 0-.5.5V8h6V6.5a.5.5 0 0 0-.5-.5h-5zM18 8V6.5c0-.83-.67-1.5-1.5-1.5h-5c-.83 0-1.5.67-1.5 1.5V8H5.5a.5.5 0 0 0 0 1H7v12.5A2.5 2.5 0 0 0 9.5 24h9a2.5 2.5 0 0 0 2.5-2.5V9h1.5a.5.5 0 0 0 0-1H18zm2 1H8v12.5c0 .83.67 1.5 1.5 1.5h9c.83 0 1.5-.67 1.5-1.5V9zm-8.5 3c.28 0 .5.22.5.5v7a.5.5 0 0 1-1 0v-7c0-.28.22-.5.5-.5zm5.5.5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z">
                  </path>
                </svg>
              </v-btn>
            </div>
          </template>
          <template v-slot:no-data>
            <v-col cols="12" class="text-center pa-16">
              <div class="mx-auto">
                <img class="align-self-stretch mx-auto" width="80px" :src="require('@/assets/no data folder.svg')"
                  alt="no data" />
                <h5 class="txt-999 font-weight-regular">There is no data here yet!</h5>
              </div>
            </v-col>
          </template>
          <!-- <template v-slot:footer>
            <v-divider></v-divider>
          
          </template> -->
        </v-data-table>
        <v-row class="pt-3">
          <v-col cols="9">
            <p class="fs-13 mb-0">
              Your SIP will be placed at 9:30 on the due date, <br />
              if the due date is a market holiday, order will be placed on the next trading day.
            </p>
          </v-col>
          <v-col cols="3" class="text-right">
            <v-btn :disabled="!sip_name || !frequency || !sipdate || !sipinstall || orderbookdata.length == 0"
              :loading="orderloader" color="btnclr" class="elevation-0 text-capitalize rounded-pill btntext--text"
              @click="setPlaceorder()">{{ setmode ? "Create" : "Modify" }} </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="canceldialog" max-width="400">
      <v-card class="rounded-xl elevation-0 text-center pt-8 pb-6 overflow-hidden">
        <img src="@/assets/orderbook/cancel-icon.svg" alt="cancel icon" />
        <p class="font-weight-medium mt-3 fs-22 lh-24 mb-8">
          Are you sure you want to <br />
          delete this <b>{{ singledata ? singledata.sip_name : "" }}</b> SIP order?
        </p>
        <v-row class="px-6" no-gluttters>
          <v-col cols="6">
            <v-btn @click="(canceldialog = false), (singledata = [])" color="outline"
              class="rounded-pill text-none subtext--text font-weight-bold elevation-0" block height="40px">No</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn @click="(canceldialog = false), setcancelBSK(singledata)" color="btnclr"
              class="rounded-pill text-none btntext--text font-weight-bold elevation-0" block height="40px">Yes</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getSiporderbook, getSIPOrderset, getKambalaSearch } from "../../components/mixins/getAPIdata.js";

import eventBus from "../../eventBus.js";
import moment from "moment";

export default {
  data: () => ({
    uid: null,
    mtoken: null,
    stoken: null,

    setmode: true,
    canceldialog: false,
    basketdialog: false,
    singledata: [],
    loading: false,

    orderloader: false,

    allbasketsdata: [],

    searchexch: "NSE",
    maxdate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    sipdate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    sip_name: null,
    frequencylist: ["Daily", "Weekly", "Fortnightly", "Monthly"],
    frequency: null,
    sipinstall: null,

    menu2: false,

    opensearch: null,
    orderbookdata: [],

    searchloading: false,
    items: [],
    sfilterdata: [],
    model: null,
    search: null,
    nodata: null,
  }),
  watch: {
    async search(val) {
      if (val && val.length > 2) {
        this.searchloading = true;
        let res = await getKambalaSearch(`jData={"uid":"${this.uid}","stext":"${val}"}&jKey=${this.mtoken}`);
        this.nodata = null;
        if (res.stat == "Ok" || res.values) {
          var data = res.values;
          for (let c = 0; c < this.orderbookdata.length; c++) {
            let Index = data.findIndex((script) => script.token == this.orderbookdata[c].token);
            if (Index >= 0) {
              data.splice(Index, 1);
            }
          }
          this.items = data;
        } else {
          this.items = [];
          this.nodata = "noooo";
          this.sfilterdata = [];
        }
        this.searchloading = false;
        this.setSearchFilter();
      }
    },
  },
  computed: {
    orderheader() {
      return [
        { text: "SIP Name", sortable: false, value: "sip_name" },
        { text: "Registered on", value: "reg_date" },
        { text: "Status", value: "internal" },
        { text: "Frequency", value: "frequency", sortable: false },
        { text: "Instalments", value: "end_period", sortable: false },
        { text: "", value: "actions", sortable: false },
      ];
    },
    singlebskheader() {
      return [
        // { text: "Exch", align: "start", sortable: false, value: "exch" },
        { text: "Instrument", value: "tsym" },
        { text: "Invest by", value: "invby", width: "24%", sortable: false },
        { text: "Amount", value: "prc", width: "20%", sortable: false },
        { text: "Qty", value: "qty", width: "20%", sortable: false },
        { text: "", value: "actions", sortable: false },
      ];
    },
  },
  mounted() {
    let res = sessionStorage.getItem("c3RhdHVz");
    if (res == "dmFsaWR1c2Vy") {
      this.mtoken = sessionStorage.getItem("msession");
      this.uid = sessionStorage.getItem("userid");
      this.getOrderbook();
    } else {
      this.$router.push("/");
    }

    eventBus.$on("orderbook-update", (book) => {
      if (book == "bsk") {
        this.getOrderbook();
      }
    });

    eventBus.$on("siporder-trigger", (item, type) => {
      if (type && item) {
        this.setSIPdialog();
        this.sip_name = item.tsym;
        this.frequency = "Daily";
        this.sipinstall = 5;
        this.sipdate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 2).toISOString().slice(0, 10);
        this.orderbookdata.push({ exch: item.exch, invby: "Qty", prd: "C", qty: "1", token: item.token, tsym: item.tsym });
      }
    });
  },
  beforeDestroy() {
    eventBus.$off("orderbook-update");
  },
  methods: {
    setDate(date) {
      var d = new Date(`${date.slice(2, 4)}/${date.slice(0, 2)}/${date.slice(4)}`).toLocaleDateString("en-US", { year: "numeric", month: "short", day: "numeric" });
      return d;
    },
    setSIPdialog(item) {
      if (item) {
        this.sip_name = item.sip_name;
        this.frequency = this.frequencylist[item.frequency];
        this.sipdate = `${item.start_date.slice(4)}-${item.start_date.slice(2, 4)}-${item.start_date.slice(0, 2)}`;
        this.sipinstall = item.end_period;
        this.singledata = item;
        if (item.Scrips && item.Scrips.length > 0) {
          for (let e = 0; e < item.Scrips.length; e++) {
            item.Scrips[e]["invby"] = item.Scrips[e].qty && item.Scrips[e].qty > 0 ? "Qty" : "Amount";
          }
          this.orderbookdata = item.Scrips;
        } else {
          this.orderbookdata = [];
        }
      } else {
        this.sip_name = null;
        this.frequency = null;
        this.sipdate = null;
        this.sipinstall = null;
        this.singledata = [];
        this.orderbookdata = [];
      }
      this.basketdialog = true;
    },
    setSearchFilter() {
      if (this.items && this.items.length > 0) {
        this.sfilterdata = this.items.filter((i) => this.searchexch == i.exch);
      } else {
        this.sfilterdata = [];
      }
    },
    setListinbsk() {
      this.orderbookdata.push({ exch: this.model.exch, invby: "Qty", prd: "C", qty: "1", token: this.model.token, tsym: this.model.tsym });
      this.model = [];
    },
    setInvestby(item) {
      var ind = this.orderbookdata.findIndex((o) => o.token == item.token);
      if (ind >= 0) {
        if (item.invby == "Qty") {
          this.orderbookdata[ind]["qty"] = 1;
          delete this.orderbookdata[ind]["prc"];
        } else {
          this.orderbookdata[ind]["prc"] = 1000;
          delete this.orderbookdata[ind]["qty"];
        }
        this.$set(this.orderbookdata, ind, this.orderbookdata[ind]);
      }
    },
    async getOrderbook() {
      let data = `jData={"uid":"${this.uid}","actid":"${this.uid}"}&jKey=${this.mtoken}`;
      let config = await getSiporderbook(data);

      if (config.SipDetails && config.SipDetails.length > 0) {
        this.allbasketsdata = config.SipDetails;
      } else {
        this.allbasketsdata = [];
        eventBus.$emit("snack-event", 2, config.emsg ? config.emsg : config);
      }
    },
    async setcancelBSK(i) {
      var item = {
        SipId: i.internal.SipId,
      };
      let odata = await getSIPOrderset(item, "CancelSipOrder");
      if (odata.ReqStatus == "OK") {
        eventBus.$emit("snack-event", 1, "SIP Order have been Cancelled");
        this.getOrderbook();
      } else {
        eventBus.$emit("snack-event", 2, odata.emsg ? odata.emsg : odata);
      }
      this.canceldialog = false;
    },
    setListcancel(item, type) {
      if (type == "pop") {
        var did = this.orderbookdata.findIndex((o) => o.token == item.token);
        this.orderbookdata.splice(did, 1);
      } else {
        this.singledata = item;
        this.canceldialog = true;
      }
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z]+$/.test(char) || char == " ") return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    setSSDtab(type, token, exch, tsym) {
      if (type == "alert") {
        eventBus.$emit("menudialog", "alert", token, exch, tsym);
      } else if (type == "cGTT") {
        this.positiondrawer = false;
        eventBus.$emit("menudialog", "order-GTT", token, exch, tsym, "b");
      } else {
        let path = [type, token, exch, tsym];
        this.$router.push({ name: "stocks details", params: { val: path } });
      }
    },
    async setPlaceorder() {
      var ind = this.allbasketsdata.findIndex((o) => o.sip_name == this.sip_name);

      if ((!this.setmode || (this.setmode && ind == -1)) && this.sipinstall > 0) {
        let date = new Date();
        let formattedDate = moment(date).format("DDMMYYYY");
        let item = {};
        if (this.setmode) {
          // jData={"uid":"ZSK162","reg_date":"19062024","start_date":"20062024","actid":"ZSK162","frequency":"1","end_period":"2","sip_name":"ERVCKUJ","Scrips":[{"exch":"NSE","tsym":"RELIANCE-EQ","prd":"C","token":"2885","qty":"1"}]}&jKey=94240079bbab096e66251dcc745c673e034015028fa109ccefa02d963c634ec9
          item = {
            uid: this.uid,
            actid: this.uid,
            reg_date: formattedDate,
            start_date: moment(this.sipdate).format("DDMMYYYY"),
            frequency: this.frequency == "Daily" ? "0" : this.frequency == "Weekly" ? "1" : this.frequency == "Fortnightly" ? "2" : "3",
            end_period: this.sipinstall.toString(),
            sip_name: this.sip_name,
            Scrips: this.orderbookdata,
          };
        } else {
          // jData={"reg_date":"18062024","frequency":"0","end_period":"4","sip_name":"ABC","internal":{"PrevExecDate":"19062024","DueDate":"20062024","ExecDate":"20062024","period":"1","active":"true","SipId":"24061800000001"},"Scrips":[{"exch":"NSE","tsym":"TCS-EQ","prd":"C","token":"11536","qty":"200"}, {"exch":"NSE","tsym":"RELIANCE-EQ","prd":"C","token":"2885","prc":"30000"}]}&jKey=94240079bbab096e66251dcc745c673e034015028fa109ccefa02d963c634ec9
          item = {
            uid: this.uid,
            actid: this.uid,
            reg_date: this.singledata.reg_date,
            start_date: moment(this.sipdate).format("DDMMYYYY"),
            frequency: this.frequency == "Daily" ? "0" : this.frequency == "Weekly" ? "1" : this.frequency == "Fortnightly" ? "2" : "3",
            end_period: this.sipinstall.toString(),
            sip_name: this.sip_name,
            internal: this.singledata.internal,
            Scrips: this.orderbookdata,
          };
        }

        let odata = await getSIPOrderset(item, this.setmode ? "PlaceSipOrder" : "ModifySipOrder");
        if (odata.ReqStatus == "OK") {
          eventBus.$emit("snack-event", 1, this.setmode ? "SIP Order have been Placed" : "SIP Order have been Modified");
          this.getOrderbook();
        } else {
          eventBus.$emit("snack-event", 2, odata.emsg ? odata.emsg : odata);
        }
        this.basketdialog = false;
        this.orderloader = false;
      } else {
        eventBus.$emit("snack-event", 2, !(this.sipinstall > 0) ? "No.of SPIs not be zero" : "SIP name is already exists");
      }
    },
    setviewSIP(item) {
      this.setSIPdialog(item);
      this.setmode = false;
    }
  },
};
</script>
