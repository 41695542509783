<template>
  <div>
    <div style="position: sticky !important; top: 80px; z-index: 1">
      <v-toolbar class="tool-sty elevation-0 pl-4 d-none d-sm-block" height="40px" color="cardbg" dense>
        <v-tabs color="maintext" v-model="bodytab" show-arrows dense>
          <template v-for="(t, index) in dashoptions">
            <v-tab :key="index" v-if="t.tab" class="text-none text-start min-w-fit">
              <span class="text-center tab-txt">{{ t.txt }}</span>
            </v-tab>
          </template>
          <v-tabs-slider class="" color="maintext"></v-tabs-slider>
        </v-tabs>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-toolbar class="tool-sty elevation-0 d-sm-none" height="40px" color="cardbg" dense>
        <v-tabs fixed-tabs color="maintext" v-model="mbodytab" show-arrows dense>
          <template v-for="(t, index) in Mdashitems">
            <v-tab :key="index" v-if="t.tab" class="text-none text-start">
              <span class="text-center tab-txt">{{ t.txt }}</span>
            </v-tab>
          </template>
          <v-tabs-slider color="maintext"></v-tabs-slider>
        </v-tabs>
      </v-toolbar>
      <v-divider></v-divider>
    </div>
    <v-tabs-items v-model="bodytab" class="pt-4 d-none d-sm-block">
      <v-tab-item>
        <StocksOrderBook />
      </v-tab-item>
      <v-tab-item>
        <StocksTradeBook />
      </v-tab-item>
      <v-tab-item>
        <StockGTTorders />
      </v-tab-item>
      <v-tab-item>
        <StockBSKorders />
      </v-tab-item>
      <v-tab-item>
        <StockSIPorders />
      </v-tab-item>

      <v-tab-item>
        <MutualOrderbook />
      </v-tab-item>
      <v-tab-item>
        <IpoOrderbook />
      </v-tab-item>
      <v-tab-item>
        <BondsOrderbook />
      </v-tab-item>
    </v-tabs-items>
    <v-tabs-items v-model="mbodytab" class="pt-4 d-sm-none">
      <v-tab-item>
        <MutualOrderbook />
      </v-tab-item>
      <v-tab-item>
        <IpoOrderbook />
      </v-tab-item>
      <v-tab-item>
        <BondsOrderbook />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import MutualOrderbook from "../Dashboard/mutualfund/MutualOrderbook.vue";
import IpoOrderbook from "../Dashboard/ipos/IpoOrderbook.vue";
import BondsOrderbook from "../Dashboard/bonds/BondsOrderbook.vue";

import StocksOrderBook from './StocksOrderBook.vue';
import StockGTTorders from './StockGTTorders.vue';
import StocksTradeBook from './StocksTradeBook.vue';
import StockBSKorders from './StockBSKorders.vue';
import StockSIPorders from './StockSIPorders.vue';


import eventBus from "../../eventBus.js";
export default {
  data: () => ({
    bodytab: 0,
    mbodytab: 0,
    Mdashitems: [
      // {tab: true, txt: "Stocks"},
      { tab: true, txt: "Mutual funds" },
      { tab: true, txt: "IPOs" },
      { tab: true, txt: "Bonds" },
    ],

    dashoptions: [
      { tab: true, key: 0, txt: "Orders Book" },
      { tab: true, key: 1, txt: "Trade Book" },
      { tab: true, key: 2, txt: "GTT Order" },
      { tab: true, key: 3, txt: "Basket Order" },
      { tab: true, key: 4, txt: "SIP Order" },
      { tab: true, key: 5, txt: "Mutual funds" },
      { tab: true, key: 6, txt: "IPOs" },
      { tab: true, key: 7, txt: "Bonds" },
      // { tab: true, key: 5, txt: 'XSIP' },
    ],

    uid: null,
    stoken: null,
    mtoken: null,

    switchbus: null,
  }),
  created() {
    let params = this.$route.params;
    if (params && params.tsym) {
      this.bodytab = 4;
      
      setTimeout(() => {
        eventBus.$emit("siporder-trigger", params);
      }, 10);
    }
    this.switchbus = eventBus;
  },
  computed: {},
  mounted() {
    this.$emit("receive", "stk");
    eventBus.$emit("login-event");

    eventBus.$on("user-event", () => {
      let res = sessionStorage.getItem("c3RhdHVz");
      if (res == "dmFsaWR1c2Vy") {
        this.mtoken = sessionStorage.getItem("msession");
        this.stoken = sessionStorage.getItem("usession");
        this.uid = sessionStorage.getItem("userid");
      } else {
        this.$router.push("/");
      }
    });

    eventBus.$on("siporder-trigger", (item, type) => {
      this.bodytab = 4;
      if (!type) {
        setTimeout(() => {
          eventBus.$emit("siporder-trigger", item, true);
        }, 10);
      }
    });
    this.setWebsocket('unsub', [])
  },
  beforeDestroy() {
    eventBus.$off("user-event");
    eventBus.$off("siporder-trigger");
  },

  methods: {
    setWebsocket(flow, data) {
      eventBus.$emit("web-scoketOn", flow, data, 'is', "ord");
    },
  },
  components: {
    MutualOrderbook, IpoOrderbook, BondsOrderbook,

    StocksOrderBook, StockGTTorders, StocksTradeBook, StockBSKorders, StockSIPorders
  },
};
</script>
