<template>
  <div>
    <div class="mb-10">
      <v-toolbar flat dense class="tool-sty pl-4 d-none d-md-block crd-trn">
        <v-tabs fixed color="primary" @change="ordertab == 1 ? (ordertype = 'exec') : (ordertype = 'open')" v-model="ordertab">
          <v-tab :disabled="tableloader" class="font-weight-bold subtitle-1 mb-0 text-none"
            >Open Orders (<span>{{ openorders ? openorders.length : "0" }}</span
            >)
          </v-tab>
          <v-tab :disabled="tableloader" class="font-weight-bold subtitle-1 mb-0 text-none"
            >Close Orders (<span>{{ execorders ? execorders.length : "0" }}</span
            >)
          </v-tab>
          <v-tabs-slider color="primary"></v-tabs-slider>
        </v-tabs>
        <v-spacer></v-spacer>
        <v-text-field style="max-width: 320px" v-model="opensearch" hide-details :disabled="tableloader" prepend-inner-icon="mdi-magnify" label="Search ipos" class="rounded-pill" dense flat solo background-color="secbg"></v-text-field>

        <!-- <v-select style="max-width:180px;" v-model="filter" hide-details :disabled="tableloader"
                    :append-icon="filter ? '' : 'mdi-chevron-down'" class="rounded-pill mr-4" dense flat solo
                    background-color="secbg" item-text="series" clearable item-value="series" :items="orderbookdata"
                    label="Ipo series"></v-select> -->
          <v-icon class="ml-3 cursor-p" @click="getOrderbook()" color="maintext" size="24">mdi-reload</v-icon>

      </v-toolbar>
      <v-tabs class="d-md-none" fixed-tabs color="primary" @change="ordertab == 1 ? (ordertype = 'exec') : (ordertype = 'open')" v-model="ordertab">
        <v-tab :disabled="tableloader" class="font-weight-bold subtitle-1 mb-0 text-none"
          >Open Orders (<span>{{ openorders ? openorders.length : "0" }}</span
          >)
        </v-tab>
        <v-tab :disabled="tableloader" class="font-weight-bold subtitle-1 mb-0 text-none"
          >Close Orders (<span>{{ execorders ? execorders.length : "0" }}</span
          >)
        </v-tab>
        <v-tabs-slider color="primary"></v-tabs-slider>
      </v-tabs>
      <v-data-table
        must-sort
        :sort-by="['response_datetime']"
        :sort-desc="[true]"
        hide-default-footer
        fixed-header
        :loading="tableloader"
        class="mt-3 rounded-lg overflow-y-auto d-none d-md-block"
        style="border-radius: 4px; border: 1px solid var(--outline)"
        height="480px"
        :headers="orderheader"
        :search="opensearch"
        :items="orderbookdata"
        :items-per-page="orderbookdata.length"
      >
        <template v-slot:body="{items, headers}">
          <tbody v-if="ordertab == 1 ? execorders && execorders.length > 0 : openorders && openorders.length > 0">
            <tr @click="setOrderrowdata(item)" class="table-row" v-for="(item, o) in items" :key="o">
              <td class="pos-rlt">
                <span class="table-hov-text maintext--text font-weight-medium">{{ item.symbol ? item.symbol : "-" }}</span> <span class="ml-1 subtext--text fs-10">{{ item.series ? item.series : "" }}</span>
                <div @click.stop class="pos-abs table-hov" style="top: 15px; right: 0">
                  <v-menu close-on-click absolute offset-y class="table-menu">
                    <template v-slot:activator="{on, attrs}">
                      <v-btn v-bind="attrs" v-on="on" style="border: 1px solid var(--outline)" min-width="20px" color="mainbg" class="px-0 font-weight-bold white--text elevation-0 mr-1" x-small>
                        <v-icon size="20" color="maintext">mdi-dots-horizontal</v-icon>
                      </v-btn>
                    </template>
                    <v-card class="table-menu-list">
                      <v-list dense>
                        <div v-for="(m, k) in ordertab == 0 ? menulist.open : menulist.exec" :key="k">
                          <v-list-item @click="m.type == 'new' ? $router.push('/ipo') : m.type == 'md' ? setModifyorder(item) : m.type == 'cd' ? setOrdercancel(item) : setOrderrowdata(item)" class="pl-3 pr-6">
                            <v-list-item-icon class="mr-3 text-center">
                              <img v-if="m.icon > 2" width="20px" class="pl-1" :src="require(`@/assets/orderbook/${m.icon}.svg`)" />
                              <v-icon v-else color="#506D84">{{ m.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title class="subline--text font-weight-medium fs-14">{{ m.name }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider v-if="m.hr" class="mx-3"></v-divider>
                        </div>
                      </v-list>
                    </v-card>
                  </v-menu>
                </div>
              </td>

              <td>
                <v-chip small class="table-hov-prd rounded-md" label text-color="subtext">
                  <span class="font-weight-medium fs-12">{{ item.applicationNumber }}</span>
                </v-chip>
              </td>

              <td>
                <span class="font-weight-medium maintext--text">{{ item.response_datetime ? item.response_datetime.slice(0, 16) : "-" }}</span>
              </td>
              <td>
                <v-chip small class="table-hov-prd rounded-md" label text-color="subtext">
                  <span class="font-weight-medium fs-12">{{ item.quantity }}</span>
                </v-chip>
              </td>
              <td class="text-right font-weight-medium">
                <p class="font-weight-medium maintext--text mb-0">{{ item.bid_detail[0] ? `₹${item.amount.toLocaleString()}` : "" }}</p>
              </td>
              <td>
                <p class="font-weight-regular maintext--text mb-0">
                  {{ item.fail_reason ? item.fail_reason : "Order placed successfully" }}
                </p>
              </td>
              <td>
                <p class="font-weight-medium maintext--text mb-0 text-capitalize">
                  <img width="20px" class="mb-n02" v-if="item.appstatus == 'Success'" src="@/assets/success.svg" />
                  <img width="20px" class="mb-n02" v-else-if="item.appstatus == 'Pending'" src="@/assets/warning.svg" />
                  <img width="20px" class="mb-n02" v-else src="@/assets/error.svg" />

                  {{ item.appstatus ? item.appstatus : "" }}
                </p>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <td :colspan="headers.length" class="text-center">
              <div class="mx-auto py-16 mt-16">
                <img class="mx-auto" width="80px" :src="require('@/assets/no data folder.svg')" />
                <h4 class="txt-999 font-weight-regular caption">
                  There is no {{ ordertab == 1 ? "Open" : "Close" }} order <br />
                  data here yet!
                </h4>
              </div>
            </td>
          </tbody>
        </template>
      </v-data-table>
      <div class="d-md-none mb-16">
        <v-divider></v-divider>
        <v-progress-linear v-if="tableloader" indeterminate></v-progress-linear>
        <div v-if="orderbookdata && orderbookdata.length > 0">
          <v-card v-for="(item, o) in orderbookdata.length < showtable ? orderbookdata.slice(0, showtable) : orderbookdata" :key="o" class="elevation-0 rounded-0 overflow-hidden crd-trn">
            <div v-if="ordertype == item.way">
              <div class="px-0" @click="setOrderrowdata(item)">
                <v-list-item class="pa-0">
                  <v-list-item-content class="pa-0 pt-1">
                    <v-list-item-title class="fs-13 font-weight-bold mb-0 maintext--text table-hov-text mt-2">
                      {{ item.symbol ? item.symbol : "" }}
                    </v-list-item-title>
                    <v-chip-group column class="mb-0">
                      <v-chip class="px-0 crd-trn" text-color="maintext">
                        <p class="font-weight-medium maintext--text mb-0 text-capitalize">
                          <img width="20px" class="mb-n02" v-if="item.appstatus == 'Success'" src="@/assets/success.svg" />
                          <img width="20px" class="mb-n02" v-else-if="item.appstatus == 'Pending'" src="@/assets/warning.svg" />
                          <img width="20px" class="mb-n02" v-else src="@/assets/error.svg" />

                          {{ item.appstatus ? item.appstatus : "" }}
                        </p>
                      </v-chip>
                      <v-chip class="pr-0 crd-trn" text-color="maintext">
                        <span class="caption">BID Qty: </span> <b> {{ item.quantity }}</b>
                      </v-chip>
                    </v-chip-group>
                  </v-list-item-content>
                  <div @click.stop>
                    <v-icon size="20" color="maintext" class="float-right">mdi-chevron-right</v-icon>

                    <v-menu close-on-click absolute offset-y>
                      <template v-slot:activator="{on, attrs}">
                        <v-btn v-bind="attrs" v-on="on" style="border: 1px solid var(--outline)" min-width="20px" color="cardbg" class="px-0 font-weight-bold white--text elevation-0 float-right" x-small>
                          <v-icon size="20" color="maintext">mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <v-card class="table-menu-list">
                        <v-list dense>
                          <div v-for="(m, k) in ordertab == 0 ? menulist.open : menulist.exec" :key="k">
                            <v-list-item @click="m.type == 'new' ? $router.push('/ipo') : m.type == 'md' ? setModifyorder(item) : m.type == 'cd' ? setOrdercancel(item) : setOrderrowdata(item)" class="pl-3 pr-6">
                              <v-list-item-icon class="mr-3 text-center">
                                <img v-if="m.icon > 2" width="20px" class="pl-1" :src="require(`@/assets/orderbook/${m.icon}.svg`)" />
                                <v-icon v-else color="#506D84">{{ m.icon }}</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title class="subline--text font-weight-medium fs-14">{{ m.name }}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider v-if="m.hr" class="mx-3"></v-divider>
                          </div>
                        </v-list>
                      </v-card>
                    </v-menu>
                  </div>
                </v-list-item>
                <v-divider></v-divider>

                <v-row class="py-2" no-glutters>
                  <v-col cols="6">
                    <p class="mb-1 fs-12 font-weight-bold maintext--text">{{ item.bid_detail[0] ? `₹${item.amount.toLocaleString()}` : "" }}</p>
                    <p class="mb-1 fs-12 subtext--text">Invested amount</p>
                  </v-col>
                  <v-col cols="6" class="text-right">
                    <p class="mb-1 fs-12 font-weight-bold maintext--text">{{ item.response_datetime ? item.response_datetime.slice(0, 16) : "-" }}</p>
                    <p class="mb-1 fs-12 subtext--text">Bid Date & time</p>
                  </v-col>
                </v-row>
              </div>
              <v-card v-if="uid && showtable - 1 != o" class="pt-1 rounded-0 elevation-0" color="secbg"></v-card>
            </div>
          </v-card>
        </div>
        <v-card v-else class="elevation-0 rounded-0 text-center">
          <div class="mx-auto py-8">
            <img class="align-self-stretch mx-auto" width="70px" :src="require('@/assets/no data folder.svg')" />
            <h4 class="txt-999 font-weight-regular fs-14">There is no data here yet!</h4>
          </div>
        </v-card>
        <v-btn v-if="showtable < orderbookdata.length" color="primary" plain class="elevation-0 text-none font-weight-bold py-4 mb-1" @click="showtable += 24" block>show more</v-btn>
      </div>
    </div>
    <v-navigation-drawer hide-overlay color="cardbg" fixed temporary app class="pt-2" v-model="orderdrawer" right width="360px">
      <template v-slot:prepend>
        <v-toolbar class="nav-drawer" dense>
          <v-icon @click="orderdrawer = false" color="subtext" size="20">mdi-close</v-icon>
          <p class="maintext--text font-weight-bold mb-0 ml-2">Order Details</p>
          <v-spacer></v-spacer>
          <!-- <v-icon color="subtext" size="20">mdi-headphones</v-icon>
                    <p class="subtext--text font-weight-medium mb-0 ml-2">Need Help ?</p> -->
        </v-toolbar>
      </template>

      <v-list-item class="pt-3">
        <v-list-item-content>
          <v-list-item-title class="font-weight-medium maintext--text">{{ singledata.company_name ? singledata.company_name : "" }}</v-list-item-title>
          <v-list-item-subtitle class="font-weight-medium subtext--text fs-12">{{ singledata.symbol ? singledata.symbol : "" }}</v-list-item-subtitle>
          <!-- <v-list-item-title
                        :class="singledata.rpnl > 0 ? 'maingreen--text'  : singledata.rpnl < 0 ? 'mainred--text'  : 'subtext--text'"
                        class="font-weight-medium fs-12">{{ singledata.rpnl ? singledata.rpnl : '0.00' }} ({{
                            singledata.pnlc ?
                            singledata.pnlc : '0.00' }}%)</v-list-item-title> -->
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle class="subtext--text font-weight-medium fs-13 mb-1">Order</v-list-item-subtitle>
          <v-list-item-title class="maintext--text font-weight-bold fs-14"
            >Entry Success
            <p class="float-right mb-0">
              <img width="20px" class="mb-n02" src="@/assets/success.svg" />
              <span> Success</span>
            </p></v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle class="subtext--text font-weight-medium fs-13 mb-1">Payment</v-list-item-subtitle>
          <v-list-item-title class="maintext--text font-weight-bold fs-14"
            >{{ singledata.verificationStatus == "S" ? "Verified" : singledata.verificationStatus == "P" ? "Pending" : "Unknown" }}
            <p class="float-right mb-0">
              <img width="20px" class="mb-n02" v-if="singledata.verificationStatus == 'S'" src="@/assets/success.svg" />
              <img width="20px" class="mb-n02" v-else-if="singledata.verificationStatus == 'P'" src="@/assets/warning.svg" />
              <img width="20px" class="mb-n02" v-else src="@/assets/warning.svg" />
              <span> {{ singledata.verificationStatus == "S" ? "Success" : singledata.verificationStatus == "P" ? "Pending" : "Unknown" }}</span>
            </p></v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
      <v-divider class="pt-1 mt-2"></v-divider>
      <v-list-item class="pb-3">
        <v-list-item-content>
          <v-list-item-subtitle class="subtext--text font-weight-medium fs-13 mb-1">Order Id</v-list-item-subtitle>
          <v-list-item-title class="maintext--text font-weight-bold fs-14"
            >{{ singledata.applicationNumber }}
            <p class="float-right mb-0">
              <img width="20px" class="mb-n02" v-if="singledata.appstatus == 'Success'" src="@/assets/success.svg" />
              <img width="20px" class="mb-n02" v-else-if="singledata.appstatus == 'Pending'" src="@/assets/warning.svg" />
              <img width="20px" class="mb-n02" v-else src="@/assets/error.svg" />
              <span> {{ singledata.appstatus ? singledata.appstatus : "" }}</span>
            </p></v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>

      <div class="px-4 pb-6">
        <v-btn v-if="singledata.way == 'exec'" @click="$router.push('/ipo')" class="rounded-pill text-none font-weight-bold" block height="40px" outlined>Place New order</v-btn>
        <v-row v-else>
          <!-- <v-col cols="6">
            <v-btn @click="setModifyorder(singledata)" class="rounded-pill text-none font-weight-bold" block height="40px" outlined>Modify Order</v-btn>
          </v-col> -->
          <v-col cols="12">
            <v-btn @click="(orderdrawer = false), (canceldialog = true)" class="rounded-pill text-none font-weight-bold" block height="40px" outlined>Cancel Order</v-btn>
          </v-col>
        </v-row>
      </div>
      <v-divider class="pt-1"></v-divider>
      <div class="px-4 pt-1">
        <v-list-item-title class="maintext--text font-weight-bold fs-14 py-4"
          >App no
          <p class="float-right mb-0">{{ singledata.resp_bid && singledata.resp_bid[0] ? singledata.resp_bid[0].bidReferenceNumber : "" }}</p></v-list-item-title
        >
        <v-divider></v-divider>
        <v-list-item-title class="maintext--text font-weight-bold fs-14 py-4"
          >Quantity
          <p class="float-right mb-0">{{ singledata.bid_detail ? singledata.quantity : "" }}</p></v-list-item-title
        >
        <v-divider></v-divider>
        <v-list-item-title class="maintext--text font-weight-bold fs-14 py-4"
          >Total amount
          <p class="float-right mb-0">₹{{ singledata.bid_detail ? singledata.amount.toLocaleString() : "" }}</p></v-list-item-title
        >
        <v-divider></v-divider>
        <v-list-item-title class="maintext--text font-weight-bold fs-14 py-4"
          >Price
          <p class="float-right mb-0">₹{{ singledata.bid_detail ? singledata.amount / singledata.quantity : "0.00" }}</p></v-list-item-title
        >
        <v-divider></v-divider>
        <v-list-item-title class="maintext--text font-weight-bold fs-14 pt-4 pb-1">{{ singledata.len ? (singledata.len == 1 ? "Single" : singledata.len == 1 ? "Double" : "Triple") : "" }} bid order</v-list-item-title>
        <div v-for="(b, s) in singledata.bid_detail" :key="s" class="px-1">
          <span class="fs-12 txt-444">bid {{ s + 1 }}</span>
          <v-list-item class="px-1">
            <v-list-item-content class="pt-0">
              <v-list-item-title class="font-weight-medium fs-12">{{ b.amount.toLocaleString() }}</v-list-item-title>
              <v-list-item-subtitle class="fs-10">Amount</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content class="pt-0">
              <v-list-item-title class="font-weight-medium fs-12">{{ b.price }}</v-list-item-title>
              <v-list-item-subtitle class="fs-10">Price</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content class="pt-0">
              <v-list-item-title class="font-weight-medium fs-12">{{ b.quantity }}</v-list-item-title>
              <v-list-item-subtitle class="fs-10">Quantity</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content class="pt-0">
              <v-list-item-title class="font-weight-medium fs-12">{{ b.atCutOff ? "Yes" : "No" }}</v-list-item-title>
              <v-list-item-subtitle class="fs-10">Cut off</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider v-if="singledata.len - 1 != s" class="max-auto"></v-divider>
        </div>
        <v-divider></v-divider>
        <v-list-item-title class="maintext--text font-weight-bold fs-14 pt-4">Reason </v-list-item-title>
        <p class="fs-12 txt-444 font-weight-regular">{{ singledata.fail_reason ? singledata.fail_reason : "Order placed successfully" }}</p>
      </div>
      <v-divider class="pt-1"></v-divider>
    </v-navigation-drawer>
    <v-dialog v-model="canceldialog" persistent max-width="400">
      <v-card class="rounded-xl elevation-0 text-center pt-8 pb-6 overflow-hidden" color="cardbg">
        <img src="@/assets/orderbook/cancel-icon.svg" alt="cancel icon" />
        <p class="font-weight-bold mt-3 fs-22 lh-24 mb-8">
          Are you sure you want to <br />
          cancel this ({{ singledata.symbol ? singledata.symbol : "" }}) order
        </p>
        <v-row class="px-6" no-gluttters>
          <v-col cols="6">
            <v-btn @click="canceldialog = false" color="secbg" class="rounded-pill text-none subtext--text font-weight-bold elevation-0" block height="40px">No</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn @click="ordCancel(singledata)" color="btnclr" class="rounded-pill text-none btntext--text font-weight-bold elevation-0" block height="40px">Yes</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog v-model="exitdialog" persistent max-width="400">
      <v-card class="rounded-xl elevation-0 pt-4 px-6 pb-6 overflow-hidden">
        <p class="font-weight-bold fs-22 lh-24 mb-4">Are you sure you want to Exit the order</p>
        <v-divider></v-divider>
        <v-list-item class="py-3 px-0 mb-2">
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold fs-16 maintext--text mb-3"
              >{{ singledata.tsym ? singledata.tsym : "" }} <span class="ml-1 subtext--text fs-10">{{ singledata.exch ? singledata.exch : "" }}</span></v-list-item-title
            >
            <v-list-item-title class="maintext--text font-weight-bold fs-14 mb-1">₹ 18,392.60 <span :class="singledata.avgprc > 0 ? 'maingreen--text' : singledata.avgprc < 0 ? 'mainred--text' : 'subtext--text'" class="font-weight-medium fs-12">-62.60 (0.34%)</span></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-row no-gluttters>
          <v-col cols="6">
            <v-btn @click="exitdialog = false" color="secbg" class="rounded-pill text-none subtext--text font-weight-bold elevation-0" block height="40px">Close</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn @click="exitdialog = false" color="btnclr" class="rounded-pill text-none btntext--text font-weight-bold elevation-0" block height="40px">Exit Order</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import apiurl from '../../../apiurl.js'
import eventBus from "../../../eventBus.js";
import {getIposbook} from "../../../components/mixins/getAPIdata.js";
// getIposorders
export default {
  data: () => ({
    uid: "",
    token: "",
    orderdrawer: false,
    canceldialog: false,
    exitdialog: false,
    singledata: [],
    tableloader: true,
    ordertab: 0,
    showtable: 24,
    menulist: {
      open: [
        // {name: "Modify Order", icon: 11, type: "md"},
        {name: "Cancel Order", icon: 12, type: "cd"},
        {name: "Order Status", icon: 3, type: "", hr: true},
        {name: "Details", icon: 10, type: "detail"},
      ],
      exec: [
        {name: "New Order", icon: "mdi-plus", type: "new", trans: "b"},
        {name: "Order Status", icon: 3, type: "", hr: true},
        {name: "Details", icon: 10, type: "detail"},
      ],
    },
    opensearch: null,
    openorders: [],
    execorders: [],
    filter: null,
    filters: [],

    ordertype: "open",
    orderbookdata: [],
  }),
  computed: {
    orderheader() {
      return [
        {text: "Symbol", value: "symbol", sortable: false},
        {text: "Order Number", value: "applicationNumber", sortable: false},
        {text: "Datetime", value: "response_datetime"},
        {text: "Qty", value: "quantity", sortable: false},
        {text: "Amount", value: "amount", sortable: false, align: "right"},
        {text: "Reason", value: "reason", sortable: false, width: "24%"},
        // { text: "Reason", value: "fail_reason", sortable: false },
        {text: "Status", value: "reponse_status", sortable: false},
        {text: "", value: "way", filter: this.setWayorder, class: " d-none"},
        {text: "", value: "series", filter: this.setSeriesorder, class: " d-none"},
      ];
    },
  },
  created() {
    //
    this.token = sessionStorage.getItem("usession");
    this.uid = sessionStorage.getItem("userid");
  },
  mounted() {
    this.getOrderbook();

    eventBus.$on("orderbook-update", (book) => {
      if (book == "ipo") {
        this.getOrderbook();
      }
    });
  },
  beforeDestroy() {
    eventBus.$off("orderbook-update");
  },
  methods: {
    async getOrderbook() {
      this.openorders = [];
      this.execorders = [];
      let data = await getIposbook([this.uid, this.token]);
      if (data.length > 0) {
        for (let q = 0; q < data.length; q++) {
          // let detail = await getIposorders([this.uid, this.token, data[q].symbol, data[q].applicationNumber, data[q].type]);
          // data[q]['detail'] = detail;
          data[q]["idx"] = q;
          if ((!data[q].upiPaymentStatus && !data[q].fail_reason && data[q].reponse_status == "new success") || data[q].reponse_status == "pending") {
            this.openorders.push(data[q]);
            data[q]["way"] = "open";
          } else {
            this.execorders.push(data[q]);
            data[q]["way"] = "exec";
          }
          data[q]["amount"] = Math.max(...data[q].bid_detail.map((o) => Number(o.amount)));
          data[q]["quantity"] = Math.max(...data[q].bid_detail.map((o) => Number(o.quantity)));
          data[q]["len"] = data[q].bid_detail.length;
          data[q]["fail_reason"] = data[q].upiPaymentStatus ? data[q].upiPaymentStatus : data[q].bid_detail.requestfor == "REMOVE" || data[q].bid_detail[0].activityType == "cancel" ? "Order was canceled by yourself." : data[q]["fail_reason"];
          data[q]["appstatus"] = !data[q].upiPaymentStatus && !data[q].fail_reason && data[q].reponse_status == "new success" ? "Success" : data[q].reponse_status == "pending" ? "Pending" : "Failed";
        }
        this.orderbookdata = data;
        this.ordertype = "open";
      }
      this.tableloader = false;
    },
    setWayorder(value) {
      if (!this.ordertype) {
        return true;
      }
      return value === this.ordertype;
    },
    setSeriesorder(value) {
      if (!this.filter) {
        return true;
      }
      return value === this.filter;
    },

    setOrdercancel(item) {
      this.singledata = {};
      this.canceldialog = true;
      this.singledata = item;
    },
    setOrderrowdata(item) {
      this.singledata = {};
      this.orderdrawer = true;
      this.singledata = item;
    },
    setModifyorder(singledata) {
      eventBus.$emit("menudialog", "iposorder", singledata, "mod");
      this.orderdrawer = false;
    },
    ordCancel(singledata) {
      eventBus.$emit("menudialog", "iposorder", singledata, "can");
      this.canceldialog = false;
    },
  },
};
</script>
