<template>
  <div>
    <div :id="containerId" class="TVChartContainer"></div>
    <v-overlay :value="showchart" class="text-center" opacity="0">
      <v-progress-circular class="my-auto" color="primary" :size="60" :width="4" indeterminate></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { widget } from "../../public/charting_library";
import Datafeed from "./mixins/feedFactory.js";
import { setIndicators, getIndicators } from "./mixins/getAPIdata.js";
import { myntappurl } from "../apiurl";

export default {
  name: "TVChartContainer",

  props: {
    symbol: {
      default: 'ABC:ABCD',
      type: String,
    },
    // interval: {
    //   default: "1",
    //   type: String,
    // },
    containerId: {
      default: "tv_chart_container",
      type: String,
    },
    datafeedUrl: {
      default: "",
      type: String,
    },
    libraryPath: {
      default: "../charting_library/",
      type: String,
    },
    chartsStorageUrl: {
      default: "https://chartbe.mynt.in",
      type: String,
    },
    chartsStorageApiVersion: {
      default: "1.1",
      type: String,
    },
    clientId: {
      default: "ZEBU",
      type: String,
    },
    userId: {
      default: "",
      type: String,
    },
    fullscreen: {
      default: false,
      type: Boolean,
    },
    autosize: {
      default: true,
      type: Boolean,
    },
    studiesOverrides: {
      type: Object,
    },
    title: String,
    subtitle: String,
    text: String,
    image: String,
  },
  tvWidget: null,

  data() {
    return {
      showchart: true,
      theme: "light",
      savedSymbol: "",
      settings: JSON.parse(localStorage.getItem("settings_mob")),
      interval: "1",
    };
  },

  mounted() {
    this.interval = this.settings && this.settings["chart.lastUsedTimeBasedResolution"] ? this.settings["chart.lastUsedTimeBasedResolution"] : "5";
    this.initTWChart(localStorage.getItem("adv_chart_mob"));

    var sss = [];
    if (this.settings) {
      for (let i in this.settings) {
        if (i.includes("savedwatch")) {
          delete this.settings[i];
        }
      }
      sss = this.settings;
    } else {
      sss = {};
    }
    localStorage.setItem("settings_mob", JSON.stringify(sss));
  },
  beforeDestroy() {
  },

  methods: {
    async setIndicatorsInterval() {
      var indicators = await getIndicators();

      setInterval(async function () {
        var d = window.tvWidget.activeChart().getAllStudies();
        var c = [];
        for (var i = 0; i < d.length; i++) {
          var a = d[i].name;
          if (a != "Volume") {
            c.push(a);
          }
        }
        if (JSON.stringify(indicators.data) != JSON.stringify(c)) {
          try {
            let res = await setIndicators(c)
            indicators = res;
          }
          catch (e) {
            console.log(e);
          }
        }
      }, 5000);

      if (indicators.data) {
        for (var i = 0; i < indicators.data.length; i++) {
          console.log(indicators['data'][0])
          window.tvWidget.activeChart().createStudy(indicators['data'][i], false, false);
        }
      }
    },

    initTWChart() {
      let params = this.$route;
      var sym = params.query.exch && params.query.symbol ? `${params.query.exch}:${params.query.symbol}` : this.symbol;
      const widgetOptions = {
        symbol: sym,
        datafeed: Datafeed,
        interval: this.interval,
        // timeframe: "5D",
        container: this.containerId,
        auto_save_delay: 1,
        library_path: this.libraryPath,
        timezone: "Asia/Kolkata",
        locale: "en",
        custom_css_url: "../css/style.css",
        // load_last_chart: true,
        disabled_features: ["timeframes_toolbar", "symbol_info", "header_compare", "end_of_period_timescale_marks", "header_symbol_search"], //"left_toolbar","bottom_toolbar","header_widget", "header_symbol_search"
        enabled_features: ["study_templates", "use_localstorage_for_settings", "iframe_loading_compatibility_mode", "iframe_loading_compatibility_mode", "custom_resolutions"
          , "header_saveload",
        ],
        charts_storage_url: "https://chartbe.mynt.in",
        charts_storage_api_version: this.chartsStorageApiVersion,
        client_id: "Mynt_web",
        user_id: "",
        fullscreen: this.fullscreen,
        autosize: this.autosize,
        // debug:true,
        studies_overrides: this.studiesOverrides,
        theme: myntappurl.theme ? "dark" : "light",
        settings_adapter: {
          initialSettings: this.settings,
          setValue: function (key, value) {
            let settings;
            if (localStorage.getItem("settings_mob") != undefined) {
              settings = JSON.parse(localStorage.getItem("settings_mob"));
              if (settings[key]) {
                settings[key] = value;
              } else {
                settings[key] = value;
              }
            } else {
              settings[key] = value;
            }
            localStorage.setItem("settings_mob", JSON.stringify(settings));
          },
          removeValue: function (key) {
            let settings = JSON.parse(localStorage.getItem("settings_mob"));
            delete settings[key];
            localStorage.setItem("settings_mob", JSON.stringify(settings));
          },
        },
        // right side widget to watchlist
        widgetbar: {
          details: false,
          news: false,
          watchlist: false,
          datawindow: false,
          watchlist_settings: {
            default_symbols: [],
            readonly: false,
          },
        },
      };

      const uid = sessionStorage.getItem("userid");
      widgetOptions['user_id'] = uid;
      const tvWidget = new widget(widgetOptions);
      this.tvWidget = tvWidget;
      window.tvWidget = tvWidget;

      tvWidget.onChartReady(() => {
        tvWidget.changeTheme(myntappurl.theme ? "dark" : "light");
        tvWidget.activeChart().dataReady(() => {
          tvWidget.activeChart().executeActionById("chartReset");
        });
        this.showchart = false;
      });
      this.setIndicatorsInterval();
    },
  },

  destroyed() {
    if (this.tvWidget !== null) {
      this.tvWidget.remove();
      this.tvWidget = null;
    }
  },
};
</script>

<style lang="scss" scoped>
.tradingview-wrapper {
  position: relative !important;
  display: flex !important;
  width: 100% !important;
}

.TVChartContainer {
  overflow: hidden !important;
  height: 100vh !important;
}

.chart-container {
  width: 100% !important;
}
</style>
