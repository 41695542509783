<template>
  <div>
    <v-breadcrumbs class="pa-0 pt-2" :items="bcitems">
      <template v-slot:item="{item}">
        <v-breadcrumbs-item :class="!item.disabled ? 'primary--text cursor-p' : ''" @click="$router.push(item.href)" :disabled="item.disabled">
          {{ item.text == "thisi" ? advdecisi.split("-")[0] : item.text == "this" ? advdecis : item.text == "sec" ? (advdeclistis ? advdeclistis.split("-")[0] : "Indices") : item.text }}
        </v-breadcrumbs-item>
      </template>
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-card style="border: thin solid var(--outline) !important" outlined class="rounded-lg mt-4 mb-8 crd-trn">
      <v-toolbar class="elevation-0 my-4" dense color="transparent">
        <div>
          <p class="font-weight-bold title mb-0 text-none ws-p lh-16">
            {{ advdecis ? advdecis.split("-")[0] : "" }} <span class="caption subtext--text font-weight-bold">({{ advdecstat.sum ? advdecstat.sum : ".." }})</span>
          </p>
          <p class="fs-14 maintext--text font-weight-medium mb-0 lh-16">
            ₹<span :id="`savddec${advdecstat.token}ltp`">{{ advdecstat.ltp ? advdecstat.ltp : "0.00" }}</span
            >&nbsp;
            <span class="fs-12" :id="`savddec${advdecstat.token}chpclr`" :class="advdecstat.ch > 0 ? 'maingreen--text' : advdecstat.ch < 0 ? 'mainred--text' : 'subtext--text'"
              ><span v-text="advdecstat.ch ? advdecstat.ch : '0.00'" :id="`savddec${advdecstat.token}ch`"></span> <span v-text="` (${advdecstat.chp ? advdecstat.chp : '0.00'}%)`" :id="`savddec${advdecstat.token}chp`"></span
            ></span>
          </p>
        </div>
        <v-spacer class="d-none d-sm-flex"></v-spacer>

        <v-select
          @change="getADindicesdata('main')"
          :readonly="isloading"
          style="max-width: 140px"
          v-model="advdecisi"
          hide-details
          append-icon="mdi-chevron-down"
          class="rounded-pill mr-3 d-none  d-sm-flex"
          item-text="[0]"
          item-value="[0]"
          dense
          flat
          solo
          background-color="secbg"
          :items="Object.entries(adindiceslist)"
          label="indices"
        >
        </v-select>
        <v-autocomplete
          @change="getADindicesdata('yes')"
          :readonly="isloading"
          style="max-width: 140px"
          v-model="advdecis"
          hide-details
          append-icon="mdi-chevron-down"
          class="rounded-pill mr-3 d-none  d-sm-flex"
          dense
          flat
          solo
          background-color="secbg"
          :items="adindiceslist[advdecisi]"
          label="indices"
        ></v-autocomplete>
        <v-text-field style="max-width: 220px" :disabled="isloading" v-model="opensearch" hide-details prepend-inner-icon="mdi-magnify" label="Search" class="rounded-pill d-none d-sm-flex" dense flat solo background-color="secbg"></v-text-field>
      </v-toolbar>

      <v-toolbar class="elevation-0 my-4 d-sm-none" dense color="transparent">
        <v-select
          @change="getADindicesdata('main')"
          :readonly="isloading"
          v-model="advdecisi"
          hide-details
          append-icon="mdi-chevron-down"
          class="rounded-pill mr-1"
          item-text="[0]"
          item-value="[0]"
          dense
          flat
          solo
          background-color="secbg"
          :items="Object.entries(adindiceslist)"
          label="indices"
        >
        </v-select>
        <v-autocomplete
          @change="getADindicesdata('yes')"
          :readonly="isloading"
          v-model="advdecis"
          hide-details
          append-icon="mdi-chevron-down"
          class="rounded-pill ml-1"
          dense
          flat
          solo
          background-color="secbg"
          :items="adindiceslist[advdecisi]"
          label="indices"
        ></v-autocomplete>
        <v-text-field style="max-width: 220px" :disabled="isloading" v-model="opensearch" hide-details prepend-inner-icon="mdi-magnify" label="Search" class="rounded-pill d-none d-sm-flex" dense flat solo background-color="secbg"></v-text-field>
      </v-toolbar>

      <div class="px-4">
        <v-card width="100%" class="elevation-0 pa-3 rounded-lg mb-3" height="60px" color="secbg">
          <v-row no-glutters>
            <!-- <v-col cols="4">
                <p class="mb-0 subtitle-2 font-weight-medium lh-16">{{ i.title }} <span
                    class="caption subtext--text font-weight-bold">({{ advdecstat.sum }})</span></p>
              </v-col> -->
            <v-col cols="12" class="d-inline-flex">
              <v-card class="elevation-0 rounded-lg crd-trn" :width="advdecstat.adp">
                <v-card class="elevation-0 rounded-lg py-1" color="maingreen" width="100%"></v-card>
                <p v-if="advdecstat.Positive > 0" class="mb-0 lh-16 mt-2 subtitle-2">
                  <v-icon color="maingreen" size="18">mdi-arrow-top-right</v-icon>
                  {{ advdecstat.Positive }}
                </p>
              </v-card>
              <v-card v-if="advdecstat.Negative > 0 || advdecstat.Neutral > 0" class="elevation-0 rounded-lg px-1 crd-trn" :width="advdecstat.adn">
                <v-card class="elevation-0 rounded-lg py-1" color="#D9D9D9" width="100%"></v-card>
              </v-card>
              <v-card class="elevation-0 rounded-lg crd-trn" :width="advdecstat.adm">
                <v-card class="elevation-0 rounded-lg py-1" color="mainred" width="100%"></v-card>
                <p v-if="advdecstat.Negative > 0" class="mb-0 lh-16 mt-2 subtitle-2 text-right">
                  {{ advdecstat.Negative }}
                  <v-icon color="mainred" size="18">mdi-arrow-bottom-right</v-icon>
                </p>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </div>

      <v-data-table must-sort :sort-by="['change']" :sort-desc="[true]" mobile-breakpoint fixed-header :loading="isloading" class="rounded-lg overflow-y-auto" :headers="tradeheader" :search="opensearch" :items="advdectabel" :items-per-page="10">
        <template v-slot:[`item.SYMBOL`]="{item}">
          <p class="mb-0 lh-16">
            <span @click="setSinglestock(item.SYMBOL.split(':')[1].split('-')[0], item)" class="font-weight-medium text-capitalize txt-dec-cust ws-p">{{ item.SYMBOL.split(":")[1] }}</span>
            <br /><span class="caption subtext--text ws-p">{{ item.Industry ? item.Industry : "" }}</span>
          </p>
        </template>
        <template v-slot:[`item.ltp`]="{item}">
          <p class="mb-0 lh-18">
            <span class="font-weight-medium maintext--text"> ₹<span :id="`avddec${item.Token}ltp`" v-text="item.ltp ? Number(item.ltp).toFixed(2) : '0.00'"></span></span> <br />
            <span class="font-weight-medium fs-12 ws-p" :id="`avddec${item.Token}chpclr`" :class="item.ch > 0 ? 'maingreen--text' : item.ch < 0 ? 'mainred--text' : 'subtext--text'">
              <span :id="`avddec${item.Token}ch`" v-text="item.ch ? item.ch : '0.00'"> </span>
              <span :id="`avddec${item.Token}chp`" v-text="` (${item.chp ? item.chp : '0.00'}%)`"></span
            ></span>
          </p>
        </template>

        <template v-slot:[`item.vol`]="{item}">
          <span class="font-weight-medium maintext--text" :id="`avddec${item.Token}vol`">{{ item.vol ? item.vol : "0.00" }}</span>
        </template>
        <template v-slot:[`item.op`]="{item}">
          <span class="font-weight-medium maintext--text" :id="`avddec${item.Token}op`">{{ item.op ? item.op : "0.00" }}</span>
        </template>
        <template v-slot:[`item.cp`]="{item}">
          <span class="font-weight-medium maintext--text" :id="`avddec${item.Token}cp`">{{ item.cp ? item.cp : "0.00" }}</span>
        </template>
        <template v-slot:[`item.high`]="{item}">
          <span class="font-weight-medium maintext--text" :id="`avddec${item.Token}high`">{{ item.high ? item.high : "0.00" }}</span>
        </template>
        <template v-slot:[`item.low`]="{item}">
          <span class="font-weight-medium maintext--text" :id="`avddec${item.Token}low`">{{ item.low ? item.low : "0.00" }}</span>
        </template>
        <template v-slot:no-data>
          <v-col cols="12" class="text-center pa-16">
            <div class="mx-auto">
              <img class="align-self-stretch mx-auto" width="80px" :src="require('@/assets/no data folder.svg')" alt="no data" />
              <h5 class="txt-999 font-weight-regular">There is no data here yet!</h5>
            </div>
          </v-col>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import {getADindices, getADindice, getLtpdata} from "@/components/mixins/getAPIdata";
import eventBus from "../../../eventBus.js";

export default {
  /* eslint-disable */
  data: () => ({
    bcitems: [
      {
        text: "Stocks",
        disabled: false,
        href: "/stocks",
      },
      {
        text: "sec",
        disabled: true,
        href: "/stocks/advance_decline",
      },
      {
        text: "Advance & Decline",
        disabled: true,
        href: "/stocks/advance_decline",
      },
      {
        text: "thisi",
        disabled: true,
      },
      {
        text: "this",
        disabled: true,
      },
    ],
    uid: null,
    mtoken: null,
    stoken: null,

    advdecisi: "Nifty-Indices",
    advdecis: "NIFTY 50",
    advdecstat: {},
    advdecfilter: [],
    advdecitems: [],
    advdectabel: [],
    adindiceslist: [],
    advdeclistis: null,
    isloading: true,
    opensearch: null,

    wsdata: [],
  }),
  computed: {
    tradeheader() {
      return [
        {text: "Symbol", value: "SYMBOL", sortable: false, class: "ws-p"},
        {text: "Price", value: "ltp", sortable: false, align: "right", class: "ws-p"},
        {text: "Open", value: "op", align: "right", class: "ws-p"},
        {text: "High", value: "high", align: "right", class: "ws-p"},
        {text: "Low", value: "low", align: "right", class: "ws-p"},
        {text: "Close", value: "cp", align: "right", class: "ws-p"},
        {text: "Volume", value: "vol", align: "right", class: "ws-p"},

        // { text: '', value: 'Company Name', class: 'd-none' },
      ];
    },
  },
  created() {
    let params = this.$route.params;
    if (params.abc) {
      this.advdecisi = params.main == "find" ? "find" : params.main == "Sectors" ? "Sectoral-Indices" : "Thematic-Indices";
      this.advdecis = params.abc;
    } else {
      this.advdecis = "NIFTY 50";
    }
  },
  mounted() {
    eventBus.$emit("tabBar-load");
    eventBus.$emit("login-event");
    eventBus.$on("user-event", () => {
      let res = sessionStorage.getItem("c3RhdHVz");
      if (res == "dmFsaWR1c2Vy") {
        if (!this.uid && !this.stoken) {
          this.mtoken = sessionStorage.getItem("msession");
          this.stoken = sessionStorage.getItem("usession");
          this.uid = sessionStorage.getItem("userid");
        }
      }
      this.getADlistdata();
    });

    eventBus.$on("web-scoketConn", (data, page) => {
      if (page == "stockAD" && this.advdectabel && typeof this.advdectabel == "object") {
        this.optionChainDataParse(data);
      }
    });
  },
  beforeDestroy() {
    eventBus.$off("user-event");
    // eventBus.$off('web-scoketConn');
  },
  methods: {
    setSinglestock(tsym, item) {
      if (this.uid) {
        let path = [0, item.Token, "NSE", item.SYMBOL.split(":")[1]];
        this.$router.push({name: "stocks details", params: {val: path}});
      } else {
        this.$router.push(`/stocks/${tsym.toLowerCase()}`);
      }
    },
    async getADlistdata() {
      this.isloading = true;
      let list = await getADindice();
      if (Object.entries(list).length > 0) {
        this.adindiceslist = list;
      }
      let data = await getADindices();
      if (data && data != 500) {
        this.advdecfilter = data;
        delete this.advdecfilter["Company"];
        this.getADindicesdata();
      } else {
        this.isloading = false;
      }
    },
    async getADindicesdata(change) {
      if (change == "main") {
        this.advdecis = this.adindiceslist[this.advdecisi][0];
      }
      let fill = this.advdecfilter[this.advdecis];
      if (fill) {
        if (change == "yes") {
          this.setWebsocket("unsub-D", this.wsdata, "ta");
        }
        this.isloading = true;
        this.advdectabel = [];
        this.advdecstat = [];
        let data = await getADindice(this.advdecis);
        this.advdecstat = this.setStatAD(this.advdecfilter[this.advdecis]);
        if (data && data.length > 0) {
          this.advdectabel = data;
          let wsdata = [];
          data.map((o) => wsdata.push({exch: o.SYMBOL.split(":")[0], token: o.Token, tsym: o.SYMBOL.split(":")[1]}));
          wsdata.push({exch: "NSE", token: this.advdecstat.token, tsym: this.advdecis});
          this.setWebsocket("sub", wsdata, "ta");
          this.wsdata = wsdata;
        }

        Object.entries(this.adindiceslist).forEach(([key, value]) => {
          for (let c = 0; c < value.length; c++) {
            let o = value.findIndex((o) => o.name === this.advdecis);
            if (o >= 0 && this.advdecis == value[o].name) {
              this.advdeclistis = key;
            }
          }
        });
        this.isloading = false;
      }
    },
    setStatAD(data) {
      data["sum"] = data.Negative + data.Neutral + data.Positive;
      data["adp"] = data.sum > 0 ? `${Math.round((data.Positive / data.sum) * 100)}%` : 0;
      data["adm"] = data.sum > 0 ? `${Math.round((data.Negative / data.sum) * 100)}%` : 0;
      data["adn"] = data.sum > 0 ? `${Math.round((data.Neutral / data.sum) * 100)}%` : 0;
      return data;
    },
    async setWebsocket(flow, data, is) {
      if (this.uid) {
        eventBus.$emit("web-scoketOn", flow, data, is, "stockAD");
      } else {
        let raw = await getLtpdata(data);
        raw = raw.data;
        if (raw) {
          let v;
          for (let l = 0; l < this.advdectabel.length; l++) {
            v = raw[this.advdectabel[l].Token];
            if (v) {
              this.advdectabel[l]["ltp"] = Number(v.lp).toFixed(2);
              this.advdectabel[l]["ch"] = Number(this.advdectabel[l].ltp - Number(v.close)).toFixed(2);
              this.advdectabel[l]["chp"] = Number(v.change);
              this.advdectabel[l]["vol"] = v.vol ? Number(v.vol).toFixed(2) : (0).toFixed(2);
              this.advdectabel[l]["op"] = Number(v.open) ? Number(v.open).toFixed(2) : (0).toFixed(2);
              this.advdectabel[l]["cp"] = Number(v.close) ? Number(v.close).toFixed(2) : (0).toFixed(2);
              this.advdectabel[l]["high"] = Number(v.high) ? Number(v.high).toFixed(2) : (0).toFixed(2);
              this.advdectabel[l]["low"] = Number(v.low) ? Number(v.low).toFixed(2) : (0).toFixed(2);
              this.advdectabel[l]["oi"] = v.oi ? Number(v.oi).toFixed(2) : (0).toFixed(2);
              // this.$set(this.advdectabel, l, this.advdectabel[l]);
            }
          }
          v = raw[this.advdecstat.token];
          if (v) {
            this.advdecstat["ltp"] = Number(v.lp).toFixed(2);
            this.advdecstat["ch"] = Number(this.advdecstat.ltp - Number(v.close)).toFixed(2);
            this.advdecstat["chp"] = Number(v.change);
          }
        }
      }
    },
    optionChainDataParse(data) {
      let p = this.advdectabel.findIndex((o) => o.Token == data.token);
      if (p >= 0 && this.advdectabel[p].Token == data.token) {
        this.advdectabel[p].ltp = Number(data.lp).toFixed(2);
        this.advdectabel[p]["ch"] = Number(data.ch) > 0 || Number(data.ch) < 0 ? Number(data.ch).toFixed(2) : (0).toFixed(2);
        this.advdectabel[p]["chp"] = Number(data.chp).toFixed(2);
        this.advdectabel[p]["vol"] = Number(data.volume);
        this.advdectabel[p]["op"] = Number(data.open_price) ? Number(data.open_price).toFixed(2) : (0).toFixed(2);
        this.advdectabel[p]["cp"] = Number(data.prev_close_price) ? Number(data.prev_close_price).toFixed(2) : (0).toFixed(2);
        this.advdectabel[p]["high"] = Number(data.high_price) ? Number(data.high_price).toFixed(2) : (0).toFixed(2);
        this.advdectabel[p]["low"] = Number(data.low_price) ? Number(data.low_price).toFixed(2) : (0).toFixed(2);

        let tag = document.getElementById(`avddec${data.token}ltp`);
        if (tag) {
          document.getElementById(`avddec${data.token}ltp`).innerHTML = this.advdectabel[p].ltp;
          document.getElementById(`avddec${data.token}ch`).innerHTML = this.advdectabel[p].ch;
          document.getElementById(`avddec${data.token}chp`).innerHTML = ` (${this.advdectabel[p].chp}%)`;
          eventBus.$emit("color-event", `avddec${data.token}chpclr`, this.advdectabel[p].ch > 0 ? "maingreen--text" : this.advdectabel[p].ch < 0 ? "mainred--text" : "subtext--text");
          document.getElementById(`avddec${data.token}vol`).innerHTML = this.advdectabel[p].vol;
          document.getElementById(`avddec${data.token}op`).innerHTML = this.advdectabel[p].op;
          document.getElementById(`avddec${data.token}cp`).innerHTML = this.advdectabel[p].cp;
          document.getElementById(`avddec${data.token}high`).innerHTML = this.advdectabel[p].high;
          document.getElementById(`avddec${data.token}low`).innerHTML = this.advdectabel[p].low;
        }
        // this.$set(this.advdectabel, p, this.advdectabel[p]);
      }

      if (this.advdecstat.token == data.token) {
        this.advdecstat["ltp"] = Number(data.lp).toFixed(2);
        this.advdecstat["ch"] = Number(data.ch) > 0 || Number(data.ch) < 0 ? Number(data.ch).toFixed(2) : (0).toFixed(2);
        this.advdecstat["chp"] = Number(data.chp).toFixed(2);

        let tag = document.getElementById(`savddec${data.token}ltp`);
        if (tag) {
          document.getElementById(`savddec${data.token}ltp`).innerHTML = this.advdecstat.ltp;
          document.getElementById(`savddec${data.token}ch`).innerHTML = this.advdecstat.ch;
          document.getElementById(`savddec${data.token}chp`).innerHTML = ` (${this.advdecstat.chp}%)`;
          eventBus.$emit("color-event", `savddec${data.token}chpclr`, this.advdecstat.ch > 0 ? "maingreen--text" : this.advdecstat.ch < 0 ? "mainred--text" : "subtext--text");
        }
      }
    },
  },
};
</script>
