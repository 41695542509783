<template>
  <div>
    <div class="mb-6">
      <v-toolbar flat dense class="tool-sty" color="transparent">
        <v-spacer></v-spacer>
        <v-text-field style="max-width: 220px" v-model="opensearch" hide-details prepend-inner-icon="mdi-magnify" label="Search" class="rounded-pill" dense flat solo background-color="secbg"></v-text-field>

        <v-icon class="ml-3 cursor-p" @click="getOrderbook()" color="maintext" size="24">mdi-reload</v-icon>
      </v-toolbar>

      <v-data-table
        must-sort
        :sort-by="['norentm']"
        :sort-desc="[true]"
        hide-default-footer
        fixed-header
        :loading="loading"
        class="mt-3 rounded-lg overflow-y-auto"
        style="border-radius: 4px; border: 1px solid var(--outline)"
        height="480px"
        :headers="orderheader"
        :search="opensearch"
        :items="orderbookdata"
        :items-per-page="orderbookdata.length"
      >
        <template v-slot:body="{items, headers}">
          <tbody v-if="orderbookdata && orderbookdata.length > 0">
            <tr @click="setOrderrowdata(item)" class="table-row" v-for="(item, o) in items" :key="o">
              <td>
                <p class="font-weight-medium maintext--text mb-0 table-hov-text ws-p mr-4">
                  {{ item.tsym ? item.tsym : "" }}
                  <span class="ml-1 txt-999 fs-10">{{ item.exch ? item.exch : "" }}</span>
                </p>
              </td>
              <td>
                <v-chip small :color="item.trantype == 'B' ? '#ECF8F1' : '#FCF3F3'" :text-color="item.trantype == 'B' ? 'maingreen' : 'mainred'" :style="`border: 1px solid ${item.trantype == 'B' ? '#C1E7BA' : '#FFCDCD'}; border-radius: 5px; padding: 10px 8px !important;`">
                  <span class="font-weight-medium fs-12">{{ item.trantype == "B" ? "BUY" : "SELL" }}</span>
                </v-chip>
              </td>
              <td class="pos-rlt">
                <p class="font-weight-medium maintext--text mb-0">{{ item.orderType ? item.orderType : "" }}</p>

                <div @click.stop class="pos-abs table-hov" style="top: 15px; right: 0">
                  <v-btn @click="(orderdrawer = false), $emit('menudialog', 'order', item.token, item.exch, item.tsym, 'b')" min-width="20px" color="maingreen" class="px-0 font-weight-bold white--text elevation-0 mr-1" x-small> B </v-btn>
                  <v-btn @click="(orderdrawer = false), $emit('menudialog', 'order', item.token, item.exch, item.tsym, 's')" min-width="20px" color="mainred" class="px-0 font-weight-bold white--text elevation-0 mr-1" x-small> S </v-btn>
                  <v-btn @click="setSSDtab('chart', item.token, item.exch, item.tsym)" style="border: 1px solid var(--outline)" min-width="20px" color="mainbg" class="px-0 font-weight-bold white--text elevation-0 mr-1" x-small>
                    <v-icon size="18" color="maintext">mdi-chart-line-variant</v-icon>
                  </v-btn>
                  <v-menu close-on-click absolute offset-y class="table-menu">
                    <template v-slot:activator="{on, attrs}">
                      <v-btn v-bind="attrs" v-on="on" style="border: 1px solid var(--outline)" min-width="20px" color="mainbg" class="px-0 font-weight-bold white--text elevation-0 mr-1" x-small>
                        <v-icon size="20" color="maintext">mdi-dots-horizontal</v-icon>
                      </v-btn>
                    </template>
                    <v-card class="table-menu-list">
                      <v-list dense>
                        <div v-for="(m, k) in menulist" :key="k">
                          <v-list-item @click="m.type != '' ? setSSDtab(m.type, item.token, item.exch, item.tsym) : setOrderrowdata(item)" class="pl-3 pr-6">
                            <v-list-item-icon class="mr-3 text-center">
                              <img v-if="m.icon > 2" width="20px" class="pl-1" :src="require(`@/assets/orderbook/${m.icon}.svg`)" />
                              <v-icon v-else color="#506D84">{{ m.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title class="subline--text font-weight-medium fs-14">{{ m.name }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider v-if="m.hr" class="mx-3"></v-divider>
                        </div>
                      </v-list>
                    </v-card>
                  </v-menu>
                </div>
              </td>
              <td>
                <v-chip small class="table-hov-prd" text-color="#666" style="border-radius: 5px; padding: 10px 8px !important">
                  <span class="font-weight-medium maintext--text fs-12">{{ item.qty }}</span>
                </v-chip>
              </td>
              <td>
                <p class="font-weight-medium maintext--text mb-0">{{ item.condition ? item.condition : "" }}</p>
              </td>
              <td>
                <p class="font-weight-medium maintext--text mb-0">{{ item.trigValue ? item.trigValue : "" }}</p>
              </td>
              <td class="font-weight-medium d-inline-flex" @click.stop>
                <v-btn icon small class="text-align-center mr-1 mt-2" @click="setSSDtab('modify', item.token, item.exch, item.tsym, item.trantype.toLowerCase(), item)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 28 28" fill="none">
                    <path
                      d="M5 18.7782C5.00006 18.481 5.11819 18.1959 5.32841 17.9858L17.8214 5.49281C17.9775 5.33658 18.1629 5.21264 18.3669 5.12808C18.571 5.04352 18.7897 5 19.0106 5C19.2315 5 19.4502 5.04352 19.6542 5.12808C19.8583 5.21264 20.0437 5.33658 20.1998 5.49281L23.0972 8.3902C23.2534 8.54634 23.3774 8.73173 23.4619 8.93578C23.5465 9.13983 23.59 9.35854 23.59 9.57942C23.59 9.8003 23.5465 10.019 23.4619 10.2231C23.3774 10.4271 23.2534 10.6125 23.0972 10.7686L10.6042 23.2616C10.3941 23.4718 10.109 23.5899 9.81179 23.59H6.12085C5.82358 23.59 5.53849 23.4719 5.32829 23.2617C5.11809 23.0515 5 22.7664 5 22.4692V18.7782ZM6.12085 18.7782V22.4692H9.81179L22.3047 9.9762C22.3569 9.92414 22.3983 9.8623 22.4266 9.79421C22.4548 9.72613 22.4694 9.65313 22.4694 9.57942C22.4694 9.5057 22.4548 9.43271 22.4266 9.36463C22.3983 9.29654 22.3569 9.2347 22.3047 9.18264L19.4074 6.28525C19.3553 6.23306 19.2935 6.19165 19.2254 6.1634C19.1573 6.13515 19.0843 6.12061 19.0106 6.12061C18.9369 6.12061 18.8639 6.13515 18.7958 6.1634C18.7277 6.19165 18.6659 6.23306 18.6138 6.28525L6.12085 18.7782Z"
                      fill="currentColor"
                    />
                    <path d="M16.3721 7.73451L20.8555 12.2179L21.649 11.4243L17.1657 6.94095L16.3721 7.73451ZM6.28448 17.8221L10.7679 22.3055L11.5614 21.512L7.07804 17.0286L6.28448 17.8221Z" fill="currentColor" />
                    <path d="M18.6138 9.18265L8.52618 19.2703L9.31974 20.0638L19.4074 9.97621L18.6138 9.18265Z" fill="currentColor" />
                  </svg>
                </v-btn>
                <v-btn icon small class="text-align-center mt-2" @click="setGTTcancel(item)">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="24" height="24">
                    <path
                      fill="currentColor"
                      fill-rule="evenodd"
                      d="M11.5 6a.5.5 0 0 0-.5.5V8h6V6.5a.5.5 0 0 0-.5-.5h-5zM18 8V6.5c0-.83-.67-1.5-1.5-1.5h-5c-.83 0-1.5.67-1.5 1.5V8H5.5a.5.5 0 0 0 0 1H7v12.5A2.5 2.5 0 0 0 9.5 24h9a2.5 2.5 0 0 0 2.5-2.5V9h1.5a.5.5 0 0 0 0-1H18zm2 1H8v12.5c0 .83.67 1.5 1.5 1.5h9c.83 0 1.5-.67 1.5-1.5V9zm-8.5 3c.28 0 .5.22.5.5v7a.5.5 0 0 1-1 0v-7c0-.28.22-.5.5-.5zm5.5.5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z"
                    ></path>
                  </svg>
                </v-btn>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <td :colspan="headers.length" class="text-center">
              <div class="mx-auto py-16 mt-16">
                <img class="mx-auto" width="80px" :src="require('@/assets/no data folder.svg')" />
                <h4 class="txt-999 font-weight-regular caption">
                  There is no order <br />
                  data here yet!
                </h4>
              </div>
            </td>
          </tbody>
        </template>
      </v-data-table>
    </div>
    <v-navigation-drawer hide-overlay color="cardbg" fixed temporary app class="pt-2" v-model="orderdrawer" right width="360px">
      <template v-slot:prepend>
        <v-toolbar class="nav-drawer" dense>
          <v-icon @click="orderdrawer = false" color="#666" size="20">mdi-close</v-icon>
          <p class="black--text font-weight-bold mb-0 ml-2">Order Details</p>
          <v-spacer></v-spacer>
          <!-- <v-icon color="#666" size="20">mdi-headphones</v-icon>
          <p class="txt-666 font-weight-medium mb-0 ml-2">Need Help ?</p> -->
        </v-toolbar>
      </template>

      <v-list-item class="py-3">
        <v-list-item-content>
          <v-list-item-title class="font-weight-medium black--text mb-3"
            >{{ singledata.tsym ? singledata.tsym : "" }} <span class="ml-1 txt-999 fs-10">{{ singledata.exch ? singledata.exch : "" }}</span></v-list-item-title
          >
          <v-list-item-title class="txt-000 font-weight-medium fs-16 mb-1">₹{{ singledata.ltp ? singledata.ltp : "0.00" }}</v-list-item-title>
          <v-list-item-title :class="singledata.rpnl > 0 ? 'maingreen--text' : singledata.rpnl < 0 ? 'mainred--text' : 'subtext--text'" class="font-weight-medium fs-12">{{ singledata.rpnl ? singledata.rpnl : "0.00" }} ({{ singledata.pnlc ? singledata.pnlc : "0.00" }}%)</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="pt-1"></v-divider>
      <v-list-item class="py-3">
        <v-list-item-content>
          <v-list-item-subtitle class="txt-666 font-weight-medium fs-13 mb-1">Order Id</v-list-item-subtitle>
          <v-list-item-title class="txt-000 font-weight-bold fs-14"
            >{{ singledata.norenordno }}
            <p class="float-right mb-0">
              <svg v-if="singledata.status == 'COMPLETE'" xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15" fill="none">
                <rect width="20" height="15" rx="7" fill="#2DB266" />
                <path d="M6.25 8.2475L8.415 10.4125L13.8275 5" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <svg v-else-if="singledata.status == 'CANCELED' || singledata.status == 'REJECTED'" xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15" fill="none">
                <rect width="20" height="15" rx="7" fill="#DC2626" />
                <path d="M7.5 10L12.5 5M7.5 5L12.5 10" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <svg v-else xmlns="http://www.w3.org/2000/svg" width="17" height="12" viewBox="0 0 17 12" fill="none">
                <path d="M0.941406 6C0.941406 2.68629 3.6277 0 6.94141 0H10.9416C14.2553 0 16.9416 2.68629 16.9416 6C16.9416 9.31371 14.2553 12 10.9416 12H6.9414C3.62769 12 0.941406 9.31371 0.941406 6Z" fill="#FFB038" />
                <path d="M5.19143 7C5.74373 7 6.19145 6.55228 6.19145 6C6.19145 5.44772 5.74373 5 5.19143 5C4.63914 5 4.19142 5.44772 4.19142 6C4.19142 6.55228 4.63914 7 5.19143 7Z" fill="white" />
                <path d="M9.19149 7C9.74378 7 10.1915 6.55228 10.1915 6C10.1915 5.44772 9.74378 5 9.19149 5C8.63919 5 8.19147 5.44772 8.19147 6C8.19147 6.55228 8.63919 7 9.19149 7Z" fill="white" />
                <path d="M13.1915 7C13.7438 7 14.1915 6.55228 14.1915 6C14.1915 5.44772 13.7438 5 13.1915 5C12.6392 5 12.1915 5.44772 12.1915 6C12.1915 6.55228 12.6392 7 13.1915 7Z" fill="white" />
              </svg>
              {{ singledata.status ? singledata.status : "" }}
            </p></v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
      <div class="px-4 pb-6">
        <v-btn v-if="singledata.status == 'COMPLETE' || singledata.status == 'CANCELED' || singledata.status == 'REJECTED'" class="rounded-pill text-none font-weight-bold" block height="40px" outlined>Place New order</v-btn>
        <v-row v-else>
          <v-col cols="6">
            <v-btn class="rounded-pill text-none font-weight-bold" block height="40px" outlined>Modify Order</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn @click="(orderdrawer = false), (canceldialog = true)" class="rounded-pill text-none font-weight-bold" block height="40px" outlined>Cancel Order</v-btn>
          </v-col>
        </v-row>
      </div>
      <v-divider class="pt-1"></v-divider>
      <div class="px-4 pt-1">
        <v-list-item-title class="txt-000 font-weight-bold fs-14 py-4"
          >Quantity
          <p class="float-right mb-0">{{ singledata.qty ? singledata.qty : "" }}</p></v-list-item-title
        >
        <v-divider></v-divider>
        <v-list-item-title class="txt-000 font-weight-bold fs-14 py-4"
          >Total trade price
          <p class="float-right mb-0">₹{{ singledata.qty ? (singledata.qty * singledata.prc).toLocaleString() : "" }}</p></v-list-item-title
        >
        <v-divider></v-divider>
        <v-list-item-title class="txt-000 font-weight-bold fs-14 py-4"
          >Last trade price
          <p class="float-right mb-0">₹{{ singledata.ltp ? singledata.ltp : "0.00" }}</p></v-list-item-title
        >
        <v-divider></v-divider>
        <v-list-item-title class="txt-000 font-weight-bold fs-14 py-4"
          >Exchange
          <p class="float-right mb-0">{{ singledata.exch ? singledata.exch : "" }}</p></v-list-item-title
        >
        <v-divider></v-divider>
        <v-list-item-title class="txt-000 font-weight-bold fs-14 py-4"
          >Validity
          <p class="float-right mb-0">END {{ singledata.ret ? singledata.ret : "" }}</p></v-list-item-title
        >
      </div>
      <v-divider class="pt-1"></v-divider>
    </v-navigation-drawer>
    <v-dialog v-model="canceldialog" max-width="400">
      <v-card class="rounded-xl elevation-0 text-center pt-8 pb-6 overflow-hidden">
        <img src="@/assets/orderbook/cancel-icon.svg" alt="cancel icon" />
        <p class="font-weight-medium mt-3 fs-22 lh-24 mb-8">
          Are you sure you want to <br />
          cancel this stock order
        </p>
        <v-row class="px-6" no-gluttters>
          <v-col cols="6">
            <v-btn @click="canceldialog = false" color="outline" class="rounded-pill text-none subtext--text font-weight-bold elevation-0" block height="40px">No</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn @click="canceldialog = false" color="btnclr" class="rounded-pill text-none btntext--text font-weight-bold elevation-0" block height="40px">Yes</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {getQuotesdata, getGttorderbook, setCancelGTT} from "../../components/mixins/getAPIdata.js";

import eventBus from "../../eventBus.js";

export default {
  data: () => ({
    orderdrawer: false,
    canceldialog: false,
    exitdialog: false,
    singledata: [],
    loading: false,

    menulist: [
      {name: "Create GTT / GTC", icon: 4, type: "cGTT"},
      {name: "Create Alert", icon: 5, type: "alert"},
      {name: "Market Depth", icon: 6, type: "depth"},
      {name: "Chart", icon: 7, type: "chart", hr: true},
      {name: "Fundamentals", icon: 9, type: "Funda"},
      {name: "Details", icon: 10, type: ""},
    ],
    opensearch: null,
    orderbookdata: [],
    orderbooks: [],
  }),
  computed: {
    orderheader() {
      return [
        {text: "Instrument", align: "tsym", sortable: false, value: "exch"},
        {text: "Buy/Sell", value: "trantype"},
        {text: "Product type", value: "orderType"},
        {text: "Qty", value: "qty", sortable: false},
        {text: "Condition", value: "condition"},
        {text: "Trig Value", value: "trigValue"},
        {text: "", value: "actions", sortable: false},
      ];
    },
  },
  mounted() {
    let res = sessionStorage.getItem("c3RhdHVz");
    if (res == "dmFsaWR1c2Vy") {
      this.getOrderbook();
    } else {
      this.$router.push("/");
    }

    eventBus.$on("orderbook-update", (book) => {
      if (book == "gtt" || (this.orderbookdata && this.orderbookdata.length > 0 && book == "orders")) {
        this.getOrderbook();
      }
    });
  },
  beforeDestroy() {
    eventBus.$off("orderbook-update");
  },
  methods: {
    async getOrderbook() {
      this.loading = true;
      this.orderbookdata = [];
      let data = await getGttorderbook();

      let pengtt = JSON.parse(JSON.stringify(data));
      if (pengtt[0] && pengtt[0].stat == "Ok") {
        pengtt.map((data) => {
          this.orderbookdata.push({
            trigValue: data.d,
            condition: data.ai_t.includes("_A_O") ? ">" : data.ai_t.includes("_B_O") ? "<" : "Select",
            qty: data.qty,
            orderType: data.prd == "C" ? "Delivery (CNC)" : data.prd == "M" ? "Carry Forward (NRML)" : data.prd == "I" ? "Intraday" : "Select",
            trantype: data.trantype,
            tsym: data.tsym,
            exch: data.exch,
            token: data.token,
            al_id: data.al_id,
            res: data,
          });
        });
      }
      this.loading = false;
    },
    async setGTTcancel(item) {
      let uid = sessionStorage.getItem("userid");
      let tok = sessionStorage.getItem("msession");
      let data = await setCancelGTT(`jData={"uid":"${uid}","al_id":"${item.al_id}"}&jKey=${tok}`);

      let penalerts = JSON.parse(JSON.stringify(data));
      if (penalerts.stat == "OI deleted") {
        eventBus.$emit("snack-event", 1, "GTT Order have been Cancelled");
        let Index = this.orderbookdata.findIndex((script) => script.al_id == item.al_id);
        this.orderbookdata.splice(Index, 1);
      } else {
        eventBus.$emit("snack-event", 0, penalerts);
      }
    },
    setSSDtab(type, token, exch, tsym, trans, item) {
      if (type == "alert") {
        eventBus.$emit("menudialog", "alert", token, exch, tsym);
      } else if (type == "cGTT") {
        this.positiondrawer = false;
        eventBus.$emit("menudialog", "order-GTT", token, exch, tsym, "b");
      } else if (type == "modify") {
        eventBus.$emit("menudialog", "mod-GTT", token, exch, tsym, trans.toLowerCase(), item);
      } else {
        let path = [type, token, exch, tsym];
        this.$router.push({name: "stocks details", params: {val: path}});
      }
    },
    async setOrderrowdata(item) {
      this.singledata = {};
      this.orderdrawer = true;
      this.singledata = item;
      this.singledata["quotes"] = await getQuotesdata(`${item.exch}|${item.token}`);
    },
  },
};
</script>
