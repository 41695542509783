<template>
  <div>
    <v-progress-linear v-if="lwloader" color="primary" indeterminate rounded></v-progress-linear>
    <div id="containerlw" class="chart-container mt-4 mb-2"></div>
    <div>
      <v-chip-group mandatory active-class="primary--text" class="" v-model="toggle" @change="setData(toggle)">
        <v-chip outlined small>1D</v-chip>
        <v-chip outlined small>1W</v-chip>
        <v-chip outlined small>1M</v-chip>
        <v-chip outlined small>YTD</v-chip>
        <v-chip outlined small>1Y</v-chip>
        <v-chip outlined small>MAX</v-chip>
      </v-chip-group>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mynturl } from "../apiurl";
import eventBus from "../eventBus.js";
import { createChart } from "lightweight-charts";

export default {
  props: {
    propstsym: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      symbol: {},
      lwloader: true,
      toggle: 0,
      userid: null,
      usession: null,

      chart: null,
      lineSeries: null,

      chartdata: [],

      law: null,
      isChartActive: true,
    };
  },
  methods: {
    async initializeData(resolution, init) {
      this.lwloader = false;
      this.chartdata = [];
      this.lineSeries.setData(this.chartdata);
      const ep = await this.getEpochRanges(resolution);
      var raw;
      let symName = this.symbol.tsym
        .replace(/\$OISYMBOL/, "")
        .replace(/ /g, "%20")
        .replace(/&/g, "%26");
      let data;
      var inx = this.symbol.instname == "UNDIND" || this.symbol.instname == "COM" ? true : false;
      var flow = resolution == "1D" || (resolution == "1W" && !inx) ? true : false;
      if (flow) {
        data = `jData={"uid":"${this.userid}","exch":"${this.symbol.exch}","token":"${symName}","st":"${ep.to}","et":"${ep.fr}","intrv":"${resolution == "1D" ? 1 : 5}"}&jKey=${this.usession}`;
      } else {
        data = `jData={"sym":"${this.symbol.exch}:${symName}","from":"${ep.to}","to":"${ep.fr}"}&jKey=${this.usession}`;
      }

      let config = {
        method: "post",
        url: `${mynturl.myntapi}${flow ? "TPSeries" : "EODChartData"}`,
        headers: {
          "Content-Type": "text/plain",
        },
        data: data,
      };

      let self = this;
      axios
        .request(config)
        .then((response) => {
          raw = flow ? response.data : response.data.map(JSON.parse);
          if (raw && raw.length > 0) {
            raw = raw.sort((a, b) => parseFloat(a.ssboe) - parseFloat(b.ssboe));
            for (var i = 0; i < raw.length; i++) {
              var r = { time: flow ? self.setEpoch(raw[i].ssboe) : self.convertToExpectedFormat(raw[i].time), value: flow ? Number(raw[i].intvwap) : Number(raw[i].intc) };
              self.chartdata.push(r);
            }
            self.lineSeries.setData(self.chartdata);
            // const adjustedRange = {
            //   from: flow ? parseFloat(raw[0].ssboe) : self.convertToExpectedFormat(raw[0].time),
            //   to: flow ? parseFloat(raw[raw.length - 1].ssboe) : self.convertToExpectedFormat(raw[raw.length - 1].time),
            // };
            // self.chart.timeScale().setVisibleRange(adjustedRange);
            self.law = self.convertToExpectedFormat(new Date());
            if (self.isChartActive && self.chart) {
              self.chart.timeScale().fitContent();
            }
            self.lwloader = false;
          }

          if (init && resolution == "1D" && raw && raw.emsg && raw.emsg.includes('no data')) {
            self.setData(1);
            self.toggle = 1;
          }
        })
        .catch((error) => {
          self.lwloader = false;
          console.log(error);
        });
    },
    convertToExpectedFormat(dateTimeString) {
      let date = new Date(dateTimeString);
      let year = date.getFullYear();
      let month = String(date.getMonth() + 1).padStart(2, "0");
      let day = String(date.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    },
    setData(range) {
      if (this.lineSeries) {
        this.initializeData(range == 0 ? "1D" : range == 1 ? "1W" : range == 2 ? "1M" : range == 3 ? "1Y" : range == 4 ? "YTD" : range == 5 ? "MAX" : range);
      }
    },

    getEpochRanges(range) {
      const now = new Date();
      const toEpoch = (date) => Math.floor(date.getTime() / 1000);

      const ranges = {
        "1D": toEpoch(new Date(new Date().setHours(0, 0, 0, 0))),
        "1W": toEpoch(new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000)),
        "1M": toEpoch(new Date(now.getFullYear(), now.getMonth() - 1, now.getDate())),
        "1Y": toEpoch(new Date(now.getFullYear() - 1, now.getMonth(), now.getDate())),
        YTD: toEpoch(new Date(now.getFullYear(), 0, 1)),
        MAX: toEpoch(new Date(1970, 0, 1)),
      };

      return {
        fr: toEpoch(now),
        to: ranges[range] || null,
      };
    },
    setEpoch(dateStr) {
      return Number(dateStr) + 19800;
    },
    showDateformat(date) {
      if (this.toggle == 0 || (this.toggle == 1 && (this.symbol.instname == "UNDIND" || this.symbol.instname == "COM"))) {
        const dateo = new Date((date - 19800) * 1000);
        let hours = dateo.getHours();
        const minutes = dateo.getMinutes();
        const ampm = hours >= 12 ? "PM" : "AM";
        hours = hours % 12 || 12;
        const minutesStr = minutes.toString().padStart(2, "0");
        return `${hours}:${minutesStr} ${ampm}`;
      } else {
        const datet = new Date(date);
        const day = datet.getDate().toString().padStart(2, "0");
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = monthNames[datet.getMonth()];
        const year = datet.getFullYear().toString();
        return `${day} ${month} ${year}`;
      }
    },
    optionChainDataParse(data) {
      let w = this.chartdata.findIndex((o) => o.time == this.law);
      if (this.toggle != 0 && (this.symbol.instname == "UNDIND" || this.symbol.instname == "COM")) {
        if (w && w >= 0) {
          this.chartdata[w].value = Number(data.lp);
          this.lineSeries.update(this.chartdata[w]);
        } else {
          this.chartdata.push({ time: this.law, value: Number(data.lp) });
        }
      }
    },
    setLWchart(init) {
      this.userid = sessionStorage.getItem("userid");
      this.usession = sessionStorage.getItem("msession");
      const chartOptions = {
        rightPriceScale: {
          visible: false,
          borderVisible: false,
        },
        leftPriceScale: {
          visible: false,
        },
        timeScale: {
          visible: false,
          timeVisible: true,
        },
        handleScroll: {
          mouseWheel: false,
          pressedMouseMove: false,
          horzTouchDrag: false,
          vertTouchDrag: false,
        },
        handleScale: {
          axisPressedMouseMove: false,
          mouseWheel: false,
          pinch: false,
        },

        layout: {
          background: { type: "solid", color: this.$vuetify.theme.dark ? "#121212" : "#fff" },
          textColor: this.$vuetify.theme.dark ? "#fff" : "#000",
        },
        grid: {
          vertLines: {
            visible: false,
            color: this.$vuetify.theme.dark ? "#222222" : "#EBEEF0",
          },
          horzLines: {
            visible: false,
            color: this.$vuetify.theme.dark ? "#222222" : "#EBEEF0",
          },
        },
      };
      /** @type {import('lightweight-charts').IChartApi} */
      this.chart = createChart(document.getElementById("containerlw"), chartOptions);

      this.lineSeries = this.chart.addAreaSeries({
        topColor: this.$vuetify.theme.dark ? "#2E65F62c" : "#0037B72c",
        bottomColor: this.$vuetify.theme.dark ? "#2E65F600" : "#0037B700",
        lineColor: this.$vuetify.theme.dark ? "#2E65F6" : "#0037B7",
        // color: this.$vuetify.theme.dark ? "#2E65F6" : "#0037B7",
        lineWidth: 2,
        crossHairMarkerVisible: false,
      });
      this.toggle = 0;
      this.initializeData("1D", init);

      const container = document.getElementById("containerlw");

      const toolTipWidth = 80;
      const toolTipHeight = 80;
      const toolTipMargin = 16;

      const toolTip = document.createElement("div");
      toolTip.style = `position: absolute; display: none; box-sizing: border-box; z-index: 1000; top: 12px; left: 12px; pointer-events: none; border: 1px solid; border-radius: 6px;`;
      toolTip.style.background = this.$vuetify.theme.dark ? "#000" : "#fff";
      toolTip.style.borderColor = "var(--outline)";
      container.appendChild(toolTip);

      this.chart.subscribeCrosshairMove((param) => {
        if (param.point === undefined || !param.time || param.point.x < 0 || param.point.x > container.clientWidth || param.point.y < 0 || param.point.y > container.clientHeight) {
          toolTip.style.display = "none";
        } else {
          // time will be in the same format that we supplied to setData.
          // thus it will be YYYY-MM-DD
          const dateStr = param.time;
          toolTip.style.display = "block";
          const data = param.seriesData.get(this.lineSeries);
          const price = data.value !== undefined ? data.value : data.close;
          toolTip.innerHTML = `<div class=" rounded-lg px-2 pt-2 pb-1"><div class="font-weight-medium maintext--text fs-14">${this.symbol.tsym}</div> <div class="font-weight-bold maintext--text fs-16 py-1">₹${Math.round(100 * price) / 100
            }</div> <div class="font-weight-medium subtext--text fs-12">${this.showDateformat(dateStr)}</div></div>`;

          const y = param.point.y;
          let left = param.point.x + toolTipMargin;
          if (left > container.clientWidth - toolTipWidth) {
            left = param.point.x - toolTipMargin - toolTipWidth;
          }

          let top = y + toolTipMargin;
          if (top > container.clientHeight - toolTipHeight) {
            top = y - toolTipHeight - toolTipMargin;
          }
          toolTip.style.left = left + "px";
          toolTip.style.top = top + "px";
        }
      });
    },
  },
  beforeDestroy() {
    this.isChartActive = false;
    if (this.chart) {
      this.chart.remove();
      this.chart = null;
    }
  },
  mounted() {
    this.symbol = this.propstsym;
    this.setLWchart(true);

    eventBus.$on("web-scoketConn", (data) => {
      if (data && data.token == this.symbol.token && this.chart && this.lineSeries && this.chartdata && this.chartdata.length > 0) {
        this.optionChainDataParse(data);
      }
    });
  },
};
</script>

<style>
.chart-container {
  position: relative;
  width: 100%;
  height: 40vh !important;
}
</style>
