<template>
  <div>
    <v-row no-glutters>
      <v-col cols="12">
        <p class="title font-weight-bold mb-3">Mutual funds categories</p>
        <v-card v-if="!mfallsearchloader" class="d-inline-flex overflow-x-auto elevation-0 no-scroll mb-0 crd-trn" width="100%">
          <v-card v-for="(n, j, k) in mfcategorie.cats" :key="k" class="px-3 py-2 mr-4" min-width="160px" outlined>
            <v-list-item-title class="subtitle-2 font-weight-bold mb-2 text-capitalize">{{ j ? j : "" }} funds</v-list-item-title>
            <v-card class="pt-02 mb-3 elevation-0 rounded-pill" width="30%" color="maintext"></v-card>
            <p class="fs-12 subtext--text font-weight-medium mb-0">{{ n ? n : "" }} funds</p>
          </v-card>
        </v-card>
        <v-card v-else class="d-inline-flex overflow-x-auto elevation-0 no-scroll mb-0 crd-trn" width="100%">
          <v-skeleton-loader v-for="n in 10" :key="n" class="py-2 mr-4" min-width="160" height="99" type="card"></v-skeleton-loader>
        </v-card>
      </v-col>
      <v-col cols="3" class="d-none d-md-none"> </v-col>
      <v-col cols="12">
        <v-card style="border: 1px solid var(--outline)" outlined class="elevation-0 rounded-lg mb-8 mb-md-0 crd-trn">
          <v-toolbar class="tool-sty elevation-0 py-4 mb-7 px-4 crd-trn" dense>
            <v-list-item v-if="bestmfdata.funds" class="pl-0">
              <v-list-item-icon class="mr-4">
                <img :src="require(`@/assets/mf/bmf_${bestmfdata.image.split('/images/')[1].split('.')[0]}.svg`)" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold title">{{ bestmfdata.title }}</v-list-item-title>
                <v-list-item-subtitle class="fs-12 txt-666">{{ bestmfdata.subtitle }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-spacer></v-spacer>
            <div>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold subtitle-1">
                    <span class="mr-2"
                      ><svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
                        <path d="M1.5 2.5H11.5" stroke="#0037B7" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M14.5 2.5H16.5" stroke="#0037B7" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M14.5 6.5H16.5" stroke="#0037B7" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M14.5 10.5H16.5" stroke="#0037B7" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M14.5 14.5H16.5" stroke="#0037B7" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M1.5 6.5H7.5" stroke="#0037B7" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M1.5 10.5H11.5" stroke="#0037B7" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M1.5 14.5H6.5" stroke="#0037B7" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" /></svg
                    ></span>
                    {{ mutualuseritems ? mutualuseritems.length : "0" }} funds
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>

            <v-btn @click="mobilefilter = true" icon class="px-0">
              <v-badge :value="mfcategorie.cat || mfcategorie.sub || mfcategorie.amc" dot>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12" viewBox="0 0 18 12" fill="none">
                  <path d="M4 6H14M1.5 1H16.5M6.5 11H11.5" stroke="#999" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </v-badge>
            </v-btn>
            <v-text-field style="max-width: 160px" v-model="opensearch" hide-details prepend-inner-icon="mdi-magnify" label="Search" class="rounded-pill ml-1 d-none d-md-flex" dense flat solo background-color="secbg"></v-text-field>
          </v-toolbar>
          <v-data-table
            must-sort
            :sort-by="['']"
            :sort-desc="[false]"
            hide-default-footer
            fixed-header
            :loading="mftableloader"
            :search="opensearch"
            mobile-breakpoint
            class="rounded-0 overflow-y-auto mftable-responsive"
            :headers="tradeheader"
            height="calc(100vh - 180px)"
            :items="mftabledata"
            :items-per-page="mftabledata && mftabledata.length > 0 ? showtable : 0"
            item-key="Scheme_Code"
          >
            <template v-slot:body="{items, headers}">
              <tbody v-if="mftabledata && mftabledata.length > 0">
                <!-- @click="setMFrowdata(item)" -->
                <tr class="table-row" v-for="(item, o) in items" :key="o">
                  <td class="pos-rlt" cols="1">
                    <P @click="setSinglepage(item)" style="width: 68%" class="fs-13 font-weight-medium mb-0 maintext--text table-hov-text txt-dec-cust mt-2">
                      {{ item.f_scheme_name ? setNamehide(item.f_scheme_name) : "" }}
                    </P>
                    <v-chip-group column class="mb-1">
                      <v-chip color="secbg" x-small class="table-hov-prd" text-color="subtext" style="border-radius: 5px; padding: 10px 8px !important">
                        <span class="font-weight-medium fs-10">
                          {{ item.splito ? item.splito : "" }}
                        </span>
                      </v-chip>
                      <v-chip color="secbg" x-small class="table-hov-prd" text-color="subtext" style="border-radius: 5px; padding: 10px 8px !important">
                        <span class="font-weight-medium fs-10">
                          {{ item.splitt ? item.splitt : "" }}
                        </span>
                      </v-chip>
                      <v-chip color="secbg" x-small class="table-hov-prd" text-color="subtext" style="border-radius: 5px; padding: 10px 8px !important">
                        <span class="font-weight-medium fs-10">
                          {{ item.splitr ? item.splitr : "" }}
                        </span>
                      </v-chip>
                    </v-chip-group>

                    <div @click.stop class="pos-abs table-hov mr-n6" style="top: 8px; right: 0">
                      <v-btn v-if="uid" @click="putMForder(item, 'buy')" small class="elevation-0 rounded-pill text-none maingreen--text font-weight-bold mr-3 mt-3" color="secgreen">Buy</v-btn>
                      <v-btn v-if="uid && item.SIP_FLAG == 'Y'" @click="putMForder(item, 'sip')" small class="elevation-0 rounded-pill text-none primary--text font-weight-bold mr-sm-3 mt-3" color="secbg">SIP</v-btn>

                      <v-btn v-if="!uid" @click="$emit('gologin')" small class="elevation-0 rounded-pill text-none primary--text font-weight-bold mr-3 mt-3" color="secbg">Invest</v-btn>

                      <v-btn v-if="uid" @click="getusedMutual(item)" style="border: 1px solid var(--outline)" min-width="20px" color="mainbg" class="px-0 elevation-0 mt-4 d-none d-sm-flex" x-small>
                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="12" viewBox="0 0 10 12" fill="none">
                          <path
                            d="M9 11L5 8.61905L1 11V1.95238C1 1.69979 1.09365 1.45755 1.26035 1.27895C1.42705 1.10034 1.65314 1 1.88889 1H8.11111C8.34686 1 8.57295 1.10034 8.73965 1.27895C8.90635 1.45755 9 1.69979 9 1.95238V11Z"
                            stroke="#999"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </v-btn>
                    </div>
                  </td>
                  <td class="text-right font-weight-medium">{{ item.AUM ? (item.AUM / 10000000).toFixed(2) : "0.00" }}</td>
                  <td class="text-right font-weight-medium">{{ item.NET_ASSET_VALUE ? Number(item.NET_ASSET_VALUE).toFixed(2) : "0.00" }}</td>
                  <td class="text-right font-weight-medium">{{ item.THREE_YEAR_DATA ? Number(item.THREE_YEAR_DATA).toFixed(2) : "0.00" }}</td>
                  <td class="text-right font-weight-medium">
                    <span class="font-weight-medium maintext--text mb-0">₹{{ item.Minimum_Purchase_Amount ? Number(item.Minimum_Purchase_Amount).toFixed(2) : "0.00" }}</span>
                  </td>
                </tr>
                <tr v-if="showtable < mftabledata.length">
                  <td :colspan="headers.length">
                    <v-btn v-if="!opensearch" color="primary" plain class="elevation-0 text-none font-weight-bold py-4" @click="showtable += 24" block>show more</v-btn>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <td :colspan="headers.length" class="text-center">
                  <div class="mx-auto py-16 mt-16">
                    <img class="mx-auto" width="80px" :src="require('@/assets/no data folder.svg')" />
                    <h4 class="txt-999 font-weight-regular caption">There is no data here yet!</h4>
                  </div>
                </td>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col cols="3" class="pt-2 pr-0 d-none d-md-none">
        <v-toolbar @click="addscript = true" flat dense class="tool-sty">
          <p class="mb-0 font-weight-bold">
            Filters
            <v-chip class="ml-1" v-if="mfcategorie.cat || mfcategorie.sub || mfcategorie.amc" x-small color="secbg" text-color="subtext" style="border-radius: 5px; padding: 10px 8px !important"
              >clear all<v-icon @click="(mfcategorie.cat = ''), (mfcategorie.sub = ''), (mfcategorie.amc = ''), setChangewl()" size="14" color="#666" class="ml-2"> mdi-close </v-icon>
            </v-chip>
          </p>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-chip-group v-if="mfcategorie.cat != '' || mfcategorie.sub != '' || mfcategorie.amc != ''" column class="my-0">
          <v-chip class="rounded-lg" v-if="mfcategorie.cat" color="secbg" text-color="subtext" style="border-radius: 5px; padding: 10px 8px !important">
            {{ mfcategorie.cat.replace("Scheme", "") }} <v-icon @click="(mfcategorie.cat = ''), setChangewl()" size="14" color="#666" class="ml-2"> mdi-close </v-icon>
          </v-chip>
          <v-chip class="rounded-lg" v-if="mfcategorie.sub" color="secbg" text-color="subtext" style="border-radius: 5px; padding: 10px 8px !important"> {{ mfcategorie.sub }} <v-icon @click="(mfcategorie.sub = ''), setChangewl()" size="14" color="#666" class="ml-2"> mdi-close </v-icon> </v-chip>
          <v-chip class="rounded-lg" v-if="mfcategorie.amc" color="secbg" text-color="subtext" style="border-radius: 5px; padding: 10px 8px !important">
            {{ mfcategorie.amc.split("_")[0] }} <v-icon @click="(mfcategorie.amc = ''), setChangewl()" size="14" color="#666" class="ml-2"> mdi-close </v-icon>
          </v-chip>
        </v-chip-group>
        <v-expansion-panels v-model="panel" class="expan">
          <v-expansion-panel>
            <v-expansion-panel-header class="px-0">
              <span>
                <v-badge :value="mfcategorie.cat" dot> Category </v-badge>
                <span class="float-right mr-3 fs-12">{{ mfcategorie.cats ? Object.keys(mfcategorie.cats).length : "" }}</span>
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="px-0">
              <div>
                <div v-for="(w, j, l) in mfcategorie.cats" :key="l">
                  <v-list-item @click="mfcategorie.cat != j ? (mfcategorie.cat = j) : (mfcategorie.cat = ''), setChangewl()" class="crd-trn px-2">
                    <v-card class="pa-2 elevation-0 mr-2" :color="mfcategorie.cat == j ? '#0037B7' : '#F1F3F8'"></v-card>
                    <v-list-item-content>
                      <v-list-item-title class="fs-12 text-capitalize" :style="`color:${mfcategorie.cat == j ? '#0037B7' : '#000000'};`"> {{ j.replace("Scheme", "") }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="px-0">
              <span>
                <v-badge :value="mfcategorie.sub" dot> Sub category </v-badge>
                <span class="float-right mr-3 fs-12">{{ mfcategorie.subs ? Object.keys(mfcategorie.subs).length : "" }}</span>
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="px-0">
              <div class="c-side-expand-panel">
                <div v-for="(w, j, l) in mfcategorie.subs" :key="l">
                  <v-list-item @click="mfcategorie.sub != j ? (mfcategorie.sub = j) : (mfcategorie.sub = ''), setChangewl()" class="px-2 crd-trn">
                    <v-card class="pa-2 elevation-0 mr-2" :color="mfcategorie.sub == j ? '#0037B7' : '#F1F3F8'"></v-card>
                    <v-list-item-content>
                      <v-list-item-title class="fs-12 text-capitalize" :style="`color:${mfcategorie.sub == j ? '#0037B7' : '#000000'};`"> {{ j }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="px-0">
              <span>
                <v-badge :value="mfcategorie.amc" dot> AMC </v-badge>
                <span class="float-right mr-3 fs-12">{{ mfcategorie.amcs ? Object.keys(mfcategorie.amcs).length : "" }}</span>
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="px-0">
              <div class="c-side-expand-panel">
                <div v-for="(w, j, l) in mfcategorie.amcs" :key="l">
                  <v-list-item @click="mfcategorie.amc != j ? (mfcategorie.amc = j) : (mfcategorie.amc = ''), setChangewl()" class="px-2 crd-trn">
                    <v-card class="pa-2 elevation-0 mr-2" :color="mfcategorie.amc == j ? '#0037B7' : '#F1F3F8'"></v-card>
                    <v-list-item-content>
                      <v-list-item-title class="fs-12 text-lowercase" :style="`color:${mfcategorie.amc == j ? '#0037B7' : '#000000'};`"> {{ j.split("_")[0] }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="px-0">
              <span id="panel3"> Min. purchase amount </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="px-0">
              <v-range-slider @change="setChangewl()" color="#FF1717" v-model="mfcategorie.minamt" :max="mfcategorie.minamt_max" :min="mfcategorie.minamt_min" hide-details :step="mfcategorie.minamt_max / 50" class="align-center mt-4">
                <template v-slot:prepend>
                  <p class="fs-12 black-text mb-0 text-right">
                    <span class="fs-10 subtext--text">MIN</span><br />
                    ₹{{ mfcategorie.minamt[0] < 1000 ? mfcategorie.minamt[0] : `${(mfcategorie.minamt[0] / 1000).toFixed(0)}k` }}
                  </p>
                </template>

                <template v-slot:append>
                  <p class="fs-12 black-text mb-0">
                    <span class="fs-10 subtext--text">MAX</span><br />
                    ₹200k
                  </p>
                </template>
              </v-range-slider>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="px-0">
              <span id="panel4"> AUM </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="px-0">
              <v-range-slider @change="setChangewl()" color="#FF1717" v-model="mfcategorie.rangeaum" :max="mfcategorie.aum_max" :min="mfcategorie.aum_min" hide-details :step="mfcategorie.aum_max / 50" class="align-center mt-4">
                <template v-slot:prepend>
                  <p class="fs-12 black-text mb-0 text-right">
                    <span class="fs-10 subtext--text">MIN</span><br />
                    ₹{{ (mfcategorie.rangeaum[0] / 10000000 / 1000).toFixed(0) }}k <span class="subtext--text">Cr</span><br />
                  </p>
                </template>

                <template v-slot:append>
                  <p class="fs-12 black-text mb-0">
                    <span class="fs-10 subtext--text">MAX</span><br />
                    ₹{{ (mfcategorie.rangeaum[1] / 10000000 / 1000).toFixed(0) }}k <span class="subtext--text">Cr</span><br />
                  </p>
                </template>
              </v-range-slider>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-bottom-sheet v-model="mobilefilter" class="d-md-none">
      <v-sheet class="rounded-t-xl px-4" height="70vh">
        <div @click="mobilefilter = false" class="text-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="3" viewBox="0 0 32 3" fill="none">
            <rect width="32" height="3" rx="1.5" fill="#DDDDDD" fill-opacity="0.866667" />
          </svg>
        </div>
        <v-chip-group v-if="mfcategorie.cat != '' || mfcategorie.sub != '' || mfcategorie.amc != ''" column class="my-0">
          <v-chip class="rounded-lg" v-if="mfcategorie.cat" color="secbg" text-color="subtext" x-small style="border-radius: 5px; padding: 10px 8px !important">
            {{ mfcategorie.cat.replace("Scheme", "") }} <v-icon @click="(mfcategorie.cat = ''), setChangewl()" size="14" color="#666" class="ml-2"> mdi-close </v-icon>
          </v-chip>
          <v-chip class="rounded-lg" v-if="mfcategorie.sub" color="secbg" text-color="subtext" x-small style="border-radius: 5px; padding: 10px 8px !important">
            {{ mfcategorie.sub }} <v-icon @click="(mfcategorie.sub = ''), setChangewl()" size="14" color="#666" class="ml-2"> mdi-close </v-icon>
          </v-chip>
          <v-chip class="rounded-lg" v-if="mfcategorie.amc" color="secbg" text-color="subtext" x-small style="border-radius: 5px; padding: 10px 8px !important">
            {{ mfcategorie.amc.split("_")[0] }} <v-icon @click="(mfcategorie.amc = ''), setChangewl()" size="14" color="#666" class="ml-2"> mdi-close </v-icon>
          </v-chip>

          <v-chip class="rounded-lg" color="secbg" text-color="subtext" x-small style="border-radius: 5px; padding: 10px 8px !important">
            Clear all <v-icon @click="(mfcategorie.cat = ''), (mfcategorie.sub = ''), (mfcategorie.amc = ''), setChangewl(), (mobilefilter = false)" size="14" color="#666" class="ml-2"> mdi-close </v-icon>
          </v-chip>
        </v-chip-group>
        <v-expansion-panels v-model="panel" class="expan">
          <v-expansion-panel>
            <v-expansion-panel-header class="px-0">
              <span>
                <v-badge :value="mfcategorie.cat" dot> Category </v-badge>
                <span class="float-right mr-3 fs-12">{{ mfcategorie.cats ? Object.keys(mfcategorie.cats).length : "" }}</span>
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="px-0">
              <div>
                <div v-for="(w, j, l) in mfcategorie.cats" :key="l">
                  <v-list-item @click="mfcategorie.cat != j ? (mfcategorie.cat = j) : (mfcategorie.cat = ''), setChangewl()" class="px-2 crd-trn">
                    <v-card class="pa-2 elevation-0 mr-2" :color="mfcategorie.cat == j ? '#0037B7' : '#F1F3F8'"></v-card>
                    <v-list-item-content>
                      <v-list-item-title class="fs-12 text-capitalize" :style="`color:${mfcategorie.cat == j ? '#0037B7' : '#000000'};`"> {{ j.replace("Scheme", "") }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="px-0">
              <span>
                <v-badge :value="mfcategorie.sub" dot> Sub category </v-badge>
                <span class="float-right mr-3 fs-12">{{ mfcategorie.subs ? Object.keys(mfcategorie.subs).length : "" }}</span>
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="px-0">
              <div class="c-side-expand-panel">
                <div v-for="(w, j, l) in mfcategorie.subs" :key="l">
                  <v-list-item @click="mfcategorie.sub != j ? (mfcategorie.sub = j) : (mfcategorie.sub = ''), setChangewl()" class="px-2 crd-trn">
                    <v-card class="pa-2 elevation-0 mr-2" :color="mfcategorie.sub == j ? '#0037B7' : '#F1F3F8'"></v-card>
                    <v-list-item-content>
                      <v-list-item-title class="fs-12 text-capitalize" :style="`color:${mfcategorie.sub == j ? '#0037B7' : '#000000'};`"> {{ j }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="px-0">
              <span>
                <v-badge :value="mfcategorie.amc" dot> AMC </v-badge>
                <span class="float-right mr-3 fs-12">{{ mfcategorie.amcs ? Object.keys(mfcategorie.amcs).length : "" }}</span>
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="px-0">
              <div class="c-side-expand-panel">
                <div v-for="(w, j, l) in mfcategorie.amcs" :key="l">
                  <v-list-item @click="mfcategorie.amc != j ? (mfcategorie.amc = j) : (mfcategorie.amc = ''), setChangewl()" class="px-2 crd-trn">
                    <v-card class="pa-2 elevation-0 mr-2" :color="mfcategorie.amc == j ? '#0037B7' : '#F1F3F8'"></v-card>
                    <v-list-item-content>
                      <v-list-item-title class="fs-12 text-lowercase" :style="`color:${mfcategorie.amc == j ? '#0037B7' : '#000000'};`"> {{ j.split("_")[0] }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="px-0">
              <span id="panel3"> Min. purchase amount </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="px-0 pb-4">
              <v-range-slider @change="setChangewl()" color="#FF1717" v-model="mfcategorie.minamt" :max="mfcategorie.minamt_max" :min="mfcategorie.minamt_min" hide-details :step="mfcategorie.minamt_max / 50" class="align-center mt-4">
                <template v-slot:prepend>
                  <p class="fs-12 black-text mb-0 text-right">
                    <span class="fs-10 subtext--text">MIN</span><br />
                    ₹{{ mfcategorie.minamt[0] < 1000 ? mfcategorie.minamt[0] : `${(mfcategorie.minamt[0] / 1000).toFixed(0)}k` }}
                  </p>
                </template>
                <template v-slot:append>
                  <p class="fs-12 black-text mb-0">
                    <span class="fs-10 subtext--text">MAX</span><br />
                    ₹200k
                  </p>
                </template>
              </v-range-slider>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="px-0">
              <span id="panel4"> AUM </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="px-0 pb-4">
              <v-range-slider @change="setChangewl()" color="#FF1717" v-model="mfcategorie.rangeaum" :max="mfcategorie.aum_max" :min="mfcategorie.aum_min" hide-details :step="mfcategorie.aum_max / 50" class="align-center mt-4">
                <template v-slot:prepend>
                  <p class="fs-12 black-text mb-0 text-right">
                    <span class="fs-10 subtext--text">MIN</span><br />
                    ₹{{ (mfcategorie.rangeaum[0] / 10000000 / 1000).toFixed(0) }}k <span class="subtext--text">Cr</span><br />
                  </p>
                </template>
                <template v-slot:append>
                  <p class="fs-12 black-text mb-0">
                    <span class="fs-10 subtext--text">MAX</span><br />
                    ₹{{ (mfcategorie.rangeaum[1] / 10000000 / 1000).toFixed(0) }}k <span class="subtext--text">Cr</span><br />
                  </p>
                </template>
              </v-range-slider>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
// import apiurl from "../../../apiurl.js";
import eventBus from "../../../eventBus.js";
import {getBestMF} from "@/components/mixins/getAPIdata";

export default {
  data: () => ({
    uid: "",
    token: "",
    mutualuseritems: [],
    mftabledata: [],
    mftableloader: true,
    showtable: 24,
    opensearch: null,

    panel: null,

    allmutualfunddata: [],
    mfcategorie: [],
    model: null,
    items: [],
    search: null,
    nodata: null,
    mfallsearchloader: true,

    bestmfdata: [],
    mobilefilter: false,
  }),
  computed: {
    tradeheader() {
      return [
        {text: "Fund name", value: "f_scheme_name", sortable: false},
        {text: "AUM (cr)", value: "AUM", align: "right", sortable: false},
        {text: "NAV", value: "NET_ASSET_VALUE", align: "right", sortable: false},
        {text: "3yr", value: "THREE_YEAR_DATA", align: "right", sortable: false},
        {text: "Min. Invest", value: "Minimum_Purchase_Amount", sortable: false, align: "right"},
      ];
    },
  },
  created() {
    //
  },
  mounted() {
    eventBus.$emit("tabBar-load");
    this.$emit("receive", "mf", "Category");
    eventBus.$on("setRec-event", (mfdata) => {
      if (mfdata == "stat_ok") {
        this.$emit("receive", "mf", "Category");
      } else {
        let res = mfdata;
        if (res && res.stat && res.stat == "Ok") {
          this.allmutualfunddata = [];
          this.allmutualfunddata = res.data;
          this.setMFcatdata();
          this.mfcategorie = [];
          this.mfcategorie["cats"] = res.cat;
          this.mfcategorie["subs"] = res.sub;
          this.mfcategorie["amcs"] = res.amc;
          this.mfcategorie["cat"] = "";
          this.mfcategorie["sub"] = "";
          this.mfcategorie["amc"] = "";

          this.mfcategorie["minamt_min"] = Math.min(...this.allmutualfunddata.map((o) => Number(o.Minimum_Purchase_Amount)));
          this.mfcategorie["minamt_max"] = 200000;
          this.mfcategorie["aum_min"] = 0;
          this.mfcategorie["aum_max"] = Math.max(...this.allmutualfunddata.map((o) => Number(o.AUM)));

          this.mfcategorie["minamt"] = [this.mfcategorie.minamt_min, this.mfcategorie.minamt_max];
          this.mfcategorie["rangeaum"] = [this.mfcategorie.aum_min, this.mfcategorie.aum_max];
        } else if (res) {
          eventBus.$emit("snack-event", 2, res.msg ? res.msg : res);
          this.mfallsearchloader = false;
        }
      }
    });
  },
  beforeDestroy() {
    eventBus.$off("setRec-event");
  },

  methods: {
    setNamehide(name) {
      return name && name.length > 30 ? `${name.slice(0, 30)}...` : name;
    },
    setSinglepage(item) {
      this.$router.push({name: "mutual fund single", params: item});
    },
    async setMFcatdata() {
      let params = this.$route.params;
      if (params.best) {
        this.bestmfdata = params.best;
      } else {
        let bestmfdata = await getBestMF();
        if (bestmfdata != 500) {
          this.bestmfdata = bestmfdata.data[0];
        } else {
          eventBus.$emit("snack-event", 0, "AxiosError: Network Error");
        }
      }

      this.mutualuseritems = [];
      this.mutualuseritems = this.allmutualfunddata.filter((item) => {
        if (item.splito == "GROWTH") {
          if (this.bestmfdata.title == "Save taxes") {
            return item.splitt == "EQUITY" && item.SCHEME_SUB_CATEGORY == "ELSS";
          } else if (this.bestmfdata.title == "Low-cost index funds") {
            return item.splitt == "OTHERS" && item.SCHEME_SUB_CATEGORY == "Index Funds" && Number(item.Scheme_Minimum_Amount) < 5000;
          } else if (this.bestmfdata.title == "Smart beta") {
            return item.splitt == "OTHERS" && item.SCHEME_SUB_CATEGORY == "Index Funds" && item.Scheme_Type == "EQUITY" && Number(item.Scheme_Minimum_Amount) < 5000;
          } else if (this.bestmfdata.title == "Equity + Debt") {
            return item.splitt == "HYBRID" && (item.SCHEME_SUB_CATEGORY == "Dynamic Asset Allocation or Balanced Advantage" || item.SCHEME_SUB_CATEGORY == "Balanced Hybrid Fund");
          } else if (this.bestmfdata.title == "Alternatives to bank FDs") {
            return item.splitt == "DEBT" && item.splitr == "LIQUID ";
          }
        }
      });

      this.mftabledata = this.mutualuseritems;
      this.mftableloader = false;
      this.mfallsearchloader = false;

      let res = sessionStorage.getItem("c3RhdHVz");
      if (res == "dmFsaWR1c2Vy") {
        this.token = sessionStorage.getItem("usession");
        this.uid = sessionStorage.getItem("userid");
      } else {
        this.token = "";
        this.uid = "";
      }
    },
    putMForder(value, item) {
      this.$emit("menudialog", "mforder", item, value);
    },
    getusedMutual(item) {
      eventBus.$emit("addscript-wl", item, "mf");
    },
    setChangewl() {
      this.showtable = 24;
      this.mftabledata = [];
      this.mftabledata = this.mutualuseritems.filter(
        (x) =>
          x["SCHEME_CATEGORY"].includes(this.mfcategorie.cat == "Other" ? "others" : this.mfcategorie.cat) &&
          x["SCHEME_SUB_CATEGORY"].includes(this.mfcategorie.sub) &&
          x["AMC_Code"].includes(this.mfcategorie.amc) &&
          x["AUM"] >= this.mfcategorie.rangeaum[0] &&
          x["AUM"] <= this.mfcategorie.rangeaum[1] &&
          x["Minimum_Purchase_Amount"] >= this.mfcategorie.minamt[0] &&
          x["Minimum_Purchase_Amount"] <= this.mfcategorie.minamt[1]
      );

      // this.mftabledata = this.mftabledata.sort((a, b) => a.Scheme_Name - b.Scheme_Name)

      this.mftableloader = false;
    },
    setMFrowdata(item) {
      this.$router.push({name: "mutual fund amc", params: {mfamc: item}});
    },
  },
};
</script>
