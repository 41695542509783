<template>
  <div>
    <div class="mb-10">
      <v-toolbar flat dense class="tool-sty pl-md-4 crd-trn">
        <p class="title font-weight-bold mb-0">Orders ({{ orderbookdata && orderbookdata.length > 0 ? orderbookdata.length : 0 }})</p>

        <v-spacer></v-spacer>
        <v-text-field style="max-width: 220px" v-model="opensearch" hide-details prepend-inner-icon="mdi-magnify" label="Search" class="rounded-pill d-none d-md-flex" dense flat solo background-color="secbg"></v-text-field>

        <v-select
          style="max-width: 160px"
          v-model="exchtype"
          hide-details
          append-icon="mdi-chevron-down"
          item-text="txt"
          item-value="val"
          prepend-inner-icon="mdi-playlist-check"
          class="rounded-pill ml-4 d-none d-sm-flex"
          dense
          flat
          solo
          background-color="secbg"
          :items="['All', 'Lumpsum', 'X-SIP', 'Redeem']"
          label="Filter"
        ></v-select>
        <v-icon class="ml-3 cursor-p" @click="getOrderbook()" color="maintext" size="24">mdi-reload</v-icon>

        <!-- <v-select style="max-width:180px;" v-model="filter" hide-details append-icon="mdi-chevron-down"
                    prepend-inner-icon="mdi-playlist-check" class="rounded-pill mr-4" dense flat solo
                    background-color="secbg" :items="filters" label="Filter"></v-select> -->
      </v-toolbar>

      <v-data-table
        must-sort
        :sort-by="['Date']"
        :sort-desc="[true]"
        hide-default-footer
        fixed-header
        :loading="mftableloader"
        class="mt-3 rounded-lg overflow-y-auto"
        mobile-breakpoint
        style="border-radius: 4px; border: 1px solid var(--outline) mftable-responsive"
        height="480px"
        :headers="orderheader"
        :search="opensearch"
        :items="orderbookdata"
        :items-per-page="orderbookdata.length"
      >
        <template v-slot:body="{items, headers}">
          <tbody v-if="orderbookdata && orderbookdata.length > 0">
            <tr v-for="(item, o) in items" :key="o" class="table-row">
              <td class="ws-p">{{ item.Date }}</td>

              <td class="pos-rlt" cols="1">
                <P style="width: 68%" class="ws-p fs-13 font-weight-medium mb-0 maintext--text table-hov-text mt-2">
                  {{ item.SchemeName ? item.SchemeName : "" }}
                </P>
                <v-chip-group row class="mb-1">
                  <v-chip color="secbg" x-small class="table-hov-prd" text-color="subtext" style="border-radius: 5px; padding: 10px 8px !important">
                    <span class="font-weight-medium fs-10">
                      {{ item.splito ? item.splito : "" }}
                    </span>
                  </v-chip>
                  <v-chip color="secbg" x-small class="table-hov-prd" text-color="subtext" style="border-radius: 5px; padding: 10px 8px !important">
                    <span class="font-weight-medium fs-10"> Ord.no : {{ item.splitt ? item.splitt : "-" }} </span>
                  </v-chip>
                  <v-chip color="secbg" x-small class="table-hov-prd" text-color="subtext" style="border-radius: 5px; padding: 10px 8px !important">
                    <span class="font-weight-medium fs-10"> Sett.no : {{ item.SettNo ? item.SettNo : "-" }} </span>
                  </v-chip>
                </v-chip-group>
              </td>
              <td class="font-weight-medium" style="min-width: 80px">
                <div class="table-hov">
                  <!-- <v-tooltip top color="black">
                    <template v-slot:activator="{on, attrs}">
                      <div v-bind="attrs" v-on="on">
                        <v-btn v-if="item.OrderStatus == 'Success' && item.type != 'X-SIP'" @click="setModification(item)" style="border: 1px solid var(--outline)" min-width="20px" color="mainbg" class="px-0 font-weight-bold white--text elevation-0 mr-1" x-small>
                          <v-icon size="16" color="maintext">mdi-pencil</v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>Modify order</span>
                  </v-tooltip> -->
                  <v-tooltip top color="black">
                    <template v-slot:activator="{on, attrs}">
                      <div v-bind="attrs" v-on="on">
                        <v-btn v-if="item.OrderStatus == 'Pending' && item.OrderRemarks != 'ALLOTMENT DONE'" @click="setExition(false, item)" style="border: 1px solid var(--outline)" min-width="20px" color="mainbg" class="px-0 font-weight-bold white--text elevation-0 mr-1" x-small>
                          <v-icon size="18" color="maintext">mdi-close</v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>Cancel {{ item.type == "Redeem" ? " redemption" : "order" }}</span>
                  </v-tooltip>
                </div>
                <!-- && item.type == 'X-SIP' -->
                <!-- <v-btn v-if="item.OrderStatus == 'Success' && item.type != 'X-SIP'" @click="setModification(item)" color="secbg" class="rounded-pill text-none subtext--text font-weight-bold elevation-0" small>Modify</v-btn> -->
              </td>

              <td class="font-weight-medium">{{ item.type ? item.type : "-" }}</td>

              <td class="text-right font-weight-medium">{{ item.Units ? Number(item.Units).toFixed(2) : "0.00" }}</td>
              <td class="text-right font-weight-medium">
                <span class="font-weight-medium maintext--text mb-0">₹{{ Number(item.Amount) ? Number(item.Amount).toFixed(2) : "0.00" }}</span>
              </td>
              <td>{{ item.DPFolioNo ? item.DPFolioNo : "-" }}</td>
              <td>
                <v-tooltip bottom color="black">
                  <template v-slot:activator="{on, attrs}">
                    <div v-bind="attrs" v-on="on">
                      <span style="min-width: 180px" class="text-rap-l">{{ item.OrderRemarks ? item.OrderRemarks : "-" }}</span>
                    </div>
                  </template>
                  <div style="max-width: 300px">{{ item.OrderRemarks ? item.OrderRemarks : "-" }}</div>
                </v-tooltip>
              </td>

              <td>
                <p class="ws-p font-weight-medium maintext--text mb-0">
                  <svg v-if="item.OrderStatus == 'Success'" xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15" fill="none">
                    <rect width="20" height="15" rx="7" fill="#2DB266" />
                    <path d="M6.25 8.2475L8.415 10.4125L13.8275 5" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <svg v-else-if="item.OrderStatus == 'Pending'" xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15" fill="none">
                    <rect width="20" height="15" rx="7" fill="#FFB038" />
                    <path d="M5.3125 8.75C6.00286 8.75 6.5625 8.19036 6.5625 7.5C6.5625 6.80964 6.00286 6.25 5.3125 6.25C4.62214 6.25 4.0625 6.80964 4.0625 7.5C4.0625 8.19036 4.62214 8.75 5.3125 8.75Z" fill="white" />
                    <path d="M10.3125 8.75C11.0029 8.75 11.5625 8.19036 11.5625 7.5C11.5625 6.80964 11.0029 6.25 10.3125 6.25C9.62214 6.25 9.0625 6.80964 9.0625 7.5C9.0625 8.19036 9.62214 8.75 10.3125 8.75Z" fill="white" />
                    <path d="M15.3125 8.75C16.0029 8.75 16.5625 8.19036 16.5625 7.5C16.5625 6.80964 16.0029 6.25 15.3125 6.25C14.6221 6.25 14.0625 6.80964 14.0625 7.5C14.0625 8.19036 14.6221 8.75 15.3125 8.75Z" fill="white" />
                  </svg>
                  <svg v-else xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15" fill="none">
                    <rect width="20" height="15" rx="7" fill="#DC2626" />
                    <path d="M7.5 10L12.5 5M7.5 5L12.5 10" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  {{ item.OrderStatus ? item.OrderStatus : "" }}
                </p>
              </td>
            </tr>
            <tr v-if="showtable < orderbookdata.length">
              <td :colspan="headers.length">
                <v-btn color="primary" plain class="elevation-0 text-none font-weight-bold py-4" @click="showtable += 24" block>show more</v-btn>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <td :colspan="headers.length" class="text-center">
              <div class="mx-auto py-16 mt-16">
                <img class="mx-auto" width="80px" :src="require('@/assets/no data folder.svg')" />
                <h4 class="txt-999 font-weight-regular caption">There is no data here yet!</h4>
              </div>
            </td>
          </tbody>
        </template>
      </v-data-table>
    </div>
    <v-dialog v-model="canceldialog" max-width="400">
      <v-card class="rounded-xl elevation-0 text-center pt-8 pb-6 overflow-hidden">
        <img src="@/assets/orderbook/cancel-icon.svg" alt="cancel icon" />
        <p class="font-weight-medium mt-3 fs-22 lh-24 mb-4">
          Are you sure you want to <br />
          cancel {{ singledata.type == "Redeem" ? " redemption" : "order" }}?
        </p>

        <div class="px-6 mb-6" v-if="this.singledata && this.singledata.type == 'X-SIP'">
          <v-select v-model="cancelis" hide-details append-icon="mdi-chevron-down" item-text="reason_name" item-value="id" class="rounded-pill mb-4" dense flat solo background-color="secbg" :items="canceleds" placeholder="Cancelation reason"></v-select>
          <v-text-field v-if="cancelis == 13" v-model="cancelremark" hide-details placeholder="Your cancelation reason" class="rounded-pill" dense flat solo background-color="secbg"></v-text-field>
        </div>

        <v-row class="px-6" no-gluttters>
          <v-col cols="6">
            <v-btn :disabled="mftableloader" @click="(canceldialog = false), (singledata = [])" color="outline" class="rounded-pill text-none subtext--text font-weight-bold elevation-0" block height="40px">No</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              :loading="mftableloader"
              :disabled="this.singledata && this.singledata.type == 'X-SIP' ? ((cancelis == '13' && cancelremark) || (cancelis && cancelis != '13') ? false : true) : false"
              @click="setExition(true)"
              color="btnclr"
              class="rounded-pill text-none btntext--text font-weight-bold elevation-0"
              block
              height="40px"
              >Yes</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import apiurl from '../../../apiurl.js'
import eventBus from "../../../eventBus.js";
import {getMFlumsum_order, getXsiporder, getMFcancellum, getMFcancelxsip, getMFcancelation} from "../../../components/mixins/getAPIdata.js";

export default {
  data: () => ({
    uid: "",
    token: "",
    canceldialog: false,
    canceleds: [],
    cancelis: null,
    cancelremark: "",

    singledata: [],
    mftableloader: true,
    ordertab: 0,
    exchtype: "All",
    showtable: 24,
    opensearch: null,
    openorders: [],
    execorders: [],
    filter: null,
    filters: [],

    ordertype: "open",
    orderbookdata: [],
    orderbooks: [],
  }),
  computed: {
    orderheader() {
      return [
        {text: "Date", value: "dt", sortable: false},
        {text: "Fund name", value: "SchemeName", sortable: false, width: "40%"},
        // { text: 'Type', value: 'AUM', sortable: false },
        {text: "", value: "action", sortable: false},
        {text: "Transaction", value: "type", sortable: false},
        {text: "Units", value: "Units", align: "right", sortable: false},
        {text: "Invest amt", value: "Amount", align: "right", class: "ws-p", sortable: false},
        {text: "Folio no.", value: "DPFolioNo", class: "ws-p", sortable: false},
        {text: "Reason", value: "OrderRemarks", sortable: false},
        {text: "Status", value: "OrderStatus", sortable: false},
        {text: "", value: "type", filter: this.setPurchasefilter, class: " d-none"},
      ];
    },
  },
  created() {
    //
  },
  mounted() {
    this.getOrderbook();
    this.token = sessionStorage.getItem("usession");
    this.uid = sessionStorage.getItem("userid");
  },
  beforeDestroy() {
    eventBus.$off("user-event");
  },
  methods: {
    async setExition(value, item) {
      if (value) {
        this.mftableloader = true;
        let inn = this.singledata.type == "X-SIP" ? await getMFcancelxsip([this.singledata.OrderNumber, this.singledata.InternalRefNo, this.cancelis, this.cancelremark]) : await getMFcancellum(this.singledata.splitt, this.singledata.type == "Redeem");

        if (inn && inn.stat == "Ok") {
          this.getOrderbook();
          eventBus.$emit("snack-event", 1, inn.msg ? inn.msg : inn);
        } else {
          eventBus.$emit("snack-event", 0, inn.emsg ? inn.emsg : inn);
        }

        this.mftableloader = false;
        this.canceldialog = false;
      } else {
        this.singledata = item;
        if (this.singledata.type == "X-SIP") {
          let data = await getMFcancelation();
          if (data && data.stat == "Ok" && data.data) {
            this.canceleds = data.data;
          }
          this.cancelis = null;
          this.cancelremark = "";
        }
        this.canceldialog = true;
      }
    },
    setPurchasefilter(value) {
      if (this.exchtype == "All") {
        if (!this.exchtype && value) {
          return true;
        }
        return value;
      }
      if (this.exchtype == "Lumpsum") {
        if (!this.exchtype && value == "Lumpsum") {
          return true;
        }
        return value === "Lumpsum";
      } else if (this.exchtype == "X-SIP") {
        if (!this.exchtype && value == "X-SIP") {
          return true;
        }
        return value === "X-SIP";
      } else if (this.exchtype == "Redeem") {
        if (!this.exchtype && value == "Redeem") {
          return true;
        }
        return value === "Redeem";
      }
    },
    async getOrderbook() {
      this.mftableloader = true;
      this.orderbookdata = [];
      // let uid = "ZP00285";
      // let uid = "ZE1A40";
      let uid = sessionStorage.getItem("userid");
      let data = await getMFlumsum_order([uid, "", ""]);
      if (data.stat == "Ok" && data.purchase.length > 0) {
        for (let w = 0; w < data.purchase.length; w++) {
          if (data.purchase[w].SchemeName) {
            let arr = data.purchase[w].SchemeName.split(" - ").slice(0);
            data.purchase[w].SchemeName = arr[0];
            for (let d = 0; d < arr.length; d++) {
              data.purchase[w]["splito"] = arr[d].includes("GROWTH") ? "GROWTH" : arr[d].includes("IDCW PAYOUT") ? "IDCW PAYOUT" : arr[d].includes("IDCW REINVESTMENT") ? "IDCW REINVESTMENT" : arr[d].includes("IDCW") ? "IDCW" : "NORMAL";
            }
          }
          data.purchase[w]["splitt"] = data.purchase[w].OrderNumber.toUpperCase();
          data.purchase[w]["splitr"] = data.purchase[w].OrderType.toUpperCase();
          data.purchase[w]["OrderStatus"] = data.purchase[w]["OrderStatus"] == "VALID" ? "Success" : "Failed";
          data.purchase[w]["type"] = "Lumpsum";
        }
        this.orderbookdata = data.purchase;
      }
      if (data.stat == "Ok" && data.pusrchase_not_listed.length > 0) {
        for (let w = 0; w < data.pusrchase_not_listed.length; w++) {
          let arr = data.pusrchase_not_listed[w].Scheme_Name;
          this.orderbookdata.push({
            Date: data.pusrchase_not_listed[w].Date,
            SchemeName: data.pusrchase_not_listed[w].Scheme_Name,
            Amount: data.pusrchase_not_listed[w].Amount,
            OrderRemarks: data.pusrchase_not_listed[w].response1,
            OrderStatus: data.pusrchase_not_listed[w].mf_status == "0" ? "Pending" : "Failed",
            splito: arr.includes("GROWTH") ? "GROWTH" : arr.includes("IDCW PAYOUT") ? "IDCW PAYOUT" : arr.includes("IDCW REINVESTMENT") ? "IDCW REINVESTMENT" : arr.includes("IDCW") ? "IDCW" : "NORMAL",
            splitt: data.pusrchase_not_listed[w].order_number,
            splitr: data.pusrchase_not_listed[w].SettNo,
            type: "Lumpsum",
          });
        }
      }

      if (data.stat == "Ok" && data.xsip_purchase_not_listed.length > 0) {
        for (let w = 0; w < data.xsip_purchase_not_listed.length; w++) {
          if (data.xsip_purchase_not_listed[w].SchemeName) {
            let arr = data.xsip_purchase_not_listed[w].SchemeName.split(" - ").slice(0);
            data.xsip_purchase_not_listed[w].SchemeName = arr[0];
            for (let d = 0; d < arr.length; d++) {
              data.xsip_purchase_not_listed[w]["splito"] = arr[d].includes("GROWTH") ? "GROWTH" : arr[d].includes("IDCW PAYOUT") ? "IDCW PAYOUT" : arr[d].includes("IDCW REINVESTMENT") ? "IDCW REINVESTMENT" : arr[d].includes("IDCW") ? "IDCW" : "NORMAL";
            }
          }
          data.xsip_purchase_not_listed[w]["splitt"] = data.xsip_purchase_not_listed[w].OrderNumber.toUpperCase();
          data.xsip_purchase_not_listed[w]["splitr"] = data.xsip_purchase_not_listed[w].OrderType.toUpperCase();
          data.xsip_purchase_not_listed[w]["type"] = "X-SIP";
          data.xsip_purchase_not_listed[w]["OrderRemarks"] = data.xsip_purchase_not_listed[w].response1;
          data.xsip_purchase_not_listed[w]["OrderStatus"] = data.xsip_purchase_not_listed[w].OrderStatus == "NEW" ? "Pending" : "Failed";

          this.orderbookdata.push(data.xsip_purchase_not_listed[w]);
        }
      }
      if (data.stat == "Ok" && data.redeemption_not_listed.length > 0) {
        for (let w = 0; w < data.redeemption_not_listed.length; w++) {
          if (data.redeemption_not_listed[w].Scheme_Name) {
            let arr = data.redeemption_not_listed[w].Scheme_Name.split(" - ").slice(0);
            data.redeemption_not_listed[w]["SchemeName"] = arr[0];
            for (let d = 0; d < arr.length; d++) {
              data.redeemption_not_listed[w]["splito"] = arr[d].includes("GROWTH") ? "GROWTH" : arr[d].includes("IDCW PAYOUT") ? "IDCW PAYOUT" : arr[d].includes("IDCW REINVESTMENT") ? "IDCW REINVESTMENT" : arr[d].includes("IDCW") ? "IDCW" : "NORMAL";
            }
          }
          data.redeemption_not_listed[w]["splitt"] = data.redeemption_not_listed[w].order_number.toUpperCase();
          data.redeemption_not_listed[w]["splitr"] = data.redeemption_not_listed[w].unique_transaction_code.toUpperCase();
          data.redeemption_not_listed[w]["type"] = "Redeem";
          data.redeemption_not_listed[w]["OrderRemarks"] = data.redeemption_not_listed[w].response1;
          data.redeemption_not_listed[w]["OrderStatus"] = data.redeemption_not_listed[w].order_number != "0" && data.redeemption_not_listed[w].mf_status == "NEW" ? "Success" : "Failed";

          this.orderbookdata.push(data.redeemption_not_listed[w]);
        }
      }
      this.mftableloader = false;
    },
    async getXsipOrders(orderno, date) {
      let data = await getXsiporder([orderno, date]);
      return data.ChildOrderDetails && data.ChildOrderDetails[0] ? data.ChildOrderDetails[0] : {};
    },
  },
};
</script>
