<template>
  <div class="pos-rlt" style="height: calc(100vh - 80px) !important">
    <div v-if="optchainbasket">
      <v-toolbar flat dense class="tool-sty crd-trn pl-1">
        <p class="font-weight-bold mb-0 lh-16">Basket Options({{ optchainbasketdata.length }})</p>
        <v-spacer></v-spacer>
        <v-card color="secbg" class="px-2 py-0 elevation-0 rounded-xl">
          <v-switch x-small v-model="prdordplace" hide-details class="mt-n01">
            <template #label>
              <p class="pl-1 fw-6 fs-13 maintext--text mb-0 pt-1">{{ prdordplace ? " MIS" : " NRML" }}</p>
            </template>
          </v-switch>
        </v-card>

        <v-btn class="mr-0" small icon @click="optchainbasket = false">
          <v-tooltip top color="black">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">mdi-minus</v-icon>
            </template>
            <span>Minimize</span>
          </v-tooltip>
        </v-btn>

        <v-btn class="mr-0" small icon @click="(optchainbasket = false), (optchainbasketdata = [])">
          <v-tooltip top color="black">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">mdi-close</v-icon>
            </template>
            <span>Close</span>
          </v-tooltip>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <div v-if="optchainbasketdata && optchainbasketdata.length > 0" style="height: calc(100vh-124px)"
        class="overflow-y-auto overflow-x-hidden no-scroll">
        <div v-for="(item, o) in optchainbasketdata" :key="o" class="pt-2 px-1">
          <p class="maintext--text mb-0 fs-13">
            <v-icon
              @click="(item.checkbox = !item.checkbox), $set(optchainbasketdata, o, optchainbasketdata[o]), getOBMargin()"
              color="primary" size="14">{{ !item.checkbox ? "mdi-checkbox-blank-outline" : "mdi-checkbox-marked"
              }}</v-icon>
            <span class="table-hov-text" v-text="item.tsyms ? item.tsyms : item.tsym ? item.tsym : ''"></span>
            <span class="subtext--text" v-text="item.exp ? item.exp : ''"></span> <span class="subtext--text fs-10"
              v-text="item.ser ? item.ser : ''"></span>
            <span class="float-right maintext--text fs-13">
              <v-icon @click="setBskmodify(o, 'delete')" color="maintext" class="font-weight-bold"
                size="16">mdi-close</v-icon>
            </span>
          </p>
          <v-row no-glutters>
            <v-col cols="2" class="px-0">
              <v-tooltip top color="black">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip :disabled="!item.checkbox" v-bind="attrs" v-on="on"
                    :color="item.buySell == 'BUY' ? 'secgreen' : 'secred'"
                    @click="setBskmodify(o, 'buySell', item.buySell == 'BUY' ? 'SELL' : 'BUY')" x-small
                    :text-color="item.buySell == 'BUY' ? 'maingreen' : 'mainred'" label class="px-2">
                    {{ item.buySell }}
                  </v-chip>
                </template>
                <span>Click to change <b :class="item.buySell != 'BUY' ? 'maingreen--text' : 'mainred--text'">{{
                  item.buySell
                    == "BUY" ? "SELL" : "BUY" }}</b></span>
              </v-tooltip>
            </v-col>
            <v-col cols="2" class="px-0">
              <v-tooltip top color="black">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip :disabled="!item.checkbox" v-bind="attrs" v-on="on" color="secbg"
                    @click="(item.ordprc = item.ltp), setBskmodify(o, 'ordvai', item.ordvai == 'MKT' ? 'LMT' : 'MKT')"
                    x-small text-color="maintext" label class="px-2">
                    {{ item.ordvai }}
                  </v-chip>
                </template>
                <span>Click to change <b>{{ item.ordvai == "MKT" ? "LIMIT" : "MARKET" }}</b></span>
              </v-tooltip>
            </v-col>
            <v-col cols="3" class="px-1 pt-2">
              <v-tooltip top color="black">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field :disabled="!item.checkbox" @keyup="$set(optchainbasketdata, o, optchainbasketdata[o])"
                    v-bind="attrs" v-on="on" prefix="📦" class="fs-12 bskfield" height="20px" v-model="item.ordlot"
                    type="number" min="1" hide-spin-buttons hide-details dense></v-text-field>
                </template>
                <span>QTY: {{ item.ordlot }} * MLot {{ item.ls }}</span>
              </v-tooltip>
            </v-col>
            <v-col cols="3" class="px-1 pt-2">
              <v-tooltip top color="black">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field @keyup="$set(optchainbasketdata, o, optchainbasketdata[o])" v-bind="attrs" v-on="on"
                    prefix="₹" :disabled="item.ordvai == 'MKT' || !item.checkbox" class="fs-12 bskfield" height="20px"
                    v-model="item.ordprc" type="number" min="0.1" hide-spin-buttons hide-details dense></v-text-field>
                </template>
                <span>Price</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-divider class="mt-1"></v-divider>
        </div>
      </div>
      <v-card v-else class="text-center py-16 px-8 elevation-0 crd-trn" width="100%">
        <img class="align-self-stretch mx-auto" width="70px"
          :src="require(`@/assets/searcha-icon${$vuetify.theme.dark ? 'd' : ''}.svg`)" />
        <p class="subtext--text fs-14 mb-1">Hmmm, Looks like no Strikes — why not add some? Choose from the option
          chain.</p>
      </v-card>
      <div v-if="optchainbasketdata && optchainbasketdata.length > 0">
        <v-divider></v-divider>
        <v-toolbar class="tool-sty elevation-0 crd-trn pt-1 pl-1" dense>
          <v-list-item class="px-0">
            <v-list-item-content class="py-0">
              <v-list-item-title class="font-weight-medium fs-12 subtext--text mb-2">Margin:

                <span class="ml-1 primary--text"><b>₹{{ Number(totalmargin) ? totalmargin : "0.00" }}</b></span>
                <v-icon class="ml-1 cursor-p" @click="getOBMargin()" color="maintext" size="12">mdi-reload</v-icon>
              </v-list-item-title>
              <v-list-item-title class="font-weight-medium fs-12 subtext--text mb-0">Post margin:
                <span class="ml-1 primary--text"><b>₹{{ Number(postTrademargin) ? postTrademargin : "0.00"
                    }}</b></span></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-spacer></v-spacer>
          <v-btn @click="setBfoPlaceorder(0)" color="primary" :loading="orderloader"
            class="text-none rounded-pill elevation-0 white--text px-6" height="40px"> Place order </v-btn>
        </v-toolbar>
      </div>
    </div>
    <div v-else-if="panel">
      <v-text-field :loading="searchloading" ref="mwref" class="rounded-pill mt-2" flat solo background-color="secbg"
        dense @input="mutfndsearch(mfsearch)"  v-model="mfsearch" hide-details label="Search funds"
        prepend-inner-icon="mdi-magnify" append-icon="" no-filter oninput="this.value = this.value.toUpperCase()">
        <template v-if="addscript" #append>
          <v-icon :disabled="isLoading" @click="onMWClear()" color="primary" class="mr-1">mdi-close</v-icon>
        </template>
      </v-text-field>
      <v-toolbar v-if="!addscript" flat dense class="tool-sty crd-trn">
        <v-btn text readonly class="elevation-0 rounded-lg text-none fs-14 d-inline-flex px-2 text-capitalize"
          max-width="220px">
          My watchlist
          <span class="ml-2 font-weight-bold fs-12">({{ mfuseritem ? mfuseritem.length : "0" }})</span>
        </v-btn>
        <v-spacer></v-spacer>

        <v-tooltip top color="black">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-icon v-if="!watchsecti && !addscript" :disabled="mfisLoading" color="maintext"
                      class="mr-1">mdi-flip-h mdi-sort-variant</v-icon>
                  </div>
                </template>
                <v-list dense>
                  <v-list-item-group @change="setMFFilter()" v-model="mffilter" active-class="primary--text">
                    <v-list-item v-for="(item, index) in mffilters" :key="index">
                      <v-list-item-title>{{ item.text }}</v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </div>
          </template>
          <span>Sort by</span>
        </v-tooltip>
        <v-icon v-if="!watchsecti && !addscript" :disabled="mfisLoading"
          @click="(addscript = true), (watchsecti = false), (mfwatchlistdata = []), (nodata = null), putMWfocus()"
          color="maintext">mdi-magnify</v-icon>
      </v-toolbar>
      <div v-if="addscript">
        <v-chip-group mandatory v-model="mfexch" @change="mfsearchFilter()" class="mb-0" column>
          <v-chip v-for="(e, x) in mfexchfilter" :key="x" class="font-weight-medium py-1 px-2"
            :color="mfexch == x ? 'maintext' : 'secbg'" :text-color="mfexch == x ? 'mainbg' : 'maintext'" small>
            {{ e }}
          </v-chip>
        </v-chip-group>
        <v-divider></v-divider>
        <div v-if="mfwatchlistdata && mfwatchlistdata.length > 0">
          <p class="subtext--text fs-14 mt-2 mb-1">{{ mfwatchlistdata.length }} search result by you</p>
          <v-divider></v-divider>
        </div>
      </div>
      <v-progress-linear v-if="mfisLoading" indeterminate rounded></v-progress-linear>
      <v-divider v-if="!watchsecti && !addscript"></v-divider>
      <div v-if="!addscript" style="height: calc(100vh - 172px)" class="overflow-y-auto overflow-x-hidden no-scroll">
        <div v-if="mfuseritem && mfuseritem.length > 0">
          <v-card v-for="(item, o) in mfuseritem" :key="o"
            class="elevation-0 rounded-0 table-row overflow-hidden crd-trn">
            <div class="table-row pos-rlt">
              <v-list-item class="pa-0 py-1">
                <v-list-item-content class="pa-0 pa-1">
                  <v-list-item-title class="fs-13 font-weight-medium mb-1 maintext--text table-hov-text">
                    <span class="txt-dec-cust" @click="setSinglepage(item)">{{ item.f_scheme_name ? item.f_scheme_name :
                      ""
                      }}</span>
                    <p class="mb-0 float-right fs-12 subtext--text font-weight-regular">
                      3yr:
                      <span class="font-weight-medium maintext--text">{{ item.THREE_YEAR_DATA ?
                        Number(item.THREE_YEAR_DATA).toFixed(2) : "0.00" }}</span>
                    </p>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <!-- <p column class="mb-0"> -->
                    <v-chip color="secbg" class="table-hide" x-small text-color="subtext"
                      style="border-radius: 4px; padding: 10px 8px !important">
                      <span class="font-weight-medium fs-9">
                        {{ item.splito ? item.splito : "" }}
                      </span>
                    </v-chip>
                    <v-chip color="secbg" class="table-hide mx-2" x-small text-color="subtext"
                      style="border-radius: 4px; padding: 10px 8px !important">
                      <span class="font-weight-medium fs-9">
                        {{ item.splitt ? item.splitt.replace("SCHEME", "") : "" }}
                      </span>
                    </v-chip>
                    <v-chip color="secbg" class="table-hide" x-small text-color="subtext"
                      style="border-radius: 4px; padding: 10px 8px !important">
                      <span class="font-weight-medium fs-9">
                        {{ item.splitr ? item.splitr : "" }}
                      </span>
                    </v-chip>
                    <!-- </p> -->
                    <span class="mb-0 fs-10 subtext--text float-right font-weight-regular mt-1">NAV:
                      <span class="font-weight-medium maintext--text">{{ item.NET_ASSET_VALUE ?
                        Number(item.NET_ASSET_VALUE).toFixed(2) : "0.00" }}</span>
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
            </div>
            <div @click.stop class="pos-abs table-hov" style="bottom: 8px; left: 50%; transform: translate(-50%, 0)">
              <v-btn @click="putMForder(item, 'buy')" x-small
                class="elevation-0 text-none maingreen--text font-weight-bold mr-1" color="secgreen">Buy</v-btn>
              <v-btn @click="putMForder(item, 'sip')" x-small
                class="elevation-0 text-none primary--text font-weight-bold" color="#F1F3F8">SIP</v-btn>
              <v-btn v-if="!PreDefinedMW.is" @click="deleteuserMutual(item)" style="border: 1px solid var(--outline)"
                min-width="20px" color="secbg" class="px-0 font-weight-bold white--text elevation-0 ml-1" x-small>
                <v-icon size="18" color="maintext">mdi-close</v-icon>
              </v-btn>
            </div>
          </v-card>
        </div>
        <v-card v-else class="text-center py-16 px-8 elevation-0 crd-trn" width="100%">
          <img class="align-self-stretch mx-auto" width="70px"
            :src="require(`@/assets/searcha-icon${$vuetify.theme.dark ? 'd' : ''}.svg`)" />
          <p class="txt-999 fs-14 mb-1">
            Hmmm, Looks like no symbols in your watchlist<span>{{ !PreDefinedMW.is ? " — why not add some?" : "."
              }}</span>
          </p>
          <v-btn v-if="!PreDefinedMW.is" color="primary" class="text-none" small
            @click="uid ? ((addscript = true), (watchsecti = false), (mfwatchlistdata = []), (nodata = null), putMWfocus()) : switchBus.$emit('login')"
            text>Add script <v-icon class="ml-1" size="14">mdi-plus-circle</v-icon></v-btn>
        </v-card>
      </div>

      <div v-else-if="addscript" style="height: calc(100vh - 188px)"
        class="overflow-y-auto overflow-x-hidden no-scroll">
        <div v-if="mfwatchlistdata && mfwatchlistdata.length > 0">
          <div v-for="(w, l) in mfwatchlistdata" :key="l" class="table-row pos-rlt">
            <v-list-item :disabled="w.watchlist"
              @click="uid ? ((mfmodel = w), getusedMutual('add'), (w.watchlist = true)) : switchBus.$emit('login')"
              class="px-0 crd-trn">
              <v-list-item-content class="py-0 pt-2">
                <v-list-item-title class="maintext--text mb-0 table-hov-text fs-14">{{ w.f_scheme_name
                }}</v-list-item-title>
                <v-chip-group column class="mb-0">
                  <v-chip color="secbg" x-small text-color="subtext"
                    style="border-radius: 4px; padding: 10px 8px !important">
                    <span class="font-weight-medium">
                      {{ w.splito ? w.splito : "" }}
                    </span>
                  </v-chip>
                  <v-chip color="secbg" x-small text-color="subtext"
                    style="border-radius: 4px; padding: 10px 8px !important">
                    <span class="font-weight-medium">
                      {{ w.splitt ? w.splitt : "" }}
                    </span>
                  </v-chip>
                  <v-chip color="secbg" x-small text-color="subtext"
                    style="border-radius: 4px; padding: 10px 8px !important">
                    <span class="font-weight-medium">
                      {{ w.splitr ? w.splitr : "" }}
                    </span>
                  </v-chip>
                </v-chip-group>
              </v-list-item-content>
              <div>
                <v-icon v-if="uid" class="float-right" :color="w.watchlist ? 'primary' : 'maintext'">{{ w.watchlist ?
                  "mdi-bookmark" : "mdi-bookmark-plus-outline" }}</v-icon>
              </div>
            </v-list-item>
            <v-divider></v-divider>
          </div>
        </div>
        <v-card v-else class="text-center py-16 px-8 elevation-0 crd-trn" width="100%">
          <p class="font-weight-bold mb-2 maintext--text">
            {{ nodata == null ? "Type more than 2 letter" : "No funds Found" }}
          </p>
          <span class="body-2 mb-5 grey--text"> {{ nodata == null ? "Eg. for Nippon Type: Nip" :
            "Search for another name."
          }}</span>
        </v-card>
      </div>
    </div>
    <div v-else>
      <v-text-field :loading="searchloading" ref="mwref" class="rounded-pill mt-2" flat solo background-color="secbg"
        dense @input="stockSearch(stksearch)"  v-model="stksearch" hide-details label="Search script"
        prepend-inner-icon="mdi-magnify" append-icon="" no-filter oninput="this.value = this.value.toUpperCase()">
        <template v-if="addscript" #append>
          <v-icon :disabled="isLoading" @click="onMWClear()" color="primary" class="mr-1">mdi-close</v-icon>
        </template>
      </v-text-field>
      <v-toolbar v-if="!addscript" flat dense class="tool-sty crd-trn">
        <div v-dragscroll.x style="width: calc(100% - 60px);"
          class="overflow-x-auto d-inline-flex no-scroll rounded-xl">
          <div v-if="!watchsecti" class="d-inline-flex">
            <div v-for="(p, d) in watchlist" :key="d" class="pr-2">
              <v-chip @click="watchlistis = p, setPDwatchlist(p)" :color="watchlistis == p ? 'maintext' : 'secbg'"
                :text-color="watchlistis == p ? 'mainbg' : 'maintext'" class="font-weight-medium px-3" medium>
                {{ p }}
              </v-chip>
            </div>
          </div>
          <div v-if="PreMWlist" class="d-inline-flex">
            <div v-for="(p, s) in PreMWlist" :key="s" class="pr-2">
              <v-chip @click="watchlistis = p.key, setPDwatchlist()"
                :color="watchlistis == p.key ? 'maintext' : 'secbg'"
                :text-color="watchlistis == p.key ? 'mainbg' : 'maintext'" class="font-weight-medium px-3" medium>
                {{ p.text }}
              </v-chip>
            </div>
          </div>

        </div>
        <v-spacer></v-spacer>

        <v-tooltip top color="black">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <!-- v-if="!watchsecti && !addscript"  -->
                    <v-icon v-if="!watchsecti" :disabled="isLoading" color="maintext" class="mr-1">mdi-flip-h mdi-sort-variant</v-icon>
                  </div>
                </template>
                <v-list dense>
                  <v-list-item-group @change="setMWfilter()" v-model="mwfilter" active-class="primary--text">
                    <v-list-item v-for="(item, index) in mwfilters" :key="index">
                      <v-list-item-title>{{ item.text }}</v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </div>
          </template>
          <span>Sort by</span>
        </v-tooltip>
        <v-tooltip left color="black">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <!-- v-if="watchsecti" -->
              <v-icon v-if="watchsecti" @click="createMWdialog = true" :disabled="(watchlist && watchlist.length >= 10) || isLoading"
                color="maintext">mdi-plus</v-icon>
            </div>
          </template>
          <span> {{ watchlist && watchlist.length >= 10 ? "Maximum 10 watchlists You can create." :
            "Create new watchlist"
          }}</span>
        </v-tooltip>
        <v-icon @click="(addscript = false), (watchsecti = !watchsecti)" :disabled="isLoading" color="maintext">{{
          !watchsecti ?
            'mdi-dots-vertical' : 'mdi-chevron-left' }}</v-icon>
      </v-toolbar>
      <!-- <div class="overflow-x-auto mb-2 no-scroll"  style="width: 100%;"> -->
      <!-- v-dragscroll.x -->
      <v-card v-if="!addscript && !watchsecti" id="pdwm" color="transparent"
        class="d-inline-flex overflow-hidden mb-2 no-scroll elevation-0 pos-rlt" width="100%">
        <!-- <div class="pos-fix z-i1 pdmwlist ml-2" style="margin-top: 10px">
          <v-btn @click="scrollToo('pdwm', -600)" class="elevation-1" fab x-small width="22" height="22" color="cardbg"> <v-icon color="maintext">mdi-chevron-left</v-icon> </v-btn>
        </div> -->
        <v-row class="px-2">
          <v-col cols="6" v-for="(s, l) in pdmwdata" :key="l" class="px-1 pdmwlists">
            <v-card width="100%" color="secbg" :class="l == 3 ? '' : 'mr-3'"
              class="rounded-md pt-2 pb-1 px-2 cursor-p elevation-0"
              @click="uid ? setSSDtab(l, s.token, s.exch, s.tsym) : null">
              <p class="maintext--text mb-0 font-weight-medium table-hov-text fs-13">
                <span v-text="s.tsym ? (s.tsym.length > 10 ? `${s.tsym.slice(0, 10)}...` : s.tsym) : ''"></span>
                <span class="float-right maintext--text">₹<span :id="`p${s.token}ltp`">{{ s.ltp ? s.ltp : "0.00"
                }}</span></span>
              </p>
              <p :class="s.ch > 0 ? 'maingreen--text' : s.ch < 0 ? 'mainred--text' : 'subtext--text'"
                class="text-right font-weight-medium fs-12 mb-0" :id="`p${s.token}chpclr`">
                <span :id="`p${s.token}ch`">{{ s.ch ? s.ch : "0.00" }}</span>
                <span :id="`p${s.token}chp`">({{ s.chp ? s.chp : "0.00" }})</span>
              </p>
              <div class="pos-abs pdmwlist z-i1 mt-n6 ml-0">
                <v-btn @click.stop @click="getAllindicedata(s, l == 0 ? 1 : 0)" class="elevation-1" fab x-small
                  width="22" height="22" color="cardbg"> <v-icon color="maintext">mdi-pencil</v-icon> </v-btn>
              </div>
            </v-card>
          </v-col>
        </v-row>

        <!-- <div class="pos-fix z-i1 pdmwlist" style="right: 18px; margin-top: 10px">
          <v-btn @click="scrollFrom('pdwm', 600)" class="elevation-1" fab x-small width="22" height="22" color="cardbg"> <v-icon color="maintext">mdi-chevron-right</v-icon> </v-btn>
        </div> -->
      </v-card>
      <!-- </div> -->
      <div v-if="addscript">
        <v-chip-group mandatory v-model="stocksexch" @change="searchFilter()" class="mb-0" column>
          <v-chip v-for="(e, x) in exchfilter" :key="x" class="font-weight-medium px-3"
            :color="stocksexch == x ? 'maintext' : 'secbg'" :text-color="stocksexch == x ? 'mainbg' : 'maintext'"
            medium>
            {{ e }}
          </v-chip>
        </v-chip-group>
        <v-divider></v-divider>
        <div v-if="items && items.length > 0">
          <p class="subtext--text fs-14 mt-2 mb-1">{{ items.length }} search result by you</p>
          <v-divider></v-divider>
        </div>
      </div>
      <v-progress-linear v-if="isLoading" indeterminate rounded></v-progress-linear>
      <v-divider v-if="!watchsecti && !addscript"></v-divider>
      <div v-if="watchsecti">
        <!-- <v-chip-group @change="setPDwatchlist(), (watchsecti = false)" class="mb-0" column>
          <v-chip v-for="(p, d) in PreMWlist" :key="d" @click="watchlistis = p.key"
            :color="watchlistis == p.key ? 'maintext' : 'secbg'"
            :text-color="watchlistis == p.key ? 'mainbg' : 'maintext'" class="font-weight-medium px-2" small>
            {{ p.text }}
          </v-chip>
        </v-chip-group>
        <v-divider></v-divider> -->

        <div v-for="(w, l) in watchlist" :key="l">
          <v-list-item @click="(watchlistis = w), setPDwatchlist(w), (watchsecti = false)" class="crd-trn px-2">
            <v-card class="pa-2 elevation-0 mr-2" :color="w == watchlistis ? 'primary' : 'secbg'"></v-card>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold fs-14 text-capitalize"
                :class="w == watchlistis ? 'primary--text' : 'maintext--text'"> {{ w }}</v-list-item-title>
            </v-list-item-content>

            <div @click.stop @click="(deleteMWdialog = true), (aaddtoMW.wl = w), (aaddtoMW.delete = true)">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28">
                <path fill="currentColor" fill-rule="evenodd"
                  d="M11.5 6a.5.5 0 0 0-.5.5V8h6V6.5a.5.5 0 0 0-.5-.5h-5zM18 8V6.5c0-.83-.67-1.5-1.5-1.5h-5c-.83 0-1.5.67-1.5 1.5V8H5.5a.5.5 0 0 0 0 1H7v12.5A2.5 2.5 0 0 0 9.5 24h9a2.5 2.5 0 0 0 2.5-2.5V9h1.5a.5.5 0 0 0 0-1H18zm2 1H8v12.5c0 .83.67 1.5 1.5 1.5h9c.83 0 1.5-.67 1.5-1.5V9zm-8.5 3c.28 0 .5.22.5.5v7a.5.5 0 0 1-1 0v-7c0-.28.22-.5.5-.5zm5.5.5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z">
                </path>
              </svg>
            </div>
          </v-list-item>
        </div>
      </div>

      <div v-else-if="!addscript" style="height: calc(100vh - 218px)"
        class="overflow-y-auto overflow-x-hidden no-scroll">
        <div v-if="watchlistdata != 'no data'">
          <div v-for="(item, o) in watchlistdata" :key="o" class="table-row pos-rlt pt-2 cursor-p"
            @click="uid ? setSSDtab(o, item.token, item.exch, item.tsym) : null">
            <p class="maintext--text mb-1 fs-13 px-1">
              <span class="table-hov-text" v-text="item.tsyms ? item.tsyms : item.tsym ? item.tsym : ''"></span>
              <span class="subtext--text" v-text="item.exp ? item.exp : ''"></span> <span
                class="float-right maintext--text fs-13">₹<span v-text="item.ltp ? item.ltp : '0.00'"
                  :id="`${item.token}ltp`"></span></span>
            </p>
            <p class="mb-0 px-1 lh-16">
              <span style="border-radius: 4px; padding: 0px 6px; background-color: var(--secbg) !important"
                class="mr-1 table-hov-prd">
                <span class="font-weight-medium fs-10 lh-16" v-text="item.exch ? item.exch : ''"> </span>
              </span>
              <span class="subtext--text fs-10" v-text="item.ser ? item.ser : ''"></span>
              <span class="float-right fw-6 fs-12" :id="`${item.token}chpclr`"
                :class="item.ch > 0 ? 'maingreen--text' : item.ch < 0 ? 'mainred--text' : 'subtext--text'">
                <span v-text="item.ch ? item.ch : '0.00'" :id="`${item.token}ch`"></span>
                <span v-text="` (${item.chp ? item.chp : '0.00'}%)`" :id="`${item.token}chp`"></span>
              </span>
              <!-- v-text="` (${item.chp ? item.chp : '0.00'}%)`" -->
            </p>
            <div v-if="uid" @click.stop class="pos-abs table-hov"
              style="bottom: 8px; left: 50%; transform: translate(-50%, 0)">
              <div v-if="item.instname != 'UNDIND' && item.instname != 'COM'"
                @click="switchBus.$emit('menudialog', 'order', item.token, item.exch, item.tsym, 'b')"
                style="min-width: 24px; background-color: var(--maingreen); border-radius: 4px"
                class="px-1 pt-1 font-weight-bold white--text elevation-0 mr-1 fs-10 text-center">
                B
              </div>
              <div v-if="item.instname != 'UNDIND' && item.instname != 'COM'"
                @click="switchBus.$emit('menudialog', 'order', item.token, item.exch, item.tsym, 's')"
                style="min-width: 24px; background-color: var(--mainred); border-radius: 4px"
                class="px-1 pt-1 font-weight-bold white--text elevation-0 mr-1 fs-10 text-center">
                S
              </div>
              <v-btn @click="setSSDtab('chart', item.token, item.exch, item.tsym)"
                style="border: 1px solid var(--outline)" min-width="20px" color="mainbg"
                class="px-0 font-weight-bold white--text elevation-0 mr-1" x-small>
                <v-icon size="18" color="maintext">mdi-chart-line-variant</v-icon>
              </v-btn>
              <v-btn v-if="!PreDefinedMW.is" @click="deleteScript(item, o)" style="border: 1px solid var(--outline)"
                min-width="20px" color="mainbg" class="px-0 font-weight-bold white--text elevation-0 mr-1" x-small>
                <v-icon size="18" color="maintext">mdi-close</v-icon>
              </v-btn>

              <v-menu close-on-click absolute offset-y class="table-menu">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" style="border: 1px solid var(--outline)" min-width="20px"
                    color="mainbg" class="px-0 font-weight-bold white--text elevation-0 mr-1" x-small>
                    <v-icon size="18" color="maintext">mdi-dots-horizontal</v-icon>
                  </v-btn>
                </template>
                <v-card class="table-menu-list">
                  <v-list dense>
                    <div v-for="(m, k) in menulist" :key="k">
                      <v-list-item
                        v-if="m.type == 'depth' || m.type == 'Funda' ? item.instname != 'UNDIND' && item.instname != 'COM' : m.type == 'delete' ? (!PreDefinedMW.is ? true : false) : true"
                        @click="m.type != 'delete' ? setSSDtab(m.type, item.token, item.exch, item.tsym) : deleteScript(item, o)"
                        class="pl-3 pr-6">
                        <v-list-item-icon class="mr-3 text-center">
                          <img v-if="m.icon > 2" width="20px" class="pl-1"
                            :src="require(`@/assets/orderbook/${m.icon}.svg`)" />
                          <v-icon v-else color="#506D84">{{ m.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title class="subline--text font-weight-medium fs-14">{{ m.name
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider v-if="m.hr" class="mx-3"></v-divider>
                    </div>
                  </v-list>
                </v-card>
              </v-menu>
            </div>
            <div v-else @click.stop class="pos-abs table-hov"
              style="bottom: 8px; left: 50%; transform: translate(-50%, 0)">
              <div @click="switchBus.$emit('login')"
                style="min-width: 24px; background-color: var(--maingreen); border-radius: 4px"
                class="px-1 pt-1 font-weight-bold white--text elevation-0 mr-1 fs-10 text-center">B</div>
              <div @click="switchBus.$emit('login')"
                style="min-width: 24px; background-color: var(--mainred); border-radius: 4px"
                class="px-1 pt-1 font-weight-bold white--text elevation-0 fs-10 text-center">S</div>
            </div>
            <v-divider class="mt-1"></v-divider>
          </div>
          <v-toolbar v-if="!PreDefinedMW.is && watchlistdata" flat dense class="tool-sty crd-trn">
            <v-btn color="primary" class="text-none px-0" small
              @click="(addscript = true), (watchsecti = false), (items = []), (nodata = null), putMWfocus()" text>Add
              symbol
              <v-icon class="ml-1" size="14">mdi-plus-circle</v-icon></v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" class="text-none px-0" small @click="(watchlistis = 'NIFTY50:NSE'), setPDwatchlist()"
              text>Explore stocks </v-btn>
          </v-toolbar>
        </div>
        <v-card v-else class="text-center py-16 px-8 elevation-0 crd-trn" width="100%">
          <img class="align-self-stretch mx-auto" width="70px"
            :src="require(`@/assets/searcha-icon${$vuetify.theme.dark ? 'd' : ''}.svg`)" />
          <p class="subtext--text fs-14 mb-1">
            Hmmm, Looks like no symbols in your watchlist<span>{{ !PreDefinedMW.is ? " — why not add some?" : "."
            }}</span>
          </p>
          <v-btn v-if="!PreDefinedMW.is" color="primary" class="text-none" small
            @click="(addscript = true), (watchsecti = false), (items = []), (nodata = null), putMWfocus()" text>Add
            symbol
            <v-icon class="ml-1" size="14">mdi-plus-circle</v-icon></v-btn>
        </v-card>
      </div>

      <div v-else-if="addscript" style="height: calc(100vh - 188px)"
        class="overflow-y-auto overflow-x-hidden no-scroll">
        <div v-if="items && items.length > 0">
          <div v-for="(w, l) in items" :key="l" class="table-row pos-rlt">
            <v-list-item
              @click="w.watchlist ? (deleteScript(w, -1), w.watchlist = false) : ((model = w), addSearch('', l), (w.watchlist = true))"
              class="table-row pos-rlt px-1">
              <v-list-item-content class="py-0">
                <v-list-item-title class="maintext--text mb-1 table-hov-text fs-13">{{ w.tsyms ? w.tsyms : w.tsym }}
                  <span class="subtext--text">{{ w.exp ? w.exp : "" }}</span></v-list-item-title>
                <v-list-item-subtitle>
                  <v-chip color="#F1F3F8" x-small outlined text-color="#666" class="mr-1"
                    style="border-radius: 4px; padding: 2px 6px 0 6px; background-color: #f1f3f8 !important">
                    <span class="font-weight-medium fs-10">
                      {{ w.exch ? w.exch : "" }}
                    </span>
                  </v-chip>
                  <span class="subtext--text fs-10">{{ w.ser ? w.ser : "" }}</span>
                </v-list-item-subtitle>
                <!-- <v-list-item-subtitle class="font-weight-medium fs-12"> {{ w.cname ? w.cname : w.dname ? w.dname : ''
                  }}</v-list-item-subtitle> -->
              </v-list-item-content>
              <div>
                <v-icon class="float-right" :color="w.watchlist ? 'primary' : 'maintext'">{{ w.watchlist ?
                  "mdi-bookmark" :
                  "mdi-bookmark-outline" }}</v-icon>
              </div>
              <div @click.stop class="pos-abs table-hov" style="bottom: 8px; left: 50%; transform: translate(-50%, 0)">
                <div v-if="w.instname != 'UNDIND' && w.instname != 'COM'"
                  @click="switchBus.$emit('menudialog', 'order', w.token, w.exch, w.tsym, 'b')"
                  style="min-width: 24px; background-color: var(--maingreen); border-radius: 4px"
                  class="px-1 pt-1 font-weight-bold white--text elevation-0 mr-1 fs-10 text-center">
                  B
                </div>
                <div v-if="w.instname != 'UNDIND' && w.instname != 'COM'"
                  @click="switchBus.$emit('menudialog', 'order', w.token, w.exch, w.tsym, 's')"
                  style="min-width: 24px; background-color: var(--mainred); border-radius: 4px"
                  class="px-1 pt-1 font-weight-bold white--text elevation-0 mr-1 fs-10 text-center">
                  S
                </div>
                <v-btn @click="setSSDtab('chart', w.token, w.exch, w.tsym)" style="border: 1px solid var(--outline)"
                  min-width="20px" color="mainbg" class="px-0 font-weight-bold white--text elevation-0 mr-1" x-small>
                  <v-icon size="18" color="maintext">mdi-chart-line-variant</v-icon>
                </v-btn>

                <v-menu close-on-click absolute offset-y class="table-menu">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" style="border: 1px solid var(--outline)" min-width="20px"
                      color="mainbg" class="px-0 font-weight-bold white--text elevation-0 mr-1" x-small>
                      <v-icon size="18" color="maintext">mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>
                  <v-card class="table-menu-list">
                    <v-list dense>
                      <div v-for="(m, k) in menulist" :key="k">
                        <v-list-item
                          v-if="m.type == 'depth' || m.type == 'Funda' ? w.instname != 'UNDIND' && w.instname != 'COM' : m.type == 'delete' ? false : true"
                          @click="setSSDtab(m.type, w.token, w.exch, w.tsym)" class="pl-3 pr-6">
                          <v-list-item-icon class="mr-3 text-center">
                            <img v-if="m.icon > 2" width="20px" class="pl-1"
                              :src="require(`@/assets/orderbook/${m.icon}.svg`)" />
                            <v-icon v-else color="#506D84">{{ m.icon }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title class="subline--text font-weight-medium fs-14">{{ m.name
                            }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider v-if="m.hr" class="mx-3"></v-divider>
                      </div>
                    </v-list>
                  </v-card>
                </v-menu>
              </div>
            </v-list-item>

            <v-divider></v-divider>
          </div>
        </div>
        <v-card v-else class="crd-trn text-center py-16 px-8 elevation-0" width="100%">
          <p class="font-weight-bold mb-2 maintext--text">
            {{ nodata == null ? "Type more than 2 letter" : "No Contract Found" }}
          </p>
          <span class="body-2 mb-5 grey--text"> {{ nodata == null ? "Eg. for Nifty Type: Nif" :
            "Search for another name."
          }}</span>
        </v-card>
      </div>
    </div>
    <!-- -->
    <div v-if="!optchainbasket && optchainbasketdata && optchainbasketdata.length > 0" class="pos-abs"
      style="bottom: 0; right: 0">
      <v-btn @click="optchainbasket = true" color="primary" fab>
        <v-badge :content="optchainbasketdata.length" color="warning">
          <img width="24px" src="@/assets/usermenu/9d.svg" />
        </v-badge>
      </v-btn>
    </div>
    <v-dialog v-model="createMWdialog" width="420">
      <v-card class="pb-4 pt-2 px-6 overflow-hidden elevation-0" color="cardbg">
        <v-list-item-title class="font-weight-bold title maintext--text mt-2 mb-4">Create New List <v-icon
            @click="createMWdialog = false" class="float-right" color="maintext">mdi-close</v-icon></v-list-item-title>
        <p class="font-weight-regular fs-14 subtext-text mb-2">Enter the watchlist name</p>
        <v-text-field hide-details height="40px" dense background-color="secbg" flat class="rounded-pill mb-4"
          placeholder="my first millions" v-on:keypress="isLetter($event)"
          v-on:keyup.enter="addSearch('wls'), (createMWdialog = false)" type="text" solo v-model="aaddtoMW.wl">
        </v-text-field>
        <v-btn @click="addSearch('wls'), (createMWdialog = false)" :disabled="!aaddtoMW.wl" color="btnclr"
          class="text-none rounded-pill elevation-0 btntext--text px-6 float-right" height="40px"> Save </v-btn>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteMWdialog" width="360">
      <v-card class="pb-4 pt-2 px-6 overflow-hidden elevation-0" color="cardbg">
        <v-list-item-title class="font-weight-bold title maintext--text mt-2 mb-4">Confirmation<v-icon
            @click="(deleteMWdialog = false), (aaddtoMW = [])" class="float-right"
            color="maintext">mdi-close</v-icon></v-list-item-title>
        <p class="font-weight-regular fs-14 subtext-text my-4">
          Do you really want to delete <b> {{ aaddtoMW ? aaddtoMW.wl : "" }} </b> watchlist ?
        </p>
        <v-toolbar flat dense class="tool-sty crd-trn">
          <v-spacer></v-spacer>
          <v-btn @click="deleteScript(), (deleteMWdialog = false)" color="btnclr"
            class="text-none rounded-pill elevation-0 btntext--text px-6" height="40px"> Yes </v-btn>
        </v-toolbar>
      </v-card>
    </v-dialog>
    <v-dialog v-model="indexdialog" hide-overlay width="420">
      <v-card class="pt-2 overflow-hidden elevation-0" color="cardbg">
        <v-list-item-title class="font-weight-bold title maintext--text my-2 px-4">Select a Index <v-icon
            @click="indexdialog = false" class="float-right" color="maintext">mdi-close</v-icon></v-list-item-title>
        <v-divider></v-divider>
        <v-expansion-panels v-model="indexpanel" flat class="expan">
          <v-expansion-panel class="" v-for="(q, w, e) in allindex" flat :key="e">
            <v-expansion-panel-header class="fs-16 font-weight-medium secbg primary--text">{{ w }} ({{ q.length
            }})</v-expansion-panel-header>
            <v-expansion-panel-content class="fs-13">
              <v-card class="elevation-0 rounded-0 overflow-y-auto" height="378px" color="transparent">
                <template v-for="(a, s) in q">
                  <v-list-item v-if="singleindex.n != a.token" :key="s" class="px-6"
                    @click="singleindex.token != a.token ? setChangeindex(a, w, s) : null">
                    <v-list-item-content class="py-0">
                      <v-list-item-title :class="singleindex.token == a.token ? 'primary--text' : 'maintext--text'"
                        class="mb-1 table-hov-text font-weight-medium">{{ a.idxname ? a.idxname : "" }}
                      </v-list-item-title>
                    </v-list-item-content>
                    <div>
                      <v-icon class="float-right" :color="singleindex.token == a.token ? 'primary' : 'maintext'">{{
                        singleindex.token == a.token ? "mdi-checkbox-marked-circle-outline" : "mdi-plus-circle-outline"
                      }}</v-icon>
                    </div>
                  </v-list-item>
                </template>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { dragscroll } from "vue-dragscroll";

import eventBus from "../../eventBus.js";
import { getPreDefinedMW, getLtpdata, getMHoldings, getMFwatchlist, getMwatchlistset, getKambalaSearch, getBSKMargin, getPlaceOrder, getIndexList } from "../../components/mixins/getAPIdata.js";
// import VueColumnsResizable from '../../plugins/';

export default {
  directives: {
    dragscroll,
  },
  data() {
    return {
      uid: null,
      mtoken: null,
      stoken: null,

      panel: false,
      addscript: false,
      watchsecti: false,

      searchloading: false,
      menulist: [
        // { name: 'Modify Order', icon: 11, type: 'order' },
        // { name: 'Exit/Cancel Order', icon: 12, type: 'order' },

        // { name: 'Add Order', icon: 'mdi-plus', type: 'order', trans: 'b' },
        // { name: 'Sell Order', icon: 'mdi-minus', type: 'order', trans: 's' },
        // { name: 'Order Status', icon: 3, type: '', hr: true },
        { name: "Create GTT / GTC", icon: 4, type: "cGTT" },
        { name: "Create Alert", icon: 5, type: "alert" },
        { name: "Market Depth", icon: 6, type: "depth" },
        { name: "Chart", icon: 7, type: "chart", hr: true },
        { name: "Delete", icon: 13, type: "delete" },
        { name: "Fundamentals", icon: 9, type: "Funda" },
        { name: "Details", icon: 10, type: "detail" },
      ],

      watchlistdata: [],
      watchlist: [],
      watchlistis: null,

      isLoading: true,
      mfisLoading: true,
      allitems: [],
      items: [],
      noitems: [],
      model: null,
      search: null,
      nodata: null,
      stocksexch: 0,

      mfmodel: null,
      mfsearch: null,
      mfexch: 0,

      aaddtoMW: [],
      createMWdialog: false,
      deleteMWdialog: false,
      switchBus: null,

      mwfilter: null,
      mwfilters: [
        { key: "a", text: "Script - A to Z" },
        { key: "z", text: "Script - Z to A" },
        { key: "ltp-h", text: "Price - High to Low" },
        { key: "ltp-l", text: "Price - Low to High" },
        { key: "ch-h", text: "Per.chg - High to Low" },
        { key: "ch-l", text: "Per.chg - Low to High" },
      ],
      PreMWlist: [
        { key: "My stocks", text: "My stocks" },
        { key: "NIFTY50:NSE", text: "Nifty50" },
        { key: "NIFTYBANK:NSE", text: "Bank Nifty" },
        { key: "SENSEX:BSE", text: "Sensex" },
      ],
      exchfilter: ["All", "Equity", "F&O", "Currency", "Commodities"],
      mfexchfilter: ["All", "Equity", "Debt", "Hybrid", "Other"],

      PreDefinedMW: [],

      mffilter: null,
      mffilters: [
        { key: "a", text: "Script - A to Z" },
        { key: "z", text: "Script - Z to A" },
        { key: "nav-h", text: "NAV - High to Low" },
        { key: "nav-l", text: "NAV - Low to High" },
        { key: "y-h", text: "3yr rtn - High to Low" },
        { key: "y-l", text: "3yr rtn - Low to High" },
      ],

      allmutualfunds: [],
      mfwatchlistdata: [],
      mfuseritem: [],
      mutualuseritems: [],

      uniqkey: [],
      stksearch: null,

      optchainbasketdata: [],
      prdordplace: false,
      optchainbasket: false,

      totalmargin: 0,
      postTrademargin: 0,
      orderloader: false,

      indexpanel: 0,
      allindex: {},
      indexdialog: false,
      singleindex: {},

      pdmwdata: [],
    };
  },

  computed: {
    // pdmwdata() {
    //   return [
    //     {key: "NIFTY50:NSE", exch: "NSE", token: "26000", tsym: "Nifty 50"},
    //     {key: "NIFTYBANK:NSE", exch: "NSE", token: "26009", tsym: "Nifty Bank"},
    //     // {key: "SENSEX:BSE", exch: "BSE", token: "1", tsym: "SENSEX"},
    //     // {key: "INDIAVIX", exch: "NSE", token: "26017", tsym: "India VIX"},
    //   ];
    // },
  },

  
  async created() {
    let path = window.location;
    if (path.pathname.includes("mutualfund")) {
      this.panel = true;
    } else {
      this.panel = false;
    }
    this.switchBus = eventBus;

    let that = this;
    window.addEventListener("keyup", function (e) {
      if (e.key === "Escape") {
        that.onMWClear();
      }
    });
  },
  mounted() {
    eventBus.$emit("login-event");
    eventBus.$on("app-user-event", () => {
      let res = sessionStorage.getItem("c3RhdHVz");
      if (res == "dmFsaWR1c2Vy") {
        if (!this.uid && !this.stoken) {
          this.mtoken = sessionStorage.getItem("msession");
          this.stoken = sessionStorage.getItem("usession");
          this.uid = sessionStorage.getItem("userid");
          this.getWatchlist();
          this.getusedMutual();
        }
      } else {
        this.watchlistis = "NIFTY50:NSE";
        // this.setPDwatchlist();
      }

      // this.pdmwdata = [...this.pdmwdata];
      var d = [
        { key: "NIFTY50:NSE", exch: "NSE", token: "26000", tsym: "Nifty 50" },
        { key: "NIFTYBANK:NSE", exch: "NSE", token: "26009", tsym: "Nifty Bank" },
      ];
      var p = JSON.parse(localStorage.getItem(`${this.uid}_pdmwdata`, this.pdmwdata));
      this.pdmwdata = p && Object.entries(p).length == 2 ? p : d;
      this.setWebsocket("sub", this.pdmwdata, "pd", "watchlist");
    });
    // eventBus.$on('appbar-event', (tab) => {
    //   this.bodytab = tab;
    // })
    eventBus.$on("setappbar-event", (data, value) => {
      if (this.allmutualfunds && data == "Mutual Fund") {
        this.panel = true;
      } else if (value == 1) {
        this.allmutualfunds = data;
        this.panel = true;
      } else {
        this.panel = false;
      }
    });

    eventBus.$on("web-scoketConn", (data) => {
      if ((this.pdmwdata && typeof this.pdmwdata == "object") || (this.watchlistdata && typeof this.watchlistdata == "object")) {
        this.optionChainDataParse(data);
      }
    });

    eventBus.$on("addscript-wl", (data, type) => {
      if (type == "searchss") {
        if (this.addscript != true) {
          this.addscript = true;
          this.watchsecti = false;
          this.items = [];
          this.nodata = null;
          this.putMWfocus(data);
        }
      } else if (type == "mf") {
        this.getusedMutual("add", data, "mf");
      } else {
        this.model = data;
        this.addSearch();
      }
    });
    eventBus.$on("bskwatch-event", (type, data) => {
      if (data) {
        let index = this.optchainbasketdata.findIndex((x) => x.tsym == data.tsym);
        if (index == -1) {
          data["ser"] = data.tsym.includes("SENSEX") && data.tsym.includes(" ") ? data.dname.split(" ") : data.tsym.split(/(\d+)/);
          data["tsyms"] = data.ser[0];
          data.checkbox = true;
          data["exp"] = `${data.ser[5] ? data.ser[5] : data.ser[3] ? data.ser[3] : ""} ${data.ser[4] ? data.ser[4] : ""}${data.ser[4] && data.ser[4].includes("E") ? "" : "E"}`;
          data.ser = `${data.ser[1]} ${data.ser[2]} ${data.ser[5] ? data.ser[3] : ""}`;
          this.optchainbasketdata.push(data);
          this.getOBMargin();
        } else {
          eventBus.$emit("snack-event", 0, "This script is already exists.");
        }
      }
      this.optchainbasket = true;
    });
  },
  beforeDestroy() {
    eventBus.$off("app-user-event");
    eventBus.$off("setappbar-event");
    eventBus.$off("bskwatch-event");
    // eventBus.$off('web-scoketConn');
  },
  methods: {
    async stockSearch(val) {
      console.log(val);
      
      if (!val || val.length <= 2) {
        if (this.items.length === 0) this.addscript = false;
        return;
      }

      this.addscript = true;
      this.searchloading = true;
      let query = `jData={"uid":"${this.uid}","stext":"${val}"}&jKey=${this.mtoken}`;
      // let query = `jData={"uid":"${this.uid}","stext":"${val.replace(/\s+/g, "_")}"}&jKey=${this.mtoken}`;
      let res = await getKambalaSearch(query);

      this.nodata = null;

      if (res.stat === "Ok" && res.values) {
        this.allitems = res.values;

        if (Array.isArray(this.watchlistdata)) {
          this.allitems.forEach((so) => {
            if (this.watchlistdata.some(o => o.token === so.token)) so["watchlist"] = true;

            if (["NFO", "BFO"].includes(so.exch)) {
              let ser = so.tsym.includes("SENSEX") ? so.dname.split(" ") : so.tsym.split(/(\d+)/);
              so["tsyms"] = ser[0];
              so["exp"] = so.instname.includes("FUT")
                ? "FUT"
                : so.instname.includes("OPT")
                  ? `${ser[5] || ser[3] || ""} ${ser[4]}${ser[4].includes("E") ? "" : "E"}`
                  : "";

              so["ser"] = so.instname.includes("OPT")
                ? `${ser[1]} ${ser[2]} ${ser[5] ? ser[3] : ""}`
                : `${ser[1]} ${ser[2].slice(0, 3)} ${ser[3] && ser[3] !== "FUT" ? ser[3] : ""}`;
            }
          });
        }
        this.searchFilter();
      } else {
        this.items = [];
        this.nodata = "noooo";
      }
      this.searchloading = false;
    },

    async mutfndsearch(val) {
      if (!val || val.length <= 2 || !this.allmutualfunds?.data) {
        this.addscript = false;
        return;
      }

      this.addscript = true;
      this.searchloading = true;
      this.nodata = null;

      let data = this.allmutualfunds.data.filter(obj =>
        JSON.stringify(obj).toUpperCase().includes(val.toUpperCase())
      );

      if (data.length) {
        this.mutualuseritems = data.slice(0, 25);

        this.mutualuseritems.forEach(so => {
          if (this.mfuseritem.some(o => o.Scheme_Code === so.Scheme_Code)) {
            so["watchlist"] = true;
          }
        });

        this.mfsearchFilter();
      } else {
        this.nodata = "noooo";
        this.mutualuseritems = [];
        this.mfwatchlistdata = [];
      }

      this.searchloading = false;
    },
    onMWClear() {
      this.items = [];
      this.nodata = 'noooo';
      this.searchloading = false;
      this.stksearch = "";
      this.mfsearch = "";
      this.setEscape();
    },
    setBskmodify(o, key, value) {
      if (key == "delete") {
        this.optchainbasketdata.splice(o, 1);
      } else {
        this.optchainbasketdata[o][key] = value;
        this.$set(this.optchainbasketdata, o, this.optchainbasketdata[o]);
      }
      this.getOBMargin();
    },
    async getOBMargin() {
      if (this.optchainbasketdata && this.optchainbasketdata.length > 0) {
        let item;
        for (let i = 0; i < this.optchainbasketdata.length; i++) {
          var opta = this.optchainbasketdata[i];
          if (opta.checkbox) {
            if (i == 0) {
              item = {
                uid: this.uid,
                actid: this.uid,
                discqty: "0",
                exch: opta.exch,
                pCode: "I",
                prctyp: opta.ordvai,
                prd: "I",
                prc: opta.ordvai == "MKT" ? "" : opta.ordprc,
                qty: String(Number(opta.ls) * Number(opta.ordlot)),
                ret: "DAY",
                symbol_id: opta.token,
                tsym: opta.tsym,
                trantype: opta.buySell == "BUY" ? "B" : "S",
                trgprc: "0",
                basketlists: [],
              };
            } else if (i > 0) {
              if (!item.basketlists) {
                item.basketlists = [];
              }
              item.basketlists.push({
                uid: this.uid,
                actid: this.uid,
                discqty: "0",
                exch: opta.exch,
                pCode: "I",
                prctyp: opta.ordvai,
                prd: "I",
                prc: opta.ordvai == "MKT" ? "" : opta.ordprc,
                qty: String(opta.ls * opta.ordlot),
                ret: "DAY",
                symbol_id: opta.token,
                tsym: opta.tsym,
                trantype: opta.buySell == "BUY" ? "B" : "S",
                trgprc: "0",
              });
            }
          }
        }

        if (item) {
          let marginData = await getBSKMargin(item);
          this.totalmargin = marginData.marginusedtrade == undefined ? 0 : marginData.marginused;
          this.postTrademargin = marginData.marginusedtrade == undefined ? 0 : marginData.marginusedtrade;
        } else {
          this.totalmargin = 0;
          this.postTrademargin = 0;
        }
      } else {
        this.totalmargin = 0;
        this.postTrademargin = 0;
      }
    },
    setBfoPlaceorder(i) {
      this.orderloader = true;
      if (i <= this.optchainbasketdata.length - 1) {
        this.setPlaceorder(this.optchainbasketdata[i], i);
      } else {
        eventBus.$emit("snack-event", 1, `Basket order placed successfully`);
        eventBus.$emit("orderbook-update", "orders");
        this.orderloader = false;
      }
    },
    async setPlaceorder(data, i) {
      let item = {
        uid: this.uid,
        actid: this.uid,
        // "amo": this.addmktord ? 'Yes' : '',
        // "blprc": this.ordertype == 1 || this.ordertype == 2 ? this.ordslprice.toString() : '',
        // "bpprc": this.ordertype == 2 ? this.ordtagprice.toString() : '',
        // "dscqty": this.addvalqty ? this.orddisqty.toString() : '',
        exch: data.exch,
        tsym: data.tsym,
        ret: "DAY",
        qty: String(Number(data.ls) * Number(data.ordlot)),
        prc: data.ordvai == "MKT" ? "0" : data.ordprc,
        prd: this.prdordplace ? "I" : "M",
        trantype: data.buySell == "BUY" ? "B" : "S",
        prctyp: data.ordvai,
        // "trgprc": (data.prctype == "SL-LMT" || data.prctype == "SL-MKT") ? this.ordtrgprice.toString() : '',
        // "mktProt": (data.prctype == 'MKT' || data.prctype == 'SL-MKT') ? this.mktprdordqty.toString() : '',
        trailprc: "",
        channel: "",
        userAgent: "",
        appInstaId: "",
      };
      // item["amo"] = ""
      item["trgprc"] = "0";
      item["mktProt"] = "";
      // data.addmktord ? (item["amo"] = "Yes") : "";
      // data.ordvai == "SL-LMT" || data.prctype == "SL-MKT" ? (item["trgprc"] = data.trgprice.toString()) : "";
      // data.ordvai == "MKT" || data.prctype == "SL-MKT" ? (item["mktProt"] = data.mktprdordqty.toString()) : "";
      let odata = await getPlaceOrder(item);
      if (odata.stat != "Ok") {
        eventBus.$emit("snack-event", 2, odata.emsg ? odata.emsg : odata);
        this.orderloader = false;
      }
      setTimeout(() => {
        this.setBfoPlaceorder(i + 1);
      }, 100);
    },
    setSinglepage(item) {
      let path = window.location;

      if (path.pathname == "/mutualfund/single") {
        eventBus.$emit("ssdmf-event", item);
      } else {
        this.$router.push({ name: "mutual fund single", params: item });
      }
    },
    searchFilter() {
      this.items = [];
      if (this.stocksexch == 0) {
        this.items = this.allitems;
      } else if (this.stocksexch >= 1 && this.stocksexch <= 4) {
        for (let s = 0; s < this.allitems.length; s++) {
          if (this.stocksexch == 1 && (this.allitems[s].exch == "NSE" || this.allitems[s].exch == "BSE")) {
            this.items.push(this.allitems[s]);
          } else if (this.stocksexch == 2 && (this.allitems[s].exch == "NFO" || this.allitems[s].exch == "BFO")) {
            this.items.push(this.allitems[s]);
          } else if (this.stocksexch == 3 && this.allitems[s].exch == "CDS") {
            this.items.push(this.allitems[s]);
          } else if (this.stocksexch == 4 && this.allitems[s].exch == "MCX") {
            this.items.push(this.allitems[s]);
          }
        }
      } else {
        this.items = [];
        this.nodata = "noooo";
      }
      this.isLoading = false;
    },
    mfsearchFilter() {
      this.mfwatchlistdata = [];
      if (this.mfexch == 0) {
        this.mfwatchlistdata = this.mutualuseritems;
      } else {
        for (let f = 0; f < this.mutualuseritems.length; f++) {
          if (this.mfexch == 1 && this.mutualuseritems[f].splitt == "EQUITY") {
            this.mfwatchlistdata.push(this.mutualuseritems[f]);
          } else if (this.mfexch == 2 && this.mutualuseritems[f].splitt == "DEBT") {
            this.mfwatchlistdata.push(this.mutualuseritems[f]);
          } else if (this.mfexch == 3 && this.mutualuseritems[f].splitt == "HYBRID") {
            this.mfwatchlistdata.push(this.mutualuseritems[f]);
          } else if (this.mfexch == 4 && (this.mutualuseritems[f].splitt == "OTHERS" || this.mutualuseritems[f].splitt == "SOLUTIONORIENTED")) {
            this.mfwatchlistdata.push(this.mutualuseritems[f]);
          }
        }
      }
    },
    setMWfilter() {
      if (this.mwfilter == 0) {
        this.watchlistdata.sort((a, b) => a.tsym.localeCompare(b.tsym));
      } else if (this.mwfilter == 1) {
        this.watchlistdata.sort((a, b) => b.tsym.localeCompare(a.tsym));
      } else if (this.mwfilter == 2) {
        this.watchlistdata.sort((a, b) => b.ltp - a.ltp);
      } else if (this.mwfilter == 3) {
        this.watchlistdata.sort((a, b) => a.ltp - b.ltp);
      } else if (this.mwfilter == 4) {
        this.watchlistdata.sort((a, b) => b.chp - a.chp);
      } else if (this.mwfilter == 5) {
        this.watchlistdata.sort((a, b) => a.chp - b.chp);
      } else {
        this.watchlistdata.sort((a, b) => b.exch.localeCompare(a.exch));
      }
    },
    setMFFilter() {
      if (this.mffilter == 0) {
        this.mfuseritem.sort((a, b) => a.f_scheme_name.localeCompare(b.f_scheme_name));
      } else if (this.mffilter == 1) {
        this.mfuseritem.sort((a, b) => b.f_scheme_name.localeCompare(a.f_scheme_name));
      } else if (this.mffilter == 2) {
        this.mfuseritem.sort((a, b) => Number(b.NET_ASSET_VALUE) - Number(a.NET_ASSET_VALUE));
      } else if (this.mffilter == 3) {
        this.mfuseritem.sort((a, b) => Number(a.NET_ASSET_VALUE) - Number(b.NET_ASSET_VALUE));
      } else if (this.mffilter == 4) {
        this.mfuseritem.sort((a, b) => Number(b.THREE_YEAR_DATA) - Number(a.THREE_YEAR_DATA));
      } else if (this.mffilter == 5) {
        this.mfuseritem.sort((a, b) => Number(a.THREE_YEAR_DATA) - Number(b.THREE_YEAR_DATA));
      } else {
        this.mfuseritem.sort((a, b) => b.Unique_No.localeCompare(a.Unique_No));
      }
    },
    putMWfocus() {
      setTimeout(() => this.$refs.mwref.$refs.input.focus(), 10);
    },
    async getusedMutual(mode, item, del) {
      this.mfisLoading = true;
      let data;
      if (mode == "add") {
        data = {
          client_code: this.uid,
          scripts: del == "mf" ? item : this.mfmodel,
          type: "add",
        };
      } else if (mode == "delete") {
        data = {
          client_code: this.uid,
          scripts: item,
          type: "delete",
        };
      } else {
        data = {
          client_code: this.uid,
        };
      }
      let res = await getMFwatchlist(JSON.stringify(data));
      // res.data == "No data"
      var showdata = [];
      if (res.scripts && res.scripts.length > 0 && res.stat == "Ok" && !res.msg) {
        showdata = res.scripts;
      } else if (res.msg == "dataupdated") {
        showdata = res.scripts;
        // this.mfuseritem.unshift(this.mfmodel);
      } else if (res.msg == "script deleted") {
        this.mfuseritem.splice(del, 1);
      } else if (res.scripts != "No data" && mode) {
        eventBus.$emit("snack-event", 0, res.msg ? res.msg : res);
      }
      if (showdata && showdata.length > 0) {
        for (let w = 0; w < showdata.length; w++) {
          let arr = showdata[w].Scheme_Name.split("-").slice(0);
          for (let d = 0; d < arr.length; d++) {
            showdata[w]["splito"] = arr[d].includes("GROWTH") ? "GROWTH" : arr[d].includes("IDCW PAYOUT") ? "IDCW PAYOUT" : arr[d].includes("IDCW REINVESTMENT") ? "IDCW REINVESTMENT" : arr[d].includes("IDCW") ? "IDCW" : "NORMAL";
          }
          showdata[w]["splitt"] = showdata[w].SCHEME_CATEGORY.toUpperCase();
          showdata[w]["splitr"] = showdata[w].SCHEME_SUB_CATEGORY.replace(/Fund|Hybrid|_/gi, "").toUpperCase();
        }
        this.mfuseritem = showdata;
        this.setMFFilter();
      }
      this.mfisLoading = false;
    },
    async addSearch(type, l) {
      var list;
      var ind;
      if (type != "wls" && this.watchlistdata && typeof this.watchlistdata == "object") {
        list = this.watchlist.indexOf(this.watchlistis);
        list = list >= 0 ? this.watchlistis : this.watchlist && this.watchlist.length > 0 ? this.watchlist[0] : "Millionaire";
        if (this.watchlistdata && this.watchlistdata.length >= 50) {
          ind = "full";
        } else {
          ind = this.watchlistdata.findIndex((o) => o.token == this.model.token);
        }
      } else {
        list = this.watchlistis;
      }
      // Maximum 50 symbols you can add in single watchlists.
      if (ind >= 0 || ind == "full") {
        eventBus.$emit("snack-event", 2, ind == "full" ? "Maximum 50 symbols you can add in single watchlists." : `${this.model.tsym}, script is already exists.`);
      } else {
        let res = await getMwatchlistset(type == "wls" ? `jData={"uid":"${this.uid}","wlname":"${this.aaddtoMW.wl}","scrips":""}&jKey=${this.mtoken}` : `jData={"uid":"${this.uid}","wlname":"${list}","scrips":"${this.model.exch}|${this.model.token}"}&jKey=${this.mtoken}`, "AddMultiScripsToMW");
        if (res.stat == "Ok") {
          eventBus.$emit("snack-event", 1, type == "wls" ? `${this.aaddtoMW.wl}, watchlist as created.` : `${this.model.tsym}, script as added.`);

          if (type == "wls") {
            this.watchlist.unshift(this.aaddtoMW.wl);
            this.watchlistis = this.aaddtoMW.wl;
          } else if (l) {
            this.items[l]["watchlist"] = true;
            this.aaddtoMW = [];
            this.stksearch = null;
            // axiosThis.watchsecti = false;
            // axiosThis.addscript = false;
          }
          this.getMWlistdata();
        } else {
          eventBus.$emit("snack-event", 0, res.emsg ? res.emsg : res);
          this.items = [];
          this.model = null;
        }
      }
    },
    deleteuserMutual(item) {
      let del = this.mfuseritem.indexOf(item);
      this.getusedMutual("delete", item, del);
    },
    async deleteScript(model, del) {
      let data;
      let item = "";
      if (this.aaddtoMW.delete && this.watchlistdata && typeof this.watchlistdata == "object") {
        for (let s = 0; s < this.watchlistdata.length; s++) {
          item += `${s > 0 ? "#" : ""}${this.watchlistdata[s].exch}|${this.watchlistdata[s].token}`;
        }
        data = `jData={"uid":"${this.uid}","wlname":"${this.aaddtoMW.wl}","scrips":"${item}"}&jKey=${this.mtoken}`;
      } else {
        data = `jData={"uid":"${this.uid}","wlname":"${this.watchlistis}","scrips":"${model && model.exch ? model.exch + "|" + model.token : ""}"}&jKey=${this.mtoken}`;
      }

      let res = await getMwatchlistset(data, "DeleteMultiMWScrips");
      if (res.stat == "Ok") {
        if (this.aaddtoMW.delete) {
          this.watchlist.splice(this.watchlist.indexOf(this.aaddtoMW.wl), 1);
          if (this.watchlistis == this.aaddtoMW.wl) {
            this.watchlistis = this.watchlist[0];
            this.getMWlistdata();
          }
          eventBus.$emit("snack-event", 2, `${this.aaddtoMW.name}, Watchlist as deleted.`);
        } else {
          if (del == -1) {
            let d = this.watchlistdata.findIndex((o) => o.token == model.token);
            this.watchlistdata.splice(d, 1);
          } else {
            this.watchlistdata.splice(del, 1);
          }
          eventBus.$emit("snack-event", 2, `${model.tsym}, script as removed`);
        }
        this.aaddtoMW = [];
      }
    },
    putMForder(value, item) {
      eventBus.$emit("menudialog", "mforder", item, value);
    },
    async getWatchlist() {
      let res = await getMwatchlistset(`jData={"uid":"${this.uid}","actid":"${this.uid}"}&jKey=${this.mtoken}`, "MWList");
      if (res && ((res.values && res.values.length > 0) || res.stat == "Ok")) {
        let data = res.values;
        this.watchlist = data.sort((a, b) => a.localeCompare(b));
        this.watchlistis = data[0];
        this.getMWlistdata();
      } else {
        this.watchlistis = "NIFTY50:NSE";
        this.setPDwatchlist();
        // this.watchsecti = false
        // this.watchlistdata = 'no data';
        // eventBus.$emit('snack-event', 2, response.data.emsg ? response.data.emsg : response.data);
        // this.loading = false;
      }
      this.isLoading = false;
    },
    async getMWlistdata() {
      this.isLoading = true;
      if (this.watchlistdata && typeof this.watchlistdata == "object") {
        this.setWebsocket("unsub", this.watchlistdata, "wl");
      }
      this.watchlistdata = [];

      let res = await getMwatchlistset(`jData={"uid":"${this.uid}","wlname":"${this.watchlistis}"}&jKey=${this.mtoken}`, "MarketWatch");

      if (res && res.values.length > 0 && res.stat == "Ok") {
        this.isLoading = false;
        let wl = res.values;
        for (let l = 0; l < wl.length; l++) {
          if (wl[l].exch == "NFO" || wl[l].exch == "BFO") {
            wl[l]["ser"] = wl[l].tsym.includes("SENSEX") ? wl[l].dname.split(" ") : wl[l].tsym.split(/(\d+)/);
            wl[l]["tsyms"] = wl[l].ser[0];
            wl[l]["exp"] = wl[l].instname.includes("FUT") ? "FUT" : wl[l].instname.includes("OPT") ? `${wl[l].ser[5] ? wl[l].ser[5] : wl[l].ser[3] ? wl[l].ser[3] : ""} ${wl[l].ser[4] ? wl[l].ser[4] : ""}${wl[l].ser[4] && wl[l].ser[4].includes("E") ? "" : "E"}` : "";
            wl[l].ser = wl[l].instname.includes("OPT") ? `${wl[l].ser[1]} ${wl[l].ser[2]} ${wl[l].ser[5] ? wl[l].ser[3] : ""}` : `${wl[l].ser[1]} ${wl[l].ser[2].slice(0, 3)} ${wl[l].ser[3] && wl[l].ser[3] != "FUT" ? wl[l].ser[3] : ""}`;
          }
          this.watchlistdata.push(wl[l]);
        }
        this.setWebsocket("sub", this.watchlistdata, "wl", "watchlist");
        this.setMWfilter();
      } else {
        this.isLoading = false;
        this.watchlistdata = "no data";
        // eventBus.$emit('snack-event', 2, res.emsg ? res.emsg : 'no data');
      }
    },
    async setPDwatchlist(w) {
      if (!w) {
        this.isLoading = true;
        if (this.watchlistdata && typeof this.watchlistdata == "object") {
          this.setWebsocket("unsub", this.watchlistdata, "wl");
        }
        this.watchlistdata = [];
        if (this.watchlistis == "My stocks") {
          let data = await getMHoldings();
          if (data && data.response && data.response.length > 0) {
            data.response.map((o) => this.watchlistdata.push(o.exch_tsym[0]));
          } else {
            this.watchlistdata = "no data";
          }
        } else if (this.watchlistis == "NIFTY50:NSE" || this.watchlistis == "NIFTYBANK:NSE" || this.watchlistis == "SENSEX:BSE") {
          if (this.PreDefinedMW.stat != "Ok") {
            this.PreDefinedMW = await getPreDefinedMW();
          }
          this.watchlistdata = this.PreDefinedMW[this.watchlistis];
        }
        if (this.watchlistdata && typeof this.watchlistdata == "object") {
          this.setWebsocket("sub", this.watchlistdata, "wl", "watchlist");
        }
        this.isLoading = false;
        this.PreDefinedMW["is"] = true;
      } else {
        this.PreDefinedMW["is"] = false;
        this.watchlistis = w ? w : this.watchlist[0];
        this.getMWlistdata();
      }
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z]+$/.test(char) || char == " ") return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },

    setSSDtab(type, token, exch, tsym) {
      if (type == "alert") {
        eventBus.$emit("menudialog", "alert", token, exch, tsym);
      } else if (type == "cGTT") {
        eventBus.$emit("menudialog", "order-GTT", token, exch, tsym, "b");
      } else {
        let path = window.location;
        path["val"] = [type, token, exch, tsym];
        if (path.pathname != "/stocks/details") {
          this.$router.push({ name: "stocks details", params: { val: path.val } });
        } else {
          eventBus.$emit("ssd-event", type, token, exch, tsym);
        }
      }
    },
    async setWebsocket(flow, data, is) {
      if (this.uid) {
        eventBus.$emit("web-scoketOn", flow, data, is, "watchlist");
      } else {
        let raw = await getLtpdata(data);
        if (is == "wl") {
          this.watchlistdata.map((e) => {
            e["ltp"] = e.token ? Number(raw.data[e.token].lp) : null;
            e["ch"] = e.token ? (e.ltp - Number(raw.data[e.token].close)).toFixed(2) : null;
            e["chp"] = e.token ? Number(raw.data[e.token].change) : null;
            // this.$set(this.watchlistdata, e, this.watchlistdata[e]);
          });
        } else if (is == "pd") {
          this.pdmwdata.map((e) => {
            e["ltp"] = e.token ? Number(raw.data[e.token].lp).toFixed(2) : null;
            e["ch"] = e.token ? Number(e.ltp - Number(raw.data[e.token].close)).toFixed(2) : null;
            e["chp"] = e.token ? Number(raw.data[e.token].change) : null;
          });
        }
      }
    },
    // scrollToo(id, value) {
    //   const element = document.getElementById(`${id}`);
    //   element.scrollBy({
    //     left: value,
    //     behavior: "smooth",
    //   });
    // },

    // scrollFrom(id, value) {
    //   const element = document.getElementById(`${id}`);
    //   element.scrollBy({
    //     left: value,
    //     behavior: "smooth",
    //   });
    // },
    optionChainDataParse(data) {
      let p = this.pdmwdata.findIndex((o) => o.token == data.token);
      if (p >= 0 && this.pdmwdata[p].token == data.token) {
        this.pdmwdata[p].ltp = Number(data.lp).toFixed(2);
        this.pdmwdata[p]["ch"] = Number(data.ch) > 0 || Number(data.ch) < 0 ? Number(data.ch).toFixed(2) : (0).toFixed(2);
        this.pdmwdata[p]["chp"] = Number(data.chp).toFixed(2);

        let tag = document.getElementById(`p${data.token}ltp`);
        if (tag) {
          document.getElementById(`p${data.token}ltp`).innerHTML = this.pdmwdata[p].ltp;
          document.getElementById(`p${data.token}ch`).innerHTML = this.pdmwdata[p].ch;
          document.getElementById(`p${data.token}chp`).innerHTML = ` (${this.pdmwdata[p].chp}%)`;
          eventBus.$emit("color-event", `p${data.token}chpclr`, this.pdmwdata[p].ch > 0 ? "maingreen--text" : this.pdmwdata[p].ch < 0 ? "mainred--text" : "subtext--text");
        }

        // this.$set(this.pdmwdata, p, this.pdmwdata[p]);
      }

      if (this.watchlistdata && typeof this.watchlistdata == "object") {
        let w = this.watchlistdata.findIndex((o) => o.token == data.token);
        if (w >= 0 && this.watchlistdata[w].token == data.token) {
          this.watchlistdata[w].ltp = Number(data.lp).toFixed(2);
          this.watchlistdata[w]["ch"] = Number(data.ch) > 0 || Number(data.ch) < 0 ? Number(data.ch).toFixed(2) : (0).toFixed(2);
          this.watchlistdata[w]["chp"] = Number(data.chp).toFixed(2);
          let tag = document.getElementById(`${data.token}ltp`);
          if (tag) {
            document.getElementById(`${data.token}ltp`).innerHTML = this.watchlistdata[w].ltp;
            document.getElementById(`${data.token}ch`).innerHTML = this.watchlistdata[w].ch;
            document.getElementById(`${data.token}chp`).innerHTML = ` (${this.watchlistdata[w].chp}%)`;
            eventBus.$emit("color-event", `${data.token}chpclr`, this.watchlistdata[w].ch > 0 ? "maingreen--text" : this.watchlistdata[w].ch < 0 ? "mainred--text" : "subtext--text");
          }
          // this.$set(this.watchlistdata, w, this.watchlistdata[w]);
          if (this.mwfilter >= 2 && this.mwfilter <= 5) {
            this.setMWfilter();
          }
        }
      }
    },
    setEscape() {
      if (this.watchsecti) {
        this.watchsecti = false;
      }
      if (this.addscript) {
        this.addscript = false;
      }
    },
    async getAllindicedata(item, callback) {
      let data = await getIndexList();
      if (data && data.stat == "Ok") {
        this.allindex["NSE"] = data["NSE"];
        this.allindex["BSE"] = data["BSE"];
        this.allindex["MCX"] = data["MCX"];
        this.indexdialog = true;
        this.singleindex = item;
        this.singleindex["n"] = this.pdmwdata[callback].token;
      }
    },
    setChangeindex(item, exch) {
      var i = this.pdmwdata.findIndex((o) => o.token === this.singleindex.token);
      this.pdmwdata[i] = { exch: exch, token: item.token, tsym: item.idxname };
      this.singleindex = item;
      this.indexdialog = false;
      localStorage.setItem(`${this.uid}_pdmwdata`, JSON.stringify(this.pdmwdata));
      this.setWebsocket("sub", this.pdmwdata, "pd", "watchlist");
    },
  },
};
</script>

<style>
.bskfield .v-text-field {
  margin-top: 0px !important;
}
</style>
