// /* eslint-disable */
import apiurl from '../../apiurl'
import { mynturl } from "../../apiurl";
// import postD from '../../posD.json'
import fire from '../../firebase';
let firebase = null

// let res = sessionStorage.getItem("c3RhdHVz");
import eventBus from "../../eventBus";

var uid = apiurl.uid
var tok = apiurl.tok
var source = null;

export async function seyCheckwebsocket() {
    uid = sessionStorage.getItem('userid');
    tok = sessionStorage.getItem('msession');
}

let myHeaders = new Headers();
let myHeader = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeader.append("Content-Type", "text/plain");

var requestOptions = {
    method: 'POST',
    redirect: 'follow',
    headers: myHeaders
};
var requestMOption = {
    method: 'POST',
    redirect: 'follow',
    headers: myHeader
};

export async function GetAllSearchData(item, cat) {
    if (cat) {
        requestOptions['body'] = `{"text":"${item}" , "category":"${cat.toUpperCase()}"}`;
    } else {
        requestOptions['body'] = `{"text":"${item}"}`;
    }
    var response = await FetchsearchData('http://192.168.5.180:5000/' + "search", requestOptions);
    return response
}

export async function getToken() {
    requestOptions['body'] = "";
    var response = await fetchMyntAPI('https://sess.mynt.in/getoken', requestOptions)
    return response
}
// Auth
export async function getKambalaAuth(item) {
    requestOptions['body'] = `{"LoginId":"${item[0]}", "token":"${item[1]}"}`
    var response = await fetchMyntAPI(apiurl.autho + "kambala_auth", requestOptions)
    return response
}

export async function getDeskLogout(item) {
    requestOptions['body'] = `{"clientid":"${item[0]}", "token":"${item[1]}"}`
    var response = await fetchMyntAPI(apiurl.autho + "desklogout", requestOptions)
    return response
}

export async function getReSession(item) {
    const c = crypto.randomUUID();
    // localStorage.setItem('timie', c)
    requestOptions['body'] = `{"imei": "${c}","clientid": "${item[0]}","hstoken": "${item[1]}","source": "TV","path": "${item[2]}"}`
    var response = await fetchMyntAPI(apiurl.copy + "redirect", requestOptions)
    // var response = await fetchMyntAPI('http://192.168.5.180:5001/' + "redirect", requestOptions)
    if (response && response.stat == "Ok") { response['timei'] = c; }
    return response
}

export async function getProfiledata(item) {
    requestOptions['body'] = item[0];
    myHeaders.append("clientid", `${item[1]}`);
    myHeaders.append("Authorization", `${item[2]}`);
    var response = await fetchMyntAPI(apiurl.ledger + "profile", requestOptions);
    return response
}

export async function getValidSession(item) {
    requestOptions['body'] = `{"clientid":"${item}", "mobile_unique": "web"}`
    var response = await fetchMyntAPI(apiurl.copy + "validate_session", requestOptions)
    return response
}
export async function getActiveSession(item) {
    if (firebase && firebase.source) {
        source = firebase.source;
    } else {
        await fire.get(fire.child(fire.ref(fire.db), `desk/`)).then((snapshot) => {
            if (snapshot.exists()) {
                firebase = snapshot.val()
                source = firebase.source;
            } else {
                firebase = 'WEB'
                source = "WEB"
            }
        }).catch((error) => {
            source = "WEB"
            console.error(error);
        });
        var soc = new URL(window.location.href).searchParams.get("src");
        var mainsoc = soc ? soc : sessionStorage.getItem(`socsrc`);
        if (mainsoc) {
            source = mainsoc;
            firebase.source = mainsoc;
            sessionStorage.setItem(`socsrc`, source);
        }

        // requestOptions['body'] = "";
        // var res = await fetchMyntAPI(apiurl.sessapi + "strapi/myntdesk", requestOptions)
        // if (res && res.data && res.data.attributes && res.data.attributes.source) {
        //     source = res.data.attributes;
        //     srcis = res.data.attributes.source;
        // } else {
        //     source = "WEB"
        //     srcis = "WEB";
        // }
    }

    var payid = seteEcryption(item)
    requestOptions['body'] = `{"string":"${payid}","source":"${source}"}`
    var response = await fetchMyntAPI(apiurl.copy + "get_sessions", requestOptions)
    var out = JSON.parse(setDecryption(response.str))
    return out
}
export async function getPreDefinedMW() {
    requestOptions['body'] = "";
    var response = await fetchMyntAPI(apiurl.copy + "PreDefinedMW", requestOptions)
    return response
}
export async function getLoggedIn() {
    requestMOption['body'] = `jData={"uid":"${uid}"}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + "ShowLoggedInSessions", requestMOption)
    return response
}

export async function getHsTokenapi() {
    requestMOption['body'] = `jData={"uid":"${uid}"}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + "GetHsToken", requestMOption)
    return response
}

export async function getKambalaSearch(data) {
    requestMOption['body'] = data
    var response = await fetchMyntAPI(mynturl.myntapi + 'SearchScrip', requestMOption)
    // var response = await fetchMyntAPI(apiurl.zebuApiUrl + 'global/SearchScrip', requestMOption)
    return response
}

export async function getMwatchlistset(data, url) {
    requestMOption['body'] = data
    var response = await fetchMyntAPI(mynturl.myntapi + url, requestMOption)
    return response
}

export async function getMHoldings() {
    requestMOption['body'] = `jData={"uid":"${uid}","actid":"${uid}","prd":"C"}&jKey=${tok}`
    var data = await fetchMyntAPI(mynturl.myntapi + "Holdings", requestMOption)
    // data = hold
    let holdlist = [];
    var sum = 0;
    if (data && data.length > 0) {
        for (let q = 0; q < data.length; q++) {
            data[q]["idx"] = q;
            data[q]["exch"] = data[q].exch_tsym[0].exch;
            data[q]["exchs"] = data[q].exch_tsym[0].tsym.includes("-TB") ? "T-BILL" : data[q].exch_tsym[0].tsym.includes("-GS") ? "G-SEC" : data[q].exch_tsym[0].tsym.includes("-GB") ? "SGB" : data[q].exch_tsym[0].exch;
            // data[q]['exch'] = data[q].exch_tsym[0].exch
            data[q]["token"] = data[q].exch_tsym[0].token;
            data[q]["tsym"] = data[q].exch_tsym[0].tsym;
            data[q]["pp"] = data[q].exch_tsym[0].pp;
            data[q]["ti"] = data[q].exch_tsym[0].ti;
            data[q]["ls"] = data[q].exch_tsym[0].ls;
            data[q]["isin"] = data[q].exch_tsym[0].isin;
            data[q]["netqty"] =
                Number((data[q].btstqty) > 0
                    ? Number(data[q].btstqty)
                    : 0 + Number(data[q].holdqty) > 0
                        ? Number(data[q].holdqty)
                        : 0 + Number(data[q].brkcolqty) > 0
                            ? Number(data[q].brkcolqty)
                            : 0 + Number(data[q].unplgdqty) > 0
                                ? Number(data[q].unplgdqty)
                                : 0 + Number(data[q].benqty) > 0
                                    ? Number(data[q].benqty)
                                    : 0


                                    + Math.max(Number(data[q].npoadt1qty) > 0 ? Number(data[q].npoadt1qty) : 0, Number(data[q].npoadqty) > 0 ? Number(data[q].npoadqty) : 0, Number(data[q].dpqty) > 0 ? Number(data[q].dpqty) : 0))

                                    - (Number(data[q].usedqty) > 0 ? Number(data[q].usedqty) : 0);
                                    console.log(data[q].tsym);
      
            
            
            data[q]["salqty"] = data[q].btstqty > 0 ? data[q].btstqty : 0 + data[q].holdqty > 0 ? data[q].holdqty : 0 + data[q].unplgdqty > 0 ? data[q].unplgdqty : 0 + data[q].benqty > 0 ? data[q].benqty : 0 + data[q].dpqty > 0 ? data[q].dpqty : 0 - data[q].usedqty > 0 ? data[q].usedqty : 0;
            data[q]["plgqty"] = data[q].brkcolqty > 0 ? data[q].brkcolqty : 0;
            data[q]["action"] = data[q].exch == "NSE" || data[q].exch == "BSE" || data[q].exch == "NFO" || data[q].exch == "MCX" || data[q].exch == "CD" ? true : false;
            holdlist.push({ ...data[q] });

            if (data[q].npoadqty && Number(data[q].npoadqty > 0)) {
                sum += Number(data[q].npoadqty);
            }
        }

    }

    return { response: holdlist, edis: sum }
}

export async function getMMHoldings() {
    requestMOption['body'] = `jData={"uid":"${uid}","actid":"${uid}","prd":"C"}&jKey=${tok}`
    var datamf = await fetchMyntAPI(mynturl.myntapi + "GetMFSSHoldInfo", requestMOption)
    // datamf = holdmf
    let statdata = {
        positive: [],
        negative: [],
    }
    if (datamf && datamf.length > 0) {
        let stockvalue = 0;
        let invested = 0;
        let pnlTotal = 0;

        for (let q = 0; q < datamf.length; q++) {
            let item = datamf[q];
            let exchTsym = item.exch_tsym[0];

            item["idx"] = datamf.length + q + 1;
            item["exch"] = exchTsym.exch;
            item["exchs"] = "MF";
            item["token"] = exchTsym.token;
            item["tsym"] = exchTsym.tsym;
            item["name"] = exchTsym.cname;
            item["pp"] = exchTsym.pp;
            item["ti"] = exchTsym.ti;
            item["isin"] = exchTsym.isin;
            item["upldprc"] = item.upload_prc;

            let npoadqty = item.npoadqty > 0 ? item.npoadqty : 0;
            let holdqty = item.holdqty > 0 ? item.holdqty : 0;
            let btstqty = item.btstqty > 0 ? item.btstqty : 0;
            let colqty = item.colqty > 0 ? item.colqty : 0;

            item["tolqty"] = Math.round(npoadqty || holdqty || btstqty);
            item["plgqty"] = Math.round(colqty);
            item["netqty"] = Math.round(npoadqty || holdqty || colqty || btstqty);

            item["quotes"] = await getMFQuotesdata(`${item.exch}|${item.token}`);
            item["ltp"] = Number(item.quotes.nav).toFixed(2);

            let inv = (Number(item.upldprc) !== 0 ? Number(item.upldprc).toFixed(2) : item.ltp) * Math.abs(item.netqty);
            let curr = Number(item.ltp) * Math.abs(item.netqty);
            let pnl = (curr - inv).toFixed(2);
            let pnlc = inv > 0 ? ((pnl / inv) * 100).toFixed(2) : "0";

            item["inv"] = inv.toFixed(2);
            item["curr"] = curr.toFixed(2);
            item["pnl"] = pnl;
            item["pnlc"] = pnlc;
            item["action"] = false;

            if (pnlc > 0) statdata["positive"].push(item);
            if (pnlc < 0) statdata["negative"].push(item);

            stockvalue += curr;
            invested += inv;
            pnlTotal += Number(pnl);
        }
        statdata["stockvalue"] = stockvalue ? differentView(Math.abs(stockvalue)) : 0;
        statdata["invested"] = invested ? differentView(Math.abs(invested)) : 0;
        statdata["pnl"] = pnlTotal ? differentView(Math.abs(pnlTotal.toFixed(2))) : 0;
        statdata["cpnl"] = invested ? ((pnlTotal / invested) * 100).toFixed(2) : 0;
    }
    return { response: datamf, stat: statdata }
}

export async function getMPosotion() {
    requestMOption['body'] = `jData={"uid":"${uid}","actid":"${uid}"}&jKey=${tok}`

    var data = await fetchMyntAPI(mynturl.myntapi + "PositionBook", requestMOption)
    // var data = postD
    var closeposition = [];
    var openposition = [];
    var positiondata = [];
    if (data && data.length > 0) {
        for (let q = 0; q < data.length; q++) {
            data[q]["idx"] = q;
            const bAvg = data[q].exch == "CDS" || data[q].exch == "BCD" ? parseFloat(data[q].totbuyavgprc).toFixed(4) : parseFloat(data[q].totbuyavgprc).toFixed(2);
            const sAvg = data[q].exch == "CDS" || data[q].exch == "BCD" ? parseFloat(data[q].totsellavgprc).toFixed(4) : parseFloat(data[q].totsellavgprc).toFixed(2);
            const bavg = !bAvg ? 0 : bAvg;
            const savg = !sAvg ? 0 : sAvg;

            if (data[q].netqty == 0) {
                data[q]["way"] = "close";
                closeposition.push(data[q]);
            } else {
                data[q]["way"] = "open";
                data[q]["disabled"] = false;
                openposition.push(data[q]);
            }
            data[q]["totbuyavgprc"] = bavg;
            data[q]["totsellavgprc"] = savg;
            data[q]["tokn"] = data[q].token + "_" + q;
            data[q]["avgprc"] = data[q].netavgprc && Number(data[q].netavgprc) > 0 ? data[q].netavgprc : data[q].netupldprc && Number(data[q].netupldprc) > 0 ? data[q].netupldprc : data[q].upldprc && Number(data[q].upldprc) > 0 ? data[q].upldprc : data[q].dayavgprc;
            data[q]["crpnl"] = data[q]["rpnl"];

            data[q]["tempqty"] = (parseFloat(data[q]['daybuyqty'] || "0") + parseFloat(data[q]['cfbuyqty'] || "0")) < (parseFloat(data[q]['daysellqty'] || "0") + parseFloat(data[q]['cfsellqty'] || "0")) ? parseFloat(data[q]['daybuyqty'] || "0") : parseFloat(data[q]['daysellqty'] || "0");
            data[q]["tempqty"] = (data[q]["tempqty"] * data[q].prcftr)
            data[q]["tempavg"] = data[q].netqty > 0 ? Number(data[q]['daysellavgprc']) - parseFloat(data[q]['netupldprc'] || "0") : parseFloat(data[q]['netupldprc'] || "0") - Number(data[q]['daybuyavgprc']);
            data[q]["temprpnl"] = data[q]["tempavg"] * data[q]["tempqty"];
        }
        positiondata = { a: data, o: openposition, c: closeposition };
    } else {
        positiondata = data
    }
    return positiondata
}

export async function getMPosotionCon(item) {
    requestMOption['body'] = `jData=${JSON.stringify(item)}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + "ProductConversion", requestMOption)
    return response
}

export async function getQuotesdata(item) {
    requestMOption['body'] = `jData={"uid":"${uid}","exch":"${item.split('|')[0]}","token":"${item.split('|')[1]}"}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + "GetQuotes", requestMOption)
    return response
}

export async function getOptionschain(item) {
    requestMOption['body'] = item
    var response = await fetchMyntAPI(mynturl.myntapi + "GetOptionChain", requestMOption)
    return response
}

export async function getMFQuotesdata(item) {
    requestMOption['body'] = `jData={"uid":"${uid}","exch":"${item.split('|')[0]}","token":"${item.split('|')[1]}"}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + "GetQuotesMF", requestMOption)
    return response
}

export async function getSecuritydata(item) {
    requestMOption['body'] = `jData={"uid":"${uid}","exch":"${item.split('|')[0]}","token":"${item.split('|')[1]}"}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + "GetSecurityInfo", requestMOption)
    return response
}

export async function getTechnicals(item) {
    requestMOption['body'] = `jData={"uid":"${uid}","exch":"${item.split('|')[0]}","tsym":"${item.split('|')[2]}"}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + "GetTechnicals", requestMOption)
    return response
}

export async function getLinkedScrips(item) {
    requestMOption['body'] = `jData={"uid":"${uid}","exch":"${item.split('|')[0]}","token":"${item.split('|')[1]}"}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + "GetLinkedScrips", requestMOption)
    return response
}

export async function getOrderMargin(item) {
    requestMOption['body'] = `jData={"uid":"${uid}","actid":"${uid}","exch":"${item.exch}","tsym":"${item.tsym}","qty":"${item.qty}","prc":"${item.prc}","prd":"${item.prd}","trantype":"${item.trantype}","prctyp":"${item.prctyp}","trgprc":"${item.trgprc}","rorgqty":"0","rorgprc":"0"}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + "GetOrderMargin", requestMOption)
    return response
}
export async function getBSKMargin(item) {
    requestMOption['body'] = `jData=${JSON.stringify(item)}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + "GetBasketMargin", requestMOption)
    return response
}

export async function getBrokerage(item) {
    requestMOption['body'] = `jData={"uid":"${uid}","actid":"${uid}","exch":"${item.exch}","tsym":"${item.tsym}","qty":"${item.qty}","prc":"${item.prc}","prd":"${item.prd}","trantype":"${item.trantype}"}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + "GetBrokerage", requestMOption)
    return response
}

export async function getPlaceOrder(item, type) {
    if (type != 'can-ex') {
        item['app_inst_id'] = sessionStorage.getItem("imei");
        item['usr_agent'] = navigator.appVersion;
        item['ipaddr'] = localStorage.getItem("uuidipadd");
    }

    requestMOption['body'] = `jData=${JSON.stringify(item)}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + (type == 'can-ex' ? "ExitSNOOrder" : type == 'can' ? "CancelOrder" : type == 'mod' ? "ModifyOrder" : "PlaceOrder"), requestMOption)
    return response
}

export async function getSIPOrderset(item, url) {
    requestMOption['body'] = `jData=${JSON.stringify(item)}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + url, requestMOption)
    return response
}

export async function getGTTPlaceOrder(item, url) {
    requestMOption['body'] = `jData=${JSON.stringify(item)}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + url, requestMOption)
    return response
}

export async function setMalert(item, type) {
    requestMOption['body'] = `jData=${JSON.stringify(item)}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + (type == 'cancel' ? 'CancelAlert' : type ? "ModifyAlert" : "SetAlert"), requestMOption)
    return response
}

export async function getMOrderbook() {
    requestMOption['body'] = `jData={"uid":"${uid}","actid":"${uid}"}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + "OrderBook", requestMOption)
    var execorders = [];
    var openorders = [];
    var stat = [0, 0, 0]
    // response = dataO
    if (response && response.length > 0) {
        for (let q = 0; q < response.length; q++) {
            response[q]["idx"] = q;
            if (response[q].status == "PENDING" || response[q].status == "OPEN" || response[q].status == "TRIGGER_PENDING") {
                response[q]["exord"] = ((response[q].s_prdt_ali == "BO" || response[q].s_prdt_ali == "CO") && response[q].snonum) ? true : false;
                response[q]["way"] = "open";
                openorders.push(response[q]);
                response[q]['disabled'] = false;
            } else {
                response[q]["way"] = "exec";
                execorders.push(response[q]);
            }
            response[q]["value"] = response[q].qty * response[q].avgprc;
            if (response[q].status == "PENDING" || response[q].status == "OPEN" || response[q].status == "TRIGGER_PENDING") {
                stat[0] += 1;
            } else if (response[q].status == "COMPLETE") {
                stat[1] += 1;
            } else {
                stat[2] += 1;
            }
        }
    }
    return { response: response, openorders: openorders, execorders: execorders, stat: stat }
}

export async function getMTradebook(item) {
    requestMOption['body'] = item
    var response = await fetchMyntAPI(mynturl.myntapi + "TradeBook", requestMOption)
    return response
}

export async function getSiporderbook(item) {
    requestMOption['body'] = item
    var response = await fetchMyntAPI(mynturl.myntapi + "SipOrderBook", requestMOption)
    return response
}

export async function getUserDetails() {
    requestMOption['body'] = `jData={"uid":"${uid}"}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + "UserDetails", requestMOption)
    return response
}


export async function getSingleorderbook(id) {
    requestMOption['body'] = `jData={"uid":"${uid}","norenordno":"${id}"}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + "SingleOrdHist", requestMOption)
    return response
}

export async function getGttorderbook() {
    requestMOption['body'] = `jData={"uid":"${uid}"}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + "GetPendingGTTOrder", requestMOption)
    return response
}

export async function setCancelGTT(item) {
    requestMOption['body'] = item
    var response = await fetchMyntAPI(mynturl.myntapi + "CancelGTTOrder", requestMOption)
    return response
}

export async function getAlertAPi(url) {
    requestMOption['body'] = `jData={"uid":"${uid}"}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + url, requestMOption)
    return response
}
export async function getMLimits(format) {
    requestMOption['body'] = `jData={"uid":"${uid}","actid":"${uid}"}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + 'Limits', requestMOption)
    if (response && response.stat == "Ok") {
        var total = (Number(response.brkcollamt) + Number(response.cash) + Number(response.payin));
        response["total"] = !format ? total : (total).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        var sum = ((Number(response.brkcollamt) > 0 ? Number(response.brkcollamt) : 0) + (Number(response.cash) > 0 ? Number(response.cash) : 0)) - Number(response.marginused ? response.marginused : 0);

        response["avbma"] = (sum > 0 || sum < 0) ? !format ? sum : (sum).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }) : 0;
        if (format) {
            for (const [key, value] of Object.entries(response)) {
                if (key && value && Number(value)) {
                    response[key] =
                        Number(value) != 0
                            ? Number(value).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })
                            : null;
                }
            }
        }
    }
    return response
}

export async function getTotpreq(val) {
    requestMOption['body'] = `jData={"uid":"${uid}"}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + (val ? "GenSecretKey" : "GetSecretKey"), requestMOption)
    return response
}

export async function getApikeyreq() {
    requestMOption['body'] = `jData={"uid":"${uid}"}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + "RequestApiKey", requestMOption)
    return response
}

export async function getUserApikeyreq(time) {
    requestMOption['body'] = `jData={"uid":"${uid}", "valTime":"${time}"}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + "GetUserApiKey", requestMOption)
    return response
}

export async function getClientDetails() {
    requestMOption['body'] = `jData={"uid":"${uid}","actid":"${uid}"}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + "ClientDetails", requestMOption)
    return response
}

export async function getMyntLogout(inn) {
    requestMOption['body'] = `jData={"uid":"${uid}","source":"${inn}"}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + "Logout", requestMOption)
    return response
}

export async function getEXPosition() {
    requestOptions['body'] = `{"client_id":"${uid}"}`
    var response = await fetchMyntAPI(apiurl.exmynt + "api/GetPosition", requestOptions)
    return response
}

export async function getOIbars(tsym) {
    requestOptions['body'] = `{"value":"${tsym}","count":"30"}`
    var response = await fetchMyntAPI(apiurl.exmynt + "/get_oi_bar", requestOptions)
    return response
}

// single stocks equity API
export async function getPostPnL(data) {
    requestOptions['body'] = JSON.stringify({ "clientid": uid, "session": tok, "source": mynturl.source, "chartdata": data })
    var response = await fetchMyntAPI(apiurl.eqapi + "positionpnlmargin", requestOptions)
    return response
}

export async function getssNews() {
    requestOptions['body'] = "";
    var response = await fetchMyntAPI(apiurl.sessapi + "newsfeedin?pagesize=5&pagecount=1&filterdate=monthly", { method: 'get' })
    return response
}

export async function getCorporateact() {
    requestOptions['body'] = "";
    var response = await fetchMyntAPI(apiurl.iposapi + "getCorporateAction", requestOptions)
    return response
}

export async function getssDetails(item) {
    requestOptions['body'] = `{"symbol":"${item}"}`
    var response = await fetchMyntAPI(apiurl.eqapi + "stockFundamentalDetails", requestOptions)
    return response
}

export async function getQuotedata(item) {
    requestOptions['body'] = `{"symbol":"${item}"}`
    var response = await fetchMyntAPI(apiurl.eqapi + "getQuotes", requestOptions)
    return response
}
export async function getSectordata() {
    requestOptions['body'] = "";
    var response = await fetchMyntAPI(apiurl.eqapi + "getSector", requestOptions)
    return response
}

export async function getIndexList() {
    requestOptions['body'] = "";
    var response = await fetchMyntAPI(apiurl.eqapi + "GetIndexList", requestOptions)
    return response
}

export async function getTopList(item) {
    requestOptions['body'] = `{"exch":"${item[0]}","bskt":"${item[1]}","crt":"${item[2]}"}`
    var response = await fetchMyntAPI(apiurl.eqapi + "TopList", requestOptions)
    return response
}

export async function getConTentList(item) {
    requestOptions['body'] = `{"exch":"${item[0]}","basket":"${item[1]}","condition":"${item[2]}"}`
    var response = await fetchMyntAPI(apiurl.eqapi + "GetContentList", requestOptions)
    return response
}

export async function getADindices() {
    requestOptions['body'] = "";
    var response = await fetchMyntAPI(apiurl.eqapi + "getadindicesAdvdec", requestOptions)
    return response
}

export async function getADindice(index) {
    requestOptions['body'] = `{"index": "${index}"}`;
    var response = await fetchMyntAPI(apiurl.eqapi + "getadindices", requestOptions)
    return response
}

export async function getHLbreakers() {
    requestOptions['body'] = "";
    var response = await fetchMyntAPI(apiurl.eqapiD + "HLbreakers", requestOptions)
    return response
}

// Mutual Fund API
export async function getMFalldata(item) {
    requestOptions['body'] = `{"filter":"${item[0]}", "Purchase_Allowed":"${item[1]}",  "Redemption_Allowed":"${item[2]}", "Purchase_Transaction_mode":["DP","D"]}`
    var response = await fetchMyntAPI(apiurl.mfapi + "master_file_datas", requestOptions)
    return response
}
export async function getBestMF() {
    requestOptions['body'] = "";
    var response = await fetchMyntAPI(apiurl.mfapi + "z_data", requestOptions)
    return response
}

export async function getMFmandate(item) {
    requestOptions['body'] = `{"client_code":"${item[0]}", "mandate_id":"${item[1]}", "from_date":"01/01/1900", "to_date":"31/12/${new Date().getFullYear()}"}`
    var response = await fetchMyntAPI(apiurl.mfapi + "mandate_details", requestOptions)
    return response
}

export async function getMFAddmandate(item) {
    requestOptions['body'] = `{"client_code":"${item[0]}", "amount":"${item[1]}", "startdate":"${item[2]}", "enddate":"${item[3]}"}`
    var response = await fetchMyntAPI(apiurl.mfapi + "mandate_creation", requestOptions)
    return response
}

export async function getMFsipvalue(item) {
    requestOptions['body'] = `{"isin":"${item[0]}", "scheme_code":"${item[1]}"}`
    var response = await fetchMyntAPI(apiurl.mfapi + "sip_values", requestOptions)
    return response
}

export async function getMFlumsum_order(item) {
    requestOptions['body'] = `{"client_code":"${item[0]}","from_date":"${item[1]}","to_date":"${item[2]}"}`
    var response = await fetchMyntAPI(apiurl.mfapi + "lumsum_orderbook", requestOptions)
    // var response = await fetchMyntAPI(apiurl.mfapi + "mf_orderbook", requestOptions)
    return response
}
export async function getMFholdings() {
    requestOptions['body'] = `{"client_code":"${uid}"}`
    var response = await fetchMyntAPI(apiurl.mfapi + "GetHoldings", requestOptions)
    return response
}

export async function getMFBankdetails() {
    requestOptions['body'] = `{"client_code":"${uid}"}`
    var response = await fetchMyntAPI(apiurl.mfapi + "client_bank_details", requestOptions)
    return response
}

export async function getMFwatchlist(data) {
    requestOptions['body'] = data
    var response = await fetchMyntAPI(apiurl.mfapi + "watchlist", requestOptions)
    return response
}

export async function getMFnofdata() {
    requestOptions['body'] = "";
    var response = await fetchMyntAPI(apiurl.mfapi + "NFO_datas", requestOptions)
    return response
}

export async function getMFsheetdata(key) {
    requestOptions['body'] = "";
    var response = await fetchMyntAPI(apiurl.mfapi + `getFactSheetData?ISIN=${key}`, requestOptions)
    return response
}
export async function getMFNAVchart(key, frmdate, todate) {
    requestOptions['body'] = "";
    var response = await fetchMyntAPI(apiurl.mfapi + `getNavGraph?ISIN=${key}&fromDate=${frmdate}&toDate=${todate}`, requestOptions)
    return response
}

export async function getMFSchemePeers(key, year) {
    requestOptions['body'] = "";
    var response = await fetchMyntAPI(apiurl.mfapi + `getSchemePeers?ISIN=${key}&year=${year}`, requestOptions)
    return response
}

export async function getMFsheetchart(key) {
    requestOptions['body'] = "";
    var response = await fetchMyntAPI(apiurl.mfapi + `getFactSheetGraph?ISIN=${key}`, requestOptions)
    return response
}

export async function getMFrollschart(isin, date, year) {
    requestOptions['body'] = `{"ISIN":${isin},"startdate":"${date}","year":"${year}"}`
    var response = await fetchMyntAPI(apiurl.mfapi + `postRollingReturn`, requestOptions)
    return response
}

export async function getXsiporder(item) {
    requestOptions['body'] = `{"client_code":"${uid}","order_number":"${item[0]}","date":"${item[1]}"}`
    var response = await fetchMyntAPI(apiurl.mfapi + "child_order_details", requestOptions)
    return response
}

export async function getMFcancellum(item, type) {
    requestOptions['body'] = `{"client_code":"${uid}","order_number":"${item}"}`
    var response = await fetchMyntAPI(apiurl.mfapi + (type ? 'lumsum_redemption_cancel' : "lumpsum_purchase_cancel"), requestOptions)
    return response
}

export async function getMFcancelxsip(item) {
    requestOptions['body'] = `{"client_code":"${uid}","xsip_reg_no":"${item[0]}","internal_refer_no":"${item[1]}","case_no":"${item[2]}","remarks":"${item[3]}"}`
    var response = await fetchMyntAPI(apiurl.mfapi + "xsip_cancel", requestOptions)
    return response
}

export async function getMFcancelation() {
    requestOptions['body'] = "";
    var response = await fetchMyntAPI(apiurl.mfapi + `reasons`, requestOptions)
    return response
}

// Bolds API
export async function getBondGsec() {
    requestOptions['body'] = "";
    var response = await fetchMyntAPI(apiurl.bondapi + "getcurrentNCB_Gsecdetails", requestOptions)
    return response
}
export async function getBondTbill() {
    requestOptions['body'] = "";
    var response = await fetchMyntAPI(apiurl.bondapi + "getcurrentNCB_TBilldetails", requestOptions)
    return response
}
export async function getBondSdl() {
    requestOptions['body'] = "";
    var response = await fetchMyntAPI(apiurl.bondapi + "getcurrentNCB_SDLdetails", requestOptions)
    return response
}
export async function getBondSgb() {
    requestOptions['body'] = "";
    var response = await fetchMyntAPI(apiurl.bondapi + "getcurrentSGBdetails", requestOptions)
    return response
}


// Ipos API
export async function getIposIpo() {
    // getcurrentIPOdetails
    requestOptions['body'] = "";
    var response = await fetchMyntAPI(apiurl.iposapi + "getcurrentIPOdetails", requestOptions)
    return response
}
export async function getIposPerform(year) {
    requestOptions['body'] = "";
    var response = await fetchMyntAPI(apiurl.iposapi + `ipo_performer?year=${year}`, requestOptions)
    return response
}
export async function getIposSme() {
    // getcurrentSMEIPOdetails
    requestOptions['body'] = "";
    var response = await fetchMyntAPI(apiurl.iposapi + "getcurrentSMEIPOdetails", requestOptions)
    return response
}
export async function getIposbook(item) {
    requestOptions['body'] = `{"client_id": "${item[0]}"}`;
    myHeaders.append("clientid", `${item[0]}`);
    myHeaders.append("Authorization", `${item[1]}`);
    var response = await fetchMyntAPI(apiurl.iposapi + "orderbookIPODetails", requestOptions)
    return response
}
export async function getIposorders(item) {
    requestOptions['body'] = `{"symbol":"${item[2]}","applicationNumber":"${item[3]}","type":"${item[4]}"}`;
    myHeaders.append("clientid", `${item[0]}`);
    myHeaders.append("Authorization", `${item[1]}`);
    var response = await fetchMyntAPI(apiurl.iposapi + "fetchIPODetail", requestOptions)
    return response
}

// collections API
export async function getCollections() {
    requestOptions['body'] = "";
    var response = await fetchMyntAPI(apiurl.collapi + "collectiondetails", requestOptions)
    return response
}

export async function getCollsingledata(item) {
    requestOptions['body'] = JSON.stringify({ basketid: item, client_id: null });
    var response = await fetchMyntAPI(apiurl.collapi + "singlebasketDateils", requestOptions)
    return response
}
export async function getCollchartdata(item) {
    requestOptions['body'] = JSON.stringify({ scripts: item[0], timeFrame: item[1] });
    var response = await fetchMyntAPI(apiurl.eqapi + "basketReturnsChartData", requestOptions)
    return response
}
export async function getCollstockdata(item) {
    requestOptions['body'] = JSON.stringify({ scripts: item[0], timeFrame: item[1] });
    var response = await fetchMyntAPI(apiurl.eqapi + "basketStocksData", requestOptions)
    return response
}

export async function getCollLtpdata(item) {
    requestOptions['body'] = JSON.stringify({ basketid: item, etfs_weights: "" });
    var response = await fetchMyntAPI(apiurl.collapi + "getltp", requestOptions)
    return response
}

export async function getCollplaceorder(url, data, temp) {
    requestOptions['body'] = data;
    myHeaders.append("clientid", `${uid}`);
    myHeaders.append("Authorization", `${temp}`);
    var response = await fetchMyntAPI(apiurl.collapi + url, requestOptions);
    return response
}

export async function getFundsupis(item) {
    requestOptions['body'] = `{"client_id":"${item}"}`;
    var response = await fetchMyntAPI(apiurl.upiurl + "withdraw/view_upi_id", requestOptions);
    return response
}

export async function getUpivpa(item) {
    requestOptions['body'] = `{"VPA":"${item[0]}","clientID":"123456789","bank_acc":"123456789"}`;
    myHeaders.append("clientid", `${item[1]}`);
    myHeaders.append("Authorization", `${item[2]}`);
    var response = await fetchMyntAPI(apiurl.upiurl + "hdfc/upi/checkClientVPA", requestOptions);
    return response
}

export async function getBondLedger(item) {
    requestOptions['body'] = `{"clientid": "${item[0]}"}`;
    var response = await fetchMyntAPI(apiurl.ledger + "all_ledger_balance", requestOptions)
    return response
}

export async function getBondbook(item) {
    requestOptions['body'] = `{"client_id": "${item[0]}"}`;
    !myHeaders.has("clientid") ? myHeaders.append("clientid", `${item[0]}`) : '';
    !myHeaders.has("Authorization") ? myHeaders.append("Authorization", `${item[1]}`) : '';
    var response = await fetchMyntAPI(apiurl.bondapi + "orderbookncbDetails", requestOptions)
    return response
}
export async function getBondsgbbook(item) {
    requestOptions['body'] = `{"client_id": "${item[0]}"}`;
    myHeaders.append("clientid", `${item[0]}`);
    myHeaders.append("Authorization", `${item[1]}`);
    var response = await fetchMyntAPI(apiurl.bondapi + "orderbooksgbDetails", requestOptions)
    return response
}

export async function getInvHoldings(item) {
    requestOptions['body'] = `{"cc": "${item[0]}", "to":""}`;
    myHeaders.append("clientid", `${item[0]}`);
    myHeaders.append("Authorization", `${item[1]}`);
    var response = await fetchMyntAPI(apiurl.repapi + "getHoldings", requestOptions)
    return response
}

export async function getLtpdata(item) {
    requestOptions['body'] = `{ "data": ${JSON.stringify(item)} }`
    var response = await fetchMyntAPI(apiurl.asvrapi + "GetLtp", requestOptions)
    return response
}

export async function getGreekoptions(item) {
    requestOptions['body'] = item
    var response = await fetchMyntAPI(apiurl.zebuApiUrl + "getoptiongreeks", requestOptions)
    return response
}



export async function getIndicators() {
    try {
        const response = await fetch(`https://be.mynt.in/getindicators?clientCode=${uid}`);
        return response.json();
    } catch (error) {
        throw new Error(`get symbols request error: ${error}`);
    }
}

export async function setIndicators(c) {
    try {
        const response = await fetch(`https://be.mynt.in/storeindicators?clientCode=${uid}&indicators=${JSON.stringify(c)}`);
        return response.json();
    } catch (error) {
        throw new Error(`get symbols request error: ${error}`);
    }
}


export async function getMasters(tsym) {
    // var response = await fetchMyntjson(apiurl.zebuApiUrl + "Masters", {
    //     method: 'GET',
    //     redirect: 'follow'
    // })
    var response = await fetchMyntjson(apiurl.zebuApiUrl + `findsymbol?tsym=${decodeURIComponent(tsym)}`, {
        method: 'GET',
        redirect: 'follow'
    })
    return response
    // return response != [] ? 'Ok' : {}


}

export async function mastermfapi() {
    // var response = await fetchMyntjson(apiurl.zebuApiUrl + "Masters", {
    //     method: 'GET',
    //     redirect: 'follow'
    // })
    var response = await fetchMyntjson('http://192.168.5.175:5002/' + `master_file_datas`, {
        method: 'GET',
        redirect: 'follow'
    })
    return response
    // return response != [] ? 'Ok' : {}


}

export async function fetchMyntAPI(path, reqopt, way) {
    try {
        const response = await fetch(path, reqopt);

        let data = await response.json();
        data = JSON.stringify(data);
        data = JSON.parse(data);
        if (way) {
            myHeader = new Headers();
            requestMOption = { method: 'POST', redirect: 'follow' }
            myHeader.append("Content-Type", "text/plain");
            requestMOption['headers'] = myHeader;
        } else {
            myHeaders = new Headers();
            requestOptions = { method: 'POST', redirect: 'follow' }
            myHeaders.append("Content-Type", "application/json");
            requestOptions['headers'] = myHeaders;
        }
        if (data && data.emsg && data.emsg == 'Session Expired :  Invalid Session Key') {
            eventBus.$emit("snack-event", 2, data.emsg);
        } else {
            return data
        }
    } catch (error) {
        if (error.status === 401) {
            eventBus.$emit("snack-event", 2, error);
        }
        if (way) {
            myHeader = new Headers();
            requestMOption = { method: 'POST', redirect: 'follow' }
            myHeader.append("Content-Type", "text/plain");
            requestMOption['headers'] = myHeader;
        } else {
            myHeaders = new Headers();
            requestOptions = { method: 'POST', redirect: 'follow' }
            myHeaders.append("Content-Type", "application/json");
            requestOptions['headers'] = myHeaders;
        }
        let data = 500
        return data
        // throw new Error(error.status);
    }
}

export async function fetchMyntjson(path) {
    try {
        const response = await fetch(path);
        let data = await response.json();
        data = JSON.stringify(data);
        data = JSON.parse(data);
        return data
    } catch (error) {
        let data = []
        return data
    }
}

export function seteEcryption(item) {
    var payld = JSON.stringify({
        clientid: item,
    });
    var CryptoJS = require("crypto-js");

    const payload = payld;
    var derived_key = CryptoJS.enc.Base64.parse(btoa("N#j2L^8pq9Fb$d@1")); //YXNkZmdoamtsenhjdmJubQ==
    var iv = CryptoJS.enc.Utf8.parse("3790514682037125");
    var test = CryptoJS.AES.encrypt(payload, derived_key, { iv: iv, mode: CryptoJS.mode.CBC }).toString();
    return test;
}

export function setDecryption(payld) {
    var CryptoJS = require("crypto-js");
    const payload = payld;
    const derived_key = CryptoJS.enc.Base64.parse(btoa("N#j2L^8pq9Fb$d@1")); //YXNkZmdoamtsenhjdmJubQ==
    const iv = CryptoJS.enc.Utf8.parse("3790514682037125");
    const encryptedData = payload;

    // Decrypt the data using AES
    const decrypted = CryptoJS.AES.decrypt(encryptedData, derived_key, { iv, mode: CryptoJS.mode.CBC });
    const decryptedData = decrypted.toString(CryptoJS.enc.Utf8);
    return decryptedData;
}

// src/services/apiService.js


export async function FetchsearchData(path, reqopt) {
    try {
        const response = await fetch(path, reqopt);
        let data = await response.json();
        return data
    } catch (error) {
        return error
    }
}

function differentView(view) {
    return view && Number(view) && view > 0 ? Number(view).toFixed(2) : "0.00";
}


