<template>
  <div>
    <v-card class="elevation-0 mb-16 pa-6 rounded-lg" color="#0D6CCA">
      <v-row no-glutters>
        <v-col md="4" cols="12">
          <p class="fs-26 d-md-none font-weight-bold lh-24 white--text">F&O Market</p>
          <p class="fs-36 d-none d-md-flex font-weight-bold lh-32 white--text">F&O Market</p>
          <p class="title white--text mb-0 lh-24 font-weight-regular">Get updates on today's stock from the broader market indices and sectors</p>
          <!-- <v-btn
                class="elevation-0 white--text font-weight-bold px-6 rounded-pill text-none fs-14 mb-2"
                large color="#000000">Invest in Bonds</v-btn> -->
        </v-col>
        <v-col cols="7" class="d-none ml-auto mr-4 d-md-flex pos-rlt">
          <v-row style="width: 100%" no-glutters class="pos-abs">
            <v-col cols="4" class="pr-0">
              <v-card width="100%" class="pt-8 pb-3 stk-land-crds rounded-xl text-center">
                <img src="@/assets/Fno/main-card-1.svg" class="px-1 pb-4" alt="main-card-1" width="50%" />
                <p class="fs-16 font-weight-bold lh-16 px-4 mt-1 mb-0">Invest in Market Indices Futures & Options</p>
              </v-card>
            </v-col>
            <v-col cols="4" class="pr-0">
              <v-card width="100%" class="pt-8 pb-3 stk-land-crds rounded-xl text-center">
                <img src="@/assets/Fno/main-card-2.svg" class="px-1 pb-4" alt="main-card-2" width="50%" />
                <p class="fs-16 font-weight-bold lh-16 px-4 mt-1 mb-0">
                  Investing in <br />
                  Sectors Futures & Options
                </p>
              </v-card>
            </v-col>
            <v-col cols="4" class="pr-0">
              <v-card width="100%" class="pt-8 pb-3 stk-land-crds rounded-xl text-center">
                <img src="@/assets/Fno/main-card-3.svg" class="px-1 pb-4" alt="main-card-3" width="50%" />
                <p class="fs-16 font-weight-bold lh-16 px-4 mt-1 mb-0">
                  Checkout top <br />
                  rated Futures & Options
                </p>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <v-toolbar class="tool-sty elevation-0 crd-trn" dense>
      <img width="32px" src="@/assets/stocks/ind.svg" alt="ind" class="mr-1 pa-1" />

      <p class="title font-weight-bold mb-0 mr-3">Top indices</p>
      <v-spacer></v-spacer>
      <v-btn @click="scrollToo('indices', -600)" class="mr-2" icon small outlined> <v-icon size="24">mdi-chevron-left</v-icon> </v-btn>
      <v-btn @click="scrollFrom('indices', 600)" class="mr-1" icon small outlined> <v-icon size="24">mdi-chevron-right</v-icon> </v-btn>
    </v-toolbar>
    <v-card id="indices" v-dragscroll.x class="crd-trn d-inline-flex overflow-x-auto elevation-0 no-scroll mb-2" width="100%">
      <v-card v-for="(s, l) in pdmwdata" :key="l" @click="s.too ? $router.push({name: 'stocks advance decline', params: {abc: s.too}}) : ''" class="px-3 py-2" :class="l != pdmwdata.length - 1 ? 'mr-4' : ''" min-width="160px" outlined>
        <p class="subtitle-2 font-weight-bold mb-2 ws-p" v-text="s.tsym ? s.tsym : ''"></p>
        <v-card class="pt-02 mb-3 elevation-0 rounded-pill" width="30%" color="maintext"></v-card>
        <p class="fs-12 txt-000 font-weight-medium mb-0">
          ₹<span :id="`ssdpd${s.token}ltp`">{{ s.ltp ? s.ltp : "0.00" }}</span>
        </p>
        <p class="fs-12 font-weight-medium mb-0" :id="`ssdpd${s.token}chpclr`" :class="s.chp > 0 ? 'maingreen--text' : s.chp < 0 ? 'mainred--text' : 'subtext--text'">
          <span :id="`ssdpd${s.token}ch`">{{ s.ch ? s.ch : "0.00" }}</span>
          <span :id="`ssdpd${s.token}chp`"> ({{ s.chp ? s.chp : "0.00" }}%)</span>
        </p>
      </v-card>
    </v-card>
    <v-btn to="/stocks/allindices" text class="text-none px-0 primary--text mb-6">See all indices</v-btn>

    <v-row no-glutters class="mb-4">
      <v-col cols="6">
        <v-row no-glutters>
          <template v-for="(l, v, p) in advdecitems">
            <v-col @click="advdectab = p" cols="6" v-if="p < 2" :key="p" class="text-center cursor-p">
              <div class="d-inline-flex">
                <img width="28px" :src="require(`@/assets/stocks/${p == 1 ? 'them' : 'sect'}.svg`)" alt="sect" class="mr-1" />
                <p class="title font-weight-bold mb-0">{{ v }}</p>
              </div>
              <v-card class="elevation-0" :color="advdectab == p ? 'primary' : 'transparent'" height="2px"></v-card>
            </v-col>
          </template>
        </v-row>
        <v-divider class="mb-2"></v-divider>
        <!-- <v-toolbar class="tool-sty elevation-0 my-1" dense>
          <v-tabs>
            <template v-for="(l, v, p) in advdecitems">
              <v-tab v-if="p < 2" :key="p">
                <img width="32px" :src="require(`@/assets/stocks/${p == 1 ? 'them' : 'sect'}.svg`)" alt="sect"
              class="mr-1 pa-1">
            <p class="title font-weight-bold mb-0">{{ v }}
            </p> 
              </v-tab>
            </template>
</v-tabs> -->
        <!-- <img width="32px" :src="require(`@/assets/stocks/${p == 1 ? 'them' : 'sect'}.svg`)" alt="sect"
              class="mr-1 pa-1">
            <p class="title font-weight-bold mb-0">{{ v }}
            </p>
          <v-spacer></v-spacer>
        </v-toolbar> -->
        <!-- <template> -->
        <v-tabs-items v-model="advdectab">
          <v-tab-item v-for="(l, v, p) in advdecitems" :key="p">
            <div v-if="p < 2">
              <v-card v-for="(i, o) in l.slice(0, 5)" :key="o" @click="$router.push({name: 'stocks advance decline', params: {abc: i.key}})" width="100%" class="elevation-0 pa-3 rounded-lg" :class="o < 4 ? 'mb-3' : ''" height="60px" color="secbg">
                <v-row no-glutters>
                  <v-col cols="4" class="pr-0">
                    <p class="mb-0 subtitle-2 font-weight-medium lh-16">
                      {{ i.title }} <span class="caption subtext-text font-weight-bold">({{ i.data && i.data.sum ? i.data.sum : ".." }})</span>
                    </p>
                    <p class="fs-12 maintext--text font-weight-medium mb-0 lh-16">
                      <span v-if="advdecitems.wsdata && i.data && i.data.token && advdecitems.wsdata[i.data.token]">
                        <span :id="`ssdad${i.data.token}ltp`">₹{{ advdecitems.wsdata && i.data && i.data.token && advdecitems.wsdata[i.data.token] && advdecitems.wsdata[i.data.token].ltp ? `${advdecitems.wsdata[i.data.token].ltp}` : "" }}</span> &nbsp;<span
                          class="fs-9"
                          :class="advdecitems.wsdata[i.data.token] ? (advdecitems.wsdata[i.data.token].chp > 0 ? 'maingreen--text' : advdecitems.wsdata[i.data.token].chp < 0 ? 'mainred--text' : 'subtext--text') : 'subtext--text'"
                          :id="`ssdad${i.data.token}chpclr`"
                          ><span :id="`ssdad${i.data.token}ch`">{{ advdecitems.wsdata[i.data.token] ? `${advdecitems.wsdata[i.data.token].ch}` : "0.00" }}</span>
                          <span :id="`ssdad${i.data.token}chp`"> ({{ advdecitems.wsdata[i.data.token] && advdecitems.wsdata[i.data.token].chp ? `${Number(advdecitems.wsdata[i.data.token].chp).toFixed(2)}` : "0.00" }}%)</span></span
                        >
                      </span>
                      <span v-else class="fs-12">0.00 <span class="fs-9"> 0.00 (0.00%)</span></span>
                    </p>
                  </v-col>
                  <v-col cols="8" v-if="i.data">
                    <v-card color="transparent" width="100%" class="elevation-0 d-inline-flex">
                      <v-card class="elevation-0 rounded-lg crd-trn" :width="i.data.adp">
                        <v-card class="elevation-0 rounded-lg py-1" color="maingreen" width="100%"></v-card>
                      </v-card>
                      <v-card v-if="i.data.Negative > 0 || i.data.Neutral > 0" class="elevation-0 rounded-lg px-1 crd-trn" :width="i.data.adn">
                        <v-card class="elevation-0 rounded-lg py-1" color="#D9D9D9" width="100%"></v-card>
                      </v-card>
                      <v-card class="elevation-0 rounded-lg crd-trn" :width="i.data.adm">
                        <v-card class="elevation-0 rounded-lg py-1" color="mainred" width="100%"></v-card>
                      </v-card>
                    </v-card>
                    <v-row no-glutters>
                      <v-col cols="6" class="pt-1">
                        <p v-if="i.data.Positive > 0" class="mb-0 lh-16 mt-2 subtitle-2">
                          <v-icon color="maingreen" size="18">mdi-arrow-top-right</v-icon>
                          {{ i.data.Positive }}
                        </p>
                      </v-col>
                      <v-col cols="6" class="pt-1">
                        <p v-if="i.data.Negative > 0" class="mb-0 lh-16 mt-2 subtitle-2 text-right">
                          {{ i.data.Negative }}
                          <v-icon color="mainred" size="18">mdi-arrow-bottom-right</v-icon>
                        </p>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="8" v-else>
                    <v-skeleton-loader height="8px" width="100%" type="card"></v-skeleton-loader>
                  </v-col>
                </v-row>
              </v-card>
            </div>
          </v-tab-item>
        </v-tabs-items>
        <!-- </template> -->
        <v-btn v-if="advdecitems && advdecitems.Sectors && advdecitems.Thematic" to="/stocks/advance_decline" block text class="text-none primary--text rounded-t-0 rounded-b-lg" height="48px">See all</v-btn>
      </v-col>
      <v-col cols="6">
        <v-card height="460px" width="100%" id="avddecchart" class="crd-trn rounded-lg elevation-0"> </v-card>
      </v-col>
    </v-row>

    <v-toolbar class="tool-sty elevation-0 my-3" dense color="transparent">
      <p class="title font-weight-bold mb-0 mr-3">Today's trade action</p>
      <v-spacer></v-spacer>
      <!-- <v-select @change="getToplistdata()" style="max-width:180px;" v-model="screent0" hide-details append-icon="mdi-chevron-down"
            item-text="text" item-value="value" class="rounded-pill mr-3" dense flat solo background-color="secbg"
            :items="screent0item" label="Condition"></v-select> -->
      <!-- <v-select @change="getToplistdata()" :readonly="isloading" style="max-width:140px;" v-model="trader1" hide-details
        append-icon="mdi-chevron-down" item-text="text" item-value="value" class="rounded-pill" dense flat solo
        background-color="secbg" :items="trader1item" label="Condition"></v-select> -->

      <v-btn @click="scrollToo('market', -600)" class="mx-2" icon small outlined> <v-icon size="24">mdi-chevron-left</v-icon> </v-btn>
      <v-btn @click="scrollFrom('market', 600)" class="mr-1" icon small outlined> <v-icon size="24">mdi-chevron-right</v-icon> </v-btn>
    </v-toolbar>
    <!-- <v-card width="100%" class="elevation-0 py-3 px-6 rounded-lg" color="#F1F3F8">
      <v-row no-glutters>
        <v-col cols="2">
          <p class="fss-16 font-weight-bold mb-0">Advance/<br>Decline Ratio</p>
        </v-col>
        <v-col cols="5">
          <v-card class="elevation-0 rounded-lg" width="100%">s</v-card>
        </v-col>
        <v-col cols="5">
          <v-card class="elevation-0 rounded-lg" width="100%">s</v-card>
        </v-col>
      </v-row>
    </v-card> -->

    <div id="market" v-dragscroll.x class="d-inline-flex overflow-x-auto no-scroll mb-12" style="width: 100%">
      <div v-for="(tabel, l) in isloading ? [[], [], [], []] : tradeactionitem" :key="l" :class="l == 3 ? 'mr-1' : 'mr-4'">
        <v-card style="border: thin solid var(--outline) !important" class="rounded-lg elevation-0" color="cardbg">
          <v-toolbar class="elevation-0 mb-0 mt-1" dense color="transparent">
            <img width="24px" :src="require(`@/assets/stocks/${l == 0 ? 'tg' : l == 1 ? 'tl' : l == 2 ? 'vb' : 'ma'}.svg`)" :alt="l == 0 ? 'tg' : l == 1 ? 'tl' : l == 2 ? 'vb' : 'ma'" class="mr-2" />
            <p class="font-weight-bold subtitle-2 mb-0 text-none">{{ l == 3 ? "Most active" : l == 0 ? "Top gainer" : l == 1 ? "Top losers" : l == 2 ? "Volume breakout" : "" }}</p>

            <v-spacer></v-spacer>
            <v-btn :disabled="isloading" @click="$router.push({name: 'stocks market', params: {abc: l}})" text class="text-none px-0 primary--text" small>See all</v-btn>
          </v-toolbar>
          <v-data-table must-sort :sort-by="[l == 2 ? 'vol' : 'chp']" :sort-desc="[true]" hide-default-footer fixed-header :loading="isloading" class="rounded-lg overflow-y-auto" style="min-width: 330px" :headers="tradeheader" :search="opensearch" :items="tabel" :items-per-page="5">
            <template v-slot:[`item.tsym`]="{item}">
              <p class="mb-0 lh-16">
                <span @click="setSinglestock(item.tsym.split('-')[0], item)" class="font-weight-medium text-capitalize txt-dec-cust" v-text="item.tsym"></span>
                <br />
                <span v-if="l == 2" class="fs-12 subtext-text" :id="`ssdta${item.token}vol`" v-text="`Vol. : ${item.v}`"></span>
              </p>
            </template>
            <template v-slot:[`item.ltp`]="{item}">
              <p class="mb-0 lh-18">
                <span class="d-none" v-if="!uid">{{ setScrpitCH(l, item, "TA") }}</span>

                <span class="font-weight-medium maintext--text">₹<span :id="`ssdta${item.token}ltp`" v-text="item.lp ? Number(item.lp).toFixed(2) : '0.00'"></span></span> <br />
                <span class="font-weight-medium fs-12" :id="`ssdta${item.token}chpclr`" :class="item.ch > 0 ? 'maingreen--text' : item.ch < 0 ? 'mainred--text' : 'subtext--text'">
                  <span :id="`ssdta${item.token}ch`" v-text="item.ch ? item.ch : '0.00'"> </span>
                  <span :id="`ssdta${item.token}chp`" v-text="` (${item.chp ? item.chp : item.pc}%)`"></span
                ></span>
              </p>
            </template>
            <template v-slot:no-data>
              <v-col cols="12" class="text-center pa-16">
                <div class="mx-auto">
                  <img class="align-self-stretch mx-auto" width="80px" :src="require('@/assets/no data folder.svg')" alt="no data" />
                  <h5 class="txt-999 font-weight-regular">There is no data here yet!</h5>
                </div>
              </v-col>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </div>

    <v-card style="border: thin solid var(--outline) !important" outlined class="rounded-lg mb-8" color="cardbg">
      <v-toolbar class="elevation-0 my-4" dense color="transparent">
        <img width="40px" src="@/assets/stocks/srcm.svg" alt="srcm" class="mr-2" />
        <p class="title font-weight-bold mb-0">Stock monitor</p>
        <v-spacer></v-spacer>
        <v-select
          @change="getContentlistdata('yes')"
          :readonly="issloading"
          style="max-width: 180px"
          v-model="screent0"
          hide-details
          append-icon="mdi-chevron-down"
          item-text="text"
          item-value="value"
          class="rounded-pill mr-3"
          dense
          flat
          solo
          background-color="secbg"
          :items="screent0item"
          label="Condition"
        ></v-select>

        <v-select
          @change="getContentlistdata('yes')"
          :readonly="issloading"
          style="max-width: 140px"
          v-model="screent1"
          hide-details
          append-icon="mdi-chevron-down"
          item-text="text"
          item-value="value"
          class="rounded-pill"
          dense
          flat
          solo
          background-color="secbg"
          :items="screent1item"
          label="Condition"
        ></v-select>
      </v-toolbar>

      <v-data-table must-sort :sort-by="['chp']" :sort-desc="[true]" hide-default-footer fixed-header :loading="issloading" class="rounded-lg overflow-y-auto" :headers="screenheader" :search="opensearch" :items="screentitems" :items-per-page="10">
        <template v-slot:[`item.tsym`]="{item}">
          <span @click="setSinglestock(item.tsym.split('-')[0], item)" class="font-weight-medium text-capitalize txt-dec-cust" v-text="item.tsym"></span>
        </template>

        <template v-slot:[`item.ltp`]="{item}">
          <p class="mb-0 lh-18">
            <span class="d-none" v-if="!uid">{{ setScrpitCH("", item, "SCR") }}</span>

            <span class="font-weight-medium maintext--text">₹<span :id="`ssdsc${item.token}ltp`" v-text="item.lp ? Number(item.lp).toFixed(2) : '0.00'"></span></span> <br />
            <span class="font-weight-medium fs-12" :id="`ssdsc${item.token}chpclr`" :class="item.ch > 0 ? 'maingreen--text' : item.ch < 0 ? 'mainred--text' : 'subtext--text'">
              <span :id="`ssdsc${item.token}ch`" v-text="item.ch ? item.ch : '0.00'"> </span>
              <span :id="`ssdsc${item.token}chp`" v-text="` (${item.chp ? item.chp : item.pc}%)`"></span
            ></span>
          </p>
        </template>

        <template v-slot:[`item.vol`]="{item}">
          <span class="font-weight-medium maintext--text">{{ item.v ? item.v : "0.00" }}</span>
        </template>
        <template v-slot:[`item.op`]="{item}">
          <span class="font-weight-medium maintext--text" :id="`ssdsc${item.token}op`">{{ item.ap ? item.ap : "0.00" }}</span>
        </template>
        <template v-slot:[`item.cp`]="{item}">
          <span class="font-weight-medium maintext--text" :id="`ssdsc${item.token}cp`">{{ item.c ? item.c : "0.00" }}</span>
        </template>
        <template v-slot:[`item.high`]="{item}">
          <span class="font-weight-medium maintext--text" :id="`ssdsc${item.token}high`">{{ item.h ? item.h : "0.00" }}</span>
        </template>
        <template v-slot:[`item.low`]="{item}">
          <span class="font-weight-medium maintext--text" :id="`ssdsc${item.token}low`">{{ item.l ? item.l : "0.00" }}</span>
        </template>

        <template v-slot:no-data>
          <v-col cols="12" class="text-center pa-16">
            <div class="mx-auto">
              <img class="align-self-stretch mx-auto" width="80px" :src="require('@/assets/no data folder.svg')" alt="no data" />
              <h5 class="txt-999 font-weight-regular">There is no data here yet!</h5>
            </div>
          </v-col>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <v-btn :disabled="issloading" to="/stocks/screener" block text class="text-none primary--text" height="48px">See all</v-btn>
    </v-card>
  </div>
</template>

<script>
import * as echarts from "echarts";
import {dragscroll} from "vue-dragscroll";
// import apiurl from "../../../apiurl.js";

import {getADindice, getADindices, getHLbreakers, getTopList, getConTentList, getLtpdata, getSectordata} from "@/components/mixins/getAPIdata";
import eventBus from "../../../eventBus.js";
// import datadiskData from '../../../datadiskData.json'

export default {
  directives: {
    dragscroll,
  },
  /* eslint-disable */
  data: () => ({
    uid: null,
    mtoken: null,
    stoken: null,

    tradeaction: 0,
    isloading: true,
    issloading: true,
    isssloading: true,
    issssloading: true,

    opensearch: null,
    openHsearch: null,

    pdmwdata: [
      {exch: "NSE", token: "26000", tsym: "Nifty 50", too: "NIFTY 50"},
      {exch: "NSE", token: "26009", tsym: "Nifty Bank", too: "NIFTY BANK"},
      {exch: "NSE", token: "26017", tsym: "India VIX", too: ""},
      {exch: "BSE", token: "1", tsym: "SENSEX", too: ""},
      {exch: "NSE", token: "26013", tsym: "Nifty Next 50", too: "NIFTY NEXT 50"},
      {exch: "NSE", token: "26060", tsym: "NIFTY MIDCAP 150", too: "NIFTY MIDCAP 150"},
      {exch: "NSE", token: "26062", tsym: "NIFTY SMLCAP 250", too: "NIFTY SMALLCAP 250"},
      {exch: "NSE", token: "26076", tsym: "NIFTY MICROCAP250", too: "NIFTY MICROCAP 250"},
    ],

    screent0item: [
      {text: "Volume & Price Up", value: "VolUpPriceUp"},
      {text: "Volume & Price Down", value: "VolUpPriceDown"},
      {text: "Open High", value: "OpenHigh"},
      {text: "Open Low", value: "OpenLow"},
      {text: "High Break", value: "HighBreak"},
      {text: "Low Break", value: "LowBreak"},
    ],
    screent0: "VolUpPriceUp",
    screent1item: [
      {text: "All", value: "A"},
      {text: "Nifty 50", value: "NIFTY50"},
      {text: "Nifty 500", value: "NIFTY500"},
      {text: "Nifty MIDCAP 50", value: "NIFTYMCAP50"},
      {text: "Nifty SMLCAP 50", value: "NIFTYSMCAP50"},
    ],
    screent1: "NIFTY50",
    screentitems: [],

    trader1item: [
      {text: "NSE", value: "NSEALL"},
      {text: "NFO", value: "NFOALL"},
    ],
    trader1: "NFOALL",
    tradeactionitem: [],

    sectoritems: [],
    sectorwslist: [],
    sectorwapper: [],

    // mrkbreaker: [],
    mrkbreakers: [],

    // advdecitems: [],
    advdectab: 0,
  }),
  computed: {
    tradeheader() {
      return [
        {text: "Symbol", value: "tsym", sortable: false, class: "ws-p"},
        {text: "Price", value: "ltp", sortable: false, align: "right", class: "ws-p"},
      ];
    },
    highheader() {
      return [
        {text: "Symbol", value: "tsym", sortable: false, class: "ws-p"},
        {text: "Last 50 minutes", value: "ltp", sortable: false, width: "80%", class: "ws-p"},
      ];
    },
    screenheader() {
      return [
        {text: "Symbol", value: "tsym", sortable: false, class: "ws-p"},
        {text: "Price", value: "ltp", sortable: false, align: "right", class: "ws-p"},
        {text: "Open", value: "op", align: "right", class: "ws-p"},
        {text: "High", value: "high", align: "right", class: "ws-p"},
        {text: "Low", value: "low", align: "right", class: "ws-p"},
        {text: "Close", value: "cp", align: "right", class: "ws-p"},
        {text: "Volume", value: "vol", align: "right", class: "ws-p"},
      ];
    },
    advdecitems() {
      return {
        Sectors: [
          {title: "Bank", key: "NIFTY BANK"},
          {title: "Auto", key: "NIFTY AUTO"},
          {title: "Financial Services", key: "NIFTY FINANCIAL SERVICES"},
          {title: "FMCG", key: "NIFTY FMCG"},
          {title: "Health Care", key: "Nifty HEALTHCARE"},
          {title: "Pharma", key: "NIFTY PHARMA"},
          {title: "IT", key: "NIFTY IT"},
          {title: "Media", key: "NIFTY MEDIA"},
          {title: "Metal", key: "NIFTY METAL"},
          {title: "Realty", key: "NIFTY REALTY"},
          {title: "Consumer Durables", key: "NIFTY CONSUMER DURABLES"},
          {title: "Oil & Gas", key: "NIFTY OIL AND GAS INDEX"},
        ],
        Thematic: [
          {title: "Core Housing", key: "Nifty Core Housing"},
          {title: "Energy", key: "NIFTY ENERGY"},
          {title: "Consumption", key: "NIFTY INDIA CONSUMPTION"},
          {title: "Defence", key: "Nifty India Defence"},
          {title: "Digital", key: "Nifty India Digital"},
          {title: "Manufacturing", key: "Nifty India Manufacturing"},
          {title: "Infrastructure", key: "NIFTY INFRASTRUCTURE"},
          {title: "MNC", key: "NIFTY MNC"},
          {title: "Mobility", key: "Nifty Mobility"},
          {title: "Transportation & Logistics", key: "Nifty Transportation & Logistics"},
          {title: "PSE", key: "NIFTY PSE"},
          {title: "REITs & InvITs", key: "Nifty REITs & InvITs"},
          {title: "SME Emerge", key: "NIFTY SME EMERGE"},
        ],
        wsdata: [],
      };
    },
  },

  mounted() {
    eventBus.$emit("tabBar-load");
    eventBus.$emit("login-event");
    eventBus.$on("user-event", () => {
      let res = sessionStorage.getItem("c3RhdHVz");
      if (res == "dmFsaWR1c2Vy") {
        if (!this.uid && !this.stoken) {
          this.mtoken = sessionStorage.getItem("msession");
          this.stoken = sessionStorage.getItem("usession");
          this.uid = sessionStorage.getItem("userid");
        }
      }
      this.setWebsocket("sub", this.pdmwdata, "ssd-pd");
      this.getToplistdata();
      this.getContentlistdata();
      this.setStatavddec();
      // this.getSectorlistdata();
      // this.getMrkBreakerdata();

      this.getADlistdata();
    });

    eventBus.$on("web-scoketConn", (data, page) => {
      if (page == "stockDASH" && this.pdmwdata && typeof this.pdmwdata == "object") {
        this.optionChainDataParse(data);
      }
    });
    // setInterval(() => {
    //   this.getMrkBreakerdata();
    // }, 50000)
  },
  beforeDestroy() {
    eventBus.$off("user-event");
    // eventBus.$off('web-scoketConn');
  },
  methods: {
    setSinglestock(tsym, item) {
      if (this.uid) {
        let path = [0, item.token, item.exch, item.tsym];
        this.$router.push({name: "stocks details", params: {val: path}});
      } else if (item.exch == "NSE") {
        this.$router.push(`/stocks/${tsym.toLowerCase()}`);
      }
    },
    scrollToo(id, value) {
      const element = document.getElementById(`${id}`);
      element.scrollBy({
        left: value,
        behavior: "smooth",
      });
    },

    scrollFrom(id, value) {
      const element = document.getElementById(`${id}`);
      element.scrollBy({
        left: value,
        behavior: "smooth",
      });
    },
    setScrpitCH(x, i, a, l) {
      if (a == "TA") {
        let f = this.tradeactionitem[x].findIndex((o) => o.token == i.token);
        this.tradeactionitem[x][f]["ch"] = Number(i.lp) && Number(i.c) ? (Number(i.lp) - Number(i.c)).toFixed(2) : 0;
        this.tradeactionitem[x][f]["chp"] = Number(i.ch) && Number(i.lp) ? ((Number(i.ch) / Number(i.lp)) * 100).toFixed(2) : 0;
      } else if (a == "SCR") {
        let f = this.screentitems.findIndex((o) => o.token == i.token);
        this.screentitems[f]["ch"] = Number(i.lp) && Number(i.c) ? (Number(i.lp) - Number(i.c)).toFixed(2) : 0;
        this.screentitems[f]["chp"] = Number(i.ch) && Number(i.lp) ? ((Number(i.ch) / Number(i.lp)) * 100).toFixed(2) : 0;
      } else if (a == "st" && l < 5) {
        let g = this.sectorwapper.indexOf(`${x}|${l}`);
        if (this.sectorwslist && this.sectorwslist.length == 20) {
          this.setWebsocket("sub", this.sectorwslist, a);
          this.sectorwslist = [];
        } else if (g == -1) {
          this.sectorwslist.push({exch: i.Symbol.split(":")[0], token: i.Token ? i.Token : "0"});
          this.sectorwapper.push(`${x}|${l}`);
        }
      }
    },
    async getToplistdata() {
      this.tradeactionitem = [];
      this.isloading = true;
      let lsto = await getTopList([this.trader1 == "NSEALL" ? "NSE" : "NFO", this.trader1, "mostActive"]);
      let lstt = await getTopList([this.trader1 == "NSEALL" ? "NSE" : "NFO", this.trader1, "topG_L"]);

      if (lsto.stat == "Ok" && lstt.stat == "Ok") {
        this.tradeactionitem.push(lstt.topGainers);
        this.tradeactionitem.push(lstt.topLosers);
        this.tradeactionitem.push(lsto.byVolume);
        this.tradeactionitem.push(lsto.byValue);

        let arr = this.tradeactionitem[0].concat(this.tradeactionitem[1].concat(this.tradeactionitem[2].concat(this.tradeactionitem[3])));
        let wsdata = [];
        arr.map((o) => wsdata.push({exch: o.exch, token: o.token, tsym: o.tsym}));
        this.setWebsocket("sub", wsdata, "ta");
      }
      this.isloading = false;
    },
    async getContentlistdata(change) {
      if (change == "yes" && this.uid) {
        this.setWebsocket("unsub-D", this.screentitems, "sc");
      }
      this.issloading = true;
      this.screentitems = [];
      let data = await getConTentList(["NSE", this.screent1, this.screent0]);
      if (data && data.length > 0) {
        this.screentitems = data;
        this.setWebsocket("sub", data, "sc");
      }
      this.issloading = false;
    },
    async getSectorlistdata() {
      this.isssloading = true;
      this.sectoritems = [];
      let data = await getSectordata();
      if (data && data.Bank) {
        this.sectoritems.push({txt: "Banking", data: data.Bank});
        this.sectoritems.push({txt: "Finance", data: data.Finance});
        this.sectoritems.push({txt: "Healthcare", data: data.Healthcare});
        this.sectoritems.push({txt: "Realty", data: data.Realty});
        // this.tradeactionitem['Power'] = data.Power;
        // this.tradeactionitem['FMCG'] = data.FMCG;
      }
      this.isssloading = false;
    },
    async getMrkBreakerdata() {
      this.mrkbreaker = [];
      this.issssloading = true;
      let data;
      // data = hig
      data = await getHLbreakers();
      if (data) {
        Object.entries(data).forEach(([key, value]) => {
          value = value.slice(-10);
          if (value && value.length < 10) {
            let l = 10 - value.length;
            for (let a = 0; a < l; a++) {
              var now = new Date(value[value.length - 1].time);
              now.setMinutes(now.getMinutes() + 5);
              const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);
              now = [padL(now.getMonth() + 1), padL(now.getDate()), now.getFullYear()].join("-") + " " + [padL(now.getHours()), padL(now.getMinutes()), padL(now.getSeconds())].join(":");
              value.push({time: now, lowbreak: false, highbreak: false, points: 0.0, percentage: 0.0, Symbol: value[0].Symbol, Token: value[0].Token});
            }
          }
          let max = Math.max(...value.map((o) => o.points));
          let min = Math.min(...value.map((o) => o.points));
          for (let v = 0; v < value.length; v++) {
            value[v]["color"] = value[v].points == 0 ? "#FAFBFF" : value[v].points > 0 ? (value[v].points <= max / 3 ? "#caedc4" : value[v].points <= max / 2 ? "#a6e19d" : "#83d576") : value[v].points <= min / 3 ? "#ff4d4d" : value[v].points <= min / 2 ? "#ff8080" : "#ffb3b3";
          }
          this.mrkbreakers.push({tsym: key, data: value});
        });
      }
      // this.mrkbreaker = data;
      this.issssloading = false;
    },
    async getADlistdata() {
      let data = await getADindices();
      this.advdecitems["Sectors"].map((o) => (o["data"] = this.setStatAD(data[o.key], o.key)));
      this.advdecitems["Thematic"].map((o) => (o["data"] = this.setStatAD(data[o.key], o.key)));


      // this.advdecitems['Sectors'] = [];
      // this.advdecitems['Thematic'] = [];
      // this.advdecitems['wsdata'] = [];
      // this.advdecitems.Sectors = [
      //   { title: 'Bank', data: this.setStatAD(data['NIFTY BANK'], 'NIFTY BANK'), key: 'NIFTY BANK' },
      //   { title: 'Auto', data: this.setStatAD(data['NIFTY AUTO'], 'NIFTY AUTO'), key: 'NIFTY AUTO' },
      //   { title: 'Financial Services', data: this.setStatAD(data['NIFTY FINANCIAL SERVICES'], 'NIFTY FINANCIAL SERVICES'), key: 'NIFTY FINANCIAL SERVICES' },
      //   { title: 'FMCG', data: this.setStatAD(data['NIFTY FMCG'], 'NIFTY FMCG'), key: 'NIFTY FMCG' },
      //   { title: 'Health Care', data: this.setStatAD(data['Nifty HEALTHCARE'], 'Nifty HEALTHCARE'), key: 'Nifty HEALTHCARE' },
      //   { title: 'Pharma', data: this.setStatAD(data['NIFTY PHARMA'], 'NIFTY PHARMA'), key: 'NIFTY PHARMA' },
      //   { title: 'IT', data: this.setStatAD(data['NIFTY IT'], 'NIFTY IT'), key: 'NIFTY IT' },
      //   { title: 'Media', data: this.setStatAD(data['NIFTY MEDIA'], 'NIFTY MEDIA'), key: 'NIFTY MEDIA' },
      //   { title: 'Metal', data: this.setStatAD(data['NIFTY METAL'], 'NIFTY METAL'), key: 'NIFTY METAL' },
      //   { title: 'Realty', data: this.setStatAD(data['NIFTY REALTY'], 'NIFTY REALTY'), key: 'NIFTY REALTY' },
      //   { title: 'Consumer Durables', data: this.setStatAD(data['NIFTY CONSUMER DURABLES'], 'NIFTY CONSUMER DURABLES'), key: 'NIFTY CONSUMER DURABLES' },
      //   { title: 'Oil & Gas', data: this.setStatAD(data['NIFTY OIL AND GAS INDEX'], 'NIFTY OIL AND GAS INDEX'), key: 'NIFTY OIL AND GAS INDEX' },
      // ];
      // this.advdecitems.Thematic = [
      //   { title: 'Core Housing', data: this.setStatAD(data['Nifty Core Housing'], 'Nifty Core Housing'), key: 'Nifty Core Housing' },
      //   { title: 'Energy', data: this.setStatAD(data['NIFTY ENERGY'], 'NIFTY ENERGY'), key: 'NIFTY ENERGY' },
      //   { title: 'Consumption', data: this.setStatAD(data['NIFTY INDIA CONSUMPTION'], 'NIFTY INDIA CONSUMPTION'), key: 'NIFTY INDIA CONSUMPTION' },
      //   { title: 'Defence', data: this.setStatAD(data['Nifty India Defence'], 'Nifty India Defence'), key: 'Nifty India Defence' },
      //   { title: 'Digital', data: this.setStatAD(data['Nifty India Digital'], 'Nifty India Digital'), key: 'Nifty India Digital' },
      //   { title: 'Manufacturing', data: this.setStatAD(data['Nifty India Manufacturing'], 'Nifty India Manufacturing'), key: 'Nifty India Manufacturing' },
      //   { title: 'Infrastructure', data: this.setStatAD(data['NIFTY INFRASTRUCTURE'], 'NIFTY INFRASTRUCTURE'), key: 'NIFTY INFRASTRUCTURE' },
      //   { title: 'MNC', data: this.setStatAD(data['NIFTY MNC'], 'NIFTY MNC'), key: 'NIFTY MNC' },
      //   { title: 'Mobility', data: this.setStatAD(data['Nifty Mobility'], 'Nifty Mobility'), key: 'Nifty Mobility' },
      //   { title: 'Transportation & Logistics', data: this.setStatAD(data['Nifty Transportation & Logistics'], 'Nifty Transportation & Logistics'), key: 'Nifty Transportation & Logistics' },
      //   { title: 'PSE', data: this.setStatAD(data['NIFTY PSE'], 'NIFTY PSE'), key: 'NIFTY PSE' },
      //   { title: 'REITs & InvITs', data: this.setStatAD(data['Nifty REITs & InvITs'], 'Nifty REITs & InvITs'), key: 'Nifty REITs & InvITs' },
      //   { title: 'SME Emerge', data: this.setStatAD(data['NIFTY SME EMERGE'], 'NIFTY SME EMERGE'), key: 'NIFTY SME EMERGE' },
      // ];
      // this.advdecitems = { ...this.advdecitems }

      this.setWebsocket("sub", Object.values(this.advdecitems.wsdata), "adv");
      this.advdecitems.Sectors.sort((a, b) => Number(b.data.marketCap) - Number(a.data.marketCap));
      this.advdecitems.Thematic.sort((a, b) => Number(b.data.marketCap) - Number(a.data.marketCap));
      this.advdectab = null;
    },
    async setStatavddec() {
      echarts.dispose(document.getElementById("avddecchart"));

      let serdata = [];
      let data = await getADindice("NIFTY 50");
      for (let c = 0; c < data.length; c++) {
        serdata.push({
          name: data[c]["SYMBOL"].split(":")[1],
          value: [
            Number(data[c].market_cap),
            `₹${data[c].ltp} <span class="fs-12 ${Number(data[c].change) > 0 ? "txt-gre" : Number(data[c].change) < 0 ? "txt-red" : ""}" >${Number(data[c].ltp - Number(data[c].close)).toFixed(2)} (${data[c].change}%) </span>`,
            Number(data[c].change),
            0,
            data[c]["Company Name"],
          ],
        });
      }
      this.convertData(serdata);
      var myChart = echarts.init(document.getElementById("avddecchart"));
      // var self = this;
      var option = {
        title: {
          show: true,
          left: "center",
          text: "NIFTY 50 Treemap",
        },
        tooltip: {
          formatter: function (info) {
            let value = info.value;
            return [
              '<div class="tooltip-title maintext--text">' + value[4] + "</div>",
              // 'Market cap: &nbsp;&nbsp;' + Number(value[0]).toFixed(2) + '<br>',
              '<p class="mb-0 maintext--text fs-14">' + `${value[1]}` + "</p>",
            ].join("");
          },
        },
        legend: false,
        series: [
          {
            roam: false,
            nodeClick: undefined,
            breadcrumb: false,
            name: "NIFTY 50",
            type: "treemap",
            visualMin: -100,
            visualMax: 100,
            visualDimension: 3,
            label: {
              show: true,
              formatter: "{b}",
            },
            itemStyle: {
              borderColor: "#fff",
            },
            levels: [
              {
                color: ["#FF1717", "#aaa", "#43A833"],
                colorMappingBy: "value",
                itemStyle: {
                  gapWidth: 1,
                },
              },
            ],
            data: serdata,
          },
        ],
      };
      option && myChart.setOption(option);

      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    convertData(originList) {
      const visualMin = -100;
      const visualMax = 100;
      const visualMinBound = -40;
      const visualMaxBound = 40;

      let min = Infinity;
      let max = -Infinity;
      for (let i = 0; i < originList.length; i++) {
        let node = originList[i];
        if (node) {
          let value = node.value;
          value[2] != null && value[2] < min && (min = value[2]);
          value[2] != null && value[2] > max && (max = value[2]);
        }
      }
      for (let i = 0; i < originList.length; i++) {
        let node = originList[i];
        if (node) {
          let value = node.value;
          // Scale value for visual effect
          if (value[2] != null && value[2] > 0) {
            value[3] = echarts.number.linearMap(value[2], [0, max], [visualMaxBound, visualMax], true);
          } else if (value[2] != null && value[2] < 0) {
            value[3] = echarts.number.linearMap(value[2], [min, 0], [visualMin, visualMinBound], true);
          } else {
            value[3] = 0;
          }
          if (!isFinite(value[3])) {
            value[3] = 0;
          }
          if (node.children) {
            this.convertData(node.children);
          }
        }
      }
    },
    setStatAD(data, tsym) {
      data["sum"] = data.Negative + data.Neutral + data.Positive;
      data["adp"] = data.sum > 0 ? `${Math.round((data.Positive / data.sum) * 100)}%` : 0;
      data["adm"] = data.sum > 0 ? `${Math.round((data.Negative / data.sum) * 100)}%` : 0;
      data["adn"] = data.sum > 0 ? `${Math.round((data.Neutral / data.sum) * 100)}%` : 0;
      this.advdecitems.wsdata[data.token] = {exch: "NSE", token: data.token, tsym: tsym};
      return data;
    },
    async setWebsocket(flow, data, is) {
      if (this.uid) {
        eventBus.$emit("web-scoketOn", flow, data, is, "stockDASH");
      } else {
        let raw = await getLtpdata(data);
        raw = raw.data;
        if (is == "ssd-pd" && raw) {
          for (let l = 0; l < this.pdmwdata.length; l++) {
            let v = raw[this.pdmwdata[l].token];
            if (v) {
              this.pdmwdata[l]["ltp"] = Number(v.lp).toFixed(2);
              this.pdmwdata[l]["ch"] = Number(this.pdmwdata[l].ltp - Number(v.close)).toFixed(2);
              this.pdmwdata[l]["chp"] = Number(v.change);
              // this.$set(this.pdmwdata, l, this.pdmwdata[l]);
            }
          }
        } else if (is == "ta" && raw) {
          for (let x = 0; x < this.tradeactionitem.length; x++) {
            for (let x_is = 0; x_is < this.tradeactionitem[x].length; x_is++) {
              let data = raw[this.tradeactionitem[x][x_is].token];
              if (data) {
                this.tradeactionitem[x][x_is].ltp = Number(data.lp) ? Number(data.lp).toFixed(2) : 0;
                this.tradeactionitem[x][x_is]["ch"] = Number(data.lp) && Number(data.close) ? (Number(data.lp) - Number(data.close)).toFixed(2) : 0;
                this.tradeactionitem[x][x_is]["chp"] = Number(data.change).toFixed(2);
                this.tradeactionitem[x][x_is]["vol"] = Number(data.vol).toFixed(2);
                this.tradeactionitem[x][x_is]["op"] = Number(data.open) ? Number(data.open).toFixed(2) : 0;
                this.tradeactionitem[x][x_is]["cp"] = Number(data.close) ? Number(data.close).toFixed(2) : 0;
                this.tradeactionitem[x][x_is]["high"] = Number(data.high) ? Number(data.high).toFixed(2) : 0;
                this.tradeactionitem[x][x_is]["low"] = Number(data.low) ? Number(data.low).toFixed(2) : 0;
                this.tradeactionitem[x][x_is]["oi"] = data.oi ? Number(data.oi).toFixed(2) : 0;
                // this.$set(this.tradeactionitem[x], x_is, this.tradeactionitem[x][x_is]);
              }
            }
          }
        } else if (is == "adv" && raw) {
          var f = Object.entries(this.advdecitems.wsdata);
          for (let l = 0; l < f.length; l++) {
            let v = raw[f[l][0]];
            if (v && f[l][0]) {
              this.advdecitems.wsdata[f[l][0]]["ltp"] = Number(v.lp).toFixed(2);
              this.advdecitems.wsdata[f[l][0]]["ch"] = Number(this.advdecitems.wsdata[f[l][0]].ltp - Number(v.close)).toFixed(2);
              this.advdecitems.wsdata[f[l][0]]["chp"] = Number(v.change);
              // this.$set(this.advdecitems.wsdata, f[l][0], this.advdecitems.wsdata[f[l][0]]);
            }
          }
          this.advdectab = 0;
        }

        // else if (is == 'st' && raw) {
        //   Object.entries(this.sectoritems).forEach(([key, value]) => {
        //     for (let l = 0; l < value.data.length; l++) {
        //       let v = raw[value.data[l].Token];
        //       if (v) {
        //         this.sectoritems[key].data[l]['ltp'] = Number(v.lp).toFixed(2);
        //         this.sectoritems[key].data[l]['ch'] = Number(this.sectoritems[key].data[l].ltp - Number(v.close)).toFixed(2);
        //         this.sectoritems[key].data[l]['chp'] = Number(v.change);
        //         this.$set(this.sectoritems[key].data, l, this.sectoritems[key].data[l]);
        //       }
        //     }
        //   })
        // }
      }
    },
    optionChainDataParse(data) {
      let p = this.pdmwdata.findIndex((o) => o.token == data.token);
      if (p >= 0 && this.pdmwdata[p].token == data.token) {
        this.pdmwdata[p].ltp = Number(data.lp).toFixed(2);
        this.pdmwdata[p]["ch"] = Number(data.ch) > 0 || Number(data.ch) < 0 ? Number(data.ch).toFixed(2) : (0).toFixed(2);
        this.pdmwdata[p]["chp"] = Number(data.chp).toFixed(2);
        let tag = document.getElementById(`ssdpd${data.token}ltp`);
        if (tag) {
          document.getElementById(`ssdpd${data.token}ltp`).innerHTML = this.pdmwdata[p].ltp;
          document.getElementById(`ssdpd${data.token}ch`).innerHTML = this.pdmwdata[p].ch;
          document.getElementById(`ssdpd${data.token}chp`).innerHTML = ` (${this.pdmwdata[p].chp}%)`;
          eventBus.$emit("color-event", `ssdpd${data.token}chpclr`, this.pdmwdata[p].ch > 0 ? "maingreen--text" : this.pdmwdata[p].ch < 0 ? "mainred--text" : "subtext--text");
        }
        // this.$set(this.pdmwdata, p, this.pdmwdata[p]);
      }
      let s = this.screentitems.findIndex((o) => o.token == data.token);
      if (s >= 0 && this.screentitems[s].token == data.token) {
        this.screentitems[s].ltp = Number(data.lp).toFixed(2);
        this.screentitems[s]["ch"] = Number(data.ch) > 0 || Number(data.ch) < 0 ? Number(data.ch).toFixed(2) : (0).toFixed(2);
        this.screentitems[s]["chp"] = Number(data.chp).toFixed(2);
        this.screentitems[s]["vol"] = Number(data.volume);
        this.screentitems[s]["op"] = Number(data.open_price) ? Number(data.open_price).toFixed(2) : (0).toFixed(2);
        this.screentitems[s]["cp"] = Number(data.prev_close_price) ? Number(data.prev_close_price).toFixed(2) : (0).toFixed(2);
        this.screentitems[s]["high"] = Number(data.high_price) ? Number(data.high_price).toFixed(2) : (0).toFixed(2);
        this.screentitems[s]["low"] = Number(data.low_price) ? Number(data.low_price).toFixed(2) : (0).toFixed(2);

        let tag = document.getElementById(`ssdsc${data.token}ltp`);
        if (tag) {
          document.getElementById(`ssdsc${data.token}ltp`).innerHTML = this.screentitems[s].ltp;
          document.getElementById(`ssdsc${data.token}ch`).innerHTML = this.screentitems[s].ch;
          document.getElementById(`ssdsc${data.token}chp`).innerHTML = ` (${this.screentitems[s].chp}%)`;
          eventBus.$emit("color-event", `ssdsc${data.token}chpclr`, this.screentitems[s].ch > 0 ? "maingreen--text" : this.screentitems[s].ch < 0 ? "mainred--text" : "subtext--text");
          document.getElementById(`ssdsc${data.token}op`).innerHTML = this.screentitems[s].op;
          document.getElementById(`ssdsc${data.token}cp`).innerHTML = this.screentitems[s].cp;
          document.getElementById(`ssdsc${data.token}high`).innerHTML = this.screentitems[s].high;
          document.getElementById(`ssdsc${data.token}low`).innerHTML = this.screentitems[s].low;
        }
        // this.$set(this.pdmwdata, p, this.pdmwdata[p]);
      }
      if (this.tradeactionitem.length == 4) {
        let o = this.tradeactionitem[0].findIndex((o) => o.token == data.token);
        let t = this.tradeactionitem[1].findIndex((o) => o.token == data.token);
        let r = this.tradeactionitem[2].findIndex((o) => o.token == data.token);
        let f = this.tradeactionitem[3].findIndex((o) => o.token == data.token);

        if (o >= 0 || t >= 0 || r >= 0 || f >= 0) {
          for (let x = 0; x < this.tradeactionitem.length; x++) {
            let x_is = x == 0 && o >= 0 ? o : x == 1 && t >= 0 ? t : x == 2 && r >= 0 ? r : x == 3 && f >= 0 ? f : null;
            if (x_is >= 0 && this.tradeactionitem[x] && this.tradeactionitem[x][x_is] && this.tradeactionitem[x][x_is].token == data.token) {
              this.tradeactionitem[x][x_is].ltp = Number(data.lp).toFixed(2);
              this.tradeactionitem[x][x_is]["ch"] = Number(data.ch) > 0 || Number(data.ch) < 0 ? Number(data.ch).toFixed(2) : (0).toFixed(2);
              this.tradeactionitem[x][x_is]["chp"] = Number(data.chp).toFixed(2);
              this.tradeactionitem[x][x_is]["vol"] = Number(data.volume);

              let tag = document.getElementById(`ssdta${data.token}ltp`);
              if (tag) {
                document.getElementById(`ssdta${data.token}ltp`).innerHTML = this.tradeactionitem[x][x_is].ltp;
                document.getElementById(`ssdta${data.token}ch`).innerHTML = this.tradeactionitem[x][x_is].ch;
                document.getElementById(`ssdta${data.token}chp`).innerHTML = ` (${this.tradeactionitem[x][x_is].chp}%)`;
                eventBus.$emit("color-event", `ssdta${data.token}chpclr`, this.tradeactionitem[x][x_is].ch > 0 ? "maingreen--text" : this.tradeactionitem[x][x_is].ch < 0 ? "mainred--text" : "subtext--text");
              }
              let tagv = document.getElementById(`ssdta${data.token}vol`);
              if (tagv) {
                document.getElementById(`ssdta${data.token}vol`).innerHTML = `Vol. : ${this.tradeactionitem[x][x_is].vol}`;
              }
              // this.$set(this.tradeactionitem[x], x_is, this.tradeactionitem[x][x_is]);
            }
          }
        }
      }
      if (this.advdecitems && this.advdecitems.wsdata) {
        var f = Object.entries(this.advdecitems.wsdata);
        let a = f.findIndex((o) => o[0] == data.token);
        if (a >= 0 && this.advdecitems.wsdata[f[a][0]] && this.advdecitems.wsdata[f[a][0]].token == data.token) {
          this.advdecitems.wsdata[f[a][0]].ltp = Number(data.lp).toFixed(2);
          this.advdecitems.wsdata[f[a][0]]["ch"] = Number(data.ch) > 0 || Number(data.ch) < 0 ? Number(data.ch).toFixed(2) : (0).toFixed(2);
          this.advdecitems.wsdata[f[a][0]]["chp"] = Number(data.chp).toFixed(2);
          let tag = document.getElementById(`ssdad${data.token}ltp`);
          if (tag) {
            document.getElementById(`ssdad${data.token}ltp`).innerHTML = this.advdecitems.wsdata[f[a][0]].ltp;
            document.getElementById(`ssdad${data.token}ch`).innerHTML = this.advdecitems.wsdata[f[a][0]].ch;
            document.getElementById(`ssdad${data.token}chp`).innerHTML = ` (${this.advdecitems.wsdata[f[a][0]].chp}%)`;
            eventBus.$emit("color-event", `ssdad${data.token}chpclr`, this.advdecitems.wsdata[f[a][0]].ch > 0 ? "maingreen--text" : this.advdecitems.wsdata[f[a][0]].ch < 0 ? "mainred--text" : "subtext--text");
          }
          // this.$set(this.advdecitems.wsdata, f[a][0], this.advdecitems.wsdata[f[a][0]]);
        }
        if (this.advdectab == null) {
          this.advdectab = 0;
        }
      }
    },
  },
};
</script>

<style>
.stk-land-crds {
  background-color: linear-gradient(270deg, #fff 77.04%, #f6f6f6 115%), #fff !important;
  box-shadow: 0px 38.519px 25.482px 0px rgba(83, 30, 0, 0.04), 0px 20px 13px 0px rgba(83, 30, 0, 0.04), 0px 8.148px 6.519px 0px rgba(83, 30, 0, 0.03), 0px 1.852px 3.148px 0px rgba(83, 30, 0, 0.02) !important;
}
</style>
