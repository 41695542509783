<template>
  <div>
    <div class="mb-6">
      <v-toolbar flat dense class="tool-sty pl-4 crd-trn">
        <v-tabs fixed color="primary" @change="ordertab == 1 ? (ordertype = 'exec') : (ordertype = 'open')"
          v-model="ordertab">
          <v-tab class="font-weight-bold subtitle-1 mb-0 text-none">Open Orders (<span>{{ openorders ? openorders.length
            : "0" }}</span>)
          </v-tab>
          <v-tab class="font-weight-bold subtitle-1 mb-0 text-none">Executed Orders (<span>{{ execorders ?
            execorders.length : "0" }}</span>)
          </v-tab>
          <v-tabs-slider color="primary"></v-tabs-slider>
        </v-tabs>
        <v-spacer></v-spacer>
        <v-text-field style="max-width: 220px" v-model="opensearch" hide-details prepend-inner-icon="mdi-magnify"
          label="Search for Stocks" class="rounded-pill mr-4" dense flat solo background-color="secbg"></v-text-field>

        <v-select style="max-width: 180px" v-model="filter" hide-details append-icon="mdi-chevron-down"
          prepend-inner-icon="mdi-playlist-check" class="rounded-pill" dense flat solo background-color="secbg"
          :items="filters" label="Filter"></v-select>
        <v-btn v-if="ordertab == 0" :disabled="openorders && openorders.length == 0"
          @click="canceldialog = true, singledata = 'all', selectAllToggle()"
          class="elevation-0 rounded-pill font-weight-bold text-none ml-4" color="primary">Exit {{ orddselected.length
            == openorders.length ? "all" : orddselected.length > 0 ? orddselected.length : "all" }}</v-btn>
        <v-icon class="ml-3 cursor-p" @click="getOrderbook()" color="maintext" size="24">mdi-reload</v-icon>
      </v-toolbar>

      <v-data-table :show-select="ordertab == 0" v-model="orddselected" @toggle-select-all="selectAllToggle" must-sort
        :sort-by="['norentm']" :sort-desc="[true]" hide-default-footer fixed-header :loading="loading"
        class="mt-3 rounded-lg overflow-y-auto" style="border-radius: 4px; border: 1px solid var(--outline)"
        height="480px" :headers="orderheader" :search="opensearch" :items="orderbookdata" :items-per-page="-1"
        item-key="norenordno">
        <template v-slot:body="{ items, headers }">
          <tbody v-if="ordertab == 1 ? execorders && execorders.length > 0 : openorders && openorders.length > 0">
            <tr @click="setOrderrowdata(item)" class="table-row" v-for="(item, o) in items" :key="o">
              <td v-if="ordertab == 0">
                <v-simple-checkbox color="primary" :value="item.disabled"
                  @click="(item.disabled = !item.disabled), selectAllToggle()"></v-simple-checkbox>
              </td>
              <td>
                <span class="font-weight-medium maintext--text">{{ item.norentm ? item.norentm.slice(0, 8) : "-"
                }}</span>
              </td>
              <td>
                <v-chip small :color="item.trantype == 'B' ? 'secgreen' : 'secred'"
                  :text-color="item.trantype == 'B' ? 'maingreen' : 'mainred'"
                  :style="`border: 1px solid ${item.trantype == 'B' ? '#C1E7BA' : '#FFCDCD'}; border-radius: 5px; padding: 10px 8px !important;`">
                  <span class="font-weight-medium fs-12">{{ item.trantype == "B" ? "BUY" : "SELL" }}</span>
                </v-chip>
              </td>
              <td class="pos-rlt">
                <p class="font-weight-medium maintext--text mb-0 table-hov-text ws-p mr-4">
                  {{ item.tsym ? item.tsym : "" }}
                  <span class="ml-1 subtext--text fs-10">{{ item.exchs ? item.exchs : item.exch ? item.exch : ""
                  }}</span>
                </p>
                <div @click.stop class="pos-abs table-hov" style="top: 15px; right: 0">
                  <v-btn v-if="ordertab == 1"
                    @click="switchbus.$emit('menudialog', 'order', item.token, item.exch, item.tsym, 'b')"
                    min-width="20px" color="maingreen" class="px-0 font-weight-bold white--text elevation-0 mr-1"
                    x-small> B </v-btn>
                  <v-btn v-if="ordertab == 1"
                    @click="switchbus.$emit('menudialog', 'order', item.token, item.exch, item.tsym, 's')"
                    min-width="20px" color="mainred" class="px-0 font-weight-bold white--text elevation-0 mr-1" x-small>
                    S </v-btn>

                  <v-tooltip v-if="ordertab == 0" top color="black">
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        <v-btn
                          @click="switchbus.$emit('menudialog', 'mod-order', item.token, item.exch, item.tsym, item.trantype.toLowerCase(), item)"
                          style="border: 1px solid var(--outline)" min-width="20px" color="mainbg"
                          class="px-0 font-weight-bold white--text elevation-0 mr-1" x-small>
                          <v-icon size="18" color="maintext">mdi-pen</v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>Modify Order</span>
                  </v-tooltip>

                  <v-tooltip v-if="ordertab == 0" top color="black">
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        <v-btn @click="(singledata = item), (canceldialog = true)"
                          style="border: 1px solid var(--outline)" min-width="20px" color="mainbg"
                          class="px-0 font-weight-bold white--text elevation-0 mr-1" x-small>
                          <v-icon size="18" color="maintext">mdi-close-circle-outline</v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>{{ item.exord ? 'Exit' : 'Cancel' }}Order</span>
                  </v-tooltip>

                  <v-btn @click="setSSDtab('chart', item.token, item.exch, item.tsym)"
                    style="border: 1px solid var(--outline)" min-width="20px" color="mainbg"
                    class="px-0 font-weight-bold white--text elevation-0 mr-1" x-small>
                    <v-icon size="18" color="maintext">mdi-chart-line-variant</v-icon>
                  </v-btn>
                  <v-tooltip top color="black">
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        <v-btn
                          @click="switchbus.$emit('menudialog', 're-order', item.token, item.exch, item.tsym, item.trantype.toLowerCase(), item)"
                          style="border: 1px solid var(--outline)" min-width="20px" color="mainbg"
                          class="px-0 font-weight-bold white--text elevation-0 mr-1" x-small>
                          <v-icon size="18" color="maintext">mdi-autorenew</v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>Repeat order</span>
                  </v-tooltip>
                  <v-menu close-on-click absolute offset-y class="table-menu">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" style="border: 1px solid var(--outline)" min-width="20px"
                        color="mainbg" class="px-0 font-weight-bold white--text elevation-0 mr-1" x-small>
                        <v-icon size="20" color="maintext">mdi-dots-horizontal</v-icon>
                      </v-btn>
                    </template>
                    <v-card class="table-menu-list">
                      <v-list dense>
                        <div v-for="(m, k) in ordertab == 0 ? menulist.open : menulist.exec" :key="k">
                          <v-list-item
                            @click="m.type == 'cancel' ? ((singledata = item), (canceldialog = true)) : m.type != '' ? setSSDtab(m.type, item.token, item.exch, item.tsym, m.trans ? m.trans : item.trantype.toLowerCase(), item) : setOrderrowdata(item)"
                            class="pl-3 pr-6">
                            <v-list-item-icon class="mr-3 text-center">
                              <img v-if="m.icon > 2" width="20px" class="pl-1"
                                :src="require(`@/assets/orderbook/${m.icon}.svg`)" />
                              <v-icon v-else color="#506D84">{{ m.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title class="subline--text font-weight-medium fs-14">{{ m.name
                              }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider v-if="m.hr" class="mx-3"></v-divider>
                        </div>
                      </v-list>
                    </v-card>
                  </v-menu>
                </div>
              </td>
              <td>
                <v-chip v-if="item.s_prdt_ali" small class="table-hov-prd" text-color="subtext"
                  style="border-radius: 5px; padding: 10px 8px !important">
                  <span class="font-weight-medium fs-12">{{ item.s_prdt_ali }}</span>
                </v-chip>
              </td>
              <td>
                <p class="font-weight-medium maintext--text mb-0">{{ item.fillshares && Number(item.fillshares) > 0 ?
                  `${item.fillshares / (item.exch == "MCX" ? item.ls : 1)}/` : '' }}{{ item.qty ? (item.qty / (item.exch
                    == "MCX" ?
                  item.ls : 1)) : "0" }}</p>
              </td>
              <td class="text-right font-weight-medium">
                <p class="font-weight-medium maintext--text mb-0">{{ item.avgprc ? Number(item.avgprc).toFixed(2) :
                  "0.00" }}</p>
              </td>
              <td class="text-right font-weight-medium">
                <p class="font-weight-medium maintext--text mb-0">{{ item.prc ? Number(item.prc).toFixed(2) : "0.00" }}
                </p>
              </td>
              <td class="text-right font-weight-medium">
                <p class="font-weight-medium maintext--text mb-0">{{ item.trgprc ? Number(item.trgprc).toFixed(2) :
                  "0.00" }}</p>
              </td>
              <td class="text-right font-weight-medium">
                <p class="font-weight-medium maintext--text mb-0">{{ item.value ? Number(item.value).toFixed(2) : "0.00"
                }}</p>
              </td>
              <td class="ws-p font-weight-medium maintext--text align-center">
                <v-tooltip top color="black">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on" class="d-inline-flex">
                      <svg v-if="ordertype == 'exec' && item.status == 'COMPLETE'" xmlns="http://www.w3.org/2000/svg"
                        width="20" height="15" viewBox="0 0 20 15" fill="none">
                        <rect width="20" height="15" rx="7" fill="#2DB266" />
                        <path d="M6.25 8.2475L8.415 10.4125L13.8275 5" stroke="white" stroke-width="1.2"
                          stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                      <svg v-else-if="ordertype == 'exec'" xmlns="http://www.w3.org/2000/svg" width="20" height="15"
                        viewBox="0 0 20 15" fill="none">
                        <rect width="20" height="15" rx="7" fill="#DC2626" />
                        <path d="M7.5 10L12.5 5M7.5 5L12.5 10" stroke="white" stroke-width="1.2" stroke-linecap="round"
                          stroke-linejoin="round" />
                      </svg>
                      {{ item.status ? item.status : "" }}
                    </div>
                  </template>
                  <span> {{ item.rejreason ? item.rejreason : item.st_intrn }}</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <td :colspan="headers.length" class="text-center">
              <div class="mx-auto py-16 mt-16">
                <img class="mx-auto" width="80px" :src="require('@/assets/no data folder.svg')" />
                <h4 class="subtext--text font-weight-regular caption">
                  There is no {{ ordertab == 1 ? "Executed" : "Open" }} order <br />
                  data here yet!
                </h4>
              </div>
            </td>
          </tbody>
        </template>
      </v-data-table>
    </div>
    <v-navigation-drawer hide-overlay color="cardbg" fixed temporary app class="pt-2" v-model="orderdrawer" right
      width="360px">
      <template v-slot:prepend>
        <v-toolbar class="nav-drawer crd-trn" dense>
          <v-icon @click="orderdrawer = false" color="maintext" size="20">mdi-close</v-icon>
          <p class="maintext--text font-weight-bold mb-0 ml-2">Order Details</p>
          <v-spacer></v-spacer>
          <!-- <v-icon color="#666" size="20">mdi-headphones</v-icon>
          <p class="txt-666 font-weight-medium mb-0 ml-2">Need Help ?</p> -->
        </v-toolbar>
      </template>

      <v-list-item class="pt-3 pb-0">
        <v-list-item-content>
          <v-list-item-title class="font-weight-medium maintext--text mb-3">{{ singledata.tsym ? singledata.tsym : "" }}
            <span class="ml-1 txt-999 fs-10">{{ singledata.exch ? singledata.exch : "" }}</span></v-list-item-title>
          <v-list-item-title class="txt-000 font-weight-medium fs-16 mb-1">₹{{ singledata.raw ? singledata.raw.lp :
            "0.00"
          }}</v-list-item-title>
          <v-list-item-title
            :class="singledata.rpnl > 0 ? 'maingreen--text' : singledata.rpnl < 0 ? 'mainred--text' : 'subtext--text'"
            class="font-weight-medium fs-12">{{ singledata.rpnl ? singledata.rpnl : "0.00" }} ({{ singledata.pnlc ?
              singledata.pnlc : "0.00" }}%)</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item class="pt-3 pb-0">
        <v-list-item-content>
          <v-list-item-subtitle class="subtext--text font-weight-medium fs-13 mb-1">Order Id</v-list-item-subtitle>
          <v-list-item-title class="txt-000 font-weight-bold fs-14">{{ singledata.norenordno }}
            <p class="float-right mb-0">
              <svg v-if="singledata.status == 'COMPLETE'" xmlns="http://www.w3.org/2000/svg" width="20" height="15"
                viewBox="0 0 20 15" fill="none">
                <rect width="20" height="15" rx="7" fill="#2DB266" />
                <path d="M6.25 8.2475L8.415 10.4125L13.8275 5" stroke="white" stroke-width="1.2" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
              <svg v-else-if="singledata.status == 'CANCELED' || singledata.status == 'REJECTED'"
                xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15" fill="none">
                <rect width="20" height="15" rx="7" fill="#DC2626" />
                <path d="M7.5 10L12.5 5M7.5 5L12.5 10" stroke="white" stroke-width="1.2" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
              <svg v-else xmlns="http://www.w3.org/2000/svg" width="17" height="12" viewBox="0 0 17 12" fill="none">
                <path
                  d="M0.941406 6C0.941406 2.68629 3.6277 0 6.94141 0H10.9416C14.2553 0 16.9416 2.68629 16.9416 6C16.9416 9.31371 14.2553 12 10.9416 12H6.9414C3.62769 12 0.941406 9.31371 0.941406 6Z"
                  fill="#FFB038" />
                <path
                  d="M5.19143 7C5.74373 7 6.19145 6.55228 6.19145 6C6.19145 5.44772 5.74373 5 5.19143 5C4.63914 5 4.19142 5.44772 4.19142 6C4.19142 6.55228 4.63914 7 5.19143 7Z"
                  fill="white" />
                <path
                  d="M9.19149 7C9.74378 7 10.1915 6.55228 10.1915 6C10.1915 5.44772 9.74378 5 9.19149 5C8.63919 5 8.19147 5.44772 8.19147 6C8.19147 6.55228 8.63919 7 9.19149 7Z"
                  fill="white" />
                <path
                  d="M13.1915 7C13.7438 7 14.1915 6.55228 14.1915 6C14.1915 5.44772 13.7438 5 13.1915 5C12.6392 5 12.1915 5.44772 12.1915 6C12.1915 6.55228 12.6392 7 13.1915 7Z"
                  fill="white" />
              </svg>
              {{ singledata.status ? singledata.status : "" }}
            </p>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <div class="px-4 pb-6">
        <v-btn
          @click="switchbus.$emit('menudialog', 're-order', singledata.token, singledata.exch, singledata.tsym, singledata.trantype.toLowerCase(), singledata)"
          v-if="singledata.status == 'COMPLETE' || singledata.status == 'CANCELED' || singledata.status == 'REJECTED'"
          class="rounded-pill text-none font-weight-bold" block height="40px" outlined>Place New order</v-btn>
        <v-row v-else>
          <v-col cols="6">
            <v-btn
              @click="switchbus.$emit('menudialog', 'mod-order', singledata.token, singledata.exch, singledata.tsym, singledata.trantype.toLowerCase(), singledata)"
              class="rounded-pill text-none font-weight-bold" block height="40px" outlined>Modify Order</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn @click="(orderdrawer = false), (canceldialog = true)" class="rounded-pill text-none font-weight-bold"
              block height="40px" outlined>{{ singledata.exord ? 'Exit' : 'Cancel' }}Order </v-btn>
          </v-col>
        </v-row>
      </div>
      <v-divider class="pt-1"></v-divider>
      <div v-if="singledata.status != 'COMPLETE'">
        <div class="py-3 px-4">
          <p class="subtext--text font-weight-medium fs-13 mb-1">Rejected reason</p>
          <p class="error--text font-weight-bold fs-14 mb-0">{{ singledata.rejreason ? singledata.rejreason : "-" }}</p>
        </div>
        <v-divider></v-divider>
      </div>
      <div class="px-4 pt-1">
        <v-list-item-title class="txt-000 font-weight-bold fs-14 py-4">Quantity
          <p class="float-right mb-0">{{ singledata.qty ? singledata.qty / (singledata.exch == "MCX" ? singledata.ls :
            1) :
            "0" }}</p>
        </v-list-item-title>
        <v-divider></v-divider>
        <v-list-item-title class="txt-000 font-weight-bold fs-14 py-4">Price
          <p class="float-right mb-0">₹{{ singledata.prc ? singledata.prc : "0.00" }}</p>
        </v-list-item-title>
        <v-divider></v-divider>
        <v-list-item-title class="txt-000 font-weight-bold fs-14 py-4">Avg price
          <p class="float-right mb-0">₹{{ singledata.rprc ? singledata.rprc : "0.00" }}</p>
        </v-list-item-title>
        <v-divider></v-divider>
        <v-list-item-title class="txt-000 font-weight-bold fs-14 py-4">Trigger price
          <p class="float-right mb-0">₹{{ singledata.trgprc ? singledata.trgprc : "0.00" }}</p>
        </v-list-item-title>
        <v-divider></v-divider>
        <v-list-item-title class="txt-000 font-weight-bold fs-14 py-4">Order type
          <p class="float-right mb-0">{{ singledata.prctyp ? singledata.prctyp : "-" }}</p>
        </v-list-item-title>
        <v-divider></v-divider>
        <v-list-item-title class="txt-000 font-weight-bold fs-14 py-4">Validity
          <p class="float-right mb-0">{{ singledata.ret ? singledata.ret : "-" }}</p>
        </v-list-item-title>
        <v-divider></v-divider>
        <v-list-item-title class="txt-000 font-weight-bold fs-14 py-4">Exch. Ord ID
          <p class="float-right mb-0">{{ singledata.exchordid ? singledata.exchordid : "-" }}</p>
        </v-list-item-title>
        <v-divider></v-divider>
        <v-list-item-title class="txt-000 font-weight-bold fs-14 py-4">Time
          <p class="float-right mb-0">{{ singledata.norentm ? singledata.norentm : "-" }}</p>
        </v-list-item-title>
        <v-divider></v-divider>
        <v-list-item-title class="txt-000 font-weight-bold fs-14 py-4">Exch time
          <p class="float-right mb-0">{{ singledata.norentm ? singledata.norentm : "-" }}</p>
        </v-list-item-title>
        <v-divider></v-divider>
        <v-list-item-title class="txt-000 font-weight-bold fs-14 py-4">Product
          <p class="float-right mb-0">{{ singledata.s_prdt_ali ? singledata.s_prdt_ali : "-" }}</p>
        </v-list-item-title>
        <v-divider></v-divider>
        <v-list-item-title class="txt-000 font-weight-bold fs-14 py-4">Place by
          <p class="float-right mb-0">{{ singledata.uid ? singledata.uid : "-" }}</p>
        </v-list-item-title>
      </div>
      <v-divider class="pt-1"></v-divider>
      <div class="px-4 pt-3 pb-4">
        <v-btn @click="setSingleorderbook(singledata.norenordno)" class="rounded-pill text-none font-weight-bold" block
          height="40px" outlined><v-icon>mdi-history</v-icon> Order history</v-btn>
      </div>
    </v-navigation-drawer>

    <v-dialog v-model="orderhistory" hide-overlay class="rounded-xl" max-width="720">
      <v-card class="rounded-xl elevation-0 py-4 overflow-hidden">
        <div class="px-6" v-if="orderhistorydata && orderhistorydata[0]">
          <v-list-item-title class="font-weight-bold title maintext--text mb-0">Order history<v-icon
              @click="(orderhistory = false), (orderhistorydata = [])" class="float-right"
              color="maintext">mdi-close</v-icon></v-list-item-title>
          <v-divider class="mb-3"></v-divider>
          <v-card class="elevation-0 py-2 mt-2 mb-3" light color="secbg">
            <v-list-item class="mb-1 maintext--text">
              <v-list-item-content class="py-0">
                <v-list-item-title class="caption">Order Number</v-list-item-title>
                <v-list-item-title class="subtitle-2 font-weight-bold">{{ orderhistorydata[0].norenordno ?
                  orderhistorydata[0].norenordno : "-" }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content class="py-0">
                <v-list-item-title class="caption">User ID</v-list-item-title>
                <v-list-item-title class="subtitle-2 font-weight-bold">{{ orderhistorydata[0].actid ?
                  orderhistorydata[0].actid : "-" }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content class="py-0">
                <v-list-item-title class="caption">Account Id</v-list-item-title>
                <v-list-item-title class="subtitle-2 font-weight-bold">{{ orderhistorydata[0].actid ?
                  orderhistorydata[0].actid : "-" }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content class="py-0">
                <v-list-item-title class="caption">Exchange</v-list-item-title>
                <v-list-item-title class="subtitle-2 font-weight-bold">{{ orderhistorydata[0].exch ?
                  orderhistorydata[0].exch : "-" }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content class="py-0">
                <v-list-item-title class="caption">Product</v-list-item-title>
                <v-list-item-title class="subtitle-2 font-weight-bold">{{ orderhistorydata[0].prd ?
                  (orderhistorydata[0].prd
                    == "C" ? "CNC" : orderhistorydata[0].prd == "M" ? "NRML" : "MIS") : "-" }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="maintext--text">
              <v-list-item-content class="py-0">
                <v-list-item-title class="caption">Trading Symbol</v-list-item-title>
                <v-list-item-title class="subtitle-2 font-weight-bold">{{ orderhistorydata[0].tsym ?
                  orderhistorydata[0].tsym : "-" }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content class="py-0">
                <v-list-item-title class="caption">ExchOrd Number</v-list-item-title>
                <v-list-item-title class="subtitle-2 font-weight-bold">{{ orderhistorydata[0].norenordno ?
                  orderhistorydata[0].norenordno : "-" }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content class="py-0">
                <v-list-item-title class="caption">Duration</v-list-item-title>
                <v-list-item-title class="subtitle-2 font-weight-bold">{{ orderhistorydata[0].ret ?
                  orderhistorydata[0].ret : "-" }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content class="py-0">
                <v-list-item-title class="caption">PriceType</v-list-item-title>
                <v-list-item-title class="subtitle-2 font-weight-bold">{{ orderhistorydata[0].prctyp ?
                  (orderhistorydata[0].prctyp == "MKT" ? "Market" : orderhistorydata[0].prctyp == "LMT" ? "LIMIT" :
                    orderhistorydata[0].prctyp == "SL-MKT" ? "SL MARKET" : "SL LIMIT") : "-" }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content class="py-0">
                <v-list-item-title class="caption">Transaction Type</v-list-item-title>
                <v-list-item-title class="subtitle-2 font-weight-bold" :class="orderhistorydata[0].trantype == 'B' ? 'maingreen--text' : 'mainred--text'">{{ orderhistorydata[0].trantype ?
                  (orderhistorydata[0].trantype == "B" ? "BUY" : "SELL") : "-" }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>

          <v-card outlined>
            <v-data-table fixed-header dense height="128px" disable-sort :headers="orderhistoryhead" hide-default-footer
              :items="orderhistorydata" :items-per-page="-1" class="elevation-0 rounded-lg">
              <template v-slot:[`item.qty`]="{ item }">
                <span>{{ item.qty / (item.exch == "MCX" ? item.ls : 1) }}</span>
              </template>
            </v-data-table>
          </v-card>
          <v-row no-glutters class="mb-4 mt-2 px-2">
            <v-col cols="4" class="pb-0">
              <p class="mb-0 fs-14">
                Child ID :<span class="float-right">{{ orderhistorydata[0].kidid ? orderhistorydata[0].kidid : "-"
                }}</span>
              </p>
            </v-col>
            <v-col cols="4" class="pb-0">
              <p class="mb-0 fs-14">
                Source :<span class="float-right">{{ orderhistorydata[0].ordersource ? orderhistorydata[0].ordersource :
                  "-"
                }}</span>
              </p>
            </v-col>
            <v-col cols="4" class="pb-0">
              <p class="mb-0 fs-14">
                Qty :<span class="float-right">{{ orderhistorydata[0].qty ? orderhistorydata[0].qty /
                  (orderhistorydata[0].exch == "MCX" ? orderhistorydata[0].ls : 1): "-" }}</span>
              </p>
            </v-col>
            <v-col cols="4" class="pb-0">
              <p class="mb-0 fs-14">
                Dist Qty :<span class="float-right">{{ orderhistorydata[0].dscqty ? orderhistorydata[0].dscqty /
                  (orderhistorydata[0].exch == "MCX" ? orderhistorydata[0].ls : 1) : "-"
                  }}</span>
              </p>
            </v-col>
            <v-col cols="4" class="pb-0">
              <p class="mb-0 fs-14">
                Broker-Id :<span class="float-right">{{ orderhistorydata.fsd ? orderhistorydata.fsd : "Zebu" }}</span>
              </p>
            </v-col>
            <v-col cols="4" class="pb-0">
              <p class="mb-0 fs-14">
                Price :<span class="float-right">{{ orderhistorydata[0].prc ? orderhistorydata[0].prc : "-" }}</span>
              </p>
            </v-col>
            <v-col cols="4" class="pb-0">
              <p class="mb-0 fs-14">
                Interop Exch :<span class="float-right">{{ orderhistorydata[0].introp_exch ?
                  orderhistorydata[0].introp_exch
                  : "-" }}</span>
              </p>
            </v-col>
            <v-col cols="4" class="pb-0">
              <p class="mb-0 fs-14">
                Report Type :<span class="float-right">{{ orderhistorydata[0].rpt ? orderhistorydata[0].rpt : "-"
                }}</span>
              </p>
            </v-col>
            <v-col cols="4" class="pb-0">
              <p class="mb-0 fs-14">
                Total Fill Qty :<span class="float-right">{{ orderhistorydata[0].fillshares ?
                  orderhistorydata[0].fillshares / (orderhistorydata[0].exch == "MCX" ? orderhistorydata[0].ls : 1)
                  : "-" }}</span>
              </p>
            </v-col>
            <v-col cols="4" class="pb-0">
              <p class="mb-0 fs-14">
                Rejected By :<span class="float-right">{{ orderhistorydata[0].rejby ? orderhistorydata[0].rejby : "-"
                }}</span>
              </p>
            </v-col>
            <v-col cols="4" class="pb-0">
              <p class="mb-0 fs-14">
                Fill Price :<span class="float-right">{{ orderhistorydata[0].flprc ? orderhistorydata[0].flprc : "-"
                }}</span>
              </p>
            </v-col>
            <v-col cols="4" class="pb-0">
              <p class="mb-0 fs-14">
                Trigger Price :<span class="float-right">{{ orderhistorydata.rejreason ? orderhistorydata.rejreason :
                  "-"
                }}</span>
              </p>
            </v-col>
          </v-row>
          <P class="font-weight-medium mb-1">Rejected reason</P>
          <P class="mainred--text">{{ orderhistorydata[0].rejreason ? orderhistorydata[0].rejreason : "-" }}</P>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="canceldialog" max-width="400">
      <v-card class="rounded-xl elevation-0 text-center pt-8 pb-6 overflow-hidden">
        <img src="@/assets/orderbook/cancel-icon.svg" alt="cancel icon" />
        <p class="font-weight-medium mt-3 fs-22 lh-24 mb-8">
          Are you sure you want to <br />
          {{ singledata == 'all' ? orddselected.length == openorders.length ? "all" : orddselected.length > 0 ?
            orddselected.length : "all" : singledata.exord ? 'exit' : 'cancel' }} <b>{{ singledata.tsym ? singledata.tsym
            :
            'Open' }} </b> {{ singledata.exord ? 'position' : 'order' }} ?
        </p>
        <v-row class="px-6" no-gluttters>
          <v-col cols="6">
            <v-btn @click="(canceldialog = false), (singledata = [])" color="outline"
              class="rounded-pill text-none subtext--text font-weight-bold elevation-0" block height="40px">No</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              @click="singledata == 'all' ? setColseOrders(0) : switchbus.$emit('menudialog', 'cancel-order', singledata.token, singledata.exch, singledata.tsym, '', singledata), (canceldialog = false)"
              color="btnclr" class="rounded-pill text-none btntext--text font-weight-bold elevation-0" block
              height="40px">{{ singledata.exord ? 'Exit' : 'Yes' }}</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import apiurl from '../../apiurl.js'
import { getLtpdata, getMOrderbook, getSingleorderbook } from "../../components/mixins/getAPIdata.js";
import eventBus from "../../eventBus.js";

// import dataO from '../../datao'

export default {
  data: () => ({
    orderdrawer: false,
    canceldialog: false,
    singledata: [],
    loading: false,

    ordertab: 0,
    menulist: {
      open: [
        { name: "Buy", icon: "mdi-plus", type: "order", trans: "b" },
        { name: "Sell", icon: "mdi-minus", type: "order", trans: "s", hr: true },
        // {name: "Modify Order", icon: 11, type: "modify"},
        // {name: "Cancel Order", icon: 12, type: "cancel", hr: true},
        { name: "History", icon: "mdi-history", type: "his", hr: true },
        { name: "Create GTT / GTC", icon: 4, type: "cGTT" },
        { name: "Create Alert", icon: 5, type: "alert" },
        { name: "Market Depth", icon: 6, type: "depth" },
        { name: "Chart", icon: 7, type: "chart", hr: true },
        { name: "Fundamentals", icon: 9, type: "Funda" },
        { name: "Details", icon: 10, type: "" },
      ],
      exec: [
        { name: "History", icon: "mdi-history", type: "his", hr: true },
        { name: "Create GTT / GTC", icon: 4, type: "cGTT" },
        { name: "Create Alert", icon: 5, type: "alert" },
        { name: "Market Depth", icon: 6, type: "depth" },
        { name: "Chart", icon: 7, type: "chart", hr: true },
        { name: "Fundamentals", icon: 9, type: "Funda" },
        { name: "Details", icon: 10, type: "" },
      ],
    },
    opensearch: null,
    openorders: [],
    execorders: [],
    filter: null,
    filters: [],

    ordertype: "open",
    orderbookdata: [],
    orderbooks: [],

    switchbus: null,

    orderhistorydata: null,
    orderhistory: false,

    orddselected: [],
  }),
  computed: {
    orderheader() {
      return [
        { text: "Time", value: "norentm" },
        { text: "Type", value: "trantype", sortable: false },
        { text: "Instrument", value: "tsym", sortable: false, class: "ws-p" },
        { text: "Product", value: "s_prdt_ali", sortable: false },
        { text: "Qty", value: "qty", sortable: false },
        { text: "Avg price", value: "rprc", sortable: false, align: "right", class: "ws-p" },
        { text: "Price", value: "prc", sortable: false, align: "right" },
        { text: "Trigger price", value: "trgprc", sortable: false, align: "right", class: "ws-p" },
        { text: "Order value", value: "value", sortable: false, align: "right", class: "ws-p" },
        // { text: 'Order value', value: 'value', sortable: false, width: '8%', align: 'right', class:'ws-p' },
        // { text: 'MKT protection', value: 'mkt_protection', sortable: false, width: '8%', align: 'right', class:'ws-p' },
        { text: "Status", value: "status", class: "ws-p" },
        { text: "", value: "way", filter: this.setSeporderbook, class: " d-none" },
      ];
    },
    orderhistoryhead() {
      return [
        { text: "Date Time", value: "norentm" },
        { text: "Exch Time", value: "exch" },
        { text: "Price", value: "prc" },
        { text: "Pending Qty", value: "qty" },
        { text: "Status", value: "status" },
        { text: "Modified by", value: "modifi" },
      ];
    },
  },
  created() {
    this.switchbus = eventBus;
  },
  mounted() {
    let res = sessionStorage.getItem("c3RhdHVz");
    if (res == "dmFsaWR1c2Vy") {
      this.getOrderbook();
    } else {
      this.$router.push("/");
    }

    eventBus.$on("orderbook-update", (book) => {
      if (book == "orders") {
        this.getOrderbook();
      }
    });
  },
  beforeDestroy() {
    eventBus.$off("orderbook-update");
  },
  methods: {
    selectAllToggle(props) {
      if (this.singledata == "all") {
        this.orddselected = this.openorders;
      } else if (props) {
        if (props.value == false) {
          this.orddselected = [];
        }
        for (let p = 0; p < this.orderbookdata.length; p++) {
          if (this.orderbookdata[p].way == "open") {
            this.orderbookdata[p]["disabled"] = props.value;
          }
        }
      } else {
        this.orddselected = this.orderbookdata.filter((p) => {
          if (p.way == "open" && p.disabled) {
            return true;
          }
        });
        if (this.orddselected.length == this.openorders.length) {
          this.orddselected = this.openorders;
        }
        if (this.orddselected.length == 0) {
          for (let p = 0; p < this.orderbookdata.length; p++) {
            this.orderbookdata[p]["disabled"] = false;
          }
        }
      }

      this.posselected = this.orderbookdata.filter((p) => {
        if (p.way == "open" && p.disabled) {
          return true;
        }
      });
    },

    async getOrderbook() {
      this.loading = true;
      let response = await getMOrderbook();
      
      if (response && response.response && response.response.length > 0) {
        this.openorders = response.openorders;
        this.execorders = response.execorders;
        this.orderbookdata = response.response;
        this.ordertype = this.ordertab != 0 ? "exec" : "open";
      } 
      this.loading = false;
    },
    setSeporderbook(value) {
      if (!this.ordertype) {
        return true;
      }
      return value === this.ordertype;
    },
    setSSDtab(type, token, exch, tsym, trans, item) {
      if (type == "alert") {
        eventBus.$emit("menudialog", "alert", token, exch, tsym);
      } else if (type == "cGTT") {
        this.holdingdrawer = false;
        eventBus.$emit("menudialog", "order-GTT", token, exch, tsym, "b");
      } else if (type == "modify") {
        eventBus.$emit("menudialog", "mod-order", token, exch, tsym, trans.toLowerCase(), item);
      } else if (type == "order") {
        eventBus.$emit("menudialog", type, token, exch, tsym, trans, item);
      } else if (type == "his") {
        this.setSingleorderbook(item.norenordno);
      } else {
        let path = [type, token, exch, tsym];
        this.$router.push({ name: "stocks details", params: { val: path } });
      }
    },
    async setSingleorderbook(item) {
      this.loading = true;
      var data = await getSingleorderbook(item);

      if (data && data[0].stat == "Ok") {
        this.orderhistorydata = data;
        for (let i = 0; i < this.orderhistorydata.length; i++) {
          this.orderhistorydata[i].modifi = this.orderhistorydata[i].rpt.includes("New") ? "NA" : this.orderhistorydata[i].actid;
        }
        this.orderhistory = true;
      } else {
        eventBus.$emit("snack-event", 2, data.emsg ? data.emsg : data);
      }

      this.loading = false;
    },
    async setOrderrowdata(item) {
      this.singledata = {};
      this.orderdrawer = true;
      this.singledata = item;
      let raw = await getLtpdata([{ exch: item.exch, token: item.token }]);
      this.singledata["raw"] = raw.data[item.token];
    },
    setColseOrders(i) {
      if (this.orddselected[i]) {
        eventBus.$emit('menudialog', 'cancel-order', this.orddselected[i].token, this.orddselected[i].exch, this.orddselected[i].tsym, '', this.orddselected[i])
      }
      if (i <= this.orddselected.length - 1) {

        setTimeout(() => {
          this.setColseOrders(i + 1);
        }, 100);
      }

    },
  },
};
</script>
