import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
// import VueColumnsResizable from './plugins/vue-columns-resizable';
// Vue.use(VueColumnsResizable);

Vue.config.productionTip = false
Vue.prototype.$glob = {}

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')

