<template>
  <div>
    <div class="d-none d-sm-block">
      <router-view @receive="setReceives" />
    </div>
    <div class="d-sm-none">
      <v-container fill-height class="pos-cent">
        <v-card class="elevation-0 mx-auto text-center" color="transparent">
          <img src="@/assets/logo.svg" width="160px" class="pb-16" />
          <br />
          <img src="@/assets/no_screen.svg" width="40%" />
          <p class="font-weight-bold title mb-1">Stocks do not yet support screen size.</p>
          <v-list-item-subtitle class="font-weight-bold grey--text"
            >This screen size is not supported. <br />
            Please switch to a screen larger than <br />
            600 <sub>px</sub> wide.</v-list-item-subtitle
          >
        </v-card>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    //
  }),
  methods: {
    setReceives(page, bread) {
      this.$emit("receive", page, bread);
    },
  },
};
</script>
