<template>
  <div>
    <div class="overflow-y-auto h91vh ss-cust-scroll overflow-x-hidden" @scroll.passive="handleScroll">
      <div class="body-part">
        <div class="mb-6 pos-rlt default-tab">
          <v-toolbar class="d-none d-md-block px-0 pos-stk z-i3" style="top: 0px" flat dense color="cardbg">
            <v-tabs @change="setbodyTab" fixed color="maintext" v-model="bodytab" show-arrows dense>
              <v-tab dense v-for="(t, index) in dashitems" :key="index" class="text-none text-start min-w-fit">
                <span class="text-center tab-txt">{{ t.txt }}</span></v-tab
              >
              <v-tabs-slider color="maintext"></v-tabs-slider>
            </v-tabs>
            <v-spacer></v-spacer>
            <v-btn v-if="uid" @click="putMForder('buy')" small class="text-none font-weight-bold elevation-0 rounded-pill mr-2 maingreen--text fs-14" color="secgreen">Buy</v-btn>
            <v-btn v-if="uid && menudata &&menudata.params && menudata.params.SIP_FLAG == 'Y'" @click="putMForder('sip')" small class="text-none font-weight-bold elevation-0 rounded-pill mr-2 primary--text fs-14" color="secbg">SIP</v-btn>
          </v-toolbar>
          <v-divider class="mb-md-0 mb-3 d-none d-md-flex pos-stk z-i3" style="top: 48px"></v-divider>
          <div>
            <v-card :loading="mainloader" id="overview" class="crd-trn ss-cards overflow-hidden mb-md-6" width="100%">
              <v-card color="secbg" class="elevation-0 rounded-0 pt-2 px-md-2 px-3">
                <v-row no-glutters>
                  <v-col cols="12" md="8" class="pb-1 pb-md-2">
                    <v-list-item class="px-0 px-md-4">
                      <v-list-item-avatar color="#fbfbfb" size="56" class="my-0">
                        <img v-if="imageicon" :src="imageicon" @error="imageLoadError" :alt="imageicon" width="100%" />
                        <span class="headline font-weight-bold" v-else>{{ menudata.fundName ? menudata.fundName : "-" }}</span>
                      </v-list-item-avatar>
                      <v-list-item-content class="pt-3 pb-0">
                        <v-list-item-title :class="menudata ? 'maintext--text' : 'txt-trn'" class="fs-18 font-weight-bold mb-0 text-capitalize lh-14"> {{ menudata ? menudata.fundName : "abcd" }}</v-list-item-title>
                        <v-chip-group class="my-0 py-0">
                          <v-chip v-if="menudata.params && menudata.params.splito" label small text-color="subtext" color="cardbg" class="font-weight-medium fs-10">
                            {{ menudata.params.splito }}
                          </v-chip>
                          <v-chip v-if="menudata.params && menudata.params.splitr" label small text-color="subtext" color="cardbg" class="font-weight-medium fs-10">
                            {{ menudata.params.splitr }}
                          </v-chip>
                          <v-chip v-if="menudata.params && menudata.params.splitt" label small text-color="subtext" color="cardbg" class="font-weight-medium fs-10">
                            {{ menudata.params.splitt }}
                          </v-chip>
                          <!-- <v-chip label small text-color="subtext" color="cardbg" class="font-weight-medium fs-10">
                            {{ menudata ? menudata.category : "Category" }}
                          </v-chip>
                          <v-chip label small text-color="subtext" color="cardbg" class="font-weight-medium fs-10">
                            {{ menudata ? menudata.benchmark : "Benchmark" }}
                          </v-chip>
                          <v-chip label small text-color="subtext" color="cardbg" class="font-weight-medium fs-10">
                            Fund Manager: {{ menudata ? menudata.fundManager : "" }}
                          </v-chip> -->
                        </v-chip-group>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" md="4" class="text-md-right text-left pt-0 pt-md-5 pb-2 pl-4 pl-md-3">
                    <v-list-item class="px-0 px-md-4">
                      <v-list-item-content class="pt-3">
                        <v-list-item-subtitle class="fs-12 subtext--text text-uppercase"
                          >NAV <span v-if="menudata.navDate">({{ menudata.navDate }})</span></v-list-item-subtitle
                        >
                        <v-list-item-title class="fs-14 maintext--text font-weight-bold">{{ Number(menudata.currentNAV) ? `₹ ${Number(menudata.currentNAV).toFixed(2)}` : "0.00" }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-content class="pt-3">
                        <v-list-item-subtitle class="fs-12 subtext--text text-uppercase">5yr CAGR</v-list-item-subtitle>
                        <v-list-item-title class="fs-14 maintext--text font-weight-bold" :class="menudata['5Year'] > 0 ? 'maingreen--text' : menudata['5Year'] < 0 ? 'mainred--text' : 'subtext--text'"
                          ><span :class="!uid ? 'blur-text' : ''">{{ menudata["5Year"] ? `${Number(menudata["5Year"]).toFixed(2)}%` : "0.00" }}</span></v-list-item-title
                        >
                      </v-list-item-content>
                      <v-list-item-content class="pt-3">
                        <v-list-item-subtitle class="fs-12 subtext--text text-uppercase">AMU (Cr.)</v-list-item-subtitle>
                        <v-list-item-title class="fs-14 maintext--text font-weight-bold">{{ menudata.params ? `${(Number(menudata.params.AUM) / 10000000).toFixed(2)}` : "0.00" }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
              </v-card>

              <div class="px-md-6 px-4 pt-4 pt-md-8">
                <v-row no-glutters>
                  <v-col cols="12" md="10">
                    <p class="font-weight-bold title mb-1 text-capitalize">{{ menudata ? menudata.fundName : "" }} - {{ menudata.category ? menudata.category : "" }}</p>
                    <p class="fs-14 subtext--text txt-x-j mb-0">{{ menudata.overview ? menudata.overview.split("The portfolio")[0] : "-" }}</p>
                  </v-col>

                  <v-col cols="12" md="2" class="pt-5">
                    <img v-if="menudata && menudata.risk" :src="require(`@/assets/mf/riskmeter/${menudata.risk ? menudata.risk.toString() : '1'}.svg`)" alt="riskmeter" width="100%" />
                  </v-col>
                </v-row>

                <v-row no-glutters class="mt-0">
                  <!-- <v-col cols="12" md="6" class="pr-md-8">
                    <p class="font-weight-bold subtitle-1 mb-1">
                      Trailing Returns (%) <span class="float-right font-weight-medium subtitle-2 lh-32"><v-icon size="16" color="maingreen">mdi-checkbox-blank-circle</v-icon> Benchmark</span>
                    </p>
                    <v-row no-glutters class="mt-0 d-flex justify-center">
                      <v-col cols="6" md="4" v-for="(r, l) in schemereturns ? schemereturns : 6" :key="l" class="pt-2">
                        <v-card :color="r.returns > 0 ? 'secgreen' : r.returns < 0 ? 'secred' : 'secbg'" class="rounded-lg pt-2 elevation-0 brd-1-solid-ccc text-center" width="100%">
                          <div class="px-3">
                            <p :class="r.returns > 0 ? 'maingreen--text' : r.returns < 0 ? 'mainred--text' : 'subtext--text'" class="fs-18 font-weight-bold mb-1">{{ Number(r.returns) ? r.returns.toFixed(2) : "0.00" }}%</p>
                            <p class="mb-1 fs-10 text-uppercase font-weight-medium">
                              {{ r.type ? r.type : "---" }}
                            </p>
                          </div>
                          <v-card color="maingreen" class="elevation-0 rounded-t-0 rounded-b-lg px-3 py-1 text-right" width="100%">
                            <p class="fs-14 font-weight-bold mb-0 white--text">{{ Number(benchreturns[l].returns) ? benchreturns[l].returns.toFixed(2) : "0.00" }}%</p>
                          </v-card>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col> -->
                  <v-col cols="12" md="12">
                    <p class="font-weight-bold subtitle-1 mb-1">Volatility Measures</p>
                    <v-row no-glutters>
                      <v-col cols="4" class="pb-0" v-for="(t, d, l) in volatilityfield" :key="l">
                        <v-text-field class="funda-field mb-0" readonly color="maintext" :label="d" :value="t ? t : '-'"> </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-divider class="mt-6 mb-3 d-none d-md-flex"></v-divider>
              </div>

              <div class="pb-3 pb-md-6">
                <v-toolbar class="nav-drawer elevation-0 px-md-2 px-0 crd-trn" dense>
                  <p class="wid-160 font-weight-bold title mb-0">Historical NAV</p>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-divider></v-divider>
                <v-row class="pt-md-5 pb-md-3 pl-md-6 pr-md-8 flex-column-reverse flex-md-row">
                  <v-col cols="12" md="4" class="px-7 px-md-3">
                    <p class="font-weight-bold subtitle-1 mb-3">
                      Trailing Returns (%) <span class="float-right font-weight-medium subtitle-2 lh-32"><v-icon size="12" color="maingreen">mdi-checkbox-blank</v-icon><v-icon size="12" color="mainred">mdi-checkbox-blank</v-icon> Benchmark</span>
                    </p>
                    <div class="d-flex">
                      <v-row no-glutters>
                        <v-col cols="6" md="4" v-for="(r, l) in schemereturns ? schemereturns : 6" :key="l" class="pt-2 px-2">
                          <v-card :color="r.returns > 0 ? 'secgreen' : r.returns < 0 ? 'secred' : 'secbg'" class="rounded-lg pt-2 elevation-0 brd-1-solid-ccc text-center" width="100%">
                            <div class="px-1">
                              <p :class="r.returns > 0 ? 'maingreen--text' : r.returns < 0 ? 'mainred--text' : 'subtext--text'" class="fs-18 font-weight-bold mb-1">
                                <span :class="!uid ? 'blur-text' : ''">{{ Number(r.returns) ? r.returns.toFixed(2) : "0.00" }}%</span>
                              </p>
                              <p class="mb-2 fs-10 text-uppercase font-weight-medium">
                                {{ r.type ? r.type : "---" }}
                              </p>
                            </div>
                            <v-card v-if="benchreturns[l] && benchreturns[l].returns" :color="benchreturns[l].returns > 0 ? 'maingreen' : benchreturns[l].returns < 0 ? 'mainred' : 'subsec'" class="elevation-0 rounded-t-0 rounded-b-lg px-3 py-1" width="100%">
                              <p class="fs-13 font-weight-bold mb-0 white--text">
                                <span :class="!uid ? 'blur-text' : ''">{{ Number(benchreturns[l].returns) ? benchreturns[l].returns.toFixed(2) : "0.00" }}%</span>
                              </p>
                            </v-card>
                          </v-card>
                        </v-col>
                        <v-col cols="12">
                          <p class="font-weight-regular fs-10 subtext--text mb-0">52 Weeks high - 52 Weeks low</p>
                          <div class="d-flex flex-row mb-1">
                            <span class="maintext--text font-weight-medium fs-12 pt-2 lh-16">
                              {{ menudata && Number(menudata.weekHigh) ? `₹${Number(menudata.weekHigh).toFixed(2)}` : "0.00" }} <br />
                              <span class="fs-10">{{ menudata.weekLowDate ? new Date(menudata.weekLowDate).toLocaleDateString() : "-" }}</span>
                            </span>
                            <v-card v-if="menudata" width="100%" class="crd-trn elevation-0 px-2 pt-1">
                              <v-slider hide-details thumb-color="maintext" color="subtext" v-model="menudata.currentNAV" readonly :min="Number(menudata.weekLow)" :max="Number(menudata.weekHigh)" track-color="maintext"></v-slider>
                            </v-card>
                            <span class="maintext--text font-weight-medium fs-12 pt-2 lh-16 float-right">
                              {{ menudata && Number(menudata.weekLow) ? `₹${Number(menudata.weekLow).toFixed(2)}` : "0.00" }}<br />
                              <span class="fs-10">{{ menudata.weekHighDate ? new Date(menudata.weekHighDate).toLocaleDateString() : "-" }}</span>
                            </span>
                          </div>
                          <v-divider></v-divider>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                  <v-col cols="12" md="8" class="pa-md-0 pt-4 pb-0 px-6">
                    <v-card class="elevation-0 rounded-0 ss-adv-chart crd-trn" v-if="menudata">
                      <v-card height="360px" width="100%" id="navchart" class="crd-trn rounded-lg elevation-0"> </v-card>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
            </v-card>

            <v-card id="fin" class="crd-trn ss-cards overflow-hidden mb-md-6" width="100%">
              <div class="pt-md-6 pt-4 px-4 px-md-6">
                <v-list-item class="px-0">
                  <v-list-item-content class="pt-0">
                    <v-list-item-title class="font-weight-bold title mb-2">Cumulative Performance of Last 3 Years</v-list-item-title>
                    <v-list-item-title class="maintext--text font-weight-medium fs-12"
                      >Cumulative Performance breakdown of
                      {{ menudata ? menudata.fundName : "" }}
                      information</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </div>
              <v-card height="420px" width="100%" id="cumchart" class="crd-trn rounded-lg pt-4 elevation-0"> </v-card>
              <v-divider class="d-md-none"></v-divider>
            </v-card>
            <v-card id="info" class="crd-trn ss-cards overflow-hidden mb-md-6" width="100%">
              <div class="pt-md-6 py-4 px-4 px-md-6">
                <p class="font-weight-bold title lh-16">Scheme Information</p>

                <v-row no-glutters>
                  <v-col cols="12" md="7">
                    <p class="font-weight-bold mb-1">Investment Objective</p>
                    <p class="fs-14 mb-0 txt-x-j">{{ menudata.overview ? `The portfolio ${menudata.overview.split("The portfolio")[1].split("Additional details")[0]}` : "-" }}</p>
                  </v-col>
                  <v-col cols="12" md="5">
                    <p class="font-weight-bold mb-1">Fund Manager</p>

                    <v-card outlined class="rounded-lg mb-4">
                      <v-list-item class="px-0 px-md-3">
                        <v-list-item-avatar color="#fbfbfb" size="52" class="mb-auto mt-3 mr-3">
                          <img v-if="menudata && menudata.manageris" :src="menudata.manageris" :alt="menudata.fundManager" width="100%" />
                        </v-list-item-avatar>
                        <v-list-item-content class="pb-2">
                          <v-list-item-title :class="menudata ? 'maintext--text' : 'txt-trn'" class="fs-18 font-weight-bold mb-1 text-capitalize lh-14"> {{ menudata ? menudata.fundManager : "-" }}</v-list-item-title>
                          <v-list-item-subtitle class="fs-14 font-weight-bold mb-0 lh-20"> {{ menudata.Manager_designation ? menudata.Manager_designation : "-" }}</v-list-item-subtitle>
                          <v-list-item-subtitle class="fs-13 font-weight-medium mb-0 lh-14"
                            ><b>{{ menudata.Manager_totalExperience ? menudata.Manager_totalExperience : "N/A" }}</b> experience</v-list-item-subtitle
                          >
                        </v-list-item-content>
                        <v-list-item-content class="pb-2">
                          <v-list-item-title class="txt-trn fs-16 font-weight-bold mb-1 text-capitalize lh-14"> none</v-list-item-title>
                          <v-list-item-title class="fs-14 font-weight-bold mb-0 lh-20"> ₹ {{ menudata.Manager_activeFundsAumSum ? menudata.Manager_activeFundsAumSum.toFixed(2) : "-" }}</v-list-item-title>
                          <v-list-item-subtitle class="fs-13 font-weight-medium mb-0 lh-14"
                            ><b>{{ menudata.Manager_numberOfActiveFunds ? Math.round(menudata.Manager_numberOfActiveFunds) : "N/A" }}</b> funds managed</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
                    <!-- <p class="fs-14"><b>About manager: </b> {{ menudata.Manager_detailedDescription ? menudata.Manager_detailedDescription : "Fund manager" }}</p> -->
                  </v-col>
                </v-row>
                <p class="fs-14 mb-0 txt-x-j">{{ menudata.overview ? `Additional details ${menudata.overview.split("Additional details")[1]}` : "-" }}</p>
              </div>
              <v-row class="px-4 px-md-6 mb-md-3 mb-4">
                <v-col cols="4" class="pb-0" v-for="(t, d, l) in schemefield" :key="l">
                  <v-text-field class="funda-field" readonly color="maintext" :label="d" :value="t ? t : '-'"> </v-text-field>
                </v-col>
              </v-row>
            </v-card>
            <v-card id="hold" class="crd-trn ss-cards overflow-hidden mb-md-6" width="100%">
              <div class="pt-md-6 py-4 px-4 px-md-6">
                <p class="font-weight-bold title lh-16">Asset allocation and Holdings</p>

                <v-row no-glutters>
                  <v-col cols="12" md="4">
                    <p class="font-weight-bold mb-0">Fund's overall asset allocation</p>
                    <p class="fs-14 txt-x-j">Each fund is uniquely allocated to suit and match customer expectations based on the risk profile and return expectations.</p>

                    <p class="font-weight-medium fs-18 subtext--text mb-0">Fund asset allocation</p>

                    <v-card height="420px" width="100%" id="asschart" class="crd-trn rounded-lg elevation-0 px-0"> </v-card>
                  </v-col>
                  <v-col cols="12" md="4">
                    <p class="font-weight-bold mb-0">Fund's equity sector distribution</p>
                    <p class="fs-14 txt-x-j">Equity Sector refers to the allocation of the fund's investments across different sectors of the economy.</p>
                    <p class="font-weight-medium fs-18 subtext--text">Equity allocation by Sector</p>
                    <div v-for="(s, j) in menudata && menudata.sectors ? menudata.sectors.slice(0, 10) : 10" :key="j">
                      <div class="mb-5">
                        <p class="fs-14 mb-1">
                          {{ s.sectorRating ? s.sectorRating : "-" }} <span class="float-right lh-24 font-weight-bold">{{ s.netAsset ? s.netAsset : "0.00" }}%</span>
                        </p>
                        <v-progress-linear v-if="s.netAsset" color="#148564" height="6px" rounded :value="Math.round(s.netAsset)"></v-progress-linear>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" md="4">
                    <p class="font-weight-bold mb-0">Fund's top stock holdings</p>
                    <p class="fs-14 txt-x-j">The performance of the mutual fund is significantly influenced by the performance of its top stock holdings.</p>
                    <p class="font-weight-medium fs-18 subtext--text">Top Stock Holdings</p>
                    <div v-for="(s, j) in menudata && menudata.holdings ? menudata.holdings.slice(0, 10) : 10" :key="j">
                      <div class="mb-5">
                        <p class="fs-14 mb-1">
                          {{ s.holdings ? s.holdings : "-" }} <span class="float-right lh-24 font-weight-bold">{{ s.netAsset ? s.netAsset : "0.00" }}%</span>
                        </p>
                        <v-progress-linear v-if="s.netAsset" color="#7CD36E" height="6px" rounded :value="Math.round(s.netAsset)"></v-progress-linear>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-card>
            <v-card id="roll" class="crd-trn ss-cards overflow-hidden mb-md-6 py-6" width="100%">
              <v-toolbar class="elevation-0 px-md-2 px-0 crd-trn" dense>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold title mb-2">Rolling Return</v-list-item-title>
                    <v-list-item-title class="maintext--text font-weight-medium fs-12"
                      >Rolling Return breakdown of
                      {{ menudata ? menudata.fundName : "" }}
                      information</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-spacer></v-spacer>
                <v-select
                  @change="getRollingData()"
                  hide-details
                  append-icon="mdi-chevron-down"
                  v-model="roolstab"
                  :items="['1 Year', '2 Year', '3 Year', '5 Year', '7 Year', '10 Year']"
                  item-text="key"
                  item-value="val"
                  class="rounded-pill max-w-160 mr-3"
                  dense
                  flat
                  solo
                  background-color="secbg"
                  label="Filter"
                ></v-select>

                <v-menu v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{on, attrs}">
                    <v-text-field dense class="rounded-pill" flat solo background-color="secbg" v-model="rolldate" hide-details readonly append-icon="mdi-chevron-down" v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker no-title :max="new Date().toISOString().split('T')[0]" class="rounded-xl" v-model="rolldate" @input="(menu2 = false), getRollingData()"></v-date-picker>
                </v-menu>
              </v-toolbar>

              <v-card width="100%" style="height: 420px; width: 100%" id="rollchart" class="crd-trn rounded-lg pt-4 elevation-0"> </v-card>
              <v-divider class="d-md-none"></v-divider>
            </v-card>
            <v-card id="peers" class="crd-trn ss-cards overflow-hidden mb-md-6 pt-6" width="100%">
              <v-toolbar class="elevation-0 px-md-2 px-0 crd-trn" dense>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold title mb-2">Comparison with {{ menudata.category }}</v-list-item-title>
                    <v-list-item-title class="maintext--text font-weight-medium fs-12">Comparison breakdown of {{ menudata ? menudata.fundName : "" }} information</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-spacer></v-spacer>
                <v-select
                  @change="setPeersdata()"
                  hide-details
                  append-icon="mdi-chevron-down"
                  v-model="peerstab"
                  :items="['1 Year', '2 Year', '3 Year', '5 Year', '10 Year']"
                  item-text="key"
                  item-value="val"
                  class="rounded-pill max-w-160 mr-3"
                  dense
                  flat
                  solo
                  background-color="secbg"
                  label="Filter"
                ></v-select>

                <v-text-field v-model="peersearch" hide-details prepend-inner-icon="mdi-magnify" label="Search" class="rounded-pill d-none d-md-flex" dense flat solo background-color="secbg"></v-text-field>
              </v-toolbar>
              <v-data-table mobile-breakpoint must-sort :sort-by="['10Year']" :sort-desc="[true]" hide-default-footer fixed-header class="rounded-0 overflow-y-auto mt-4" :headers="peerheader" height="334px" width="800px" :search="peersearch" :items="peeritem" :items-per-page="peeritem.length">
                <template v-slot:[`item.name`]="{item}">
                  <td class="pl-md-6 pl-4 font-weight-medium maintext--text text-capitalize">
                    {{ item.name }}
                  </td>
                </template>
                <template v-slot:[`item.aum`]="{item}">
                  <td class="text-right">
                    {{ item.aum ? Number(item.aum).toFixed(2) : "0.00" }}
                  </td>
                </template>
                <template v-slot:[`item.10Year`]="{item}">
                  <td class="text-right">
                    {{ item[peerstab.replace(" ", "")] ? Number(item[peerstab.replace(" ", "")]).toFixed(2) : "0.00" }}
                  </td>
                </template>
                <template v-slot:[`item.fundRat`]="{item}">
                  <p v-if="item.fundRat" class="mb-0 lh-16"><v-icon color="#F6CE47" v-for="(n, l) in Number(item.fundRat)" :key="l">mdi-star</v-icon> <v-icon color="secbg" v-for="s in 5 - Number(item.fundRat)" :key="s + Number(item.fundRat) + 1">mdi-star</v-icon></p>
                </template>
                <template v-slot:no-data>
                  <v-col cols="12" class="text-center pa-16">
                    <div class="mx-auto">
                      <img class="align-self-stretch mx-auto" width="80px" :src="require('@/assets/no data folder.svg')" alt="no data" />
                      <h5 class="txt-999 font-weight-regular">There is no Peers comparison data here yet!</h5>
                    </div>
                  </v-col>
                </template>
              </v-data-table>
              <v-divider class="d-md-none"></v-divider>
            </v-card>
          </div>
        </div>
        <v-overlay color="mainbg" :value="mainloader" opacity="0.1" class="rounded-0"> </v-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import apiurl from "../../../apiurl.js";
import eventBus from "../../../eventBus.js";
import {getMFsheetdata, getMFNAVchart, getMFSchemePeers, getMFsheetchart, getMFrollschart} from "@/components/mixins/getAPIdata.js";

export default {
  data: () => ({
    uid: null,
    mtoken: null,
    stoken: null,

    mainloader: true,
    bodytab: 0,
    valid: true,
    isni: null,
    menu2: false,
    mfnavchart: [],

    dashitems: [{txt: "Overview"}, {txt: "Performance"}, {txt: "Scheme"}, {txt: "Allocation"}, {txt: "Rollings"}, {txt: "Comparison"}],
    menudata: {},

    volatilityfield: {
      Alpha: "",
      "Sharp Ratio": "",
      Mean: "",
      Beta: "",
      "Std. Deviation": "",
      YTM: "",
      "Modified Duration": "",
      "Average Maturity": "",
      "Face Value": "",
    },

    schemefield: {
      Launched: "",
      "SIP Minimum": "",
      "Corpus (Cr.)": "",
      "Expense Ratio": "",
      "Lumpsum Min.": "",
      "AMU (Cr.)": "",
      "Settlement type": "",
      "Lock-in": "",
      "Scheme type": "",
    },

    peerstab: "10 Year",
    peersearch: null,
    peeritem: [],

    pricecompar: [],

    imageicon: "",

    roolstab: "1 Year",
    rollingitem: [],
    rolldate: "1970-01-01",

    schemereturns: [],
    benchreturns: [],
  }),
  computed: {
    peerheader() {
      return [
        {text: "Scheme", value: "name", sortable: false, class: "peer-w-head pl-md-6 pl-4"},
        {text: "AUM (Cr)", value: "aum", sortable: false, align: "right", class: "d-none d-md-table-cell"},
        {text: "10 Year %", value: "10Year", sortable: false, align: "right", class: "ws-p d-none d-md-table-cell"},
        {text: "Rating", value: "fundRat", sortable: false, align: "right", class: "ws-p d-none d-md-table-cell"},
      ];
    },
    mfholdheader() {
      return [
        {text: "Funds", value: "mutual_fund", sortable: false, class: "mf-w-head pl-md-6 pl-4"},
        {text: "Mkt. cap held%", value: "market_cap_Held", sortable: false, align: "right", class: "ws-p d-none d-md-table-cell"},
        {text: "AUM", value: "mf_aum", sortable: false, align: "right", class: "d-none d-md-table-cell"},
        {text: "Weight%", value: "mf_holding_percent", sortable: false, align: "right", class: "pr-md-6 pr-4 d-none d-md-table-cell"},
        {text: "Trend", value: "mftrend", sortable: false, align: "right", class: "pr-4 d-md-none"},
        // { text: 'Mkt. value (cr)', value: 'market_value', sortable: false, align: 'right', class: 'pr-6 ws-p' },
      ];
    },
  },
  async created() {
    let params = this.$route.params;
    this.getMenuData(params);
  },
  mounted() {
    eventBus.$emit("tabBar-load");

    eventBus.$emit("login-event");
    eventBus.$on("user-event", () => {
      let res = sessionStorage.getItem("c3RhdHVz");
      if (res == "dmFsaWR1c2Vy") {
        if (!this.uid && !this.stoken) {
          this.mtoken = sessionStorage.getItem("msession");
          this.stoken = sessionStorage.getItem("usession");
          this.uid = sessionStorage.getItem("userid");
        }
      }
    });

    eventBus.$on("ssdmf-event", (params) => {
      if (this.isni != params.ISIN) {
        this.mainloader = true;
        this.menudata = [];
        this.mfnavchart = [];
        this.volatilityfield = {
          Alpha: "",
          "Sharp Ratio": "",
          Mean: "",
          Beta: "",
          "Std. Deviation": "",
          YTM: "",
          "Modified Duration": "",
          "Average Maturity": "",
          "Face Value": "",
        };
        this.schemefield = {
          Launched: "",
          "SIP Minimum": "",
          "Corpus (Cr.)": "",
          "Expense Ratio": "",
          "Lumpsum Min.": "",
          "AMU (Cr.)": "",
          "Settlement type": "",
          "Lock-in": "",
          "Scheme type": "",
        };
        this.peerstab = "10 Year";
        this.peersearch = null;
        this.peeritem = [];
        this.pricecompar = [];
        this.imageicon = "";
        this.roolstab = "1 Year";
        this.rollingitem = [];
        this.rolldate = "1970-01-01";
        this.schemereturns = [];
        this.benchreturns = [];
        this.getMenuData(params);
      }
    });
    // var styleElement = document.createElement("style");
    // styleElement.appendChild(document.createTextNode("::-webkit-scrollbar {width: 0px; height:0px}"));
    // document.getElementsByTagName("head")[0].appendChild(styleElement);
  },
  beforeDestroy() {
    eventBus.$off("ssdmf-event");
  },
  methods: {
    async getMenuData(params) {
      if (params) {
        var x = new Date().getFullYear() - 1;
        this.rolldate = new Date(x.toString()).toISOString().split("T")[0];

        this.isni = params.ISIN;
        this.menudata = await getMFsheetdata(params.ISIN);
        if (this.menudata && this.menudata.stat == "Ok") {
          this.mfnavchart = await getMFNAVchart(params.ISIN, "1990-01-01", new Date().toISOString().split("T")[0]);
          this.menudata = this.menudata.data;
          this.menudata["params"] = params;
          this.menudata.navDate = new Date(this.menudata.navDate).toLocaleDateString("en-US", {month: "short", day: "numeric"});
          this.setSingleSheet(this.menudata, this.mfnavchart, this.peeritem);
          this.imageicon = `${apiurl.mfapi}static/images/mf/${params.AMC_Code}.png`;
          this.menudata["manageris"] = `${apiurl.mfapi}static/images/manager/${this.menudata.fundManager.replace(" ", "").toLowerCase()}.png`;

          this.setPeersdata();

          this.mainloader = false;
        } else {
          eventBus.$emit("snack-event", 0, this.menudata.data ? this.menudata.data : this.menudata.data);

          this.$router.push(`/mutualfund`);
        }
      } else {
        this.$router.push(`/mutualfund`);
      }
    },
    imageLoadError() {
      this.imageicon = null;
    },
    async setSingleSheet(o, t) {
      this.volatilityfield = {
        Alpha: o.alpha,
        "Sharp Ratio": o.sharpRatio,
        Mean: o.mean,
        Beta: o.beta,
        "Std. Deviation": o.standardDev,
        YTM: o.ytm,
        "Modified Duration": o.modifiedDuration,
        "Average Maturity": o.avgMat,
        "Face Value": this.menudata.params.Face_Value,
      };

      this.schemefield = {
        Launched: this.menudata.params.Launch_Date,
        "SIP Minimum": o.sipMinAmount,
        "Corpus (Cr.)": this.menudata.corpus,
        "Expense Ratio": this.menudata.expenseRatio,
        "Lumpsum Min.": o.purchaseMinAmount,
        "AMU (Cr.)": `${(Number(this.menudata.params.AUM) / 10000000).toFixed(2)}`,
        "Settlement type": this.menudata.SETTLEMENT_TYPE,
        "Lock-in": this.menudata.params.Lock_in_Period,
        "Scheme type": this.menudata.params.NAV_Scheme_Type,
      };

      let ass = [
        {value: o.vEquity && o.vEquity > 0 ? o.vEquity : 0, name: "Equity"},
        {value: o.vDebt && o.vDebt > 0 ? o.vDebt : 0, name: "Debt"},
        {value: o.goldPercent && o.goldPercent > 0 ? o.goldPercent : 0, name: "Gold"},
        {value: o.globalEquityPercent && o.globalEquityPercent > 0 ? o.globalEquityPercent : 0, name: "Global Equity"},
        {value: o.vOther && o.vOther > 0 ? o.vOther : 0, name: "Others"},
      ];
      this.setAssetchart(ass);

      if (t && t.stat == "Ok") {
        var key = [];
        var data = [];

        for (let b = 0; b < t.data.length; b++) {
          key.push(new Date(t.data[b].navDate).toLocaleDateString("en-US", {day: "numeric", month: "short", year: "2-digit"}));
          data.push(t.data[b].nav ? Number(t.data[b].nav).toFixed(2) : null);
        }
        this.setNavchart(key, data);
      }

      var c = await getMFsheetchart(this.isni);
      if (c && c.stat == "Ok") {
        var set1 = [];
        var set2 = [];
        var key1 = [];

        for (let b = 0; b < c.data.length; b++) {
          key1.push(new Date(c.data[b].navDate).toLocaleDateString("en-US", {day: "numeric", month: "short", year: "numeric"}));
          set1.push(c.data[b].schReturns ? Number(c.data[b].schReturns).toFixed(2) : null);
          set2.push(c.data[b].benchmarkReturns ? Number(c.data[b].benchmarkReturns).toFixed(2) : null);
        }
        this.setCumPerchart(key1, set1, set2);
      }

      this.schemereturns = [
        // {returns: o["30Day"], type: "1 Month"},
        {returns: o["3Month"], type: "3 Months"},
        {returns: o["6Month"], type: "6 Months"},
        {returns: o["1Year"], type: "1 Year"},
        {returns: o["3Year"], type: "3 Years"},
        {returns: o["5Year"], type: "5 Years"},
        // {returns: o["7Year"], type: "7 Years"},
        {returns: o["10Year"], type: "10 Years"},
      ];
      this.benchreturns = [
        // {returns: o.benchmarkTrailingReturn["30DayBenchMarkReturn"], type: "1 Month"},
        {returns: o.benchmarkTrailingReturn["3MonthBenchMarkReturn"], type: "3 Months"},
        {returns: o.benchmarkTrailingReturn["6MonthBenchMarkReturn"], type: "6 Months"},
        {returns: o.benchmarkTrailingReturn["1YearBenchMarkReturn"], type: "1 Year"},
        {returns: o.benchmarkTrailingReturn["3YearBenchMarkReturn"], type: "3 Years"},
        {returns: o.benchmarkTrailingReturn["5YearBenchMarkReturn"], type: "5 Years"},
        // {returns: o.benchmarkTrailingReturn["7YearBenchMarkReturn"], type: "7 Years"},
        {returns: o.benchmarkTrailingReturn["10YearBenchMarkReturn"], type: "10 Years"},
      ];
    },
    async getRollingData() {
      var q = await getMFrollschart(`[${this.rollingitem}]`, this.rolldate, this.roolstab.replace(" Year", ""));
      if (q && q.stat == "Ok") {
        var rollings = {key: [], data: []};
        var colors = ["#148564", "#7CD36E", "#F9CD6C", "#FDEBC4", "#DEDEDE"];
        rollings.key = q.data.map((o) => o.finalDate);
        for (let x = 0; x < this.rollingitem.length; x++) {
          rollings.data.push({
            name: q.data[0][`${x}schemeName`],
            type: "line",
            symbol: "none",
            sampling: "lttb",
            data: q.data.map((o) => (o[`ret${x}`] ? o[`ret${x}`].toFixed(2) : null)),
            color: colors[x],
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: colors[x] + "30",
                },
                {
                  offset: 1,
                  color: colors[x] + "05",
                },
              ]),
            },
          });
        }
        this.setRollchart(rollings.key, rollings.data);
      }
    },
    async setPeersdata() {
      this.peeritem = [];
      var r = await getMFSchemePeers(this.isni, this.peerstab.replace(" ", ""));
      if (r && r.stat == "Ok") {
        this.peerheader[2].text = `${this.peerstab} %`;
        this.peerheader[2].value = this.peerstab.replace(" ", "");
        this.peeritem = r.topSchemes;
        this.rollingitem = [];
        for (var i = 0; i < r.topSchemes.length; i++) {
          this.rollingitem.push(`"${r.topSchemes[i].ISIN}"`);
        }
        this.rollingitem = this.rollingitem.slice(0, 4);
        this.rollingitem.unshift(`"${this.isni}"`);
        this.getRollingData();
      }
    },
    setNavchart(a, b) {
      var myChart = echarts.init(document.getElementById("navchart"));

      var option = {
        grid: {
          left: 24,
          right: 32,
          top: 32,
          bottom: 24,
        },
        tooltip: {
          trigger: "axis",
        },
        toolbox: {
          show: true,
          orient: "vertical",
          left: "right",
          top: "center",
        },
        xAxis: {
          type: "category",
          data: a,
        },
        yAxis: {
          type: "value",
          position: "right",
          splitLine: {
            show: false,
          },
          axisLabel: {
            formatter: (val) => (val >= 1000 ? `${val / 1000} K` : val),
          },
        },

        series: [
          {
            // name: "Fake Data",
            type: "line",
            symbol: "none",
            sampling: "lttb",
            itemStyle: {
              color: "#1e53e5",
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#1e53e5" + "30",
                },
                {
                  offset: 1,
                  color: "#1e53e5" + "05",
                },
              ]),
            },
            markLine: {
              silent: true,
              symbol: "none",
              data: [
                {
                  yAxis: Number(this.menudata.currentNAV),
                  label: {
                    show: true,
                  },
                },
              ],
            },
            data: b,
          },
        ],
      };

      option && myChart.setOption(option);

      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    setCumPerchart(a, b, c) {
      var myChart = echarts.init(document.getElementById("cumchart"));

      var option = {
        grid: {
          left: 52,
          right: 24,
          y: "4%",
          bottom: 80,
        },
        tooltip: {
          trigger: "axis",
        },
        toolbox: {
          show: true,
          orient: "vertical",
          left: "right",
          top: "center",
        },
        legend: {
          bottom: 16,
        },
        xAxis: {
          type: "category",
          // boundaryGap: false,
          data: a,
        },
        yAxis: {
          type: "value",
          axisLabel: {
            formatter: (val) => `${val / 1000} K`,
          },
        },
        // dataZoom: [
        //   {
        //     type: "inside",
        //     realtime: true,
        //     start: 0,
        //     end: 100,
        //     xAxisIndex: [0, 1],
        //   },
        // ],
        series: [
          {
            name: "Scheme Returns",
            type: "line",
            symbol: "none",
            sampling: "lttb",
            itemStyle: {
              color: "#1e53e5",
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#1e53e5" + "30",
                },
                {
                  offset: 1,
                  color: "#1e53e5" + "05",
                },
              ]),
            },
            data: b,
          },
          {
            name: "Benchmark Returns",
            type: "line",
            symbol: "none",
            sampling: "lttb",
            itemStyle: {
              color: "#D86F10",
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#D86F10" + "30",
                },
                {
                  offset: 1,
                  color: "#D86F10" + "05",
                },
              ]),
            },
            data: c,
          },
        ],
      };

      option && myChart.setOption(option);

      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    setAssetchart(data) {
      var myChart = echarts.init(document.getElementById("asschart"));

      var option = {
        grid: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 8,
        },
        legend: {
          bottom: -4,
        },
        tooltip: {
          trigger: "item",
        },

        series: [
          {
            name: "Asset allocation",
            type: "pie",
            radius: ["58%", "88%"],
            label: {
              show: false,
            },
            color: ["#148564", "#7CD36E", "#F9CD6C", "#FDEBC4", "#DEDEDE"],
            data: data,
          },
        ],
      };

      option && myChart.setOption(option);

      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    setRollchart(a, b) {
      var myChart = echarts.init(document.getElementById("rollchart"));

      var option = {
        grid: {
          left: 60,
          right: 24,
          y: "4%",
          bottom: 100,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          // data: this.pricecompar.script,
          orient: "horizontal",
          bottom: 0,
          top: "bottom",
        },
        toolbox: {
          show: true,
          orient: "vertical",
          left: "right",
          top: "center",
        },
        xAxis: {
          type: "category",
          // boundaryGap: false,
          data: a,
        },
        yAxis: {
          type: "value",
          axisLabel: {
            formatter: "{value} %",
          },
          // boundaryGap: [0, '100%']
        },
        // dataZoom: [
        //   {
        //     type: "inside",
        //     realtime: true,
        //     start: 0,
        //     end: 100,
        //     xAxisIndex: [0, 1],
        //   },
        // ],
        series: b,
      };

      option && myChart.setOption(option);

      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    setbodyTab() {
      var element;
      if (this.bodytab == 0) {
        element = document.getElementById("overview");
      } else if (this.bodytab == 1) {
        element = document.getElementById("fin");
      } else if (this.bodytab == 2) {
        element = document.getElementById("info");
      } else if (this.bodytab == 3) {
        element = document.getElementById("hold");
      } else if (this.bodytab == 4) {
        element = document.getElementById("roll");
      } else if (this.bodytab == 5) {
        element = document.getElementById("peers");
      }
      element.scrollIntoView({block: "start", behavior: "smooth", inline: "start"});
    },
    handleScroll(e) {
      var scrollPos = e.target.scrollTop;
      var fin = document.getElementById("fin").offsetTop - 216;
      var info = document.getElementById("info").offsetTop - 216;
      var hold = document.getElementById("hold").offsetTop - 216;
      var roll = document.getElementById("roll").offsetTop - 216;
      var peers = document.getElementById("peers").offsetTop - 216;
      // var event = document.getElementById("event").offsetTop - 216;
      // var news = document.getElementById("news").offsetTop - 216;

      if (scrollPos >= 0 && scrollPos < fin) {
        this.bodytab = 0;
      } else if (scrollPos > fin && scrollPos < info) {
        this.bodytab = 1;
      } else if (scrollPos > info && scrollPos < hold) {
        this.bodytab = 2;
      } else if (scrollPos > hold && scrollPos < roll) {
        this.bodytab = 3;
      } else if (scrollPos > roll && scrollPos < peers) {
        this.bodytab = 4;
      } else if (scrollPos > peers) {
        this.bodytab = 5;
      }
    },
    putMForder(value) {
      this.$emit("menudialog", "mforder", value, this.menudata.params);
    },
  },
};
</script>
