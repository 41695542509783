<template>
  <div class="pt-16">
    <v-row no-glutters>
      <v-col cols="3">
        <v-card class="rounded-0 elevation-0" color="transparent" height="40px">
          <v-row>
            <v-col cols="6" class="pr-0"><v-card @click="prc = 'MKT'" :color="prc == 'MKT' ? 'maintext' : 'secbg'"
                :class="prc == 'MKT' ? 'mainbg--text' : 'maintext--text'"
                class="elevation-0 fs-12 font-weight-bold rounded-pill rounded-r-0 justify-center d-flex align-center"
                height="40px">MKT</v-card></v-col>
            <v-col cols="6" class="pl-0"><v-card @click="prc = 'LMT'" :color="prc == 'LMT' ? 'maintext' : 'secbg'"
                :class="prc == 'LMT' ? 'mainbg--text' : 'maintext--text'"
                class="elevation-0 fs-12 font-weight-bold rounded-pill rounded-l-0 justify-center d-flex align-center"
                height="40px">LMT</v-card></v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="9">
        <v-text-field :readonly="prc == 'MKT' || prc == 'SL-MKT'" height="40px" hide-details dense
          background-color="secbg" flat block class="rounded-pill" solo type="number" hide-spin-buttons
          v-model="ordprice">
          <template #append>
            <svg v-if="prc == 'MKT' || prc == 'SL-MKT'" xmlns="http://www.w3.org/2000/svg" width="14" height="14"
              viewBox="0 0 14 14" fill="none">
              <path
                d="M10.0625 4.8125H9.1875V3.0625C9.1875 1.85456 8.20794 0.875 7 0.875C5.79206 0.875 4.8125 1.85456 4.8125 3.0625V4.8125H3.9375V3.0625C3.9375 1.37112 5.30862 0 7 0C8.69137 0 10.0625 1.37112 10.0625 3.0625V4.8125Z"
                fill="#999999" />
              <path
                d="M11.5938 5.6875H2.40625C1.80206 5.6875 1.3125 6.17706 1.3125 6.78125V12.9062C1.3125 13.5104 1.80206 14 2.40625 14H11.5938C12.1979 14 12.6875 13.5104 12.6875 12.9062V6.78125C12.6875 6.17706 12.1979 5.6875 11.5938 5.6875ZM7.4375 10.8754V11.8125C7.4375 12.054 7.2415 12.25 7 12.25C6.7585 12.25 6.5625 12.054 6.5625 11.8125V10.8754C5.62669 10.6339 5.06406 9.67925 5.30556 8.74344C5.54706 7.80763 6.50169 7.245 7.4375 7.4865C8.37331 7.728 8.93594 8.68263 8.69444 9.61844C8.53519 10.2349 8.05394 10.7161 7.4375 10.8754Z"
                fill="#999999" />
            </svg>
          </template>

          <template #prepend-inner>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
                fill="white" />
              <path
                d="M15.4285 9.25426L15.0805 10.4574H8.58762L8.93066 9.25426H15.4285ZM12.3014 17L8.71689 12.6399L8.70197 11.7102H10.4818C10.9326 11.7102 11.3137 11.6423 11.6253 11.5064C11.9401 11.3705 12.1788 11.1716 12.3412 10.9098C12.5069 10.648 12.5898 10.3232 12.5898 9.93537C12.5898 9.36198 12.4191 8.90956 12.0777 8.57812C11.7363 8.24337 11.2043 8.07599 10.4818 8.07599H8.58762L8.94061 6.81818H10.4818C11.3005 6.81818 11.9733 6.95076 12.5003 7.21591C13.0306 7.47775 13.425 7.84067 13.6835 8.30469C13.942 8.7687 14.0713 9.29901 14.0713 9.8956C14.0713 10.4325 13.9603 10.9214 13.7382 11.3622C13.5194 11.7997 13.1698 12.156 12.6892 12.4311C12.2086 12.7029 11.5756 12.8537 10.79 12.8835L10.7453 12.8935L14.0365 16.9155V17H12.3014ZM15.4285 6.81818L15.0805 8.03125L9.91504 7.99645L10.2631 6.81818H15.4285Z"
                fill="#999999" />
            </svg>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4"> <v-text-field v-model="ids" label="Client id" outlined></v-text-field></v-col>
      <v-col cols="8"> <v-text-field v-model="session" label="session" outlined></v-text-field></v-col>
    </v-row>
    <v-btn color="primary" :disabled="!ids || !session" @click="setcheckPos(0)"> fetch </v-btn>
    <div class="pt-6">
      <v-row v-for="(q, w, e) in resis" :key="e">
        <v-col cols="2"> {{ w }} ({{ q && q.length > 0 ? q.length : 0 }})</v-col>
        <v-col cols="10" @click="setCopy(q)">{{ q }} </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    ids: null,
    session: null,
    resis: {
      NorenWClient: {},
      NorenWClientTV: {},
      NorenWClientTP: {},
      NorenWClientWeb: {},


      orderpop: true,
      wllayout: false,
      ordsrcpop: false,
      bodytab: "Stocks",
      riskdialog: false,

      mobilemenu: false,
      dashitems: [
        { txt: "Stocks", path: "/stocks", idx: 0 },
        // {txt: "F&O", path: "/fno", idx: 1},
        { txt: "Mutual Fund", path: "/mutualfund", idx: 1 },
        { txt: "IPOs", path: "/ipo", idx: 2 },
        { txt: "Bonds", path: "/bonds", idx: 3 },
        { txt: "Collection", path: "/collection", idx: 4, type: "Beta" },
        // { txt: 'details', path: '/stocks/details', idx: 4 },
      ],

      mdashitems: [
        "Stocks",
        // "F&O",
        "Mutual Fund",
        "IPOs",
        "Bonds",
        "Collection",
      ],

      apptab: 0,
      sidetab: null,

      valid: true,
      mobile: "",
      numberis: [(v) => !!v || "Your mobile number is required", (v) => /^[6-9][0-9]{9}$/.test(v) || "mobile number must be 10 Digit Number"],

      uid: null,
      token: null,
      mtoken: null,

      snackbar: false,
      snackcolor: "#121212",
      snacktxt: "",
      subloader: false,
      mainloader: true,

      // stock order
      buyrsell: false,
      orderexch: "bse",
      investype: "I",
      prcitems: [
        { txt: "Limit", val: "LMT" },
        { txt: "Market", val: "MKT" },
        { txt: "SL Limit", val: "SL-LMT" },
        { txt: "SL Mkt", val: "SL-MKT" },
      ],
      prc_c_items: [
        { txt: "Limit", val: "LMT" },
        { txt: "Market", val: "MKT" },
        { txt: "SL Limit", val: "SL-LMT" },
      ],
      alertitems: ["LTP", "Perc. change", "ATP", "OI", "TOI", "52HIGH", "VOLUME"],
      alertis: "LTP",
      condition: ">",
      alertremarks: "",
      alertvalue: 0,

      orderloader: false,
      prc: "LMT",
      ocoprc: "LMT",
      mktprdordpec: 5,
      ordqty: 0,
      ordgttqty: 0,
      orddisqty: 0,
      ordlegqty: 0,
      ordprice: 0,
      ordgttprice: 0,
      ordtrgprice: 0,
      ordtagprice: 0,
      ordslprice: 0,
      ordgtttrgprice: 0,
      ordvalqtyprice: "DAY",
    addvalqty: false,
    addmktord: false,
    gttagree: false,
    orderselect: ["Regular", "Cover", "Bracket", "GTT", "SIP"],
    // 'AMO', 'Iceberg', 'GTT'
    ordertype: 3,

    gttBSM: "new",
    gttalertitemso: ["LTP", "Perc. change", "ATP", "OI", "TOI", "52HIGH", "VOLUME"],
    gttalertitemst: ["LTP", "Perc. change", "ATP", "OI", "TOI", "52LOW"],

    gttconitem: [">", "<"],
    gttcond: ">",
    gttocopanel: false,
    gttplacebtn: false,
    gttalert: "LTP",
    ocoalert: "LTP",
    gttvalue: 0,
    ocovalue: 0,
    gttremark: null,
    ocoremark: null,
    gttqty: 1,
    ocoqty: 1,
    gttprice: null,
    ocoprice: null,
    gtttrgprice: null,
    ocotrgprice: null,
    //

    // Mutual fund
    mf_mandates: [],
    mf_frequencys: [],
    mf_dates: false,
    mf_frequency: null,
    mf_mandate: "",
    mf_date: "",
    mf_invest_amt: 1000,
    mf_initial_amt: 1000,
    mf_instal_amt: 1000,
    mf_tenure: false,
    mf_sipinit: false,
    mf_noof_instal: 0,
    mf_redqty: 0,

    mfpayinmode: null,
    mfpayinmodes: [
      { key: "UPI", val: "UPI" },
      { key: "NET BANKING", val: "Net banking" },
      // {key: "NIFT", val: "NIFT"},
    ],
    mfpainids: null,
    mf_accact: null,
    mfupivalid: true,

    orderpoploader: false,

    creatManmfamt: 1000,
    creatManmffrommodel: "",
    creatManmffrommenu: false,

    creatManmftomodel: "",
    creatManmftomenu: false,
    creatManmRemarks: "",
    // creatManmffrom: '',
    // creatManmfto: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    //

    bondqty: null,

    showalertmsg: null,
    showalert: false,
    alertdialog: false,
    orderdialog: false,
    mforderdialog: false,
    bondorderdialog: false,
    iposorderdialog: false,

    chartitems: [{ txt: "1M" }, { txt: "3M" }, { txt: "6M" }, { txt: "1YR" }, { txt: "3YR" }, { txt: "5YR" }, { txt: "MAX" }],
    chartdate: 0,
    Fundamentalsfield: [],

    iposbids: [],

    menudata: {},
    alertloader: false,
    iposupivalid: true,
    iposcheckbox: false,
    iposupiid: null,
    iposupiDomain: null,

    upiidfield: false,

    wsstocksdata: [],

    messages: [],
    wsorderalertdata: [],

    masteron: true,
    snackalerts: [],
    },
  }),

  methods: {
    setCheck() {
      // console.log("setCheck");

      let config = {
        method: "post",
        url: `https://go.mynt.in/NorenWClientWeb/ValidateHsToken?LoginId=${this.ids.toUpperCase()}&token=7N2mh4VSRt3j0k4Ux3tQMqfv`,
        headers: {
          Cookie: "NWC_ID=66b0fe6554e955199672ffebe90c2e78467be9c1a0a550356be5f3166c328f14",
        },
      };

      let axiosThis = this;
      axios
        .request(config)
        .then((response) => {
          axiosThis.resis = response;
        })
        .catch((error) => {
          axiosThis.resis = error;
          console.log(error);
        });
    },
    setcheckPos(f) {
      let data = `jData={"uid":"${this.ids.toUpperCase()}","actid":"${this.ids.toUpperCase()}"}&jKey=${this.session}`;

      var point = f == 0 ? "NorenWClient" : f == 1 ? "NorenWClientTV" : f == 2 ? "NorenWClientTP" : f == 3 ? "NorenWClientWeb" : null;
      // NorenWClient
      // NorenWClientTV
      // NorenWClientTP
      // NorenWClientWeb
      if (f <= 3) {
        let config = {
          method: "post",
          url: `https://go.mynt.in/${point}/PositionBook`,
          data: data,
        };

        let axiosThis = this;
        axios
          .request(config)
          .then((response) => {
            axiosThis.resis[`${point}`] = response.data;
            axiosThis.setcheckPos(f + 1);
          })
          .catch((error) => {
            axiosThis.resis[`${point}`] = {...error.response.data};
            axiosThis.setcheckPos(f + 1);
            axiosThis.resis = {...axiosThis.resis};
          });
      }
    },
    setCopy(textToCopy) {
      navigator.clipboard
        .writeText(JSON.stringify(textToCopy))
        .then(() => {
          alert("Text copied to clipboard!");
        })
        .catch((err) => {
          console.error("Failed to copy text:", err);
        });
    },
  },
};
</script>
