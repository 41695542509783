<template>
  <div>
    <div class="overflow-y-auto h91vh ss-cust-scroll overflow-x-hidden" @scroll.passive="handleScroll">
      <div class="body-part">
        <div class="mb-6 pos-rlt default-tab">
          <v-toolbar class="d-none d-md-block px-0 pos-stk z-i3" style="top: 0px" flat dense color="cardbg">
            <v-tabs @change="setbodyTab" fixed color="maintext" v-model="bodytab" show-arrows dense>
              <v-tab dense v-for="(t, index) in dashitems" :key="index" class="text-none text-start min-w-fit">
                <span class="text-center tab-txt">{{ t.txt }}</span></v-tab
              >
              <v-tabs-slider color="maintext"></v-tabs-slider>
            </v-tabs>
            <v-spacer></v-spacer>
            <v-btn :loading="mainloader" @click="uid ? setPlacecoll() : $emit('gologin')" color="btnclr" class="elevation-0 text-capitalize rounded-pill btntext--text">Invest {{ uid ? (menudata.price ? `₹${Number(menudata.price).toFixed(2)}` : "0.00") : "" }} </v-btn>
          </v-toolbar>
          <v-divider class="mb-md-4 mb-3 d-none d-md-flex pos-stk z-i3" style="top: 48px"></v-divider>
          <div>
            <v-card :loading="mainloader" id="overview" class="crd-trn ss-cards overflow-hidden mb-md-6" width="100%">
              <v-card color="secbg" class="elevation-0 rounded-0 pt-2 px-md-2 px-3">
                <v-row no-glutters>
                  <v-col cols="12" md="9" class="pb-1 pb-md-2">
                    <v-list-item class="px-0 px-md-4">
                      <v-list-item-avatar :color="isin >= 9 ? letters[isin % 10] : letters[isin]" size="56" class="my-0">
                        <img v-if="imageicon" :src="imageicon" @error="imageLoadError" :alt="imageicon" width="100%" />
                        <span class="headline font-weight-bold white--text" v-else>{{ menudata.basket_title ? menudata.basket_title.slice(0, 1) : "-" }}</span>
                      </v-list-item-avatar>
                      <v-list-item-content class="pt-3 pb-0">
                        <v-list-item-title :class="menudata ? 'maintext--text' : 'txt-trn'" class="fs-18 font-weight-bold mb-0 text-capitalize lh-14"> {{ menudata ? menudata.basket_title : "abcd" }}</v-list-item-title>
                        <v-chip-group v-if="menudata.tags" class="my-0 py-0">
                          <v-chip v-for="(a, b) in menudata.tags" :key="b" label small text-color="subtext" color="cardbg" class="font-weight-medium fs-10">
                            {{ a }}
                          </v-chip>
                          <v-chip label small text-color="subtext" color="cardbg" class="font-weight-medium fs-10">
                            {{ menudata.access }}
                          </v-chip>
                        </v-chip-group>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" md="3" class="text-md-right text-left pt-0 pt-md-5 pb-2 pl-4 pl-md-3">
                    <v-list-item class="px-0 px-md-4">
                      <v-list-item-content class="pt-3">
                        <v-list-item-subtitle class="fs-12 subtext--text text-uppercase">Min. invest</v-list-item-subtitle>
                        <v-list-item-title class="fs-14 maintext--text font-weight-bold">{{ menudata.price ? Number(menudata.price).toFixed(2) : "0.00" }} <v-icon :disabled="ltploader" @click="setUpdateltp()" size="16">mdi-refresh</v-icon> </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-content class="pt-3">
                        <v-list-item-subtitle class="fs-12 subtext--text text-uppercase">3yr CAGR</v-list-item-subtitle>
                        <v-list-item-title class="fs-14 maintext--text font-weight-bold" v-if="menudata.cagr" :class="menudata.cagr.cagr_3year > 0 ? 'maingreen--text' : menudata.cagr.cagr_3year < 0 ? 'mainred--text' : 'subtext--text'"
                          ><span :class="!uid ? 'blur-text' : ''">{{ menudata.cagr ? menudata.cagr.cagr_3year : "0.00" }}%</span></v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
              </v-card>

              <div class="px-md-6 px-4 pt-4 pt-md-8">
                <p class="fs-14 subtext--text txt-x-j mb-0">{{ menudata.mang_sht_con ? menudata.mang_sht_con : "-" }}</p>

                <v-row no-glutters class="mt-3 mb-2">
                  <v-col cols="3" class="pb-0">
                    <v-text-field class="funda-field mb-0" readonly color="maintext" label="Launched Date" :value="menudata.rebal_freq ? new Date(menudata.date_time).toLocaleDateString() : '---'"> </v-text-field>
                  </v-col>
                  <v-col cols="3" class="pb-0">
                    <v-text-field class="funda-field mb-0" readonly color="maintext" label="Rebalance Frequency" :value="menudata.rebal_freq ? menudata.rebal_freq : '---'"> </v-text-field>
                  </v-col>
                  <v-col cols="3" class="pb-0">
                    <v-text-field class="funda-field mb-0" readonly color="maintext" label="Last Rebalance" :value="menudata.last_rebal ? menudata.last_rebal : '---'"> </v-text-field>
                  </v-col>
                  <v-col cols="3" class="pb-0">
                    <v-text-field class="funda-field mb-0" readonly color="maintext" label="Next Rebalance" :value="menudata.next_rebal ? menudata.next_rebal : '---'"> </v-text-field>
                  </v-col>
                </v-row>
                <!-- <v-divider class="mt-6 mb-3 d-none d-md-flex"></v-divider> -->
              </div>
            </v-card>

            <v-card id="perf" class="crd-trn ss-cards overflow-hidden mb-md-6" width="100%">
              <div class="pt-md-6 pt-4 px-4 px-md-6">
                <v-list-item class="px-0">
                  <v-list-item-content class="pt-0">
                    <v-list-item-title class="font-weight-bold title mb-2">Historical NAV</v-list-item-title>
                    <v-list-item-title class="maintext--text font-weight-medium fs-12">Past ETF performance</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
              <v-card height="420px" width="100%" id="mainnew" class="crd-trn rounded-lg pt-4 elevation-0"> </v-card>
              <v-divider class="d-md-none"></v-divider>

              <div class="py-6 px-4 px-md-6">
                <p class="font-weight-bold title">Fund(CAGR) returns</p>

                <v-row no-glutters>
                  <v-col cols="6" md="3" v-for="(r, l) in schemereturns ? schemereturns : 4" :key="l" class="pt-2 px-2">
                    <v-card :color="r.returns > 0 ? 'secgreen' : r.returns < 0 ? 'secred' : 'secbg'" class="rounded-lg pt-2 px-4 elevation-0 brd-1-solid-ccc" width="100%">
                      <div class="px-1">
                        <p :class="r.returns > 0 ? 'maingreen--text' : r.returns < 0 ? 'mainred--text' : 'subtext--text'" class="fs-18 font-weight-bold mb-1">
                          <span :class="!uid ? 'blur-text' : ''">{{ Number(r.returns) ? r.returns.toFixed(2) : "0.00" }}%</span>
                        </p>
                        <p class="mb-2 fs-14 text-uppercase">
                          {{ r.type ? r.type : "---" }}
                        </p>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
            </v-card>
            <v-card id="weight" class="crd-trn ss-cards overflow-hidden mb-md-6" width="100%">
              <div class="pt-md-6 py-4 px-4 px-md-6">
                <p class="font-weight-bold title lh-16">Collection Weights</p>
                <v-row no-glutters>
                  <v-col cols="12" md="8">
                    <v-expansion-panels v-model="panel" flat v-if="collnavchart.bsk">
                      <v-expansion-panel class="crd-trn" flat v-for="(s, j) in setCollweight()" :key="j">
                        <v-expansion-panel-header class="fs-16 font-weight-medium text-right"
                          >{{ s.name ? s.name : "-" }} <span class="font-weight-bold"> {{ Number(s.value) ? Number(s.value).toFixed(2) : "0.00" }}%</span></v-expansion-panel-header
                        >
                        <v-expansion-panel-content class="pb-0">
                          <v-list-item v-for="(q, w) in sectordata[s.name]" :key="w" class="px-0">
                            <v-list-item-content>
                              <v-list-item-title class="fs-13"
                                >{{ q.company_name }} <span class="font-weight-medium float-right">{{ Number(q.avg_weight_percent) ? Number(q.avg_weight_percent).toFixed(2) : '0.00' }}%</span></v-list-item-title
                              >
                            </v-list-item-content>
                          </v-list-item></v-expansion-panel-content
                        >
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>
                  <v-col cols="12" md="4">
                    <p class="font-weight-medium fs-18 subtext--text mb-0">Weightage allocation by Sector</p>
                    <v-card height="380px" width="100%" id="weightchart" class="crd-trn rounded-lg elevation-0 px-0"> </v-card>
                  </v-col>
                </v-row>
              </div>
            </v-card>
            <v-card id="allo" class="crd-trn ss-cards overflow-hidden mb-md-6" width="100%">
              <div class="pt-md-6 py-4 px-4 px-md-6">
                <p class="font-weight-bold title lh-16">Asset allocation and Holdings</p>
                <v-row no-glutters>
                  <v-col cols="12" md="4">
                    <p class="font-weight-medium fs-18 subtext--text mb-0">Fund asset allocation</p>

                    <v-card height="270px" width="100%" id="asschart" class="crd-trn rounded-lg elevation-0 px-0"> </v-card>
                  </v-col>
                  <v-col cols="12" md="4">
                    <p class="font-weight-medium fs-18 subtext--text">Equity allocation by Sector</p>
                    <div v-for="(s, j) in collnavchart && collnavchart.bsk ? collnavchart.bsk.slice(0, 5) : 5" :key="j">
                      <div class="mb-5">
                        <p class="fs-14 mb-1">
                          {{ s.name ? s.name : "-" }} <span class="float-right lh-24 font-weight-bold">{{ Number(s.value) ? Number(s.value).toFixed(2) : "0.00" }}%</span>
                        </p>
                        <v-progress-linear v-if="s.value" color="#148564" height="6px" rounded :value="Math.round(s.value)"></v-progress-linear>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" md="4">
                    <p class="font-weight-medium fs-18 subtext--text">Top Stock Holdings</p>
                    <div v-for="(s, j) in collnavchart && collnavchart.stocks ? collnavchart.stocks.slice(0, 5) : 5" :key="j">
                      <div class="mb-5">
                        <p class="fs-14 mb-1">
                          {{ s.company_name ? s.company_name : "-" }} <span class="float-right lh-24 font-weight-bold">{{ Number(s.avg_weight_percent) ? Number(s.avg_weight_percent).toFixed(2) : '0.00' }}%</span>
                        </p>
                        <v-progress-linear v-if="s.avg_weight_percent" color="#7CD36E" height="6px" rounded :value="Math.round(s.avg_weight_percent)"></v-progress-linear>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-card>
            <v-card id="abt" class="crd-trn ss-cards overflow-hidden" width="100%">
              <div class="pt-md-6 py-4 px-4 px-md-6">
                <p class="font-weight-bold title lh-16">About the {{ menudata.master_name ? menudata.master_name : "-" }}</p>

                <v-row no-glutters>
                  <v-col cols="12" md="7">
                    <p class="font-weight-bold mb-1">Collection Objective</p>
                    <p class="fs-14 mb-0 txt-x-j">{{ menudata.master_sht_cont ? menudata.master_sht_cont : "-" }}</p>
                  </v-col>
                  <v-col cols="12" md="5">
                    <p class="font-weight-bold mb-1">Collection Managers</p>
                    <v-card outlined class="rounded-lg mb-4">
                      <v-list-item class="px-0 px-md-3">
                        <v-list-item-avatar color="#fbfbfb" size="52" class="mb-auto mt-3 mr-3">
                          <img v-if="menudata && menudata.manageris" :src="menudata.manageris" :alt="menudata.master_name" width="100%" />
                        </v-list-item-avatar>
                        <v-list-item-content class="pb-4">
                          <v-list-item-title :class="menudata ? 'maintext--text' : 'txt-trn'" class="fs-18 font-weight-bold mb-1 text-capitalize lh-14"> {{ menudata ? menudata.master_name : "-" }}</v-list-item-title>
                          <v-list-item-subtitle class="fs-13 font-weight-medium mb-0 lh-14"
                            ><b>{{ menudata.experience ? menudata.experience : "N/A" }}</b> experience</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </div>
        </div>
        <v-overlay color="mainbg" :value="mainloader" opacity="0.1" class="rounded-0"> </v-overlay>
      </div>
    </div>
    <v-dialog v-model="basketdialog" hide-overlay width="580px">
      <v-card class="pb-6 px-6 overflow-hidden rounded-lg" color="cardbg">
        <v-list-item-title class="font-weight-bold title maintext--text mt-4 mb-1">Order Basket Details<v-icon @click="basketdialog = false" class="float-right" color="maintext" :disabled="orderloader">mdi-close</v-icon></v-list-item-title>
        <v-divider class="mb-2"></v-divider>

        <div>
          <p class="font-weight-regular fs-14 subtext--text mb-0">Choose tenure</p>
          <div class="d-inline-flex mb-3">
            <span class="fs-14 font-weight-bold mt-2" :class="!tenure ? 'maintext--text' : 'subtext--text'">Lumpsum</span>
            <v-switch :disable="orderloader" @change="(frequency = null), (sipinstall = null)" v-model="tenure" hide-details class="mx-5 cust" inset></v-switch>
            <span class="fs-14 font-weight-bold mt-2" :class="tenure ? 'maintext--text' : 'subtext--text'">SIP</span>
          </div>
        </div>

        <v-row class="pb-4">
          <v-col cols="4">
            <p class="font-weight-regular fs-14 subtext--text mb-2">Start date</p>
            <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{on, attrs}">
                <v-text-field :disabled="!tenure || orderloader" placeholder="YYYY-MM-DD" hide-details height="40px" dense background-color="secbg" flat class="rounded-pill mb-0" hide-spin-buttons solo v-model="sipdate" append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker :disabled="!tenure || orderloader" no-title :min="maxdate" v-model="sipdate" @input="menu2 = false"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="4">
            <p class="font-weight-regular fs-14 subtext--text mb-2">Frequency</p>
            <v-select :disabled="!tenure || orderloader" placeholder="Daily, Weekly, Monthly" hide-details v-model="frequency" :items="frequencylist" height="40px" append-icon="mdi-chevron-down" dense background-color="secbg" flat block class="rounded-pill" solo> </v-select>
          </v-col>
          <v-col cols="4">
            <p class="font-weight-regular fs-14 subtext--text mb-2">No.of SIPs</p>
            <v-text-field :disabled="!tenure || orderloader" placeholder="4" hide-details height="40px" dense background-color="secbg" flat class="rounded-pill mb-0" type="number" hide-spin-buttons solo v-model="sipinstall"> </v-text-field>
          </v-col>
        </v-row>

        <v-data-table dense must-sort :sort-by="['idx']" :sort-desc="[true]" hide-default-footer fixed-header :loading="loading" class="mt-0 rounded-lg overflow-y-auto" style="border: 1px solid var(--outline)" height="240px" :headers="orderheader" :items="this.collnavchart.stocks">
          <template v-slot:[`item.tsym`]="{item}">
            <p class="font-weight-medium maintext--text mb-0 ws-p lh-16 fs-12 mt-1">
              {{ item.tsym ? item.tsym : "" }}
              <span class="ml-1 txt-999 fs-10">{{ item.exch ? item.exch : "" }}</span>
            </p>
          </template>

          <template v-slot:[`item.weights`]="{item}">
            <span> {{ item.weights }}%</span>
          </template>
          <template v-slot:[`item.price`]="{item}">
            <span> {{ Number(item.price).toFixed(2) }}</span>
          </template>

          <template v-slot:no-data>
            <v-col cols="12" class="text-center pa-16">
              <div class="mx-auto">
                <img class="align-self-stretch mx-auto" width="80px" :src="require('@/assets/no data folder.svg')" alt="no data" />
                <h5 class="txt-999 font-weight-regular">There is no data here yet!</h5>
              </div>
            </v-col>
          </template>
        </v-data-table>
        <v-btn v-if="tenure" @click="setPlacecoll(true)" :disabled="!sip_name || !frequency || !sipdate || !sipinstall" :loading="orderloader" color="btnclr" class="elevation-0 text-capitalize rounded-pill btntext--text px-6 mt-3 float-right">Proceed to SIP</v-btn>
        <div v-else>
          <v-btn v-if="menudata && menudata.ledger" @click="setPlacecoll(true)" :loading="orderloader" color="btnclr" class="elevation-0 text-capitalize rounded-pill btntext--text px-6 mt-3 float-right">Proceed</v-btn>

          <v-card v-else color="primhover" class="rounded-lg px-4 py-2 elevation-0 mt-4">
            <p class="mb-0 primary--text fs-12">
              <v-icon size="16" color="primary">mdi-information-outline</v-icon> Insufficient balance, Add fund ₹{{ menudata.ledgerbal }}
              <router-link text class="text-none font-weight-bold" color="primary" to="/funds">Click here</router-link>
            </p>
          </v-card>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as echarts from "echarts";
import apiurl from "../../../apiurl.js";
import {mynturl} from "../../../apiurl.js";
import eventBus from "../../../eventBus.js";
import {getCollsingledata, getCollchartdata, getCollstockdata, getCollLtpdata, getBondLedger, getCollplaceorder} from "@/components/mixins/getAPIdata.js";
import moment from "moment";

export default {
  data: () => ({
    uid: null,
    mtoken: null,
    stoken: null,

    orderloader: false,
    loading: false,
    basketdialog: false,

    maxdate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    sipdate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    sip_name: null,
    frequencylist: ["Daily", "Weekly", "Fortnightly", "Monthly"],
    frequency: null,
    sipinstall: null,
    tenure: false,

    menu2: false,

    mainloader: true,
    bodytab: 0,
    panel: 0,
    collnavchart: [],
    sectordata: {},
    schemereturns: [],

    isin: 0,
    letters: ["#F44336", "#E91E63", "#9C27B0", "#673AB7", "#3F51B5", "#2196F3", "#03A9F4", "#00BCD4", "#009688", "#FFC107"],

    dashitems: [{txt: "Overview"}, {txt: "Performance"}, {txt: "Weights"}, {txt: "Allocation"}, {txt: "About "}],
    menudata: {},

    imageicon: "",
    ltploader: false,
  }),

  computed: {
    orderheader() {
      return [
        {text: "Symbol", value: "tsym"},
        {text: "Amount", value: "price", align: "right"},
        {text: "Qty", value: "quantity"},
        {text: "Invest by", value: "weights"},
      ];
    },
  },
  async created() {
    let params = this.$route.params;
    this.getMenuData(params.best, params.trigger);
    // this.getMenuData(83);
  },
  mounted() {
    eventBus.$emit("tabBar-load");
    eventBus.$emit("login-event");
    eventBus.$on("user-event", () => {
      let res = sessionStorage.getItem("c3RhdHVz");
      if (res == "dmFsaWR1c2Vy") {
        if (!this.uid && !this.stoken) {
          this.mtoken = sessionStorage.getItem("msession");
          this.stoken = sessionStorage.getItem("usession");
          this.uid = sessionStorage.getItem("userid");
        }
      }
    });

    // eventBus.$off('web-scoketConn');
  },
  beforeDestroy() {
    eventBus.$off("user-event");
    // eventBus.$off('web-scoketConn');
  },
  methods: {
    setCollweight() {
      return this.collnavchart.bsk.sort((a, b) => b.value - a.value);
    },
    async getMenuData(params, trigger) {
      if (params) {
        var raw = await getCollsingledata(params);
        if (raw && raw.stat == "ok") {
          this.menudata = raw.msg[0];
          this.isin = params;
          this.imageicon = this.menudata.basket_img ? `${apiurl.collapi}/${this.menudata.basket_img.split("Baskets/")[1]}` : null;
          this.menudata["manageris"] = this.menudata.master_img_path ? `${apiurl.collapi}/${this.menudata.master_img_path.split("Baskets/")[1]}` : null;
          this.schemereturns = [
            {returns: this.menudata.cagr.cagr_month, type: "1 Month"},
            {returns: this.menudata.cagr.cagr_1year, type: "1 Year"},
            {returns: this.menudata.cagr.cagr_3year, type: "3 Years"},
            {returns: this.menudata.cagr.cagr_5year, type: "5 Years"},
          ];

          this.menudata["list"] = raw.msg[0].etfs_weights.equity.map((o) => {
            o["key"] = `${o.exch}:${o.tsym}`;
            return o.key;
          });
          this.collnavchart["0"] = await getCollchartdata([this.menudata["list"], "5Y"]);
          if (this.collnavchart[0] && this.collnavchart[0].benchmarkIndex) {
            this.setNavHiscahart(this.collnavchart[0]);
          }

          var stocks = await getCollstockdata([this.menudata["list"], "5Y"]);

          stocks.map((o, l) => {
            stocks[l] = {...o, ...this.menudata.etfs_weights.equity.filter((s) => s.key == stocks[l].SYMBOL)[0]};
          });
          var sec = Object.groupBy(stocks, ({baskeqdeat}) => baskeqdeat);
          var bsk = Object.groupBy(stocks, ({sector}) => sector);
          this.sectordata = {...bsk};
          this.collnavchart["stocks"] = [...stocks];
          this.collnavchart["sec"] = [];
          this.collnavchart["bsk"] = [];
          Object.entries(sec).forEach(([k, v]) => {
            this.collnavchart["sec"].push({name: k, value: Math.round(v.reduce((n, {avg_weight_percent}) => n + Number(avg_weight_percent), 0))});
          });
          Object.entries(bsk).forEach(([k, v]) => {
            this.collnavchart["bsk"].push({name: k, value: Math.round(v.reduce((n, {avg_weight_percent}) => n + Number(avg_weight_percent), 0))});
          });
          this.setAssetchart();
          this.setWeightchart();

          if (trigger) {
            this.setPlacecoll();
          }
          this.mainloader = false;
        } else {
          eventBus.$emit("snack-event", 0, this.menudata.msg ? this.menudata.msg : this.menudata);
          this.$router.push(`/collection`);
        }
      } else {
        this.$router.push(`/collection`);
      }
    },
    imageLoadError() {
      this.imageicon = null;
    },
    setNavHiscahart(data) {
      var o = [];
      var t = [];
      var r = [];
      data.basketChart.map((e, l) => {
        o.push(e.returns);
        t.push(e.date);
        r.push(data.benchmarkChart[l].returns);
      });
      this.setPerformchart(o, t, r);
    },
    setAssetchart() {
      var myChart = echarts.init(document.getElementById("asschart"));
      var option = {
        grid: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 8,
        },
        legend: {
          orient: "horizontal",
          bottom: "left",
          type: "scroll",
          textStyle: {
            fontSize: 12,
            color: "#000",
          },
          itemWidth: 9,
          itemHeight: 9,
        },
        tooltip: {
          trigger: "item",
          formatter: "{b}: {d}%",
        },

        series: [
          {
            type: "pie",
            radius: ["42%", "68%"],
            label: {
              show: false,
            },
            color: ["#148564", "#7CD36E", "#F9CD6C", "#FDEBC4", "#DEDEDE"],
            data: this.collnavchart.sec,
          },
        ],
      };

      option && myChart.setOption(option);

      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    setWeightchart() {
      var myChart = echarts.init(document.getElementById("weightchart"));
      var option = {
        grid: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 8,
        },
        legend: {
          // orient: "horizontal",
          bottom: "left",
          // top: 16,
          // type: "scroll",
          textStyle: {
            fontSize: 12,
            color: "#000",
          },
          itemWidth: 8,
          itemHeight: 8,
          data: this.collnavchart.bsk.map((item) => item.name),
        },
        tooltip: {
          trigger: "item",
          formatter: "{b}: {d}%",
        },

        series: [
          {
            type: "pie",
            radius: ["42%", "68%"],
            label: {
              show: false,
            },
            // color: ["#148564", "#7CD36E", "#F9CD6C", "#FDEBC4", "#DEDEDE"],
            data: this.collnavchart.bsk,
          },
        ],
      };

      option && myChart.setOption(option);

      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    setPerformchart(onee, twoo, three) {
      var myChart = echarts.init(document.getElementById("mainnew"));

      const minval = [Math.min(...onee), Math.min(...three)];
      const minvalo = Math.min(...minval);

      var option = {
        title: {
          text: "",
        },

        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        legend: {
          data: [this.menudata.basket_title, this.collnavchart[0].benchmarkIndex],
          // bottom: 'left',
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: twoo,
          },
        ],
        yAxis: [
          {
            type: "value",
            min: minvalo,
          },
        ],
        series: [
          {
            name: this.menudata.basket_title,
            type: "line",
            areaStyle: {color: "transparent"},
            smooth: false,
            showSymbol: false,
            label: {
              position: "top",
            },
            emphasis: {
              focus: "series",
            },
            data: onee,
          },
          {
            name: this.collnavchart[0].benchmarkIndex,
            type: "line",
            areaStyle: {color: "transparent"},
            smooth: false,
            showSymbol: false,
            label: {
              position: "top",
            },
            emphasis: {
              focus: "series",
            },
            data: three,
          },
        ],
      };
      option && myChart.setOption(option);

      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    async setUpdateltp() {
      this.ltploader = true;
      var ltp = await getCollLtpdata(this.isin);

      if (ltp.msg && ltp.msg.equity && ltp.minprice > 0) {
        this.menudata.price = Number(ltp.minprice).toFixed(2);
        this.collnavchart.stocks.map((o, l) => {
          this.collnavchart.stocks[l] = {...o, ...ltp.msg.equity.filter((s) => s.token == o.token)[0]};
        });
      }
      this.ltploader = false;
    },
    async setPlacecoll(value) {
      if (value) {
        eventBus.$emit("snack-event", 2, "It's a BETA version intended solely for exploring.");
        this.basketdialog = false;
      } else {
        if (value) {
          this.orderloader = true;
          var data;
          if (this.tenure) {
            const baskets = [];
            for (let i = 0; i < this.collnavchart.stocks.length; i++) {
              baskets.push({
                exch: this.collnavchart.stocks[i].exch,
                prd: "C",
                qty: this.collnavchart.stocks[i].quantity,
                token: this.collnavchart.stocks[i].token,
                tsym: this.collnavchart.stocks[i].tsym,
              });
            }
            data = {
              siptype: "new",
              session: this.mtoken,
              url__: mynturl.myntapi,
              etfs_weights: baskets,
              startdate: moment(this.sipdate).format("DDMMYYYY"),
              end_period: this.sipinstall,
              sip_name: this.sip_name,
              frequency: this.frequency,
            };
          } else {
            data = {
              basketid: this.menudata.id,
              etfs_weights: this.menudata.etfs_weights,
              session: this.mtoken,
              url__: mynturl.myntapi,
              rebalance_status: this.menudata.rebalance ? "yes" : "",
            };
            this.menudata.rebalance ? (data["deleted_etf"] = this.menudata.deleted_etf) : ((data["order_count"] = ""), (data["rebalance_status"] = ""));
          }
          var order = await getCollplaceorder(this.tenure ? "/siporder" : "/orderExecute", JSON.stringify(data), this.stoken);

          if ((order && order.msg == "order initiated") || order.msg == "success") {
            eventBus.$emit("snack-event", 1, "Order initiated successfully");
          } else {
            eventBus.$emit("snack-event", 0, order.msg ? order.msg : order);
          }
          this.basketdialog = false;
          this.orderloader = false;
        } else {
          this.mainloader = true;
          this.sip_name = this.menudata.basket_title;
          var ledger = await getBondLedger([this.uid]);
          if (ledger && ledger.clientid == this.uid) {
            this.menudata["ledger"] = Number(ledger.total) >= Number(this.menudata.price) ? true : true;
          } else {
            this.menudata["ledger"] = false;
          }
          this.menudata["ledgerbal"] = (Number(this.menudata.price) - Number(ledger.total)).toFixed(2);
          this.basketdialog = true;
          this.mainloader = false;
        }
      }
    },

    setbodyTab() {
      var element;
      if (this.bodytab == 0) {
        element = document.getElementById("overview");
      } else if (this.bodytab == 1) {
        element = document.getElementById("perf");
      } else if (this.bodytab == 2) {
        element = document.getElementById("weight");
      } else if (this.bodytab == 3) {
        element = document.getElementById("allo");
      } else if (this.bodytab == 4) {
        element = document.getElementById("abt");
      }
      element.scrollIntoView({block: "start", behavior: "smooth", inline: "start"});
    },
    handleScroll(e) {
      var scrollPos = e.target.scrollTop;
      var perf = document.getElementById("perf").offsetTop - 216;
      var weight = document.getElementById("weight").offsetTop - 216;
      var allo = document.getElementById("allo").offsetTop - 216;
      var abt = document.getElementById("abt").offsetTop - 216;

      if (scrollPos >= 0 && scrollPos < perf) {
        this.bodytab = 0;
      } else if (scrollPos > perf && scrollPos < weight) {
        this.bodytab = 1;
      } else if (scrollPos > weight && scrollPos < allo) {
        this.bodytab = 2;
      } else if (scrollPos > allo && scrollPos < abt) {
        this.bodytab = 3;
      } else if (scrollPos > abt) {
        this.bodytab = 4;
      }
    },
  },
};
</script>
