<template>
  <div>
    <p class="font-weight-bold fs-22 mb-0 mt-4">Settings</p>
    <p class="subtitle-2 subtext--text mb-4">Catch the log, setting up preference, get API key, and change themes.</p>

    <v-card class="mb-8 rounded-lg elevation-0 crd-trn" outlined width="100%">
      <v-expansion-panels v-model="panel" multiple flat>
        <v-expansion-panel class="crd-trn" flat>
          <v-expansion-panel-header class="fs-16 font-weight-medium">User details</v-expansion-panel-header>
          <v-expansion-panel-content class="fs-14">
            <v-row no-gutters>
              <v-col cols="2">Name</v-col>
              <v-col cols="10">: <span class="font-weight-medium subtext--text pl-2">{{ clientdata.cliname ?
                clientdata.cliname : "-" }}</span></v-col>

              <v-col cols="2">Mobile no</v-col>
              <v-col cols="10">: <span class="font-weight-medium subtext--text pl-2">{{ clientdata.m_num ?
                clientdata.m_num : "-" }}</span></v-col>

              <v-col cols="2">Email</v-col>
              <v-col cols="10">: <span class="font-weight-medium subtext--text pl-2">{{ clientdata.email ?
                clientdata.email : "-" }}</span></v-col>

              <v-col cols="12" class="mt-2">Bank:-</v-col>
              <v-col cols="10" v-for="(a, s) in clientdata.bankdetails" :key="s">
                Name:<span class="font-weight-medium subtext--text pl-2">{{ a.bankn }}</span> | Account no:<span
                  class="font-weight-medium subtext--text pl-2">{{ a.acctnum }}</span></v-col>

              <v-col cols="12" class="mt-2">DP no:-</v-col>
              <v-col cols="10" v-for="(a, s) in clientdata.dp_acct_num" :key="s">
                {{ s + 1 }}: <span class="font-weight-medium subtext--text pl-2">{{ a.dpnum }}</span></v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel class="crd-trn" flat>
          <v-expansion-panel-header class="fs-16 font-weight-medium">API key</v-expansion-panel-header>
          <v-expansion-panel-content class="fs-14">
            <v-row v-if="keydata != null" no-gutters>
              <v-col cols="2">Generate API</v-col>
              <v-col cols="10">: <span class="font-weight-medium subtext--text pl-2">{{ keydata && keydata.apistatus ?
                keydata.apistatus : "-" }}</span></v-col>

              <v-col cols="2">Expire date</v-col>
              <v-col cols="10">:
                <span class="font-weight-medium subtext--text pl-2">{{ keydata && keydata.exp ? keydata.exp : "-" }}
                  <span v-if="keydata && keydata.dd">({{ keydata.dd }} days)</span></span></v-col>

              <v-col cols="2">Vender code(vc)</v-col>
              <v-col cols="10">: <span class="font-weight-medium subtext--text pl-2">{{ keydata && keydata.uid ?
                keydata.uid : "-" }}</span></v-col>

              <v-col cols="2" class="d-flex align-center">API key</v-col>
              <v-col cols="10">: <span class="font-weight-medium subtext--text pl-2">{{ keydata && keydata.apikey ?
                keydata.apikey : "-" }}</span>
                <v-btn @click="setCopyText(keydata.apikey, 'API key')" v-if="keydata && keydata.apikey" text small
                  class="primary--text font-weight-medium px-0 text-none ml-1"><v-icon
                    size="12">mdi-content-copy</v-icon> {{ keydata.time ? "Copied" : "Copy" }}</v-btn></v-col>
            </v-row>
            <v-row v-else no-gutters>
              <v-col cols="3">
                <v-select background-color="secbg" append-icon="mdi-chevron-down" v-model="selectexp" dense
                  :items="expitems" hide-details class="rounded-pill mr-3" flat solo></v-select>
              </v-col>
              <v-col cols="8">
                <v-btn @click="setUserAPikeydata()" color="btnclr"
                  class="font-weight-bold text-none rounded-pill elevation-0 btntext--text px-6"> Generate API key
                </v-btn>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel class="crd-trn" flat>
          <v-expansion-panel-header class="fs-16 font-weight-medium">TOTP</v-expansion-panel-header>
          <v-expansion-panel-content class="fs-14">
            <div v-if="totpdata && totpdata.pwd">

              <p class="font-weight-bold fs-14 mb-2">Authenticator QR</p>
              <div class="mb-4">
                <qrcode-vue size="180." :value="totpqr"></qrcode-vue>
              </div>

              <v-divider class="mb-2"></v-divider>
              <p class="font-weight-bold fs-14 mb-2">Authenticator Key</p>
              <v-card class="rounded-lg px-3 pb-1 pt-2 elevation-0" color="secbg" max-width="400px">
                {{ totpeye ? "••••••••••••••••••••••••••••••••••••••••••••••••" : totpdata.pwd }}

                <v-btn @click="totpeye = !totpeye" icon class="ml-1"><v-icon size="20">{{ !totpeye ? 'mdi-eye' :
                  'mdi-eye-off' }}</v-icon></v-btn>

                <v-btn @click="setCopyText(totpdata.pwd, 'Auth key')" icon><v-icon size="20">mdi-content-copy</v-icon></v-btn>
              </v-card>
              <v-divider class="my-4"></v-divider>

              <p class="font-weight-bold fs-14 mb-2">Your TOTP</p>


              <v-btn @click="setCopyText(totpis, 'OTP')" text>
                <p class="font-weight-bold fs-20 mb-0">{{ totpis ? `${totpis.slice(0, 3)} ${totpis.slice(3, 6)} ` :
                  "*** *** " }}</p>
                <v-icon size="20">mdi-content-copy</v-icon>
              </v-btn>

              <p class="subtext--text fs-12">This OTP will automatically update every 30 seconds.</p>
            </div>

            <v-btn v-else text class="text-none pa-1 rounded-lg" color="primary" @click="setTotpdata(true)">Click to
              generated new code</v-btn>

          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel class="crd-trn" flat>
          <v-expansion-panel-header class="fs-16 font-weight-medium">Password & Security</v-expansion-panel-header>
          <v-expansion-panel-content class="fs-14">
            <v-btn @click="setChangepws()" class="text-none font-weight-bold elevation-0" color="secbg">Change
              Password</v-btn>

            <v-divider class="my-4"></v-divider>

            <p class="font-weight-medium subtext--text">Loggedin sessions</p>
            <v-data-table disable-sort :headers="sessionheader" hide-default-footer :items="logsitems"
              :items-per-page="5" class="elevation-0">
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn text class="font-weight-bold text-none px-0" color="primary" @click="setLogoutseee(item)">
                  Logout</v-btn>
              </template>

              <template v-slot:[`item.llt`]="{ item }">
                <span> {{ new Date(item.llt * 1000).toLocaleString() }}</span>
              </template>

              <template v-slot:[`item.source`]="{ item }">
                <v-badge color="primary" dot :value="item.source == source">
                  <span> {{ item.source }}</span>
                </v-badge>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel flat>
          <v-expansion-panel-header class="fs-16 font-weight-medium">Themes</v-expansion-panel-header>
          <v-expansion-panel-content class="fs-14">
            <v-radio-group @change="setChangeTheme()" v-model="themeradio">
              <v-row>
                <v-col cols="6" md="2"><v-card outlined class="pa-3 rounded-lg" width="100%"> <v-radio label="Light"
                      value="light"></v-radio> </v-card></v-col>
                <v-col cols="6" md="2"><v-card outlined class="pa-3 rounded-lg" width="100%"> <v-radio label="Dark"
                      value="dark"></v-radio> </v-card></v-col>
                <v-col cols="6" md="2"><v-card outlined class="pa-3 rounded-lg" width="100%"> <v-radio label="Default"
                      value="default"></v-radio> </v-card></v-col>
              </v-row>
            </v-radio-group>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel class="crd-trn" flat>
          <v-expansion-panel-header class="fs-16 font-weight-medium">Order Preference</v-expansion-panel-header>
          <v-expansion-panel-content class="fs-14">
            <p class="font-weight-medium subtext--text">Exchange default values</p>
            <v-row class="mb-4">
              <v-col cols="6" md="3">
                <v-select class="rounded-lg" append-icon="mdi-chevron-down" v-model="exchtype" :items="exchitems"
                  label="Exchange" outlined hide-details dense></v-select>
              </v-col>
              <v-col cols="6" md="3">
                <v-select class="rounded-lg" append-icon="mdi-chevron-down" v-model="qtypreitems[exchtype][0]"
                  :items="['NSE', 'BSE'].includes(exchtype) ? prditemso : prditemst" label="Product" outlined
                  hide-details dense></v-select>
              </v-col>
              <v-col cols="6" md="3">
                <v-select class="rounded-lg" append-icon="mdi-chevron-down" v-model="qtypreitems[exchtype][1]"
                  item-text="txt" item-value="val"
                  :items="['CO', 'BO'].includes(qtypreitems[exchtype][0]) ? ordtypeitemst : ordtypeitemso"
                  label="Order type" outlined hide-details dense></v-select>
              </v-col>
              <v-col cols="6" md="3">
                <v-select class="rounded-lg" append-icon="mdi-chevron-down" v-model="qtypreitems[exchtype][2]"
                  :items="['BFO', 'BCD'].includes(exchtype) ? duritemst : ['BSE'].includes(exchtype) ? duritemsr : duritemso"
                  label="Duration" outlined hide-details dense></v-select>
              </v-col>
            </v-row>

            <p class="font-weight-medium subtext--text">Quantity preferences</p>
            <v-radio-group v-model="qtypre" row class="mb-0">
              <v-radio label="Minimum quantity" value="0"></v-radio>
              <v-radio label="Set no.of market lots" value="1"></v-radio>
            </v-radio-group>
            <v-row class="mb-4">
              <v-col cols="4" sm="3" md="2" v-for="(e, x) in qtypreitems" :key="x">
                <v-text-field :disabled="qtypre == '0'" class="rounded-lg" v-model="qtypreitems[x][3]" :label="x"
                  type="number" placeholder="1" hide-spin-buttons outlined hide-details dense></v-text-field>
              </v-col>
            </v-row>

            <p class="font-weight-medium subtext--text">Market protection % preferences</p>
            <v-text-field class="max-w-280 mb-6 rounded-lg" append-icon="mdi-percent" v-model="mktpro"
              label="Market protection %" type="number" hide-spin-buttons outlined hide-details dense></v-text-field>

            <p class="font-weight-medium subtext--text">Position exit</p>
            <v-select class="max-w-280 mb-6 rounded-lg" append-icon="mdi-chevron-down" v-model="expos" item-text="txt"
              item-value="val" :items="ordtypeitemex" label="Order type" outlined hide-details dense></v-select>

            <v-toolbar class="tool-sty elevation-0" color="cardbg" dense>
              <v-btn @click="setSaveperf()" color="btnclr"
                class="font-weight-bold text-none rounded-pill elevation-0 btntext--text mr-4 px-6"> Apply </v-btn>
              <v-btn @click="setSaveperf(true)" color="secbg"
                class="font-weight-bold text-none rounded-pill elevation-0 subtext--text px-6"> Reset </v-btn>
            </v-toolbar>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel class="crd-trn" flat>
          <v-expansion-panel-header class="fs-16 font-weight-medium">Layout Preference</v-expansion-panel-header>
          <v-expansion-panel-content class="fs-14">
            <p class="font-weight-medium subtext--text pb-0">WatchList</p>
            <v-radio-group @change="setWLlayout()" v-model="wllayoutradio">
              <v-row>
                <v-col cols="6" md="2"><v-card outlined class="pa-3 rounded-lg" width="100%"> <v-radio label="Left"
                      value="true"></v-radio> </v-card></v-col>
                <v-col cols="6" md="2"><v-card outlined class="pa-3 rounded-lg" width="100%"> <v-radio label="Right"
                      value="false"></v-radio> </v-card></v-col>
              </v-row>
            </v-radio-group>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel class="" flat>
          <v-expansion-panel-header class="fs-16 font-weight-medium">Log</v-expansion-panel-header>
          <v-expansion-panel-content class="fs-14">
            <v-data-table hide-default-footer must-sort :headers="snackheaders" :items="snacklogs"></v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import eventBus from "../../eventBus.js";
import { getApikeyreq, getLoggedIn, getMyntLogout, getDeskLogout, getClientDetails, getUserApikeyreq, getTotpreq } from "../../components/mixins/getAPIdata.js";
import { mynturl } from "../../apiurl.js";

export default {
  data: () => ({
    totpqr: "",
    uid: null,
    token: null,

    panel: [],

    keydata: [],
    themeradio: null,
    wllayoutradio: null,
    snacklogs: [],
    snackheaders: [
      { text: "time", value: "time" },
      { text: "message", value: "msg", sortable: false },
    ],

    qtypreitems: { NSE: "", BSE: "", MCX: "", NFO: "", CDS: "", BFO: "", BCD: "" },

    exchitems: ["NSE", "BSE", "MCX", "NFO", "CDS", "BFO", "BCD"],
    prditemso: ["MIS", "CNC", "CO", "BO"], //NSE, BSE
    prditemst: ["NRML", "MIS", "CO", "BO"],

    ordtypeitemso: [
      { txt: "Market", val: "MKT" },
      { txt: "Limit", val: "LMT" },
      { txt: "SL Limit", val: "SL-LMT" },
      { txt: "SL Mkt", val: "SL-MKT" },
    ],
    ordtypeitemst: [
      { txt: "Market", val: "MKT" },
      { txt: "Limit", val: "LMT" },
      { txt: "SL Limit", val: "SL-LMT" },
    ],

    ordtypeitemex: [
      { txt: "Market", val: "MKT" },
      { txt: "Limit", val: "LMT" },
    ],
    // ["MARKET", "LIMIT", "SL", "SLM"]
    duritemso: ["IOC", "DAY"],
    duritemst: ["IOC", "EOS"], // BFO, BCD
    duritemsr: ["DAY"], //BSE

    exchtype: "NSE",
    expos: "MKT",

    qtypre: "0",
    mktpro: 5,

    orderpref: {},

    sessionheader: [
      { text: "User Id", align: "start", sortable: false, value: "uid" },
      { text: "Source", value: "source", sortable: false },
      { text: "Last loggedin time", value: "llt", sortable: false },
      { text: "Actions", value: "actions", align: "start", sortable: false },
    ],
    logsitems: [],
    clientdata: {},

    source: null,

    selectexp: '1 month',

    expitems: [
      '1 month',
      '3 month',
      '6 month',
      '10 years',
    ],

    totpdata: {},
    totpeye: true,
    totpis: null,

    intervalId: null,
  }),
  created() {
    this.source = mynturl.source;
  },
  mounted() {
    eventBus.$emit("login-event");

    eventBus.$on("user-event", () => {
      let res = sessionStorage.getItem("c3RhdHVz");
      if (res == "dmFsaWR1c2Vy") {
        this.token = sessionStorage.getItem("msession");
        this.uid = sessionStorage.getItem("userid");
        this.setOrderpre();
        this.setAPikeydata();
        let t = localStorage.getItem("web.theme");
        this.themeradio = t;
        this.setClientdata();
        this.setTotpdata();
        let wll = localStorage.getItem(`${this.uid}_wllayout`);
        this.wllayoutradio = wll && wll == 'true' ? 'true' : 'false';
      } else {
        eventBus.$emit("login");
      }
    });
    this.setWebsocket('unsub', [])

  },

  methods: {
    setWebsocket(flow, data) {
      eventBus.$emit("web-scoketOn", flow, data, 'is', "settings");
    },
    setWLlayout() {
      eventBus.$emit("wllayout-event");
    },

    async setClientdata() {
      let res = await getClientDetails();
      if (res && res.stat == "Ok") {
        this.clientdata = res;
      } else {
        eventBus.$emit("snack-event", 3, "Failed to fetch client logs.");
      }
    },
    setOrderpre() {
      var data = JSON.parse(localStorage.getItem(`${this.uid}_ordpref`));
      if (data && data.mainpreitems) {
        this.orderpref = data;
      } else {
        this.orderpref = {
          expos: this.expos,
          mktpro: this.mktpro,
          qtypre: this.qtypre,
          mainpreitems: { NSE: ["CNC", "LMT", "DAY", ""], BSE: ["CNC", "LMT", "DAY", ""], MCX: ["NRML", "LMT", "DAY", ""], NFO: ["NRML", "LMT", "DAY", ""], CDS: ["NRML", "LMT", "DAY", ""], BFO: ["NRML", "LMT", "EOS", ""], BCD: ["NRML", "LMT", "EOS", ""] },
        };
        localStorage.setItem(`${this.uid}_ordpref`, JSON.stringify(this.orderpref));
      }

      this.qtypre = this.orderpref.qtypre;
      this.mktpro = this.orderpref.mktpro;
      this.qtypreitems = this.orderpref.mainpreitems;
    },
    setSaveperf(type) {
      if (type) {
        localStorage.removeItem(`${this.uid}_ordpref`);
        this.setOrderpre();
        eventBus.$emit("snack-event", 2, "Order preference has been reset.");
      } else {
        this.orderpref["qtypre"] = this.qtypre;
        this.orderpref["mktpro"] = this.mktpro;
        this.orderpref["mainpreitems"] = this.qtypreitems;
        this.orderpref["expos"] = this.expos;
        localStorage.setItem(`${this.uid}_ordpref`, JSON.stringify(this.orderpref));
        eventBus.$emit("snack-event", 1, "Order preference has been changed.");
      }
    },
    setChangepws() {
      window.location.assign(`https://desk.mynt.in/change_password/?url=${window.location.origin + window.location.pathname}&uid=${this.uid}`);
    },
    setChangeTheme() {
      var theme = null;
      if (this.themeradio == "default") {
        theme = window.matchMedia?.("(prefers-color-scheme: dark)").matches ? "dark" : "light";
        this.$vuetify.theme.dark = theme == "dark" ? true : false;
      } else {
        this.$vuetify.theme.dark = this.themeradio == "dark" ? true : false;
        theme = this.themeradio;
      }
      localStorage.setItem("web.theme", this.themeradio);
      document.getElementById("theme-link").setAttribute("href", `theme-${theme}.css`);
    },

    async setUserAPikeydata() {
      let key = await getUserApikeyreq(this.selectexp);
      if (key && key.status == "ISSUED") {
        this.setAPikeydata();
        eventBus.$emit("snack-event", 1, "API key as generated");
      } else {
        eventBus.$emit("snack-event", 2, key.status);
      }
    },
    async setAPikeydata() {
      let key = await getApikeyreq();
      if (key && key.uid && key.apistatus) {
        key["exp"] = new Date(key.exd * 1000).toDateString();
        key["dd"] = Math.round(Math.abs(new Date(key.exp) - new Date()) / (1000 * 60 * 60 * 24) + 1);
        key["time"] = false;
        this.keydata = key;
      } else {
        this.keydata = null
      }

      var logid = this.uid + new Date().toLocaleDateString();
      let log = sessionStorage.getItem(logid);
      if (log) {
        this.snacklogs = JSON.parse(log);
      }

      var logs = await getLoggedIn();
      if (logs) {
        this.logsitems = logs;
      }
    },
    async setTotpdata(bool) {
      const data = await getTotpreq(bool)
      this.totpdata = data;
      this.totpqr = `otpauth://totp/MYNT:${data.uid}?secret=${data.pwd}&issuer=MYNT WEB`
      this.generateTOTP(data.pwd);

      this.intervalId = setInterval(() => {
        this.generateTOTP(data.pwd)
      }, 5000);

    },
    setCopyText(key, text) {
      navigator.clipboard.writeText(key);
      eventBus.$emit("snack-event", 1, `📋 ${text} copied to clipboard!`);
    },

    async setLogoutseee(item) {
      await getMyntLogout(item.source);
      if (item.source == mynturl.source) {
        await getDeskLogout([this.uid, this.token]);
        eventBus.$emit("storage-reset", true);
      } else {
        this.setAPikeydata();
      }
    },

    base32ToHex(base32) {
      const base32Chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ234567';
      let bits = '';
      let hex = '';

      for (let i = 0; i < base32.length; i++) {
        const val = base32Chars.indexOf(base32.charAt(i).toUpperCase());
        bits += val.toString(2).padStart(5, '0');
      }

      for (let i = 0; i + 8 <= bits.length; i += 8) {
        const byte = bits.substr(i, 8);
        hex += parseInt(byte, 2).toString(16).padStart(2, '0');
      }

      return hex;
    },

    hexToUint8Array(hex) {
      const bytes = [];
      for (let i = 0; i < hex.length; i += 2) {
        bytes.push(parseInt(hex.substr(i, 2), 16));
      }
      return new Uint8Array(bytes);
    },

    async generateTOTP(pwd) {
      const secretKey = pwd;
      const key = this.base32ToHex(secretKey);

      const epoch = Math.floor(new Date().getTime() / 1000.0);
      const time = Math.floor(epoch / 30).toString(16).padStart(16, '0');
      const timeBuffer = this.hexToUint8Array(time);

      const keyBuffer = this.hexToUint8Array(key);

      const cryptoKey = await window.crypto.subtle.importKey(
        'raw', keyBuffer, { name: 'HMAC', hash: { name: 'SHA-1' } },
        false, ['sign']
      );
      const signature = await window.crypto.subtle.sign(
        'HMAC', cryptoKey, timeBuffer
      );

      const hash = Array.from(new Uint8Array(signature))
        .map(b => b.toString(16).padStart(2, '0')).join('');

      const offset = parseInt(hash.substring(hash.length - 1), 16);
      const otp = (parseInt(hash.substr(offset * 2, 8), 16) & 0x7fffffff) % 1000000;

      this.totpis = otp.toString().padStart(6, '0');
    }

  },
  beforeDestroy() {
    eventBus.$off("user-event");
    clearInterval(this.intervalId);
  },
  components: {
    QrcodeVue,
  },
};
</script>
