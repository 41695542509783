<template>
    <div class="mb-4">
        <v-row>
            <v-col sm="6" cols="12">
                <v-card class="elevation-0 px-2 pt-1 pb-4 rounded-0 stat-card rounded">
                    <v-list-item>
                        <v-list-item-avatar tile size="20">
                            <img src="@/assets/stocks/holdings.svg" alt="">
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title class="font-weight-medium fs-18">Holdings </v-list-item-title>
                            <!-- <v-list-item-subtitle class="fs-14">Stocks held by you in your various
                                accounts</v-list-item-subtitle> -->
                        </v-list-item-content>
                    </v-list-item>

                    <v-card class="elevation-0 card-border py-1 mx-4 mt-2 cardbg">
                        <v-list-item>
                            <v-list-item-content style="max-width: 22%;">
                                <v-list-item-title class="font-weight-medium fs-16"><span id="holdstatinv">{{
                                    statholding.invested ? setFormatNumber(statholding.invested) : "0.00"
                                        }}</span></v-list-item-title>
                                <v-list-item-subtitle class="fs-14 font-weight-regular">Invested</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-content style="max-width: 22%;">
                                <v-list-item-title class="font-weight-medium fs-16"><span id="holdstatval">{{
                                    statholding.stockvalue ? setFormatNumber(statholding.stockvalue) : "0.00"
                                        }}</span>
                                </v-list-item-title>
                                <v-list-item-subtitle class="fs-14 font-weight-regular">Current</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-content style="max-width: 30%;">
                                <v-list-item-title class="font-weight-medium fs-16">
                                    <span id="holdstatpnlcclr"
                                        :class="statholding.cpnl > 0 ? 'maingreen--text' : statholding.cpnl < 0 ? 'mainred--text' : 'subtext--text'">
                                        <span id="holdstatpnl">{{ statholding.pnl > 0 ? setFormatNumber(statholding.pnl)
                                            : "0.00"
                                            }}</span>
                                    </span>
                                    <span class="fs-14" id="holdstatpnlcpclr"
                                        :class="statholding.cpnl > 0 ? 'maingreen--text' : statholding.cpnl < 0 ? 'mainred--text' : 'subtext--text'">(<span
                                            id="holdstatpnlc"> {{ statholding.cpnl > 0 || statholding.cpnl < 0 ?
                                                statholding.cpnl : "0.00" }}</span>%)
                                        </span>
                                </v-list-item-title>
                                <v-list-item-subtitle class="fs-14 font-weight-regular">Total P&L</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-content style="max-width: 26%;">
                                <v-list-item-title class="font-weight-medium fs-16 maingreen--text">

                                    <span id="holdstatdpnlcclr"
                                        :class="statholding.d_cpnl > 0 ? 'maingreen--text' : statholding.d_cpnl < 0 ? 'mainred--text' : 'subtext--text'">
                                        <span id="holdstatdpnl">{{ statholding.d_pnl > 0 ?
                                            setFormatNumber(statholding.d_pnl) : "0.00"
                                            }}</span>
                                    </span>
                                    <span class="fs-13" id="holdstatdpnlcpclr"
                                        :class="statholding.d_cpnl > 0 ? 'maingreen--text' : statholding.d_cpnl < 0 ? 'mainred--text' : 'subtext--text'">(<span
                                            id="holdstatdpnlc"> {{ statholding.d_cpnl > 0 || statholding.d_cpnl < 0 ?
                                                statholding.d_cpnl : "0.00" }}</span>%)
                                        </span>
                                </v-list-item-title>
                                <v-list-item-subtitle class="fs-14 font-weight-regular">Today P&L</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-card>

                    <div class="px-4 pt-4 pb-2 d-flex justify-space-between align-center">
                        <p class="subtext--text fs-14 mb-0">No of holdings - <b>{{ holdingdata.length }}</b>
                        </p>
                        <v-btn to="/holdings" text class="fs-12 text-none px-0" small dense color="primary">View
                            details</v-btn>
                    </div>
                    <div class="px-4">
                        <v-card :disabled="holdingdata.length == 0" color="secbg" width="100%"
                            class="elevation-0 d-inline-flex">
                            <v-card class="elevation-0 rounded-0 crd-trn overflow-hidden d-inline-flex"
                                id="holdstatavdp" :width="`${statholding.p_advdec}%`">
                                <div v-for="n in 250" :key="n">
                                    <v-card v-if="n % 2 === 0" class="elevation-0 rounded-0 py-4"
                                        :color="holdingdata.length == 0 ? 'secbg' : 'secgreen'" width="4px"></v-card>
                                    <v-card v-else class="elevation-0 rounded-0 py-4"
                                        :color="holdingdata.length == 0 ? 'cardbg' : 'maingreen'" width="4px"></v-card>
                                </div>
                            </v-card>
                            <v-card class="elevation-0 rounded-0 crd-trn" width="4px">
                            </v-card>
                            <v-card class="elevation-0 rounded-0 crd-trn overflow-hidden d-inline-flex"
                                id="holdstatavdn" :width="`${statholding.n_advdec}%`">
                                <div v-for="n in 250" :key="n">
                                    <v-card v-if="n % 2 === 0" class="elevation-0 rounded-0 py-4"
                                        :color="holdingdata.length == 0 ? 'secbg' : 'secred'" width="4px"></v-card>
                                    <v-card v-else class="elevation-0 rounded-0 py-4"
                                        :color="holdingdata.length == 0 ? 'cardbg' : 'mainred'" width="4px"></v-card>
                                </div>
                            </v-card>
                        </v-card>
                    </div>

                    <v-chip-group row class="px-4 pt-2">
                        <v-chip class="mr-2 rounded-pill card-success" color="maingreen" outlined text-color="maintext">
                            <img src="@/assets/stocks/pos-icon.svg" alt="">
                            <span class="fs-14 font-weight-medium pl-2" id="holdstatP"> {{ statholding.positive ?
                                statholding.positive.length : "0" }}
                            </span>&nbsp;Positive
                        </v-chip>
                        <v-chip class="mr-2 rounded-pill card-error" color="mainred" outlined text-color="maintext">
                            <img src="@/assets/stocks/neg-icon.svg" alt="">
                            <span class="fs-14 font-weight-medium pl-2" id="holdstatN"> {{ statholding.negative ?
                                statholding.negative.length : "0" }}
                            </span>&nbsp;Negative
                        </v-chip>
                    </v-chip-group>

                </v-card>
            </v-col>
            <v-col sm="6" cols="12">
                <v-card class="elevation-0 px-2 pt-1 pb-4 rounded-0 stat-card rounded">
                    <v-list-item>
                        <v-list-item-avatar tile size="24">
                            <img src="@/assets/stocks/position.svg" alt="">
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title class="font-weight-medium fs-18">Position </v-list-item-title>
                            <!-- <v-list-item-subtitle class="fs-14">Stocks held by you in your various
                                accounts</v-list-item-subtitle> -->
                        </v-list-item-content>
                    </v-list-item>

                    <v-card class="elevation-0 card-border py-1 mx-4 mt-2 cardbg">
                        <v-list-item>
                            <v-list-item-content style="max-width: 22%;">
                                <v-list-item-title class="font-weight-medium fs-16"><span id="poststatval">{{
                                    statposition.tradeval ? setFormatNumber(statposition.tradeval) : "0.00"
                                        }}</span></v-list-item-title>
                                <v-list-item-subtitle class="fs-14 font-weight-regular">Trade
                                    value</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-content style="max-width: 22%;">
                                <v-list-item-title class="font-weight-medium fs-16"> <span
                                        :class="statposition.mtm > 0 ? 'maingreen--text' : statposition.mtm < 0 ? 'mainred--text' : 'subtext--text'"
                                        id="poststatmtmclr">

                                        <span id="poststatmtm">{{ statposition.mtm ? setFormatNumber(statposition.mtm) :
                                            "0.00" }}</span>
                                    </span>
                                </v-list-item-title>
                                <v-list-item-subtitle class="fs-14 font-weight-regular">MTM</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-content style="max-width: 30%;">
                                <v-list-item-title class="font-weight-medium fs-16">
                                    <span id="poststatpnlclr"
                                        :class="statposition.cpnl > 0 ? 'maingreen--text' : statposition.cpnl < 0 ? 'mainred--text' : 'subtext--text'"><span
                                            id="poststatpnl">{{ statposition.pnl ? setFormatNumber(statposition.pnl) :
                                                "0.00"
                                            }}</span></span>
                                </v-list-item-title>
                                <v-list-item-subtitle class="fs-14 font-weight-regular">Total P&L</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-content style="max-width: 26%;">
                                <v-list-item-title class="font-weight-medium fs-16 maingreen--text">

                                    <span id="poststatopnlclr"
                                        :class="statposition.opcpnl > 0 ? 'maingreen--text' : statposition.opcpnl < 0 ? 'mainred--text' : 'subtext--text'"><span
                                            id="poststatopnl">{{ statposition.oppnl > 0 ?
                                                setFormatNumber(statposition.oppnl) : "0.00"
                                            }}</span></span>
                                </v-list-item-title>
                                <v-list-item-subtitle class="fs-14 font-weight-regular">Open P&L</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-card>

                    <div class="px-4 pt-4 pb-2 d-flex justify-space-between align-center">
                        <p class="subtext--text fs-14 mb-0">No of positions - <b>{{ positiondata.length }}</b> / Open
                            positions - <span><b>{{ statposition && statposition.open ? statposition.open.length : 0
                                    }}</b></span>
                        </p>
                        <v-btn to="/positions" text class="fs-12 text-none px-0" small dense color="primary">View
                            details</v-btn>
                    </div>
                    <div class="px-4">
                        <v-card :disabled="positiondata.length == 0" color="secbg" width="100%"
                            class="elevation-0 d-inline-flex">
                            <v-card class="elevation-0 rounded-0 crd-trn overflow-hidden d-inline-flex"
                                id="poststatavdp" :width="`${statposition.p_advdec}%`">
                                <div v-for="n in 250" :key="n">
                                    <v-card v-if="n % 2 === 0" class="elevation-0 rounded-0 py-4"
                                        :color="positiondata.length == 0 ? 'secbg' : 'secgreen'" width="4px"></v-card>
                                    <v-card v-else class="elevation-0 rounded-0 py-4"
                                        :color="positiondata.length == 0 ? 'cardbg' : 'maingreen'" width="4px"></v-card>
                                </div>
                            </v-card>
                            <v-card class="elevation-0 rounded-0 crd-trn" width="4px">
                            </v-card>
                            <v-card class="elevation-0 rounded-0 crd-trn overflow-hidden d-inline-flex"
                                id="poststatavdn" :width="`${statposition.n_advdec}%`">
                                <div v-for="n in 250" :key="n">
                                    <v-card v-if="n % 2 === 0" class="elevation-0 rounded-0 py-4"
                                        :color="positiondata.length == 0 ? 'secbg' : 'secred'" width="4px"></v-card>
                                    <v-card v-else class="elevation-0 rounded-0 py-4"
                                        :color="positiondata.length == 0 ? 'cardbg' : 'mainred'" width="4px"></v-card>
                                </div>
                            </v-card>
                        </v-card>
                    </div>

                    <v-chip-group row class="px-4 pt-2">
                        <v-chip class="mr-2 rounded-pill card-success" color="maingreen" outlined text-color="maintext">
                            <img src="@/assets/stocks/pos-icon.svg" alt="">
                            <span class="fs-14 font-weight-medium pl-2" id="poststatP"> {{ statposition.positive ?
                                statposition.positive.length : "0" }}
                            </span>&nbsp;Positive
                        </v-chip>
                        <v-chip class="mr-2 rounded-pill card-error" color="mainred" outlined text-color="maintext">
                            <img src="@/assets/stocks/neg-icon.svg" alt="">
                            <span class="fs-14 font-weight-medium pl-2" id="poststatN"> {{ statposition.negative ?
                                statposition.negative.length : "0" }}
                            </span>&nbsp;Negative
                        </v-chip>
                    </v-chip-group>

                </v-card>
            </v-col>

            <v-col sm="6" cols="12">
                <v-card class="elevation-0 px-2 pt-1 pb-4 rounded-0 stat-card rounded">
                    <v-list-item>
                        <v-list-item-avatar tile size="20">
                            <img src="@/assets/stocks/orders.svg" alt="">
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title class="font-weight-medium fs-18">Orders
                            </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-content>
                            <v-btn class="ml-auto" to="/orders" icon
                                color="primary"><v-icon>mdi-arrow-right</v-icon></v-btn>
                        </v-list-item-content>
                    </v-list-item>
                    <v-card class="elevation-0 card-border py-3 mx-4 mt-2 cardbg">

                        <v-row>
                            <v-col cols="4">
                                <div class="text-center">
                                    <p class="fs-16 font-weight-bold mb-0">{{ statorders && statorders[0] ?
                                        statorders[0] : 0 }}</p>
                                    <span class="fs-14 font-weight-regular">Open Orders</span>
                                </div>
                            </v-col>
                            <v-col cols="4">
                                <div class="text-center">
                                    <p class="fs-16 font-weight-bold mb-0">{{ statorders && statorders[1] ?
                                        statorders[1] : 0 }}</p>
                                    <span class="fs-14 font-weight-regular">Execute Orders</span>
                                </div>
                            </v-col>
                            <v-col cols="4">
                                <div class="text-center">
                                    <p class="fs-16 font-weight-bold mb-0">{{ statorders && statorders[2] ?
                                        statorders[2] : 0 }}</p>
                                    <span class="fs-14 font-weight-regular">Rejected</span>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-card>
            </v-col>

            <v-col sm="6" cols="12">
                <v-card class="elevation-0 px-2 pt-1 pb-4 rounded-0 stat-card rounded">
                    <v-list-item>
                        <v-list-item-avatar tile size="20">
                            <img src="@/assets/stocks/margin.svg" alt="">
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title class="font-weight-medium fs-18">Margins
                            </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-content>
                            <v-btn class="ml-auto" to="/funds" icon
                                color="primary"><v-icon>mdi-arrow-right</v-icon></v-btn>
                        </v-list-item-content>
                    </v-list-item>

                    <v-card class="elevation-0 card-border py-3 mx-4 mt-2 cardbg">
                        <v-row>
                            <v-col cols="4">
                                <v-tooltip top color="black">
                                    <template v-slot:activator="{ on, attrs }">
                                        <div v-bind="attrs" v-on="on" class="text-center">
                                            <p :class="statlimits.avbma < 0 ? 'mainred--text' : ''"
                                                class="fs-16 font-weight-bold mb-0">{{ statlimits.avbma ?
                                                    setFormatNumber(statlimits.avbma) : '0.00' }}</p>
                                            <span class="fs-14 font-weight-regular">Available balance</span>
                                        </div>
                                    </template>
                                    <span>{{ statlimits.avbma ?
                                        differentView(statlimits.avbma) : '0.00' }}</span>
                                </v-tooltip>
                            </v-col>
                            <v-col cols="4">
                                <v-tooltip top color="black">
                                    <template v-slot:activator="{ on, attrs }">
                                        <div v-bind="attrs" v-on="on" class="text-center">
                                            <p class="fs-16 font-weight-bold mb-0">{{ statlimits.total ?
                                                setFormatNumber(statlimits.total) : '0.00' }}</p>
                                            <span class="fs-14 font-weight-regular">Total credits</span>
                                        </div>
                                    </template>
                                    <span>{{ statlimits.total ?
                                        differentView(statlimits.total) : '0.00' }}</span>
                                </v-tooltip>
                            </v-col>
                            <v-col cols="4">
                                <v-tooltip top color="black">
                                    <template v-slot:activator="{ on, attrs }">
                                        <div v-bind="attrs" v-on="on" class="text-center">
                                            <p class="fs-16 font-weight-bold mb-0">{{ statlimits.marginused ?
                                                setFormatNumber(statlimits.marginused) : '0.00' }}</p>
                                            <span class="fs-14 font-weight-regular">Margin used</span>
                                        </div>
                                    </template>
                                    <span>{{ statlimits.marginused ?
                                        differentView(statlimits.marginused) : '0.00' }}</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
/* eslint-disable */
import { getMHoldings, getMMHoldings, getMPosotion, getMFholdings, getMOrderbook, getMLimits } from "../../components/mixins/getAPIdata.js";

import eventBus from "../../eventBus.js";

export default {

    data: () => ({
        loading: false,
        switchBus: null,
        holdingdata: [],
        positiondata: [],
        statholding: {},
        statposition: {},
        statorders: [],
        statlimits: {},

        holdstatavd: false,
        poststatavd: false,
    }),
    mounted() {
        eventBus.$on("statboard-event", () => {
            this.getStatboardapi();
        });
        eventBus.$on("web-scoketConn", (data, page) => {
            if (data && data.token && page == "stockDASH") {
                this.optionChainHoldDataParse(data);
                this.optionChainPosiDataParse(data);
            }
        });
        eventBus.$on("orderbook-update", (book) => {
            if (book == "port-order") {
                this.getStatboardapi();
            }
        });
        this.switchBus = eventBus;
    },
    methods: {
        setWebsocket(flow, data, is) {
            eventBus.$emit("web-scoketOn", flow, data, is, "stockDASH");
        },
        async getStatboardapi() {
            this.loading = true;
            this.getPositionbook();
            this.getHoldingbook();
            this.getOrderbook();
            this.getAllmargin();
            this.loading = false;
        },
        async getPositionbook() {
            let data = await getMPosotion();
            if (data && data.a && data.a.length > 0) {
                this.positiondata = data.a;
                this.statposition.open = data.o
                this.setWebsocket("sub", this.positiondata, "pos");
            }
        },
        async getHoldingbook() {
            let data = await getMHoldings();
            // let datamf = await getMMHoldings();
            if (data && data.response && data.response.length > 0) {
                this.holdingdata = Object.freeze([...data.response]);
                this.setWebsocket("sub", this.holdingdata, "hold");
            }
            // if (datamf && datamf.response && datamf.response.length > 0) {
            //     this.holdingdata.concat(datamf.response);
            //     this.setWebsocket("sub", datamf.response, "hold");
            // }
            // if (this.mfholds > 0) {
            //     let mfhold = await getMFholdings();
            //     if (mfhold && mfhold.data && mfhold.data.length > 0) {
            //         for (let q = 0; q < this.holdingdata.length; q++) {
            //             if (this.holdingdata[q].exchs === "MF") {
            //                 var s = mfhold.data.findIndex((o) => o.ISIN == this.holdingdata[q].isin);
            //                 this.holdingdata[q]["red"] = mfhold.data[s];
            //             }
            //         }
            //     }
            // }
        },
        async getOrderbook() {
            let response = await getMOrderbook();
            if (response && response.response) {
                this.statorders = response.stat
            }
        },
        async getAllmargin() {
            let limit = await getMLimits();
            if (limit && limit.stat == "Ok") {
                this.statlimits = limit;
            }
        },

        optionChainHoldDataParse(data) {
            let w = this.holdingdata.findIndex((o) => o.token == data.token);
            if (w >= 0) {
                if (this.holdingdata[w].token == data.token) {
                    if (this.holdingdata[w].exchs == "MF") {
                        data["lp"] = this.holdingdata[w].quotes && this.holdingdata[w].quotes.nav ? this.holdingdata[w].quotes.nav : 0;
                    }
                    this.holdingdata[w].ltp = this.holdingdata[w].exchs == "MF" ? Number(data.lp) : Number(data.lp).toFixed(2);
                    this.holdingdata[w]["inv"] = ((Number(this.holdingdata[w].upldprc) != 0 ? Number(this.holdingdata[w].upldprc) : this.holdingdata[w].ltp) * Math.abs(this.holdingdata[w].netqty)).toFixed(2);
                    this.holdingdata[w]["curr"] = (Number(data.lp) * Math.abs(this.holdingdata[w].netqty)).toFixed(2);
                    // this.holdingdata[w]['pre'] = (Number(data.lp) * Math.abs(this.holdingdata[w].netqty)).toFixed(2);
                    this.holdingdata[w]["pnl"] = (this.holdingdata[w]["curr"] - this.holdingdata[w]["inv"]).toFixed(2);
                    this.holdingdata[w]["pnlc"] = this.holdingdata[w]["inv"] > 0 ? ((this.holdingdata[w]["pnl"] / this.holdingdata[w]["inv"]) * 100).toFixed(2) : "0";
                    this.holdingdata[w]["d_pnl"] = ((Number(data.lp) - Number(data.prev_close_price)) * Math.abs(this.holdingdata[w].netqty)).toFixed(2);
                    this.holdingdata[w]["d_cpnl"] = ((this.holdingdata[w].d_pnl / this.holdingdata[w].inv) * 100).toFixed(2);
                }
                //   let statdata = this.holdingdata.filter((x) => (this.holdingtype == 0 ? x.exchs != "MF" : x.exchs == "MF"));
                let statdata = this.holdingdata;
                if (statdata) {
                    this.holdstatavd = true;

                    this.statholding["positive"] = statdata.filter((x) => x.pnlc > 0);
                    this.statholding["negative"] = statdata.filter((x) => x.pnlc < 0);

                    let stockvalue = statdata.reduce((acc, o) => acc + (Number(o.curr) ? Number(o.curr) : 0), 0);
                    this.statholding["stockvalue"] = stockvalue > 0 || stockvalue < 0 ? this.differentView(Math.abs(stockvalue)) : 0;
                    let invested = statdata.reduce((acc, o) => acc + (Number(o.inv) ? Number(o.inv) : 0), 0);
                    this.statholding["invested"] = invested > 0 || invested < 0 ? this.differentView(Math.abs(invested)) : 0;

                    let d_pnl = statdata.reduce((acc, o) => acc + (Number(o.d_pnl) ? Number(o.d_pnl) : 0), 0);
                    this.statholding["d_pnl"] = d_pnl > 0 || d_pnl < 0 ? Math.abs(d_pnl).toFixed(2) : 0;
                    this.statholding["d_cpnl"] = d_pnl > 0 || d_pnl < 0 ? ((statdata.reduce((acc, o) => acc + Number(o.d_pnl), 0) / statdata.reduce((acc, o) => acc + (Number(o.inv) ? Number(o.inv) : 0), 0)) * 100).toFixed(2) : 0;

                    let pnl = statdata.reduce((acc, o) => acc + (Number(o.pnl) ? Number(o.pnl) : 0), 0).toFixed(2);
                    this.statholding["pnl"] = pnl > 0 || pnl < 0 ? this.differentView(Math.abs(pnl)) : 0;
                    this.statholding["cpnl"] = pnl > 0 || pnl < 0 ? ((statdata.reduce((acc, o) => acc + (Number(o.pnl) ? Number(o.pnl) : 0), 0) / statdata.reduce((acc, o) => acc + (Number(o.inv) ? Number(o.inv) : 0), 0)) * 100).toFixed(2) : 0;

                    this.statholding["chp"] = ((statdata.reduce((acc, o) => acc + (Number(o.d_pnl) ? Number(o.d_pnl) : 0), 0) / statdata.reduce((acc, o) => acc + (Number(o.hp) ? Number(o.hp) : 0), 0)) * 100).toFixed(2);
                    this.statholding["clp"] = ((statdata.reduce((acc, o) => acc + (Number(o.d_pnl) ? Number(o.d_pnl) : 0), 0) / statdata.reduce((acc, o) => acc + (Number(o.lp) ? Number(o.lp) : 0), 0)) * 100).toFixed(2);

                    let tag = document.getElementById(`holdstatval`);
                    if (tag) {
                        tag.innerHTML = this.setFormatNumber(stockvalue);

                        document.getElementById(`holdstatP`).innerHTML = this.statholding.positive.length;
                        document.getElementById(`holdstatN`).innerHTML = this.statholding.negative.length;
                        document.getElementById(`holdstatinv`).innerHTML = this.setFormatNumber(this.statholding.invested);

                        document.getElementById(`holdstatpnl`).innerHTML = this.setFormatNumber(this.statholding.pnl);
                        document.getElementById(`holdstatpnlc`).innerHTML = this.statholding.cpnl;
                        eventBus.$emit("color-event", `holdstatpnlcclr`, this.statholding.cpnl > 0 ? "maingreen--text" : this.statholding.cpnl < 0 ? "mainred--text" : "subtext--text");
                        eventBus.$emit("color-event", `holdstatpnlcpclr`, this.statholding.cpnl > 0 ? "maingreen--text" : this.statholding.cpnl < 0 ? "mainred--text" : "subtext--text");
                    }
                    let tago = document.getElementById(`holdstatdpnl`);
                    if (tago) {
                        document.getElementById(`holdstatdpnl`).innerHTML = this.setFormatNumber(this.statholding.d_pnl);
                        document.getElementById(`holdstatdpnlc`).innerHTML = this.statholding.d_cpnl;
                        eventBus.$emit("color-event", `holdstatdpnlcclr`, this.statholding.d_cpnl > 0 ? "maingreen--text" : this.statholding.d_cpnl < 0 ? "mainred--text" : "subtext--text");
                        eventBus.$emit("color-event", `holdstatdpnlcpclr`, this.statholding.d_cpnl > 0 ? "maingreen--text" : this.statholding.d_cpnl < 0 ? "mainred--text" : "subtext--text");
                    }
                    this.statholding['p_advdec'] = Math.round((this.statholding.positive.length / this.holdingdata.length) * 100)
                    this.statholding['n_advdec'] = Math.round(100 - this.statholding.p_advdec)
                    if (document.getElementById(`holdstatavdp`) && document.getElementById(`holdstatavdn`)) {
                        document.getElementById(`holdstatavdp`).setAttribute("style", `width: ${this.statholding.p_advdec}% !important;`);
                        document.getElementById(`holdstatavdn`).setAttribute("style", `width: ${this.statholding.n_advdec}% !important;`);
                    }
                }
            }

        },
        optionChainPosiDataParse(data) {
            var mult = this.positiondata.filter((arr) => {
                return arr.token == data.token;
            });
            if (mult && mult.length > 0) {
                for (let a = 0; a < mult.length; a++) {
                    data["tokn"] = mult[a].tokn;
                    let w = this.positiondata.findIndex((o) => o.tokn == mult[a].tokn);
                    if (w >= 0 && this.positiondata[w].tokn == data.tokn) {
                        this.positiondata[w]["ltp"] = Number(data.lp).toFixed(2);
                        this.positiondata[w]["val"] = (Number(this.positiondata[w].avgprc) * Math.abs(Number(this.positiondata[w].netqty))).toFixed(2);

                        let p = this.positiondata[w];
                        let qtyis = Number(this.positiondata[w].netqty);
                        var cmtm;
                        var crpnl;

                        if (qtyis == 0) {
                            if (p.cfbuyqty != "0") {
                                crpnl = Number(p.daysellavgprc) * (Number(p.daysellqty) * Number(p.prcftr)) - ((Number(p.cfbuyqty) * Number(p.prcftr)) * Number(p.avgprc) + (Number(p.daybuyqty) * Number(p.prcftr)) * Number(p.daybuyavgprc));
                            } else if (p.cfsellqty != "0") {
                                crpnl = (Number(p.daysellqty) * Number(p.prcftr)) * Number(p.daysellavgprc) + (Number(p.cfsellqty) * Number(p.prcftr)) * Number(p.avgprc) - Number(p.daybuyavgprc) * (Number(p.daybuyqty) * Number(p.prcftr));
                            } else {
                                crpnl = p.rpnl;
                            }
                        } else {
                            var tempunpnl = Number(data.lp) - parseFloat(p.netupldprc || "0");
                            crpnl = Number(Number(tempunpnl) * (qtyis * p.prcftr) + Number(p.temprpnl)).toFixed(2);
                        }
                        if (["NFO", "BFO", "NSE", "BSE"].includes(p.exch)) {
                            cmtm = qtyis == 0 ? Number(p.crpnl) : (Number(data.lp) - Number(p.netavgprc)) * (qtyis * Number(p.prcftr)) + Number(p.crpnl);
                        } else {
                            cmtm = qtyis == 0 ? Number(p.crpnl) : (Number(data.lp) - Number(p.netavgprc)) * (Number(p.mult) * qtyis * Number(p.prcftr)) + Number(p.crpnl);
                        }

                        this.positiondata[w]["mtm"] = cmtm ? Number(cmtm).toFixed(2) : this.positiondata[w].mtm ? this.positiondata[w].mtm : cmtm;
                        this.positiondata[w]["rpnl"] = crpnl ? Number(crpnl).toFixed(2) : this.positiondata[w].rpnl ? this.positiondata[w].rpnl : crpnl;

                        var chgper = Number(p.avgprc) > 0 ? ((Number(p.avgprc) - Number(data.lp)) / Number(p.avgprc)) * 100 : 0;
                        this.positiondata[w]["pnlc"] = chgper ? Number(chgper).toFixed(2) : "0.00";
                    }
                }

                var statdata = this.positiondata;
                if (statdata && statdata.length > 0) {
                    this.statposition["positive"] = statdata.filter((x) => x.rpnl > 0);
                    this.statposition["negative"] = statdata.filter((x) => x.rpnl < 0);

                    this.statposition["close"] = statdata.filter((x) => x.netqty == 0);
                    this.statposition["open"] = statdata.filter((x) => x.netqty != 0);

                    let tradeval = statdata.reduce((acc, o) => acc + (Number(o.val) > 0 ? Number(o.val) : 0), 0);
                    this.statposition["tradeval"] = tradeval > 0 || tradeval < 0 ? this.differentView(Math.abs(tradeval)) : 0;

                    let oppnl = this.statposition["open"].reduce((acc, o) => acc + (Number(o.rpnl) ? Number(o.rpnl) : 0), 0);
                    this.statposition["oppnl"] = oppnl > 0 || oppnl < 0 ? this.differentView(Math.abs(oppnl)) : 0;
                    let opcpnl = oppnl > 0 || oppnl < 0 ? ((this.statposition["open"].reduce((acc, o) => acc + (Number(o.rpnl) ? Number(o.rpnl) : 0), 0) / this.statposition["open"].reduce((acc, o) => acc + (Number(o.val) > 0 ? Number(o.val) : 0), 0)) * 100).toFixed(2) : 0;
                    opcpnl = opcpnl != "Infinity" ? Number(opcpnl) : (0).toFixed(2);
                    this.statposition["opcpnl"] = opcpnl;

                    let pnl = statdata.reduce((acc, o) => acc + (Number(o.rpnl) ? Number(o.rpnl) : 0), 0);
                    this.statposition["pnl"] = pnl > 0 || (pnl < 0 && tradeval > 0) || tradeval < 0 ? this.differentView(pnl) : 0;
                    this.statposition["cpnl"] = (pnl > 0 || pnl < 0) && (tradeval > 0 || tradeval < 0) ? ((pnl / tradeval) * 100).toFixed(2) : 0;


                    let mtm = statdata.reduce((acc, o) => acc + (Number(o.mtm) ? Number(o.mtm) : 0), 0);
                    this.statposition["mtm"] = mtm ? this.differentView(mtm) : 0;

                    this.statposition["chp"] = ((statdata.reduce((acc, o) => acc + (Number(o.rpnl) ? Number(o.rpnl) : 0), 0) / statdata.reduce((acc, o) => acc + (Number(o.hp) ? Number(o.hp) : 0), 0)) * 100).toFixed(2);
                    this.statposition["clp"] = ((statdata.reduce((acc, o) => acc + (Number(o.rpnl) ? Number(o.rpnl) : 0), 0) / statdata.reduce((acc, o) => acc + (Number(o.lp) ? Number(o.lp) : 0), 0)) * 100).toFixed(2);
                    let tag = document.getElementById(`poststatval`);
                    if (tag) {
                        document.getElementById(`poststatmtm`).innerHTML = this.setFormatNumber(this.statposition.mtm);
                        eventBus.$emit("color-event", `poststatmtmclr`, this.statposition.mtm > 0 ? "maingreen--text" : this.statposition.mtm < 0 ? "mainred--text" : "subtext--text");

                        document.getElementById(`poststatval`).innerHTML = this.setFormatNumber(this.statposition.tradeval);

                        document.getElementById(`poststatP`).innerHTML = this.statposition.positive.length;
                        document.getElementById(`poststatN`).innerHTML = this.statposition.negative.length;

                        document.getElementById(`poststatpnl`).innerHTML = this.setFormatNumber(this.statposition.pnl);
                        // document.getElementById(`poststatpnlc`).innerHTML = this.statposition.cpnl;
                        eventBus.$emit("color-event", `poststatpnlclr`, pnl > 0 ? "maingreen--text" : pnl < 0 ? "mainred--text" : "subtext--text");
                        // eventBus.$emit("color-event", `poststatcpnlclr`, pnl > 0 ? "maingreen--text" : pnl < 0 ? "mainred--text" : "subtext--text");

                        document.getElementById(`poststatopnl`).innerHTML = this.setFormatNumber(this.statposition.oppnl);
                        // document.getElementById(`poststatopnlc`).innerHTML = this.statposition.opcpnl;
                        eventBus.$emit("color-event", `poststatopnlclr`, opcpnl > 0 ? "maingreen--text" : opcpnl < 0 ? "mainred--text" : "subtext--text");
                        // eventBus.$emit("color-event", `poststatocpnlclr`, opcpnl > 0 ? "maingreen--text" : opcpnl < 0 ? "mainred--text" : "subtext--text");
                    }
                    this.statposition['p_advdec'] = Math.round((this.statposition.positive.length / this.positiondata.length) * 100)
                    this.statposition['n_advdec'] = Math.round(100 - this.statposition.p_advdec)
                    if (document.getElementById(`poststatavdp`) && document.getElementById(`poststatavdn`)) {
                        document.getElementById(`poststatavdp`).setAttribute("style", `width: ${this.statposition.p_advdec}% !important;`);
                        document.getElementById(`poststatavdn`).setAttribute("style", `width: ${this.statposition.n_advdec}% !important;`);
                    }
                }
            }
        },
        differentView(view) {
            return view && Number(view) && (view > 0 || view < 0) ? Number(view).toFixed(2) : "0.00";
        },

        setFormatNumber(value) {
            if (value > 9999999) {
                return (value / 10000000).toFixed(2) + "Cr";
            } else if (value > 99999) {
                return (value / 100000).toFixed(2) + "L";
            } else if (value > 9999) {
                return (value / 1000).toFixed(2) + "K";
            } else if (value > 0) {
                return Number(value).toFixed(2);
            }
            if (value < 0) {
                return `-${this.setFormatNumber(Math.abs(value))}`;
            }
            return value;
        },
    }
}
</script>
